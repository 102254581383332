exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*\n * alignCheckbox\n * required to align label and input in the same line\n * !important display is required to override pt-control css\n * can be removed once Checkbox is updated to not use absolute positioning\n * ---------------------------------------------- */\n.UrgentCase__alignCheckbox___iY-HB {\n  align-items: center;\n  display: flex !important;\n}\n", ""]);

// exports
exports.locals = {
	"alignCheckbox": "UrgentCase__alignCheckbox___iY-HB"
};