import get from 'lodash/get';
import { createSelector } from 'reselect';

import { VisitNoteTemplate } from 'EntityTypes';
import { EntitiesById } from 'StoreTypes';

/**
 * Get all visit note templates, sorted by name.
 */
// eslint-disable-next-line import/prefer-default-export
export const getVisitNoteTemplates = createSelector(
  (state): EntitiesById<VisitNoteTemplate> =>
    get(state, ['entities', 'visitNoteTemplates', 'byId']),
  (visitNoteTemplatesById): VisitNoteTemplate[] => {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'VisitNoteTe... Remove this comment to see the full error message
    if (!visitNoteTemplatesById) return null;

    return Object.values(visitNoteTemplatesById).sort((a, b): number =>
      a.name.localeCompare(b.name),
    );
  },
);
