import React from 'react';
import styles from './EmergencyOnlyStatus.less';

export const EmergencyOnlyStatus = (): JSX.Element => {
  return (
    <div role="alert" className={styles.alert}>
      EMERGENCY ACCESS ONLY!!!
    </div>
  );
};
