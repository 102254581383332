import { normalize, schema } from 'normalizr';
import { Reference } from 'EntityTypes';

export const workspacesSchema = new schema.Entity('workspaces');

const referencesSchema = new schema.Entity(
  'references',
  {},
  { idAttribute: 'source_resource_type' },
);

export const normalizeReferences = (
  data: Reference[],
): {
  entities: {
    references: { [entityType: string]: Reference[] };
  };
} => normalize(data, [referencesSchema]);
