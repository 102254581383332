import React from 'react';
import { Tag } from '@el8/vital';
import { logEvent } from 'utils/amplitude';
import { NavbarItem, NavbarItemDropdownProps } from '../../components/NavbarItem';

const getMenuItems = (): NavbarItemDropdownProps['items'] => {
  const isPhysician = el8Globals.USER_STAFF_TYPE === 'Physician';

  const result: NavbarItemDropdownProps['items'] = [
    {
      type: 'link',
      href: '/population/?initialtab=patientlist',
      text: 'Patient List',
      'data-testid': 'navbar-reports-patient-list-link',
    },
  ];

  if (el8Globals.FEATURES.PatientCohort && el8Globals.IS_ADMIN) {
    result.push({
      type: 'link',
      href: '/population/?initialtab=patientcohort',
      text: 'Patient Cohorts',
      'data-testid': 'navbar-reports-patient-cohorts-link',
      onClick: (): void =>
        logEvent('Patient Cohorts - Access cohort page', {
          Source: 'Reports Dropdown in Elation Navigation',
        }),
    });
  }

  result.push({
    type: 'link',
    href: '/population/?initialtab=bulkletterdashboard',
    text: 'Bulk Letter Dashboard',
    target: '_blank',
    rel: 'noopener',
    'data-testid': 'navbar-reports-bulk-letter-dashboard-link',
  });

  if (el8Globals.FEATURES.BillingHomeEnabled) {
    result.push({
      type: 'link',
      href: '/billing',
      text: 'Billing Home',
      'data-testid': 'navbar-reports-billing-report-link',
    });
  } else {
    result.push({
      type: 'link',
      href: `/billing/physician/${isPhysician ? el8Globals.USER_PROFILE_ID : 'unassigned'}/`, // conditional missing
      text: 'Billing Report',
      'data-testid': 'navbar-reports-billing-report-link',
    });
  }

  if (el8Globals.FEATURES.PracticeStripeInt) {
    result.push({
      type: 'link',
      href: '/new-reports/patient-payment-report/',
      text: 'Patient Payment Report',
      'data-testid': 'navbar-reports-patient-payment-report-link',
    });
  }

  if (el8Globals.FEATURES.PracticeMemberships) {
    result.push({
      type: 'link',
      href: '/new-reports/practice-memberships-report/',
      text: 'Membership Report',
      'data-testid': 'navbar-reports-membership-report-link',
    });
  }

  const key = el8Globals.FEATURES.ReqPhyscnOnApptRprt ? 'select' : 'all';

  result.push({
    type: 'link',
    href: `/schedule-report/physician/${isPhysician ? el8Globals.USER_PROFILE_ID : key}/`,
    text: 'Appointment Report',
    target: '_blank',
    rel: 'noopener',
    'data-testid': 'navbar-reports-appointment-report-link',
  });

  if (el8Globals.FEATURES.EPA) {
    result.push({
      type: 'link',
      href: '/new-reports/worklist/',
      text: 'Prior Auth Worklist',
      'data-testid': 'navbar-reports-prior-auth-worklist-link',
    });
  }

  if (el8Globals.FEATURES.CareGapsV2) {
    result.push({
      type: 'link',
      href: '/population/?initialtab=caregapsreport',
      text: 'Care Gaps Report',
      'data-testid': 'navbar-reports-care-gaps-reports-link',
    });
  }

  if (el8Globals.FEATURES.InAppReports) {
    result.push({
      type: 'link',
      href: '/inappreports/?initialtab=appointmentdashboard',
      text: 'Appointment Dashboard',
      'data-testid': 'navbar-reports-embedded-reports-appt-dashboard-link',
    });

    result.push({
      type: 'link',
      href: '/inappreports/?initialtab=appointmentlist',
      text: 'Appointment List',
      'data-testid': 'navbar-reports-embedded-reports-appt-list-link',
    });

    result.push({
      type: 'link',
      href: '/inappreports/?initialtab=visitnotedashboard',
      text: 'Visit Note Dashboard',
      'data-testid': 'navbar-reports-embedded-reports-vn-dashboard-link',
    });

    result.push({
      type: 'link',
      href: '/inappreports/?initialtab=visitnotelist',
      text: 'Visit Note List',
      'data-testid': 'navbar-reports-embedded-reports-vn-list-link',
    });
  }

  if (el8Globals.FEATURES.MUReports) {
    result.push({
      type: 'link',
      href: '/population/?initialtab=cqmdashboard',
      text: 'Clinical Quality Measures',
      'data-testid': 'navbar-reports-clinical-quality-measures-link',
      rightAccessory: (
        <Tag label={el8Globals.FEATURES.CQMsForCPCPlus ? 'CPC+' : 'MIPS'} intent="menu-attribute" />
      ),
    });

    result.push({
      type: 'link',
      href: '/reports?tab=mu',
      text: 'Promoting Interoperability',
      'data-testid': 'navbar-reports-advancing-care-information-link',
      rightAccessory: <Tag label="MIPS" intent="menu-attribute" />,
    });
  }

  if (el8Globals.PRACTICE_STATE === 'OH') {
    result.push({
      type: 'link',
      href: '/rx-report-oh',
      text: 'Rx Report',
      'data-testid': 'navbar-reports-rx-report-oh-link',
    });
  }

  if (el8Globals.FEATURES.AWVDashboard) {
    result.push({
      type: 'link',
      href: '/population/?initialtab=annualwellness',
      text: 'Annual Wellness Report',
      'data-testid': 'navbar-annual-wellness-report-link',
    });
  }

  result.push({
    type: 'link',
    href: '/activity',
    text: 'User Activity Log',
    'data-testid': 'navbar-activity-log-link',
  });

  return result;
};

export const ReportsNavItem = (): JSX.Element => {
  return (
    <NavbarItem
      type="dropdown"
      items={getMenuItems()}
      data-testid="navbar-reports-button"
      onClick={(): void => logEvent('open: navigation reports menu')}
    >
      Reports
    </NavbarItem>
  );
};
