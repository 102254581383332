import { useEffect } from 'react';
import { useLocation, useNavigate } from '@reach/router';
import isEqual from 'lodash/isEqual';

import usePrevious from './usePrevious';

export type UrlQueryParamConfig = {
  /**
   * The name of the query string parameter to sync the value to.
   */
  param: string;

  /**
   * The value to sync to the current URL as a query string parameter.
   */
  value: string | number | boolean | null | undefined;
};

/**
 * A hook that syncs arbitrary values with the current page URL's query string.
 *
 * This hook must be used in a router-aware route component; otherwise, the behavior of this
 * hook is unspecified and may (probably) lead to errors.
 */
export default function useUrlQueryParamsSync(config: UrlQueryParamConfig[]): void {
  const location = useLocation();
  const navigate = useNavigate();

  const values = config.map((item) => item.value);
  const prevValues = usePrevious(values);

  useEffect(() => {
    if (!isEqual(prevValues, values)) {
      const searchParams = new URLSearchParams(location.search);

      config.forEach((item) => {
        if (item.value == null) {
          searchParams.delete(item.param);
        } else {
          searchParams.set(item.param, String(item.value));
        }
      });

      // Replace current entry in browser history stack with new URL
      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    }
  });
}
