import React from 'react';

import { Stack, Text2 } from '@el8/vital';
import { CaseIntakeStep } from 'modules/support/supportConstants';
import { CaseIntakeStepProps } from '../CaseIntakeTypes';
import CaseIntakeStepHeader from '../components/CaseIntakeStepHeader';
import styles from './CaseIntakeAIAnswerResolved.less';

const CaseIntakeAIAnswerResolved = ({ isInModal }: CaseIntakeStepProps): JSX.Element => {
  return (
    <Stack data-testid="support-ai-answer-resolved-container" gap="md" className={styles.content}>
      <CaseIntakeStepHeader isInModal={isInModal} step={CaseIntakeStep.IssueResolved} />
      <Text2 sizing="lg">
        We&apos;re thrilled to hear that your issue has been resolved! If you have any more
        questions, please check out the{' '}
        <Text2
          data-testid="support-aianswer-resolved-help-center-link"
          href="https://help.elationhealth.com/s/"
          rel="noopener noreferrer"
          tag="a"
          target="_blank"
          sizing="lg"
        >
          Help Center
        </Text2>
        .
      </Text2>
    </Stack>
  );
};

export default CaseIntakeAIAnswerResolved;
