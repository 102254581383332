import { logEvent, updateGroup } from './base';

// These group property updates should probably get moved to the back-
// end since they log info stored in the database, and don't track
// user actions

/**
 * 1. Logs an event, 2. Updates the '# lab vendors' practice group property.
 * NOTE: function name refers to 'integrations' even though the property is '# lab VENDORS'
 * because from a model POV we are counting the # of integrations.
 * @param {String} vendorName - display name of lab vendor
 * @param {Integer} numIntegrations - # of lab vendor integrations for the practice
 */
export const logIntegrationCreate = (vendorName, numIntegrations) => {
  logEvent('add lab vendor', {
    vendor: vendorName,
  });
  updateGroup('practice', el8Globals.PRACTICE_ID, {
    '# lab vendors': numIntegrations,
  });
};

/**
 * 1. Logs an event, 2. Updates the '# lab vendors' practice group property.
 * NOTE: function name refers to 'integrations' even though the property is '# lab VENDORS'
 * because from a model POV we are counting the # of integrations.
 * @param {String} vendorName - display name of lab vendor
 * @param {Integer} numIntegrations - # of lab vendor integrations for the practice
 */
export const logIntegrationDelete = (vendorName, numIntegrations) => {
  logEvent('delete lab vendor', {
    vendor: vendorName,
  });
  updateGroup('practice', el8Globals.PRACTICE_ID, {
    '# lab vendors': numIntegrations,
  });
};

/**
 * Log the user submitting a setup request.
 * NOTE: it's assumed that at least one of forOrders and forResults is true
 *
 * @param {string} vendorName - display name of lab vendor
 * @param {bool} forOrders - if the request included orders.
 * @param {bool} forResults - if the request included results
 */
export const logSetupIntegration = (vendorName, forOrders, forResults) => {
  let setupType;
  if (forOrders && forResults) {
    setupType = 'Orders & Results';
  } else {
    // has to be either orders or results
    setupType = forOrders ? 'Orders' : 'Results';
  }

  logEvent('start lab vendor setup', {
    vendor: vendorName,
    'setup type': setupType,
  });
};
