import React from 'react';
import { QueriesState } from 'redux-query';
import { Provider } from 'redux-query-react';

import { RootState } from 'StoreTypes';

const getQueries = (state: RootState): QueriesState => state.queries;

const ReduxQueryProvider: React.FC = ({ children }) => {
  return <Provider queriesSelector={getQueries}>{children}</Provider>;
};

export default ReduxQueryProvider;
