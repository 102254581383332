import { PatientFormNormalized } from 'EntityTypes';
import { PatientFormType } from 'constants/patientForms';

export const PATIENT_FORMS_TEMPLATES_DROPDOWN_NAMES: { [key: string]: string } = {
  blank: 'Blank',
  newPatientMedicalHistoryForm: 'New Patient Medical History Template',
  followUpMedicalHistoryForm: 'Follow-Up Medical History Template',
  demographicsForm: 'Demographics Template',
  insuranceInformationForm: 'Insurance Information Template',
  covid19ScreeningForm: 'COVID-19 Screening Template',
};

export const PATIENT_FORMS_TEMPLATES: {
  [key: string]: RecursivePartial<PatientFormNormalized> & {
    form_type: PatientFormType;
    name: string;
    title: string;
  };
} = {
  blank: {
    form_type: PatientFormType.MedicalHistory,
    name: '(Blank Form)',
    title: '(Blank Form)',
    topics: [],
  },
  newPatientMedicalHistoryForm: {
    form_type: PatientFormType.MedicalHistory,
    name: 'New Patient Medical History Form',
    title: 'Medical History Form',
    prompt: 'Please help us understand your medical history.',
    topics: [
      {
        concept: 'other',
        questions: [
          {
            prompt: 'What is the reason for your visit?',
            input: {
              placeholder_text: 'e.g. sore throat, annual checkup',
            },
          },
        ],
      },
      { concept: 'caffeine_use' },
      { concept: 'smoking_status' },
      { concept: 'alcohol_use' },
      { concept: 'drug_use' },
      { concept: 'exercise' },
      { concept: 'diet' },
      { concept: 'sleep' },
      { concept: 'family_history' },
      { concept: 'medical_history' },
      { concept: 'allergies' },
      { concept: 'medications' },
      { concept: 'surgical_history' },
      { concept: 'occupation' },
      { concept: 'place_of_birth' },
      { concept: 'colon_cancer_screening' },
      { concept: 'breast_cancer_screening' },
      { concept: 'depression_phq_2' },
      {
        concept: 'other',
        questions: [
          {
            prompt: 'Anything else you would like us to know?',
            input: {
              placeholder_text: '',
            },
          },
        ],
      },
    ],
  },
  followUpMedicalHistoryForm: {
    form_type: PatientFormType.MedicalHistory,
    name: 'Follow-Up Medical History Form',
    title: 'Medical History Form',
    prompt: 'Please help us understand your medical history.',
    topics: [
      {
        concept: 'other',
        questions: [
          {
            prompt: 'What is the reason for your visit?',
            input: {
              placeholder_text: 'e.g. sore throat, annual checkup',
            },
          },
        ],
      },
      { concept: 'exercise' },
      { concept: 'diet' },
      { concept: 'sleep' },
      { concept: 'medications' },
      {
        concept: 'other',
        questions: [
          {
            prompt: 'Any changes in your health since the last visit?',
            input: {
              placeholder_text: '',
            },
          },
        ],
      },
      {
        concept: 'other',
        questions: [
          {
            prompt: 'Anything else you would like us to know?',
            input: {
              placeholder_text: '',
            },
          },
        ],
      },
    ],
  },
  demographicsForm: {
    form_type: PatientFormType.MedicalHistory,
    name: 'Demographics Form',
    title: 'Patient Information',
    prompt:
      'For any question, if there have been no changes since your last visit, feel free to leave the answer blank. If this is your first visit with us, please answer all questions.',
    topics: [
      {
        concept: 'other',
        questions: [
          {
            prompt: 'Full Legal Name',
            input: {
              placeholder_text: 'e.g. Robert M. Smith',
            },
          },
        ],
      },
      {
        concept: 'other',
        questions: [
          {
            prompt: 'Nickname or Preferred Name',
            input: {
              placeholder_text: '',
            },
          },
        ],
      },
      {
        concept: 'other',
        questions: [
          {
            prompt: 'Sex, Gender Identity, Preferred Pronoun',
            input: {
              placeholder_text: 'e.g. female, she/her/hers',
            },
          },
        ],
      },
      {
        concept: 'other',
        questions: [
          {
            prompt: 'Home Address',
            input: {
              placeholder_text: 'e.g. 123 Main St., San Francisco, CA, 94109',
            },
          },
        ],
      },
      {
        concept: 'other',
        questions: [
          {
            prompt: 'What is your race?',
            input: {
              placeholder_text: 'e.g. Asian, Hispanic, African American, Caucasian, other',
            },
          },
        ],
      },
      {
        concept: 'other',
        questions: [
          {
            prompt: 'What is your primary language?',
            input: {
              placeholder_text: 'e.g. English, Spanish, etc',
            },
          },
        ],
      },
      {
        concept: 'other',
        questions: [
          {
            prompt: 'What is your mobile phone number?',
            input: {
              placeholder_text: 'e.g. 123-456-7890',
            },
          },
        ],
      },
      {
        concept: 'other',
        questions: [
          {
            prompt: 'Can your mobile phone do texting (SMS) and/or video calls?',
            input: {
              placeholder_text: 'e.g. texting and video',
            },
          },
        ],
      },
      {
        concept: 'other',
        questions: [
          {
            prompt: 'What is your home phone number?',
            input: {
              placeholder_text: 'e.g. 123-456-7890',
            },
          },
        ],
      },
      {
        concept: 'other',
        questions: [
          {
            prompt: 'What is your email address?',
            input: {
              placeholder_text: 'e.g. yourname@email.com',
            },
          },
        ],
      },
      {
        concept: 'other',
        questions: [
          {
            prompt: 'How do you prefer to be contacted? (list in order of preference)',
            input: {
              placeholder_text: 'e.g. (1) Text message, (2) phone, (3) email',
            },
          },
        ],
      },
      {
        concept: 'other',
        questions: [
          {
            prompt: 'May we leave you detailed messages with health information?',
            input: {
              placeholder_text: 'yes or no',
            },
          },
        ],
      },
      {
        concept: 'other',
        questions: [
          {
            prompt:
              'Who is your emergency contact and what is their relationship to you? What is their phone number?',
            input: {
              placeholder_text: 'e.g. Mary Smith, wife, 123-456-7890',
            },
          },
        ],
      },
      {
        concept: 'other',
        questions: [
          {
            prompt:
              'Were you referred to us by another provider? If so, by whom, and what is their phone number?',
            input: {
              placeholder_text: 'e.g. Dr. Greg Burns, 123-456-7890',
            },
          },
        ],
      },
      {
        concept: 'other',
        questions: [
          {
            prompt: 'What is your preferred pharmacy? (name and address)',
            input: {
              placeholder_text: 'e.g. Walgreens, 789 Main Street, San Francisco, CA 94109',
            },
          },
        ],
      },
    ],
  },
  insuranceInformationForm: {
    form_type: PatientFormType.MedicalHistory,
    name: 'Insurance Information Form',
    title: 'Insurance Information',
    prompt:
      'For any question, if there have been no changes since your last visit, feel free to leave the answer blank. If this is your first visit with us, please answer all questions.',
    topics: [
      {
        concept: 'other',
        questions: [
          {
            prompt:
              "What is your primary insurance policy? (Policyholder's name, date of birth, and relationship to you; Member ID number; Rx BIN, PCN, and Group ID or Rx Group if applicable)",
            input: {
              placeholder_text:
                'Ex. John Smith, 01/01/1980, Husband, Blue Cross Blue Shield of Arizona, Member 123456, Group 123456',
            },
          },
        ],
      },
      {
        concept: 'other',
        questions: [
          {
            prompt:
              "What is your secondary insurance policy? (Policyholder's name, date of birth, and relationship to you; Member ID number; Rx BIN, PCN, and Group ID or Rx Group if applicable)",
            input: {
              placeholder_text:
                'Ex. John Smith, 01/01/1980, Husband, Blue Cross Blue Shield of Arizona, Member 123456, Group 123456',
            },
          },
        ],
      },
    ],
  },
  covid19ScreeningForm: {
    form_type: PatientFormType.MedicalHistory,
    name: 'COVID-19 Screening Form',
    title: 'COVID-19 Screening',
    prompt:
      "We're going to ask you a few questions according to CDC guidelines to determine your risk for novel coronavirus (COVID-19).",
    topics: [
      {
        concept: 'other',
        questions: [
          {
            prompt:
              'In the past 14 days, have you had contact with anyone known to test positive for Coronavirus, COVID-19?',
            input: {
              placeholder_text: '',
            },
          },
        ],
      },
      {
        concept: 'other',
        questions: [
          {
            prompt:
              'Are you a healthcare worker who has been within 6 feet of a patient suspected of COVID-19 infection or have an occupation where you are in close contact with large numbers of people each day? (example: Health care Facility, Retail Store, Public Transit, Food Industry or Airports)',
            input: {
              placeholder_text: '',
            },
          },
        ],
      },
      {
        concept: 'other',
        questions: [
          {
            prompt:
              'Do you live with, or frequently interact with people who are older than 60 years of age or who have chronic medical conditions such as cancer, heart disease, COPD, asthma, or immune disorders?',
            input: {
              placeholder_text: '',
            },
          },
        ],
      },
      {
        concept: 'other',
        questions: [
          {
            prompt:
              'Which of the following symptoms do you currently have - Fever, Cough, Shortness of breath? (List all that apply)',
            input: {
              placeholder_text: '',
            },
          },
        ],
      },
      {
        concept: 'other',
        questions: [
          {
            prompt:
              'Are you having any of these additional symptoms - Loss of smell, sore throat, fatigue, loss of taste, sore muscles, runny nose, headache, diarrhea? (List all that apply)',
            input: {
              placeholder_text: '',
            },
          },
        ],
      },
      {
        concept: 'other',
        questions: [
          {
            prompt:
              'Do you have any underlying chronic medical conditions - Cancer, heart disease, ESRD, liver disease, history of immune disorder, chronic lung disease, immunosuppression, asthma, COPD, diabetes, pregnancy, hypertension? (List all that apply)',
            input: {
              placeholder_text: '',
            },
          },
        ],
      },
      {
        concept: 'other',
        questions: [
          {
            prompt: 'Did you get tested for COVID-19 anywhere else? If yes, what was the result?',
            input: {
              placeholder_text: '',
            },
          },
        ],
      },
      {
        concept: 'other',
        questions: [
          {
            prompt: 'Please provide your current zip code.',
            input: {
              placeholder_text: '',
            },
          },
        ],
      },
    ],
  },
};
