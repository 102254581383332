import { useRequest } from 'redux-query-react';
import { PracticeUser, UserInviteStatus, UserPermissionLogs } from 'EntityTypes';
import { UseDataInfo } from 'QueryTypes';
import { EntitiesById } from 'StoreTypes';
import useAppSelector from 'utils/hooks/useAppSelector';
import {
  adminInviteStatusQuery,
  practiceUsersQuery,
  userPermissionLogsQuery,
} from './practiceUsersQueries';
import {
  getActivePracticeUsersById,
  getPhysicianUsers,
  getPhysicianUsersById,
  getPhysicianUsersInWorkspace,
  getPracticeUsers,
  getPracticeUsersInWorkspace,
  selectPracticeUserInviteStatus,
} from './practiceUsersSelectors';

/**
 * A hook that returns the current user.
 */
export function useCurrentUser(): PracticeUser {
  const currentUser = useAppSelector((state) => state.user);

  return currentUser;
}

/**
 * A hook that fetches and returns an array of the current practice's active physician users,
 * sorted by full name. Additionally, if the current user has a default physician user set,
 * that physician user will be the first item in the array.
 * @param {{ isInWorkspace: boolean}} options
 */
export function usePhysicianUsers({ isInWorkspace = false } = {}): UseDataInfo<
  PracticeUser[] | undefined
> {
  const [queryState, refresh] = useRequest(practiceUsersQuery());
  const activePhysicianUsers = useAppSelector((state) => getPhysicianUsers(state));
  const activePhysicianUsersInWorkspace = useAppSelector(getPhysicianUsersInWorkspace);

  const physicianUsers = isInWorkspace ? activePhysicianUsersInWorkspace : activePhysicianUsers;
  return [physicianUsers, queryState, refresh];
}

/**
 * A hook that fetches ard returns the current practice's active physician users keyed by `id`.
 */
export function usePhysicianUsersById(): UseDataInfo<EntitiesById<PracticeUser> | undefined> {
  const [queryState, refresh] = useRequest(practiceUsersQuery());
  const activePhysicianUsersById = useAppSelector((state) => getPhysicianUsersById(state));
  return [activePhysicianUsersById, queryState, refresh];
}

/**
 * A hook that fetches and returns an array of the current practice's active users,
 * sorted by full name.
 *
 * @param {{ isInWorkspace: boolean}} options
 *
 */
export function usePracticeUsers({ isInWorkspace = false } = {}): UseDataInfo<PracticeUser[]> {
  const [queryState, refresh] = useRequest(practiceUsersQuery());
  const practiceUserSelector = isInWorkspace ? getPracticeUsersInWorkspace : getPracticeUsers;
  const practiceUsers = useAppSelector(practiceUserSelector);

  // @ts-expect-error ts-migrate(2322) FIXME: Type 'PracticeUser[] | undefined' is not assignabl... Remove this comment to see the full error message
  return [practiceUsers, queryState, refresh];
}

/**
 * A hook that fetches and returns the current practice's active practice users keyed by `id`.
 */
export function usePracticeUsersById(): UseDataInfo<EntitiesById<PracticeUser> | undefined> {
  const [_, queryState, refresh] = usePracticeUsers();
  const activePracticeUsersById = useAppSelector((state) => getActivePracticeUsersById(state));
  return [activePracticeUsersById, queryState, refresh];
}

/**
 * A hook that fetches and returns the specified practice user, even if they're inactive.
 */
export function usePracticeUser(
  userId: number | null,
): UseDataInfo<PracticeUser | null | undefined> {
  const [queryState, refresh] = useRequest(userId ? practiceUsersQuery(userId) : null);

  const practiceUser = useAppSelector((state) =>
    userId ? state.entities.practiceUsers?.byId?.[userId] : null,
  );

  return [practiceUser, queryState, refresh];
}

/**
 * A hook that fetches and returns the specified practice user's invite status.
 */
export function usePracticeUserInviteStatus(
  practiceId: number,
  userId: number,
): UseDataInfo<UserInviteStatus | null | undefined> {
  const [queryState, refresh] = useRequest(adminInviteStatusQuery(practiceId, userId));
  const inviteStatus = useAppSelector((state) => selectPracticeUserInviteStatus(state, userId));
  return [inviteStatus, queryState, refresh];
}

/**
 * A hook that fetches and returns the specified practice user's permission logs.
 */
export function usePracticeUserPermissionLogs(
  practiceId: number,
  userId: number | null,
): UseDataInfo<UserPermissionLogs | null | undefined> {
  const currentUser = useCurrentUser();
  const [queryState, refresh] = useRequest(
    userId && currentUser.isAdmin ? userPermissionLogsQuery(practiceId, userId) : null,
  );
  const inviteStatus = useAppSelector((state) =>
    userId && currentUser.isAdmin
      ? state.entities.practiceUsers?.permissionLogsByUserId?.[userId]
      : null,
  );
  return [inviteStatus, queryState, refresh];
}
