import { useEffect, useState } from 'react';

/**
 * Utility function to check if the current window media meets the given query
 *
 * @param {string} query
 * @returns {boolean}
 */
const matchQuery = (query: string): boolean => window.matchMedia(query).matches;

/**
 * Custom React Hook to check if the current window media meets the given query similar to CSS media queries.
 * The hook will re-run whenever the window is resized or when query changes.
 *
 * @param {string} query - The media query to check against
 * @returns {boolean}
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_media_queries/Using_media_queries
 */
export const useMediaMatches = (query: string): boolean => {
  const [matches, setMatches] = useState(matchQuery(query));

  useEffect(() => {
    const handleResize = (): void => {
      setMatches(matchQuery(query));
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [query]);

  return matches;
};

/**
 * Preset hook to check if the current window width is within the XS breakpoint (<=767px)
 * @returns {boolean}
 * @see https://vital.el8.io/tokens/breakpoints#tokens
 */
export const useIsXSBreakpoint = (): boolean => useMediaMatches('(max-width: 767px)');
