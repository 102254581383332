exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* RM-41564: Avoid sporadic print preview crash (Chrome error code: 11) when some elements\n * in some dialogs (e.g. Bill 2.0) overflow their `overflow: auto` containers.\n */\n@media print {\n  .LegacyFriendlyDialog__dialogBody___350pC {\n    overflow: visible !important;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"dialogBody": "LegacyFriendlyDialog__dialogBody___350pC"
};