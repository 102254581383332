import React from 'react';
import { Button2, ButtonGroup, DialogCloseButton, HeaderText, Stack, Text2 } from '@el8/vital';
import { Flexbox } from 'components/layout';
import { useCurrentUser } from 'modules/practice-users';
import { TrialViewProps } from '../../TrialActivationDialog';
import styles from './NoteAssistOfferView.less';

export const NoteAssistOfferView = ({ onActivate, onClose }: TrialViewProps): JSX.Element => {
  const { isAdmin } = useCurrentUser();
  return (
    <div className={styles.container}>
      <Flexbox justify="space-between" className={styles.header}>
        <HeaderText tag="h1">
          Activate your free trial of <strong>Note Assist</strong> today!
        </HeaderText>
        <DialogCloseButton onClick={onClose} />
      </Flexbox>
      <br />
      <Stack className={styles.content} gap="sm">
        <Text2>
          <strong>Note Assist</strong> is Elation’s AI-powered medical scribing and note generation
          solution. With <strong>Note Assist</strong>, providers can expect to{' '}
          <strong>save two hours daily</strong> while reducing documentation burden and after-hours
          work.
        </Text2>
        <Text2>
          <strong>Note Assist</strong> is available to all provider-level user accounts (e.g.,
          physicians, APCs, non-prescribing providers), and is embedded in the visit workflow,
          seamlessly incorporating ambient scribing capabilities into the encounter documentation
          process.
        </Text2>
        <Text2>
          Watch our short video below and review our{' '}
          <a
            href="https://help.elationhealth.com/s/article/Note-Assist-Guide"
            target="_blank"
            rel="noopener noreferrer"
          >
            Note&nbsp;Assist&nbsp;guide
          </a>{' '}
          for instructions and tips.
        </Text2>
        <div className={styles.video}>
          <iframe
            src="https://player.vimeo.com/video/1018105560?h=4d5273336b&badge=0&autopause=0&player_id=0&app_id=58479"
            allow="fullscreen"
            title="Note Assist Video"
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            sandbox="allow-scripts allow-same-origin allow-presentation"
            referrerPolicy="strict-origin"
          />
        </div>
        <Text2>
          The free trial of Note Assist can only be enabled by <strong>admin-level</strong> users.
          This free trial will automatically expire 5 days after activation. At the end of the free
          trial period, Elation will contact you to discuss next steps. There is no obligation to
          purchase.
        </Text2>
        <Text2>
          All provider-level users will be able to launch <strong>Note Assist</strong> by clicking
          the &quot;Scribe&quot; button within a visit note, and the workflow will also include a
          link to additional information and resources.tes
        </Text2>
      </Stack>
      <ButtonGroup className={styles.footer}>
        {isAdmin && <Button2 label="Start Free Trial" kind="primary" onClick={onActivate} />}
      </ButtonGroup>
    </div>
  );
};
