import { PatientDeletedDocument } from 'EntityTypes';
import DATE_FORMATS from 'constants/DATE_FORMATS';
import ModelClass from 'constants/ModelClass';
import { useGetReferencedPhysician } from 'modules/physicians';
import { capitalizeWords } from 'utils/strings/capitalizeWords';
import { practiceMoment } from 'utils/timezone';

/**
 * Formats the title of a deleted document. For `VisitNote`'s the
 * title includes a reference to an external physician if the `VisitNote`
 * was imported. We have to fetch the referenced physician in order
 * to display the correct title.
 * @param document The deleted document
 */
export function useDeletedDocumentTitle(document: PatientDeletedDocument): string {
  const { data: referencedPhysician } = useGetReferencedPhysician(
    document.model_class === ModelClass.VisitNote ? document.physician_id : undefined,
  );

  if (document.model_class === 'Report') {
    return capitalizeWords(document.report_type_name);
  }

  if (document.is_imported_note) {
    const date = practiceMoment(document.documentDate).format(DATE_FORMATS.DATE);

    if (referencedPhysician) {
      return capitalizeWords(
        `${document.type.name} by ${referencedPhysician.full_name} on ${date}`,
      );
    }
  }

  return capitalizeWords(document.type.name);
}
