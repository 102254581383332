import moment, { Moment } from 'moment-timezone';
import DATE_FORMATS from 'constants/DATE_FORMATS';

/**
 * Converts the given date into a Moment in the practice's locale. This
 * is primarily useful for preparing dates for display to the user, since we
 * want to show dates and times as if they were in the practice's timezone.
 *
 * NOTE: For our newer APIs that use ISO 8601 to format their dates, use `practiceMomentNew`
 * instead. Yes, it's dumb, but our API is currently in date-format-limbo. Or for timestamps
 * use `practiceMomentFromSecondsTimestamp`.
 *
 * @param date - the date. If this is a string, then the second
 * argument `dateFormat` will be used to parse the string.
 * @param dateFormat - the format of dateStr. Defaults to DATE_FORMATS.SERVER_DATETIME_FORMAT
 * @returns a Moment representing the given date in the practice's locale.
 */
export default function practiceMoment(
  date: string | Date,
  dateFormat = DATE_FORMATS.SERVER_DATETIME_FORMAT,
): Moment {
  if (typeof date === 'string') {
    return moment.tz(date, dateFormat, el8Globals.PRACTICE_LOCALE);
  }
  return moment.tz(date, el8Globals.PRACTICE_LOCALE);
}

/**
 * Python timestamps are in seconds, not milliseconds, and should have converted to UTC when being
 * made into a timestamp instead of a date object.
 *
 * @param timestamp a UTC timestamp in seconds (not milliseconds)
 * @returns a Moment representing the given date in the practice's locale.
 */
export function practiceMomentFromSecondsTimestamp(timestamp: number): Moment {
  return moment.tz(timestamp * 1000, el8Globals.PRACTICE_LOCALE);
}

/**
 * An extremely stupid function we need due to the backend not using consistent date formats.
 * This function is specifically for our newer APIs that use the ISO 8601 format. The end goal
 * should be to get rid of the original `practiceMoment`, which uses a different, more painful-to-use
 * format, and rename this to `practiceMoment` to take its place.
 */
export function practiceMomentNew(date: string | Date): Moment {
  return moment.tz(date, el8Globals.PRACTICE_LOCALE);
}
