import { QueryKey } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import merge from 'lodash/merge';

import { addQueryParams } from 'utils/http';

/**
 * Given a `react-query` query key, returns the corresponding URL for the query
 * according to our convention that maps between URLs and query keys. See
 * `AppQueryClient` for more info on the convention.
 */
export function getQueryUrl(
  queryKey: QueryKey,
  options: { trailingSlashUrl?: boolean } = {},
): string {
  const { trailingSlashUrl = true } = options;

  const pathSegments: (string | number)[] = [];
  const queryParams = {};

  queryKey.forEach((keyPart) => {
    if (isPlainObject(keyPart)) {
      merge(queryParams, keyPart);
    } else if (typeof keyPart === 'string' || typeof keyPart === 'number') {
      pathSegments.push(keyPart);
    }
  });

  let url = `/${pathSegments.join('/')}`;
  if (trailingSlashUrl) {
    url += '/';
  }
  if (!isEmpty(queryParams)) {
    url = addQueryParams(url, queryParams);
  }

  return url;
}
