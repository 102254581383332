import { NextStep as GeneratedCaseIntakeSteps } from '@elation/api-client/src/support';

enum ManualCaseIntakeSteps {
  Intake = 'intake',
  ContactInfo = 'contactInfo',
  CaseSubmitted = 'caseSubmitted',
  IssueResolved = 'issueResolved',
}

export const CaseIntakeStep = {
  ...GeneratedCaseIntakeSteps,
  ...ManualCaseIntakeSteps,
};

export type CaseIntakeStep = typeof CaseIntakeStep[keyof typeof CaseIntakeStep];
