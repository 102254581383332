import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { Physician, ReferencedPhysician } from 'EntityTypes';
import {
  AppQueryConfig,
  LocalPhysicianRequestBody,
  PracticePhysiciansRequestParams,
  PhysiciansResponseBody,
} from 'QueryTypes';
import { EntitiesById, EntitiesSlice, PhysiciansState } from 'StoreTypes';
import { mapArrayToObject } from 'utils/arrays';
import { addQueryParams } from 'utils/http';
import { makeUpdateFn } from 'utils/redux-query';

const urls = {
  localPhysician(): string {
    return '/connections/';
  },
  physiciansInPractice(practiceId: number, params?: PracticePhysiciansRequestParams): string {
    const url = `/practice/${practiceId}/physicians/`;
    if (params) return addQueryParams(url, params);
    return url;
  },
};

const queryKeys = {
  referencedPhysicians: (physicianId: number | null | undefined): QueryKey => [
    'referenced-physicians',
    physicianId,
  ],
};

/**
 * Fetch all active physicians in the specified practice.
 */
export const practicePhysiciansQuery = (
  practiceId: number,
  params?: PracticePhysiciansRequestParams,
): AppQueryConfig => ({
  url: urls.physiciansInPractice(practiceId, params),
  transform: (responseJson: PhysiciansResponseBody): { physicians: EntitiesById<Physician> } => ({
    physicians: mapArrayToObject(responseJson.results, 'id'),
  }),
  update: {
    physicians: makeUpdateFn(),
  },
});

export const localPhysicianQuery = (body: LocalPhysicianRequestBody): AppQueryConfig => ({
  url: urls.localPhysician(),
  body,
  transform: (responseJson: Physician): EntitiesSlice<'physicians'> => ({
    physicians: {
      byId: {
        [responseJson.id]: responseJson,
      },
    },
  }),
  update: {
    physicians: (prev, next): PhysiciansState => ({
      ...prev,
      byId: {
        ...prev?.byId,
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        ...next.byId,
      },
    }),
  },
});

/**
 * Fetches and returns the specified `ReferencedPhysician`.
 */
export const useGetReferencedPhysician = (
  physicianId: number | null | undefined,
  options?: UseQueryOptions<ReferencedPhysician>,
): UseQueryResult<ReferencedPhysician> => {
  return useQuery({
    queryKey: queryKeys.referencedPhysicians(physicianId),
    ...options,
    enabled: Boolean(physicianId) && options?.enabled !== false,
  });
};
