import React from 'react';
import { IconSize, VdsIcon } from '@el8/vital';
import { PRACTICE_VIEW_WORKSPACE_ID } from 'modules/workspaces';
import { logEvent } from 'utils/amplitude';
import { ScopedSentryErrorBoundary } from 'utils/sentry/ScopedSentryErrorBoundary';
import { NavbarItem, NavbarItemDropdownProps } from '../../components/NavbarItem';
import {
  UseWorkspaceContextSwitcherResult,
  useWorkspaceContextSwitcher,
} from '../../hooks/useWorkspaceContextSwitcher';

const getMenuItems = ({
  workspaces = [],
  currentWorkspace,
  handleSwitchWorkspace,
  isUserAdmin,
  returnToPracticeView,
}: UseWorkspaceContextSwitcherResult): NavbarItemDropdownProps['items'] => {
  const result: NavbarItemDropdownProps['items'] = workspaces.map((workspace) => {
    const isCurrent = workspace.id === currentWorkspace?.id;

    return {
      type: 'button',
      onClick: () => handleSwitchWorkspace(workspace),
      text: workspace.name,
      key: workspace.id,
      icon: (
        <VdsIcon
          icon="selected"
          size={IconSize.XSmall}
          style={{ visibility: isCurrent ? 'visible' : 'hidden' }}
        />
      ),
      'data-testid': `navbar-workspace-${workspace.name}-button`,
    };
  });

  if (isUserAdmin) {
    result.push({
      type: 'button',
      onClick: () => {
        returnToPracticeView();
      },
      text: 'Return to practice view',
      key: PRACTICE_VIEW_WORKSPACE_ID,
      'data-testid': 'navbar-workspace-practice-view-button',
    });
  }

  return result;
};

const WorkspaceContextSwitcherItemComponent = (): JSX.Element | null => {
  const workspaceContextSwitcher = useWorkspaceContextSwitcher();

  const { dropdownLabel, isPending, workspaces } = workspaceContextSwitcher;

  if (isPending) {
    return (
      <NavbarItem type="button" data-testid="navbar-workspace-button-loading">
        Loading...
      </NavbarItem>
    );
  }

  if (!workspaces || workspaces.length < 1) {
    return null;
  }

  return (
    <NavbarItem
      type="dropdown"
      items={getMenuItems(workspaceContextSwitcher)}
      data-testid="navbar-workspace-button"
      id="navbar-workspace-button"
      onClick={(): void => logEvent('open: navigation workspaces menu')}
    >
      {dropdownLabel}
    </NavbarItem>
  );
};

export const WorkspaceContextSwitcherItem = (): JSX.Element => {
  return (
    <ScopedSentryErrorBoundary
      withScope={(scope): void => {
        scope.setTag('fe_service', 'Navbar');
      }}
    >
      <WorkspaceContextSwitcherItemComponent />
    </ScopedSentryErrorBoundary>
  );
};
