import { AppQueryConfig } from 'QueryTypes';
import { UserExperiencesEntitiesState } from 'StoreTypes';
import UserExperience from 'constants/UserExperience';

const urls = {
  userExperience(userId: number, experience: UserExperience): string {
    return `/user-experiences/${userId}/experience/${experience}/`;
  },
  userExperiences(userId: number): string {
    return `/user-experiences/${userId}/`;
  },
};

export const getUserExperienceQuery = (
  userId: number,
  experience: UserExperience,
): AppQueryConfig => ({
  url: urls.userExperience(userId, experience),
  transform: (responseJson: {
    data: boolean;
  }): { userExperiences: { [experienceName: string]: boolean } } => ({
    userExperiences: { [experience]: responseJson.data },
  }),
  update: {
    userExperiences: (prevUserExperiences, newUserExperience): UserExperiencesEntitiesState => ({
      ...prevUserExperiences,
      byId: {
        ...prevUserExperiences?.byId,
        ...newUserExperience,
      },
    }),
  },
});
