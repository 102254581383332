import PropTypes from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const visitNoteTypeShape = PropTypes.shape({
  // model_class: 'VisitNoteType',
  is_mu_office_visit: PropTypes.bool,
  id: PropTypes.number.isRequired,
  rank: PropTypes.number,
  name: PropTypes.string,
});
