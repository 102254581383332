import { EntitiesById, GenericEntity, RootState } from 'StoreTypes';
import isNil from 'lodash/isNil';
import { createSelector, OutputSelector } from 'reselect';
import {
  WorkspaceFeFilteredResourceTypeNames,
  WorkspaceFeFilteredResourceTypes,
} from 'modules/workspace-filters/currentWorkspaceFiltersTypes';

/**
 * As long as your selector returns T[] | undefined, it should be safe to add here
 * FUTURE: will generic (...args) or similar allow any combination of res, res1, etc?
 */
type ImplementingOutputSelectors<T extends GenericEntity> =
  | OutputSelector<RootState, T[] | undefined, (res: T[] | undefined) => T[] | undefined>
  | OutputSelector<
      RootState,
      T[] | undefined,
      (res1: T[] | undefined, res2: number | null) => T[] | undefined
    >
  | OutputSelector<RootState, T[] | undefined, (res: EntitiesById<T>) => T[] | undefined>;

/**
 * For use in YOUR selector file, this generates a selector that will filter
 * by workspace if you have setup a query to tap into workspace frontend filters.
 */
export function makeSelectWorkspaceFilteredEntites<T extends WorkspaceFeFilteredResourceTypes>(
  selectUnfilteredEntities: ImplementingOutputSelectors<T>,
  selectorEntityType: WorkspaceFeFilteredResourceTypeNames,
) {
  return createSelector(
    (state: RootState) => selectUnfilteredEntities(state),
    (state: RootState) =>
      state.entities.currentWorkspaceFilters &&
      state.entities.currentWorkspaceFilters.filterToggles[selectorEntityType]
        ? state.entities.currentWorkspaceFilters.filterSets[selectorEntityType]
        : undefined,
    (unfilteredItems, filter) => {
      if (isNil(unfilteredItems) || isNil(filter)) return unfilteredItems;
      return unfilteredItems.filter((item) => filter.has(item.id));
    },
  );
}
