import { Answer, NextStep, Persona, QuestionCategories } from '@elation/api-client/src/support';
import { logEvent } from 'utils/amplitude';
import { CaseIntakeFormValues } from './CaseIntakeForm';

export const logStartSupportIntake = (userType: Persona): void => {
  logEvent(`Intake - Land on Intake Experience`, { 'User Type': userType });
};

export const logIntakeDetailsSubmitted = (
  userType: Persona,
  purpose?: QuestionCategories,
): void => {
  logEvent(`Intake - Submit Details`, { 'User Type': userType, Purpose: purpose });
};

const typeToAmplitudeString = {
  [NextStep.answered]: 'Help Center',
  [NextStep.create_case]: 'No AI Answer',
  [NextStep.direct_to_physician]: 'Contact Provider',
};
export const logIntakeAIResponded = (formValues: CaseIntakeFormValues, answer: Answer): void => {
  logEvent(`Intake - View AI Response`, {
    'User Type': formValues.persona,
    'Answer Type': typeToAmplitudeString[answer.next_step],
    Answer: answer.answer,
    Purpose: formValues.category,
  });
};

export const logIntakeAIAnswerResolved = (
  formValues: CaseIntakeFormValues,
  type: NextStep,
): void => {
  logEvent(`Intake - Confirmed AI Resolved Issue`, {
    'User Type': formValues.persona,
    'Answer Type': typeToAmplitudeString[type],
    Answer: formValues.answer,
    Purpose: formValues.category,
  });
};

export const logIntakeCreateTicket = (formValues: CaseIntakeFormValues, type: NextStep): void => {
  logEvent(`Intake - View Support Ticket Creation Fields`, {
    'User Type': formValues.persona,
    Purpose: formValues.category,
    'Answer Type': typeToAmplitudeString[type],
    Answer: formValues.answer,
  });
};

export const logIntakeTicketSubmitted = (formValues: CaseIntakeFormValues): void => {
  logEvent(`Intake - Submit ticket details`, {
    'User Type': formValues.persona,
    Purpose: formValues.category,
  });
};

export const logIntakeContactInfoSubmitted = (formValues: CaseIntakeFormValues): void => {
  logEvent(`Intake - Submit contact details`, {
    'User Type': formValues.persona,
    Purpose: formValues.category,
  });
};

export const logIntakeViewTicketConfirmation = (formValues: CaseIntakeFormValues): void => {
  logEvent(`Intake - View Submission Confirmation`, {
    'User Type': formValues.persona,
    Purpose: formValues.category,
  });
};

export const logIntakeExit = (): void => {
  logEvent('Intake - Exit');
};

export const logIntakeAddAttachment = (formValues: CaseIntakeFormValues): void => {
  logEvent('Intake - Add Attachment', {
    'User Type': formValues.persona,
    Purpose: formValues.category,
  });
};

export const logDemoLinkClicked = (): void => {
  logEvent(`Intake - Demo Link Clicked`);
};
