import { useRequest } from 'redux-query-react';
import { UseDataInfo } from 'QueryTypes';
import { useAppSelector } from 'utils/hooks';
import { AppointmentType } from 'EntityTypes';
import { appointmentTypesQuery } from './appointmentTypesQueries';
import { getAppointmentTypes } from './appointmentTypesSelectors';

/**
 * Fetches and selects appointment types for the given `practiceID`.
 */
export const useAppointmentTypes = (
  practiceId: number,
): UseDataInfo<AppointmentType[] | undefined> => {
  const [queryState, refresh] = useRequest(appointmentTypesQuery(practiceId));
  const appointmentTypes = useAppSelector(getAppointmentTypes);
  return [appointmentTypes, queryState, refresh];
};
