import { useAppSelector } from 'utils/hooks';
import { getProfilePhotoLastUpdated } from 'modules/practice-users';

/**
 * A hook that returns a cache-busted profile photo url for the specified user.
 * If the userId is not yet known, it will return an placeholder image based on
 * the passed in userType.
 */
export default function useProfilePhoto(
  userId: number | null,
  userType: 'staff' | 'physician',
  size = 125,
): string {
  const profilePhotoLastUpdated: number = useAppSelector(getProfilePhotoLastUpdated);
  return userId
    ? `/user/${userId}/photo/profile/${size}/${size}/?lastUpdated=${profilePhotoLastUpdated}`
    : `/static/images/avatars/unknown-${userType}-avatar.png`;
}
