import { DateSchema } from 'yup';

/**
 * An extension for yup's DateSchema to validate that a date is not in the future.
 *
 * Sample usage: `yup.date().pastOrToday().required()`
 */
export function pastOrToday(message = 'Must be today or earlier'): DateSchema {
  // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  return this.test('pastOrToday', message, function validatePastOrTodayDate(value: Date) {
    let isValid = true;

    if (value) {
      const now = new Date();
      isValid = value.valueOf() <= now.valueOf();
    }

    // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
    return isValid || this.createError({ path: this.path });
  });
}

/**
 * An extension for yup's DateSchema to validate that a date is in the future.
 *
 * Sample usage: `yup.date().future().required()`
 */
export function future(this: DateSchema, message = 'Must be in the future'): DateSchema {
  return this.test('future', message, function validateFutureDate(value: Date) {
    let isValid = true;

    if (value) {
      const now = new Date();
      isValid = value.valueOf() > now.valueOf();
    }

    return isValid || this.createError({ path: this.path, message });
  });
}
