import { Button2, Button2Props } from '@el8/vital';
import React from 'react';
import cn from 'classnames';
import { useIsXSBreakpoint } from 'utils/media/useMediaMatches';
import styles from './IntakeButton.less';

interface IntakeButtonProps extends Omit<Button2Props, 'className' | 'fill'> {
  className?: string;
}

/**
 * A button component with additional styles and behavior to accommodate the mobile view of the intake form on the /support page
 *
 * @param {string=} className
 * @param {IntakeButtonProps} props
 * @returns {JSX.Element}
 */
export const IntakeButton = ({ className, ...props }: IntakeButtonProps): JSX.Element => {
  const isSupportPage = window.location.pathname.includes('/support');
  const isXSBreakpoint = useIsXSBreakpoint();

  return (
    <Button2
      className={cn(isSupportPage ? styles.btnMobile : styles.btn, className)}
      fill={isXSBreakpoint}
      {...props}
    />
  );
};
