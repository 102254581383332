import React from 'react';
import { Group, VdsIconName, IconSize, VdsIcon } from '@el8/vital';
import cn from 'classnames';
import styles from './NavbarItem.less';
import { NavbarItemTooltip } from './NavbarItemTooltip';

export interface NavbarItemButtonProps
  extends Omit<
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    'onClick'
  > {
  type: 'button';
  icon?: VdsIconName | JSX.Element;
  rightIcon?: VdsIconName | JSX.Element;
  className?: string;
  text?: string;
  rightAccessory?: JSX.Element;
  tooltip?: string;
  disabled?: boolean;
  'data-testid': string;
  onClick?: () => void;
}

export const NavbarItemButton = ({
  children,
  icon,
  rightIcon,
  className,
  text,
  rightAccessory,
  tooltip,
  ...restProps
}: React.PropsWithChildren<Omit<NavbarItemButtonProps, 'type'>>): JSX.Element => {
  const renderIcon = (iconOrElement?: VdsIconName | JSX.Element): JSX.Element | null => {
    if (!iconOrElement) return null;

    if (typeof iconOrElement === 'string') {
      return <VdsIcon icon={iconOrElement} size={IconSize.XXSmall} />;
    }

    return iconOrElement;
  };

  return (
    <NavbarItemTooltip tooltip={tooltip}>
      <button
        {...restProps}
        type="button"
        className={cn(styles.button, className)}
        data-dd-privacy="allow"
      >
        <Group verticallyAlign="center" gap="2xs" className={styles.noWrap}>
          {renderIcon(icon)}
          <span className={styles.truncate}>{text ?? children}</span>
          {rightAccessory ?? renderIcon(rightIcon)}
        </Group>
      </button>
    </NavbarItemTooltip>
  );
};
