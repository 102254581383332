import isNil from 'lodash/isNil';
import { useMemo } from 'react';
import { useRequest } from 'redux-query-react';
import { Reference, Workspace } from 'EntityTypes';
import { UseDataInfo } from 'QueryTypes';
import { useAppSelector } from 'utils/hooks';
import { useCurrentPractice } from 'modules/practice';
import { aggregateQueryState } from 'utils/redux-query/queryHelpers';
import {
  getPracticeWorkspaces,
  makeSelectWorkspace,
  selectReferencesByWorkspaceId,
  selectUserCurrentWorkspace,
} from './workspacesSelectors';
import { workspacesQuery, userCurrentWorkspaceQuery, referencesQuery } from './workspacesQueries';

type QueryConfigOptions = {
  showAll?: boolean;
};

/**
 * A Hook for fetching and using the Workspaces belonging to a particular
 * practice that the current user has access to. For admin users (in the settings view only;
 * same restriction as non-admins in the context switcher view) this will
 * mean all of the workspaces in the practice. For non-admin users they
 * will have be to assigned via user-group to a workspace to access it.
 */
export const usePracticeWorkspaces = (
  practiceId: number,
  options: QueryConfigOptions = {},
): UseDataInfo<Workspace[] | undefined> => {
  const workspaces = useAppSelector(getPracticeWorkspaces);
  const [queryState, refreshWorkspaces] = useRequest(workspacesQuery(practiceId, options));
  return [workspaces, queryState, refreshWorkspaces];
};

/**
 * Fetches and retrieves the User's currently selected Workspace
 * (as chosen by the Workspace Switcher). Returns `null` when the User
 * explicitly does not have a workspace selected, and `undefined` when
 * no workspace is found (perhaps the query is still loading)
 */
export const useUserCurrentWorkspace = (): UseDataInfo<Workspace | null | undefined> => {
  const practice = useCurrentPractice();
  const [workspacesQueryState, _refreshWorkspaces] = useRequest(workspacesQuery(practice.id));
  const [currentWorkspaceQueryState, refreshCurrentWorkspace] = useRequest(
    userCurrentWorkspaceQuery(),
  );
  const currentWorkspace = useAppSelector(selectUserCurrentWorkspace);
  const queryState = aggregateQueryState([workspacesQueryState, currentWorkspaceQueryState]);
  return [currentWorkspace, queryState, refreshCurrentWorkspace];
};

/**
 * Retrieves a specific workspace by id
 */
export const useWorkspace = (
  practiceId: number,
  workspaceId: number,
  options: QueryConfigOptions = {},
): UseDataInfo<Workspace | undefined | null> => {
  const selectWorkspace = useMemo(makeSelectWorkspace, []);
  const workspace = useAppSelector((state) => selectWorkspace(state, workspaceId));
  const [_workspaces, queryState, refresh] = usePracticeWorkspaces(practiceId, options);
  // TODO: When the API Supports the single workspace GET operation, update this
  // to use that instead of filtering the list response for a particular id.
  // const [queryState, refresh] = useRequest(workspaceQuery(el8Globals.PRACTICE_ID, workspaceId));
  if (isNil(workspace) && queryState.isFinished) {
    // We don't get a 404 at the moment because we're not fetching a specific
    // workspace. We're fetching all the workspaces and then trying to find
    // a particular workspace in the list. If we don't find it we want to return
    // something other than undefined to indicate that the resource actually
    // doesn't exist.
    return [null, queryState, refresh];
  }
  return [workspace, queryState, refresh];
};

/**
 * Fetches and retrieves the References associated with a particular
 * workspace (workspaceId).
 *
 * If it's the actual reference data your interested (and not just
 * firing off the query) you probably want to use the Entity specific
 * version of this, like: `useStaffGroupReferencesInWorkspace` for
 * `StaffGroup`
 */
export const useWorkspaceReferences = (
  practiceId: number,
  workspaceId: number,
): UseDataInfo<Reference[] | undefined> => {
  const [queryState, refresh] = useRequest(referencesQuery(practiceId, workspaceId));
  const references = useAppSelector((state) => selectReferencesByWorkspaceId(state, workspaceId));
  return [references, queryState, refresh];
};
