import React from 'react';
import {
  Button,
  ButtonGroup,
  ButtonProps,
  Classes,
  HeaderText,
  Popover,
  Text,
  PopoverProps,
} from '@el8/vital';

export type ConfirmPopoverProps = MergeWithPriority<
  {
    cancelText?: string;
    confirmDisabled?: boolean;
    confirmKind?: ButtonProps['kind'];
    confirmText?: string;
    content: React.ReactNode;
    hideCancel?: boolean;
    onCancelClick?: () => void;
    onConfirmClick: () => void;
    title?: React.ReactNode;
    width?: number | string;
  },
  PopoverProps
>;

const ConfirmPopover: React.FC<ConfirmPopoverProps> = ({
  cancelText = 'Cancel',
  confirmDisabled = false,
  confirmKind = 'primary',
  confirmText,
  content,
  hideCancel = false,
  onCancelClick,
  onConfirmClick,
  target,
  title = 'Are you sure?',
  width = 400,
  ...rest
}) => {
  return (
    <Popover {...rest}>
      {target}
      <div style={{ width }}>
        <HeaderText
          tag="h2"
          style={{ marginBottom: 8 }}
          legacyVariant="legacyHeaderText"
          sizing="subheading-md"
        >
          {title}
        </HeaderText>
        <Text tag="div">{content}</Text>
        <ButtonGroup spaced style={{ marginTop: 12 }}>
          <Button
            disabled={confirmDisabled}
            className={Classes.POPOVER_DISMISS}
            kind={confirmKind}
            text={confirmText}
            onClick={onConfirmClick}
          />
          {!hideCancel && (
            <Button className={Classes.POPOVER_DISMISS} text={cancelText} onClick={onCancelClick} />
          )}
        </ButtonGroup>
      </div>
    </Popover>
  );
};

export default ConfirmPopover;
