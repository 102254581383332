import React from 'react';
import { logEvent } from 'utils/amplitude';
import { NavbarItem } from '../../components/NavbarItem';

export const LogoutItem = (): JSX.Element => {
  return (
    <NavbarItem
      type="button"
      onClick={(): void => {
        logEvent('log out');
        window.location.assign('/sso/logout');
      }}
      data-testid="navbar-logout-button"
      id="sign-out-trigger"
    >
      Logout
    </NavbarItem>
  );
};
