import { useState, useCallback } from 'react';
import isArray from 'lodash/isArray';

type SelectFns<T extends number | string> = {
  select: (newSelection: T[] | T) => void;
  deselect: (newSelection: T[] | T) => void;
  selectAll: (newSelection: T[]) => void;
  deselectAll: () => void;
  setSelected: (newSelection: T[]) => void;
};

const useSelect = <T extends number | string = number>(): [T[], SelectFns<T>] => {
  const [selected, setSelected] = useState<T[]>([]);

  const select = useCallback(
    (newSelection: T[] | T): void => {
      if (isArray(newSelection)) {
        setSelected([...selected, ...newSelection]);
      } else {
        setSelected([...selected, newSelection]);
      }
    },
    [selected, setSelected],
  );

  const deselect = useCallback(
    (deselectedIds: T[] | T): void => {
      if (isArray(deselectedIds)) {
        setSelected(selected.filter((id: T): boolean => !deselectedIds.includes(id)));
      } else {
        setSelected(selected.filter((id: T): boolean => id !== deselectedIds));
      }
    },
    [selected, setSelected],
  );

  const selectAll = useCallback(
    (selectedIds: T[]): void => setSelected([...selectedIds]),
    [setSelected],
  );

  const deselectAll = useCallback((): void => {
    setSelected([]);
  }, [setSelected]);

  return [
    selected,
    {
      select,
      deselect,
      selectAll,
      deselectAll,
      setSelected,
    },
  ];
};

export default useSelect;
