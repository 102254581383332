import { createSelector } from 'reselect';
import { getCurrentUser } from 'modules/practice-users';
import { getPracticesById } from 'modules/practices';
import { RootState, UserPracticesEntitiesState } from 'StoreTypes';

const getUserPractices = (state: RootState): UserPracticesEntitiesState =>
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'UserPracticesEntitiesState | undefined' is n... Remove this comment to see the full error message
  state.entities.userPractices;

export const getCurrentUserPractices = createSelector(
  getCurrentUser,
  getUserPractices,
  getPracticesById,
  (currentUser, userPractices, practicesById) => {
    if (!currentUser || !userPractices || !practicesById) return undefined;
    return userPractices.byUserId?.[currentUser.id]?.map((practiceId) => practicesById[practiceId]);
  },
);
