import { StaffType } from 'constants/index';
import { logEvent } from 'utils/amplitude';

type ContextSwitchSource = 'calendar' | 'context switcher' | 'patient search bar' | 'find chart';

export const logContextSwitchInitiated = (
  staffType: StaffType,
  source: ContextSwitchSource,
  origin: number,
  destination: number,
): void => {
  try {
    logEvent('context switch initiated', {
      source,
      'staff type': staffType,
      'origin id': origin,
      'destination id': destination,
    });
  } catch (err) {
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'
    console.warn(`Error while sending amplitude event: ${err.message}`);
  }
};
