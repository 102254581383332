import { createSlice } from '@reduxjs/toolkit';

export interface InviteColleagueDialogState {
  isOpen: boolean;
}

export interface ProviderSecurityCodeDialogState {
  isOpen: boolean;
}

export interface RestoreDeletedDocumentsDialogState {
  isOpen: boolean;
}

export interface NavbarSliceState {
  inviteColleagueDialog: InviteColleagueDialogState;
  providerSecurityCodeDialog: ProviderSecurityCodeDialogState;
  restoreDeletedDocumentsDialog: RestoreDeletedDocumentsDialogState;
}

const initialState: NavbarSliceState = {
  inviteColleagueDialog: {
    isOpen: false,
  },
  providerSecurityCodeDialog: {
    isOpen: false,
  },
  restoreDeletedDocumentsDialog: {
    isOpen: false,
  },
};

const slice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    openInviteColleagueDialog(state): void {
      state.inviteColleagueDialog.isOpen = true;
    },
    closeInviteColleagueDialog(state): void {
      state.inviteColleagueDialog = initialState.inviteColleagueDialog;
    },
    openProviderSecurityCodeDialog(state): void {
      state.providerSecurityCodeDialog.isOpen = true;
    },
    closeProviderSecurityCodeDialog(state): void {
      state.providerSecurityCodeDialog = initialState.providerSecurityCodeDialog;
    },
    openRestoreDeletedDocumentsDialog(state): void {
      state.restoreDeletedDocumentsDialog.isOpen = true;
    },
    closeRestoreDeletedDocumentsDialog(state): void {
      state.restoreDeletedDocumentsDialog = initialState.restoreDeletedDocumentsDialog;
    },
  },
});

const { actions, reducer: navbarReducer } = slice;

const {
  openInviteColleagueDialog,
  closeInviteColleagueDialog,
  openProviderSecurityCodeDialog,
  closeProviderSecurityCodeDialog,
  openRestoreDeletedDocumentsDialog,
  closeRestoreDeletedDocumentsDialog,
} = actions;

export {
  navbarReducer,
  openInviteColleagueDialog,
  closeInviteColleagueDialog,
  openProviderSecurityCodeDialog,
  closeProviderSecurityCodeDialog,
  openRestoreDeletedDocumentsDialog,
  closeRestoreDeletedDocumentsDialog,
};
