import { useCallback, useEffect, useRef } from 'react';

/**
 * Custom hook that returns a function to check if the component is currently mounted.
 * See https://usehooks-ts.com/react-hook/use-is-mounted
 */
export default function useIsMounted(): () => boolean {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
}
