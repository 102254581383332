import { Stack, Text2 } from '@el8/vital';
import { InputFormik } from '@el8/vital-formik';
import React from 'react';
import { useAppSelector, useFormikFormReset } from 'utils/hooks';

export const InviteColleagueDialogContent = (): JSX.Element => {
  const isOpen = useAppSelector((state) => state.navbar.inviteColleagueDialog.isOpen);

  useFormikFormReset(isOpen);

  return (
    <Stack>
      <Text2 tag="strong">
        Elation grows through your referrals. Let us know here if you have a colleague or peer
        who&apos;d love to learn more about Elation. Thank you for your support!
      </Text2>
      <InputFormik name="name" label="Colleague's Name" disablePasswordManagers />
      <InputFormik name="email" label="Colleague's Email" disablePasswordManagers />
      <InputFormik name="number" label="Colleague's Phone" disablePasswordManagers />
      <InputFormik name="specialty" label="Colleague's Specialty" disablePasswordManagers />
      <Text2 appearance="system" tag="i">
        Once you submit the form below, a representative from our team will reach out to your
        referral to learn more about their practice.
      </Text2>
    </Stack>
  );
};
