import { Moment } from 'moment-timezone';
import { useState, useCallback } from 'react';

import { practiceMoment } from 'utils/timezone';

import useInterval from './useInterval';

/**
 * A hook that returns the current time, as a Moment in the practice's timezone.
 * The current time is recalculated at a configurable interval.
 *
 * @param updateInterval the update interval in milliseconds. Defaults to 60000 (one minute).
 */
export default function useNowMoment(updateInterval = 60000): Moment {
  const [now, setNow] = useState(practiceMoment(new Date()));

  const refresh = useCallback(() => {
    setNow(practiceMoment(new Date()));
  }, []);

  useInterval(refresh, updateInterval);

  return now;
}
