import { Persona, QuestionCategories } from '@elation/api-client/src/support';
import { CaseIntakeStep } from 'modules/support/supportConstants';
import { CaseIntakeIssueOption } from '../CaseIntakeTypes';
import DemoRequest from '../components/DemoRequest';
import { CaseIntakeFormValues } from '../CaseIntakeForm';
import FeatureRequest from '../components/FeatureRequest';
import IntegrationRequest from '../components/IntegrationRequest';

export const ROLES = [
  { label: 'I am a patient or caretaker', value: Persona.passport_user },
  { label: 'I am a provider or staff member who uses Elation', value: Persona.ehr_user },
  { label: 'I received an Elation referral', value: Persona.connect_user },
  { label: 'I am a vendor', value: Persona.vendor },
  { label: 'I am requesting an Elation demo', value: Persona.prospect },
];

const GENERIC_ISSUE = { label: 'Report an issue', value: QuestionCategories.issue };
const ACCESS_ISSUE = { label: 'Cannot access account', value: QuestionCategories.access_issue };
const INTEGRATION_REQUEST = {
  label: 'Request an integration',
  value: QuestionCategories.integration_request,
};
const FEATURE_REQUEST = { label: 'Request a feature', value: QuestionCategories.feature_request };
const UNKNOWN = { label: 'Something else', value: QuestionCategories.something_else };
const SANDBOX_ACCESS_ISSUE = {
  label: 'Cannot access sandbox account',
  value: QuestionCategories.access_issue,
};
const SETUP_ENVIRONMENT = {
  label: 'Set up test environment',
  value: QuestionCategories.setup_environment,
};

type PersonaIssueOptions = {
  [key in Persona]?: CaseIntakeIssueOption[];
};

export const toIntakeIssues = (
  persona: Persona | undefined,
  authUser: boolean,
): CaseIntakeIssueOption[] => {
  const intakeIssueMap: PersonaIssueOptions = {
    [Persona.ehr_user]: [
      GENERIC_ISSUE,
      ...(!authUser ? [ACCESS_ISSUE] : []),
      INTEGRATION_REQUEST,
      FEATURE_REQUEST,
      UNKNOWN,
    ],
    [Persona.vendor]: [
      SANDBOX_ACCESS_ISSUE,
      SETUP_ENVIRONMENT,
      INTEGRATION_REQUEST,
      GENERIC_ISSUE,
      UNKNOWN,
    ],
  };
  return persona ? intakeIssueMap[persona] || [] : [];
};

// Map a Tuple of Persona and Category to the next step
export const toStepKey = ([persona, category]: [Persona, QuestionCategories | undefined]): string =>
  `${persona}-${category}`;

export const SupportIntakeInitialInfoMap = {
  [toStepKey([Persona.prospect, undefined])]: DemoRequest,
  [toStepKey([Persona.ehr_user, QuestionCategories.feature_request])]: FeatureRequest,
  [toStepKey([Persona.ehr_user, QuestionCategories.integration_request])]: IntegrationRequest,
  [toStepKey([Persona.vendor, QuestionCategories.integration_request])]: IntegrationRequest,
};

export const nextStepMap: Record<string, (values: CaseIntakeFormValues) => CaseIntakeStep> = {
  [toStepKey([Persona.ehr_user, QuestionCategories.integration_request])]: () =>
    CaseIntakeStep.ContactInfo,
  [toStepKey([Persona.ehr_user, QuestionCategories.feature_request])]: () =>
    CaseIntakeStep.ContactInfo,
};
