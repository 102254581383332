import { combineReducers } from 'redux';
import { entitiesReducer } from 'redux-query';
import { commonReducers } from 'utils/redux/common-reducers';
import queriesReducer from '../store/queriesReducer';

const rootReducer = combineReducers({
  ...commonReducers,
  // third party reducers
  entities: entitiesReducer,
  queries: queriesReducer,
});

export default rootReducer;
