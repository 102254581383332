import React from 'react';
import { IntakeButton } from './IntakeButton';

const NextStepButton: React.FC<{ isLoading: boolean; onNext: () => void }> = ({
  isLoading,
  onNext,
}): JSX.Element => {
  return (
    <IntakeButton
      data-testid="support-intake-form-next-button"
      disabled={isLoading}
      label="Next"
      kind="primary"
      onClick={onNext}
    />
  );
};

export default NextStepButton;
