exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AlertPopup__overlay___5cwMm {\n  background-color: rgba(0, 0, 0, 0.3);\n  display: block;\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: 7999;\n}\n.AlertPopup__container___2pEWn {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  padding: 15px;\n  border: 1px solid #8e0202;\n  border-radius: 6px;\n  box-shadow: var(--vds-elevation-2x, 0 3px 15px #000);\n  background-color: white;\n  font-size: 15px;\n  width: 480px;\n}\n.AlertPopup__title___1_D74 {\n  color: #900 !important;\n  margin: 10px !important;\n}\n.AlertPopup__content___2SvLT {\n  padding: 10px;\n}\n.AlertPopup__buttons___3g07b {\n  padding: 10px 10px 15px 10px;\n}\n", ""]);

// exports
exports.locals = {
	"overlay": "AlertPopup__overlay___5cwMm",
	"container": "AlertPopup__container___2pEWn",
	"title": "AlertPopup__title___1_D74",
	"content": "AlertPopup__content___2SvLT",
	"buttons": "AlertPopup__buttons___3g07b"
};