import { Practice } from 'EntityTypes';
import { useAppSelector } from 'utils/hooks';

import { getCurrentPractice } from './practiceSelectors';

export const useCurrentPractice = (): Practice => {
  const practice = useAppSelector(getCurrentPractice);

  return practice;
};
