import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PatientAccessError = {
  errorMessage: string;
};
export type AuthorizationStatusState = {
  patientAccessError: PatientAccessError | null;
};

export const initialState: AuthorizationStatusState = {
  patientAccessError: null,
};

const slice = createSlice({
  name: 'authorizationStatus',
  initialState,
  reducers: {
    openPatientAccessDeniedDialog(state, action: PayloadAction<string>): void {
      state.patientAccessError = {
        errorMessage: action.payload,
      };
    },
    dismissPatientAccessDeniedDialog(state): void {
      state.patientAccessError = null;
    },
  },
});

const { actions, reducer: authorizationReducer } = slice;
const { openPatientAccessDeniedDialog, dismissPatientAccessDeniedDialog } = actions;

export { authorizationReducer, openPatientAccessDeniedDialog, dismissPatientAccessDeniedDialog };
