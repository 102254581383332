import React from 'react';

type Props<T extends HTMLElement> = {
  containerRef?: React.Ref<T>;
  children?: React.ReactNode;
  className?: string;
  direction?: React.CSSProperties['flexDirection'];
  tag?: keyof JSX.IntrinsicElements;
  // 'start' and 'end' correspond to 'flex-start' and 'flex-end', respectively
  align?: 'baseline' | 'stretch' | 'start' | 'end' | 'center';
  justify?: 'start' | 'end' | 'center' | 'space-around' | 'space-between' | 'space-evenly';
  style?: React.CSSProperties;
} & React.HTMLProps<T>;

/**
 * Self explanatory
 */
function convertAlignmentPropToCssValue<T extends HTMLElement>(
  propValue: Props<T>['align'] | Props<T>['justify'],
): React.CSSProperties['alignItems'] | React.CSSProperties['justifyContent'] {
  if (propValue === 'start' || propValue === 'end') {
    return `flex-${propValue}`;
  }
  return propValue;
}

/**
 * A convenience/utility component that makes it easier to use flex containers,
 * particularly with aligning their content.
 */
const Flexbox = <T extends HTMLElement>({
  align,
  children,
  containerRef,
  justify,
  direction,
  style,
  className,
  tag: Tag,
  ...props
}: Props<T>): JSX.Element => {
  const finalStyle: React.CSSProperties = {
    ...style,
    display: 'flex',
    flexDirection: direction,
  };
  if (align) {
    finalStyle.alignItems = convertAlignmentPropToCssValue<T>(align);
  }
  if (justify) {
    finalStyle.justifyContent = convertAlignmentPropToCssValue<T>(justify);
  }

  return React.createElement(
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    Tag,
    {
      className,
      style: finalStyle,
      // Not using `React.forwardRef` because it totally screws up this component's generics
      ref: containerRef,
      ...props,
    },
    children,
  );
};
Flexbox.defaultProps = {
  direction: 'row',
  tag: 'div',
};

export default Flexbox;
