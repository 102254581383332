exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProviderSecurityCodeDialogContent__securityCodeCopyText___1wEab {\n  align-self: center;\n  padding: var(--vds-padding-2xs) var(--vds-padding-xs);\n  border: 1px solid var(--vds-border-color-300);\n}\n.ProviderSecurityCodeDialogContent__securityCodeCopyText___1wEab:hover {\n  border: 1px solid var(--vds-border-color-300);\n}\n.ProviderSecurityCodeDialogContent__securityCode___29fCM {\n  font: var(--vds-font-mono-600-semi);\n}\n", ""]);

// exports
exports.locals = {
	"securityCodeCopyText": "ProviderSecurityCodeDialogContent__securityCodeCopyText___1wEab",
	"securityCode": "ProviderSecurityCodeDialogContent__securityCode___29fCM"
};