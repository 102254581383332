exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Spacer__spacer___ikwcv.Spacer__xsmall___2UHwE {\n  height: 4px;\n}\n.Spacer__spacer___ikwcv.Spacer__small___1n96J {\n  height: 8px;\n}\n.Spacer__spacer___ikwcv.Spacer__medium___1y4sC {\n  height: 12px;\n}\n.Spacer__spacer___ikwcv.Spacer__large___3GUca {\n  height: 16px;\n}\n.Spacer__spacer___ikwcv:first-child,\n.Spacer__spacer___ikwcv:last-child,\n.Spacer__spacer___ikwcv + .Spacer__spacer___ikwcv {\n  display: none;\n}\n", ""]);

// exports
exports.locals = {
	"spacer": "Spacer__spacer___ikwcv",
	"xsmall": "Spacer__xsmall___2UHwE",
	"small": "Spacer__small___1n96J",
	"medium": "Spacer__medium___1y4sC",
	"large": "Spacer__large___3GUca"
};