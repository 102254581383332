exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BaseMindreader__menuItem___K9zXE .pt-menu-item-label {\n  overflow: hidden;\n}\n.BaseMindreader__tagsContainer___1VvZ2 .BaseMindreader__tag___1LI-u {\n  margin-left: var(--vds-space-2xs, 4px);\n}\n.BaseMindreader__tag___1LI-u {\n  border-radius: var(--vds-border-radius-max, 20px);\n  border: 1px solid;\n  display: inline-block;\n  font-size: var(--vds-font-base-size-75, 12px);\n  padding: 0 0.3125rem;\n}\n.BaseMindreader__description___2XPj0 {\n  color: rgba(57, 69, 79, 0.7);\n  margin-top: 0.1875rem;\n}\n.BaseMindreader__description___2XPj0.BaseMindreader__active___3oIRg {\n  color: white;\n}\n", ""]);

// exports
exports.locals = {
	"menuItem": "BaseMindreader__menuItem___K9zXE",
	"tagsContainer": "BaseMindreader__tagsContainer___1VvZ2",
	"tag": "BaseMindreader__tag___1LI-u",
	"description": "BaseMindreader__description___2XPj0",
	"active": "BaseMindreader__active___3oIRg"
};