exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  --vds-navigation-height: 2rem;\n}\n.ElationLogoFull__li___cGbBi {\n  align-self: center;\n  align-items: stretch;\n  display: flex;\n  margin-inline-end: var(--vds-space-xs);\n  height: calc(var(--vds-navigation-height) * .70);\n  z-index: 2;\n}\na.ElationLogoFull__a___3x5r- {\n  --vds-elation-badge-surface: transparent;\n  background: var(--vds-navigation-surface);\n  background-color: var(--vds-navigation-surface);\n}\na.ElationLogoFull__a___3x5r-:visited,\na.ElationLogoFull__a___3x5r- a:link {\n  background-color: var(--vds-navigation-surface);\n}\na.ElationLogoFull__a___3x5r-:focus-visible {\n  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.24);\n  outline: 3px solid transparent;\n}\n.ElationLogoFull__svg___1SJpZ {\n  height: calc(var(--vds-navigation-height) * .70);\n  width: calc(var(--vds-navigation-height) * 2.1);\n}\n", ""]);

// exports
exports.locals = {
	"li": "ElationLogoFull__li___cGbBi",
	"a": "ElationLogoFull__a___3x5r-",
	"svg": "ElationLogoFull__svg___1SJpZ"
};