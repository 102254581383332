/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Support Intake and Communication
 * This API defines the endpoints dealing with Customer feedback/support requests.

 * OpenAPI spec version: 0.1.0
 */

export type NextStep = typeof NextStep[keyof typeof NextStep];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NextStep = {
  answered: 'answered',
  direct_to_physician: 'direct_to_physician',
  create_case: 'create_case',
} as const;
