exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RestoreDeletedDocumentsDialog__dialog___18__G {\n  padding-bottom: var(--vds-padding-md);\n}\n", ""]);

// exports
exports.locals = {
	"dialog": "RestoreDeletedDocumentsDialog__dialog___18__G"
};