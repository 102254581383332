import { useState, useMemo, useCallback } from 'react';

import { ListAPIResponseBody } from 'QueryTypes';
import { EntitiesById, RootState } from 'StoreTypes';

import useAppSelector from './useAppSelector';

type PaginationInfo<TDatum> = Omit<ListAPIResponseBody<TDatum>, 'results'> & {
  ids: number[];
};

export type UsePaginatedDataBag<TDatum> = {
  data: TDatum[];
  fetchFirstPage: () => void;
  fetchNextPage: () => void;
  fetchPrevPage: () => void;
  isBusy: boolean;
  paginationInfo: PaginationInfo<TDatum>;
  setPaginationInfo: (info: PaginationInfo<TDatum>) => void;
};

/**
 * A utility hook that simplifies working with paginated data (for example, with
 * tables). Manages pagination state, table data, and first/prev/next page handling.
 *
 * @param params.entitiesByIdSelector - a selector that accepts the `RootState` object and
 * returns the `byId` lookup dictionary for the type of data used by this hook.
 * @param params.fetchPage - a function that accepts an optional URL and fetches a first
 * page of results, returning the `ListAPIResponseBody` from the fetch (or null/undefined if
 * the request errored). Pages after the first page should be fetchable by passing in the
 * optional URL argument, which usually would come from `previous_page_url`/`next_page_url` in
 * a prior server response.
 */
export default function usePaginatedData<TDatum extends { id: number }>(params: {
  entitiesByIdSelector: (state: RootState) => EntitiesById<TDatum> | undefined;
  fetchPage: (url?: string) => Promise<ListAPIResponseBody<TDatum> | null | undefined>;
}): UsePaginatedDataBag<TDatum> {
  const { entitiesByIdSelector, fetchPage } = params;

  const [isBusy, setIsBusy] = useState(false);

  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo<TDatum>>({
    current_page_num: 0,
    next_page_url: null,
    page_last_item_num: 0,
    page_first_item_num: 0,
    previous_page_url: null,
    total_item_count: 0,
    total_page_count: 0,
    ids: [],
  });

  const entitiesById = useAppSelector(entitiesByIdSelector);
  const data: TDatum[] = useMemo(() => {
    if (!entitiesById) return [];

    return paginationInfo.ids.map((id) => entitiesById[id]).filter(Boolean);
  }, [entitiesById, paginationInfo.ids]);

  const processFetchResponse = useCallback(
    (resBody: ListAPIResponseBody<TDatum> | null | undefined) => {
      if (!resBody) return;

      const { results, ...rest } = resBody;
      setPaginationInfo({ ...rest, ids: results.map((result) => result.id) });
    },
    [],
  );

  const clearBusy = useCallback(() => setIsBusy(false), []);

  const fetchFirstPage = useCallback((): void => {
    setIsBusy(true);
    fetchPage().then(processFetchResponse).finally(clearBusy);
  }, [clearBusy, fetchPage, processFetchResponse]);

  const fetchPrevPage = useCallback((): void => {
    if (!paginationInfo.previous_page_url) return;

    setIsBusy(true);
    fetchPage(paginationInfo.previous_page_url).then(processFetchResponse).finally(clearBusy);
  }, [clearBusy, fetchPage, paginationInfo.previous_page_url, processFetchResponse]);

  const fetchNextPage = useCallback((): void => {
    if (!paginationInfo.next_page_url) return;

    setIsBusy(true);
    fetchPage(paginationInfo.next_page_url).then(processFetchResponse).finally(clearBusy);
  }, [clearBusy, fetchPage, paginationInfo.next_page_url, processFetchResponse]);

  return {
    data,
    fetchFirstPage,
    fetchNextPage,
    fetchPrevPage,
    isBusy,
    paginationInfo,
    setPaginationInfo,
  };
}
