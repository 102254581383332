import flatten from 'lodash/flatten';
import findIndex from 'lodash/findIndex';
import head from 'lodash/head';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import reject from 'lodash/reject';
import { normalize } from 'normalizr';
import { Reference, Workspace } from 'EntityTypes';
import { createOptimisticId } from 'utils/redux-query/queryHelpers';
import {
  AppQueryConfig,
  CreateWorkspaceRequestBody,
  UpdateWorkspaceRequestBody,
  ReferencesResponse,
  CreateReferenceBody,
  CurrentWorkspaceResponse,
} from 'QueryTypes';
import {
  EntitiesById,
  EntitiesSlice,
  ReferenceEntitiesState,
  WorkspacesEntitiesState,
} from 'StoreTypes';
import { mapArrayToObject } from 'utils/arrays';
import {
  makeDeleteFnsNew,
  makeOptimisticUpdateFn,
  makeRollbackFn,
  makeUpdateFn,
} from '../../utils/redux-query';
import { workspacesSchema } from './workspacesSchemas';

type QueryConfigOptions = {
  showAll?: boolean;
};

const urls = {
  workspaces(practiceId: number, options: QueryConfigOptions = {}): string {
    const { showAll } = options;
    const args = showAll ? `?show_all=${showAll}` : '';
    return `/practice/${practiceId}/workspaces/${args}`;
  },
  workspace(practiceId: number, workspaceId: number): string {
    return `/practice/${practiceId}/workspaces/${workspaceId}/`;
  },
  references(practiceId: number, workspaceId: number): string {
    return `/practice/${practiceId}/workspaces/${workspaceId}/references/`;
  },
  reference(practiceId: number, workspaceId: number, referenceId: number): string {
    return `/practice/${practiceId}/workspaces/${workspaceId}/references/${referenceId}/`;
  },
  userCurrentWorkspace(): string {
    return `/user-preferences/workspace/`;
  },
};

export const workspacesQuery = (
  practiceId: number,
  options: QueryConfigOptions = {},
): AppQueryConfig => ({
  url: urls.workspaces(practiceId, options),
  transform: (responseJson: Workspace[]): { workspaces: EntitiesById<Workspace> } =>
    normalize(responseJson, [workspacesSchema]).entities,
  update: {
    workspaces: makeUpdateFn<Workspace>(),
  },
});

/**
 * Query to create a new Workspace.
 * @param practiceId
 * @param body The Workspace Metadata. Does not include references.
 */
export const createWorkspaceQuery = (
  practiceId: number,
  body: CreateWorkspaceRequestBody,
): AppQueryConfig => ({
  url: urls.workspaces(practiceId),
  meta: { autoCaseKeys: false },
  body,
  transform: (responseJson: Workspace): EntitiesSlice<'workspaces'> => {
    return {
      workspaces: {
        [responseJson.id]: responseJson,
      },
    };
  },
  update: {
    workspaces: makeUpdateFn(),
  },
});

/**
 * Query to update an existing Workspace by the provided workspaceId.
 * Performs an Optimistic Update.
 * @param practiceId
 * @param workspaceId
 * @param body Workspace Metadata. Does not include References.
 */
export const updateWorkspaceQuery = (
  practiceId: number,
  workspaceId: number,
  body: UpdateWorkspaceRequestBody,
): AppQueryConfig => ({
  url: urls.workspace(practiceId, workspaceId),
  meta: { autoCaseKeys: false },
  body,
  options: { method: 'PATCH' },
  transform: (responseJson: Workspace): EntitiesSlice<'workspaces'> => {
    return {
      workspaces: {
        [responseJson.id]: responseJson,
      },
    };
  },
  update: {
    workspaces: makeUpdateFn(),
  },
  optimisticUpdate: {
    // @ts-expect-error FIXME: Fix the utility function types
    workspaces: makeOptimisticUpdateFn<Workspace>(workspaceId, body),
  },
  rollback: {
    // @ts-expect-error FIXME: Fix the utility function types
    workspaces: makeRollbackFn<Workspace>(workspaceId, body),
  },
});

/**
 * Query to Delete a Workspace with the provided workspaceId. Performs
 * an Optimistic Update.
 */
export const deleteWorkspaceQuery = (practiceId: number, workspaceId: number): AppQueryConfig => {
  const { update, optimisticUpdate, rollback } =
    makeDeleteFnsNew<WorkspacesEntitiesState>(workspaceId);
  return {
    url: urls.workspace(practiceId, workspaceId),
    options: { method: 'DELETE' },
    update: {
      workspaces: update,
    },
    optimisticUpdate: {
      workspaces: optimisticUpdate,
    },
    rollback: {
      workspaces: rollback,
    },
  };
};

export const userCurrentWorkspaceQuery = (): AppQueryConfig => ({
  url: urls.userCurrentWorkspace(),
  transform: (responseJson: CurrentWorkspaceResponse): EntitiesSlice<'workspaces'> => {
    const { id } = responseJson;
    return {
      workspaces: {
        // Current API returning 0 for "No Selected Workspace", we don't want
        // to store things in the redux store in an inconsistent way though.
        currentWorkspace: id === 0 ? null : id,
      },
    };
  },
  update: {
    workspaces: (prevValue, newValue): WorkspacesEntitiesState => {
      return {
        ...prevValue,
        currentWorkspace: newValue?.currentWorkspace,
      };
    },
  },
});

export const workspaceContextSwitchQuery = (workspaceId: number | null): AppQueryConfig => {
  // For some reason the API uses zero to represent not having a current
  // workspace. We want to insulate consumers from this, and present an
  // interface that looks similar to other sections of the application
  // i.e. Using `null`.
  const id = isNil(workspaceId) ? 0 : workspaceId;
  return {
    url: urls.userCurrentWorkspace(),
    options: { method: 'POST' },
    body: { id },
  };
};

export const referencesQuery = (practiceId: number, workspaceId: number): AppQueryConfig => ({
  url: urls.references(practiceId, workspaceId),
  transform: (responseJson: ReferencesResponse): EntitiesSlice<'references'> => {
    const references = flatten(Object.values(responseJson));
    const referenceIds = references.map((r) => r.id);
    const referencesById = mapArrayToObject(references, 'id');

    return {
      references: {
        byId: referencesById,
        byWorkspaceId: {
          [workspaceId]: referenceIds,
        },
      },
    };
  },
  update: {
    references: (prevValue, references): ReferenceEntitiesState => {
      const newRefsByWorkspaceId = references?.byWorkspaceId?.[workspaceId] || [];
      return {
        ...prevValue,
        byId: {
          ...prevValue?.byId,
          ...references?.byId,
        },
        byWorkspaceId: {
          ...prevValue?.byWorkspaceId,
          [workspaceId]: newRefsByWorkspaceId,
        },
      };
    },
  },
});

export const createReferenceQuery = (
  practiceId: number,
  workspaceId: number,
  body: CreateReferenceBody,
  optimistic = true,
): AppQueryConfig => {
  const optimisticId = createOptimisticId();
  return {
    url: urls.references(practiceId, workspaceId),
    meta: { autoCaseKeys: false },
    body,
    transform: (responseJson: Reference): EntitiesSlice<'references'> => {
      return {
        references: {
          byId: {
            [responseJson.id]: responseJson,
          },
          byWorkspaceId: {
            [workspaceId]: [responseJson.id],
          },
        },
      };
    },
    update: {
      references: (prevReferences, newReferences): ReferenceEntitiesState => {
        const prevIdsByWorkspace = prevReferences?.byWorkspaceId?.[workspaceId] || [];
        const newIdsByWorkspace = newReferences?.byWorkspaceId?.[workspaceId] || [];
        let prevReferencesById = prevReferences?.byId;
        let finalIdsByWorkspace;

        const prevOptimisticIdIndex = findIndex(prevIdsByWorkspace, (id) => id === optimisticId);
        const newId = head(newReferences?.byWorkspaceId?.[workspaceId]);
        if (prevOptimisticIdIndex !== -1 && !isNil(newId)) {
          // Optimistic Update
          finalIdsByWorkspace = [...prevIdsByWorkspace];
          finalIdsByWorkspace.splice(prevOptimisticIdIndex, 1, newId);
          prevReferencesById = omit(prevReferences?.byId, optimisticId);
        } else {
          // Non-Optimistic Update
          finalIdsByWorkspace = [...prevIdsByWorkspace, ...newIdsByWorkspace];
        }

        return {
          byId: {
            ...prevReferencesById,
            ...newReferences?.byId,
          },
          byWorkspaceId: {
            ...prevReferences?.byWorkspaceId,
            [workspaceId]: finalIdsByWorkspace,
          },
        };
      },
    },
    optimisticUpdate: {
      references: (prevValue): ReferenceEntitiesState | undefined => {
        if (!optimistic) return prevValue;
        const prevIdsByWorkspace = prevValue?.byWorkspaceId?.[workspaceId] || [];
        const optimisticReference: Reference = {
          id: optimisticId,
          edge_type: body.edge_type,
          source_id: body.source_id,
          source_type: body.source_type,
          target_id: workspaceId,
          link: '',
        };
        return {
          byId: {
            ...prevValue?.byId,
            [optimisticId]: optimisticReference,
          },
          byWorkspaceId: {
            ...prevValue?.byWorkspaceId,
            [workspaceId]: [...prevIdsByWorkspace, optimisticId],
          },
        };
      },
    },
    rollback: {
      references: (_initialValue, currentValue): ReferenceEntitiesState | undefined => {
        if (!optimistic) return currentValue;
        const ids = currentValue?.byWorkspaceId?.[workspaceId] || [];
        return {
          byId: omit(currentValue?.byId, optimisticId),
          byWorkspaceId: {
            ...currentValue?.byWorkspaceId,
            [workspaceId]: reject(ids, (id) => id === optimisticId),
          },
        };
      },
    },
  };
};

export const deleteReferenceQuery = (
  practiceId: number,
  workspaceId: number,
  referenceId: number,
  optimistic = true,
): AppQueryConfig => {
  return {
    url: urls.reference(practiceId, workspaceId, referenceId),
    options: { method: 'DELETE' },
    update: {
      references: (prevValue): ReferenceEntitiesState | undefined => {
        const prevReferenceIdsByWorkspace = prevValue?.byWorkspaceId?.[workspaceId] || [];
        if (!prevReferenceIdsByWorkspace.includes(referenceId)) return prevValue;
        const newReferenceIdsByWorkspace = prevReferenceIdsByWorkspace.filter((id) => {
          return id !== referenceId;
        });
        return {
          ...prevValue,
          byId: omit(prevValue?.byId, referenceId),
          byWorkspaceId: {
            ...prevValue?.byWorkspaceId,
            [workspaceId]: newReferenceIdsByWorkspace,
          },
        };
      },
    },
    optimisticUpdate: {
      references: (prevValue): ReferenceEntitiesState | undefined => {
        if (!optimistic) return prevValue;
        const prevReferenceIdsByWorkspace = prevValue?.byWorkspaceId?.[workspaceId] || [];
        if (!prevReferenceIdsByWorkspace.includes(referenceId)) return prevValue;
        return {
          byId: omit(prevValue?.byId, referenceId),
          byWorkspaceId: {
            ...prevValue?.byWorkspaceId,
            [workspaceId]: reject(prevReferenceIdsByWorkspace, (id) => id === referenceId),
          },
        };
      },
    },
  };
};
