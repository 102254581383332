/**
 * This is temporarily the official store for the app - it has some special
 * logic for translating preloaded el8 data for our store.
 * For new pages that don't have to deal with legacy stuff, use createStore instead.
 */
import { applyMiddleware, createStore } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import { queryMiddleware } from 'redux-query';
import superagentInterface from 'redux-query-interface-superagent';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxToLegacySynchronizer from './legacy-data-sync/reduxToLegacySynchronizer';
import reduxQueryConfigEnhancer from './reduxQueryConfigEnhancer';
import reduxQueryEntityIndexer from './reduxQueryEntityIndexer';

export const getQueries = (state) => state.queries;
export const getEntities = (state) => state.entities;

const middlewares = [
  reduxQueryConfigEnhancer,
  queryMiddleware(superagentInterface, getQueries, getEntities),
  reduxQueryEntityIndexer,
  reduxToLegacySynchronizer,
];

// if (process.env.NODE_ENV === 'development') {
//   const createLogger = require('redux-logger').createLogger;
//   const logger = createLogger();
//   middlewares.push(logger);
// }

let hydratedStore = null;

/**
 * Creates and returns a function that can be called to configure and hydrate
 * the store.
 *
 * @param {function} reducer - the root reducer for the store
 * @param {function} hydrateFn - a function that accepts the preloaded el8 data
 * object and returns the initial hydrated store state.
 * @returns {function} a function that accepts the preloaded el8 data object and
 * configures and hydrates the store, which can then be retrieved using `getHydratedStore`
 */
export function createStoreConfigurer(reducer, hydrateFn) {
  return function configureStore(el8Data) {
    hydratedStore = createStore(
      enableBatching(reducer),
      hydrateFn(el8Data),
      composeWithDevTools(applyMiddleware(...middlewares)),
    );
  };
}

/**
 * Get the hydrated store.
 */
export function getHydratedStore() {
  return hydratedStore;
}
