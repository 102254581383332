import Cookie from 'js-cookie';
import { ResponseHeaders } from 'redux-query';

import { ResponseError } from 'utils/errors';

/**
 * Returns true if the given HTTP status code indicates success.
 *
 * @param {number} statusCode
 */
export function isSuccessCode(statusCode: number): boolean {
  return statusCode >= 200 && statusCode < 300;
}

/**
 * Returns true if the given server response indicates success.
 *
 * @param {object} res - a server response object. It should have a `status` property.
 */
export function isSuccess(res: { status?: number }): boolean {
  if (res.status) return isSuccessCode(res.status);
  return false;
}

// FUTURE: Update checkSuccess() and alertError() to include error correlation ID in ResponseError
/**
 * Validates that a given server response indicates success.
 *
 * This function THROWS a ResponseError object, which works well when combined
 * with the `alertError` function in `utils/errors`. You can catch a call to
 * this function by calling `alertError` with the caught error, and generally
 * expect to have an appropriate error message shown to the user.
 *
 * @param {object} res - a server response object. It must have a `status` property.
 */
export function checkSuccess<T extends { status: number }>(res: T): T {
  if (!isSuccess(res)) {
    throw new ResponseError(res, `Response status not OK, got status: ${res.status}`);
  }
  return res;
}

/**
 * Update a GET URL parameter to a given URL string
 * Taken from: https://gist.github.com/niyazpk/f8ac616f181f6042d1e0
 */
export function updateUrlParameter(uri: string, key: string, value: string | number): string {
  // remove the hash part before operating on the uri
  const i = uri.indexOf('#');
  const hash = i === -1 ? '' : uri.substr(i);
  let newUri = i === -1 ? uri : uri.substr(0, i);

  const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
  const separator = newUri.indexOf('?') !== -1 ? '&' : '?';

  if (!value) {
    // remove key-value pair if value is empty
    newUri = newUri.replace(new RegExp(`([?&]?)${key}=[^&]*`, 'i'), '');
    if (newUri.slice(-1) === '?') {
      newUri = newUri.slice(0, -1);
    }
    // replace first occurrence of & by ? if no ? is present
    if (newUri.indexOf('?') === -1) newUri = newUri.replace(/&/, '?');
  } else if (newUri.match(re)) {
    newUri = newUri.replace(re, `$1${key}=${value}$2`);
  } else {
    newUri = `${newUri}${separator}${key}=${value}`;
  }
  return newUri + hash;
}

/**
 * This is a simpler version of updateUrlParameter that will always add a URL parameter
 * for a given URL string, regardless of whether the parameter exists or not. This
 * method exists because Django Rest Framework filtering for multiple values is in the
 * form https://url.com/?field=value1&field=value2.
 */
export function addUrlParameter(
  uri: string,
  key: string,
  value: string | number | boolean,
): string {
  // remove the hash part before operating on the uri
  const i = uri.indexOf('#');
  const hash = i === -1 ? '' : uri.substr(i);
  let newUri = i === -1 ? uri : uri.substr(0, i);

  const separator = newUri.indexOf('?') !== -1 ? '&' : '?';

  const encodedValue = encodeURIComponent(value);

  newUri = `${newUri}${separator}${key}=${encodedValue}`;
  return newUri + hash;
}

/**
 * Converts an object containing query parameters into a string and appends it to the given URL.
 *
 * @param [options.convertBoolsToNumbers] - if true, boolean values will be converted to numbers (1 or 0)
 * in the resulting query string. Defaults to false.
 *
 * @returns a complete URL with the query params appended as a query string
 */
export function addQueryParams(
  url: string,
  queryParams: {
    [queryParamKey: string]: string | number | boolean | (null | string | number | boolean)[];
  },
  options: { convertBoolsToNumbers?: boolean } = {},
): string {
  const { convertBoolsToNumbers = false } = options;

  const getValueString = (value: string | number | boolean | null): string => {
    if (convertBoolsToNumbers && typeof value === 'boolean') {
      return value ? '1' : '0';
    }
    return String(value);
  };

  let newUrl = url;
  Object.keys(queryParams).forEach((param) => {
    const paramValueOrValues = queryParams[param];
    if (Array.isArray(paramValueOrValues)) {
      newUrl = paramValueOrValues.reduce<string>(
        (accum, value) => addUrlParameter(accum, param, getValueString(value)),
        newUrl,
      );
    } else {
      newUrl = addUrlParameter(newUrl, param, getValueString(paramValueOrValues));
    }
  });
  return newUrl;
}

/**
 * @DEPRECATED
 *
 * Adds the X-CSRFToken header to the given headers object, returning a new object.
 * Generally this should not be necessary, because reduxQueryConfigEnhancer middleware
 * already handles this automatically for requests made via redux-query.
 */
export function addCSRF(headers = {}): {} {
  return Object.assign({}, headers, {
    'X-CSRFToken': Cookie.get('csrftoken'),
  });
}

/**
 * Get request correlation ID from response headers object.
 * Useful for rendering Error IDs for user-facing error messages.
 */
export function getCorrelationId(headers: ResponseHeaders): string {
  return headers['correlation-id'] || '';
}
