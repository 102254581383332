// NOTE: The `invalidateQueryGroup` functionality is a type-safety disaster that clashes with redux-query's
// own type for `QueriesState`, and it was concocted well before we ever adopted Typescript. The proper fix
// is to redo this whole functionality with a type-safe design; for now we're just going to ts-ignore all
// the type safety errors.
import {
  queriesReducer as baseQueriesReducer,
  actionTypes,
  QueriesState,
  ReduxQueryAction,
} from 'redux-query';
import get from 'lodash/get';
import { INVALIDATE_QUERY, INVALIDATE_QUERY_GROUP } from 'utils/redux-query';
import produce from 'immer';
import { AnyAction } from 'redux';

const initialState = {
  ...baseQueriesReducer(undefined, {} as ReduxQueryAction),
};

/**
 * An enhanced redux-query queriesReducer that enables us to invalidate cached
 * groups of queries.
 *
 * To enable this, the appropriate request query config(s) must include
 * meta: ${group-name}, e.g. meta: 'week-schedules'. Later, all queries with
 * that group may be invalidated by dispatching invalidateQueryGroup('week-schedules').
 */
export default function queriesReducer(state = initialState, action: AnyAction): QueriesState {
  switch (action.type) {
    case actionTypes.REQUEST_START: {
      const newState = baseQueriesReducer(state, action as ReduxQueryAction);
      const groupName = get(action, ['meta', 'group']);

      if (groupName) {
        const group = newState[groupName] || {};
        // @ts-ignore see comment at top of file
        newState[groupName] = {
          ...group,
          [action.queryKey]: true,
        };
      }

      return newState;
    }
    case INVALIDATE_QUERY:
      return produce(state, (draft) => {
        delete draft[action.queryKey];
      });
    case INVALIDATE_QUERY_GROUP: {
      const keysToInvalidate = state[action.group];

      if (!keysToInvalidate) return state;

      const newState = {};
      Object.keys(state).forEach((key) => {
        // @ts-ignore see comment at top of file
        if (key !== action.group && !keysToInvalidate[key]) {
          // @ts-ignore see comment at top of file
          newState[key] = state[key];
        }
      });

      // @ts-ignore see comment at top of file
      if (!newState.invalidationsByGroup) {
        // @ts-ignore see comment at top of file
        newState.invalidationsByGroup = {};
      }
      const prevInvalidationCount = get(state, ['invalidationsByGroup', action.group], 0);
      // @ts-ignore see comment at top of file
      newState.invalidationsByGroup[action.group] = prevInvalidationCount + 1;

      return newState;
    }
    default:
      return baseQueriesReducer(state, action as ReduxQueryAction);
  }
}
