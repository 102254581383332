import { ReferenceEntity } from 'EntityTypes';
import { CommonReferenceEntityProperties } from './workspacesTypes.d';

export enum ReferenceEdgeType {
  // Links a workspace defined on the practice's workspace page to the users that will see it in the workspace switcher
  PracticeWorkspaceAccess = 1,
  // Links a workspace to a resource for the workspace to filter those resources
  PracticeWorkspaceFilter = 2,
}

/**
 * A workspace id of zero is the way workspaces indicates practice view
 */
export const PRACTICE_VIEW_WORKSPACE_ID = 0;

/**
 * Given a list of entities, and a list of Workspace References to those
 * entities, returns a list of entities that do not appear in the
 * reference list.
 *
 * Intended to provide a common interface for all of the Entity Types
 * that are capable of being Workspace References to be able to get just
 * the entities that have not been referenced (selected) yet.
 * @param entities
 * @param references
 */
export function getEntitiesWithoutReferences<T extends CommonReferenceEntityProperties>(
  entities: T[],
  references?: ReferenceEntity<T>[],
): T[] {
  const usedIds = references?.map((r) => r.sourceEntity.id) || [];
  return entities.filter((e) => !usedIds.includes(e.id));
}
