import { normalize, Schema } from 'normalizr';

/**
 * Helper function for hydrating entities that require normalization, when initializing
 * the redux store with preloaded state.
 */
export default function hydrateNormalizedEntities(
  rootEntities: {
    [entityName: string]: {
      byId?: {};
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entitiesToNormalize: any,
  entitySchema: Schema,
): void {
  /* eslint-disable no-param-reassign */
  const { entities: normalizedEntities } = normalize(entitiesToNormalize, entitySchema);
  Object.keys(normalizedEntities).forEach((entityName): void => {
    if (rootEntities[entityName]) {
      rootEntities[entityName].byId = {
        ...rootEntities[entityName].byId,
        ...normalizedEntities[entityName],
      };
    } else {
      rootEntities[entityName] = {
        byId: normalizedEntities[entityName],
      };
    }
  });
  /* eslint-enable no-param-reassign */
}
