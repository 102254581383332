/** Auto-generated by the constants_user_experience.py script */
enum UserExperience {
  FaxLetter = 'FaxLetter',
  GlobalPatientSearchPopup = 'GlobalPatientSearchPopup',
  PLR4Everyone = 'PLR4Everyone',
  Payments2ElectricBoogalooPopup = 'Payments2ElectricBoogalooPopup',
  PostDateLetter = 'PostDateLetter',
  SendTelehealthInstructions = 'SendTelehealthInstructions',
  TelehealthInRemindersNotice = 'TelehealthInRemindersNotice',
}

export default UserExperience;
