import React from 'react';
import { Group } from '@el8/vital';
import cn from 'classnames';
import { logEvent } from 'utils/amplitude';
import { ScopedSentryErrorBoundary } from 'utils/sentry/ScopedSentryErrorBoundary';
import { NavbarItem, NavbarItemDropdownProps } from '../../components/NavbarItem';
import {
  UsePracticeContextSwitcherResult,
  usePracticeContextSwitcher,
} from '../../hooks/usePracticeContextSwitcher';
import styles from './PracticeContextSwitcherItem.less';

const getMenuItems = ({
  currentPractice,
  handleSelect,
  userPractices,
}: UsePracticeContextSwitcherResult): NavbarItemDropdownProps['items'] => {
  const result: NavbarItemDropdownProps['items'] =
    userPractices?.map((practice) => ({
      type: 'button',
      disabled: practice.id === currentPractice.id,
      id: practice.id.toString(),
      key: practice.id,
      text: practice.name,
      rightAccessory: (
        <Group verticallyAlign="center" className={styles.practiceItemRightAccessory}>
          {practice.isUnread && <span className={styles.unreadDot} />}

          {practice.notificationCount ? (
            <span className={styles.badge}>{practice.notificationCount}</span>
          ) : null}
        </Group>
      ),
      onClick: (): void => {
        handleSelect({ id: practice.id, label: practice.name });
      },
      'data-testid': `navbar-practice-switcher-${practice.id}-button`,
    })) ?? [];

  return result;
};

export const PracticeContextSwitcherItemComponent = (): JSX.Element | null => {
  const practiceContextSwitcher = usePracticeContextSwitcher();

  const { isPending, currentPractice, anyUnreadItems } = practiceContextSwitcher;

  if (isPending) {
    return (
      <NavbarItem type="button" data-testid="navbar-practice-button">
        Loading...
      </NavbarItem>
    );
  }

  return (
    <NavbarItem
      type="dropdown"
      items={getMenuItems(practiceContextSwitcher)}
      data-testid="navbar-practice-button"
      onClick={(): void => logEvent('open: navigation practice switcher')}
    >
      <Group verticallyAlign="center">
        {currentPractice?.name}
        {anyUnreadItems && <span className={cn(styles.unreadDot, styles.super)} />}
      </Group>
    </NavbarItem>
  );
};

export const PracticeContextSwitcherItem = (): JSX.Element => {
  return (
    <ScopedSentryErrorBoundary
      withScope={(scope): void => {
        scope.setTag('fe_service', 'Navbar');
      }}
    >
      <PracticeContextSwitcherItemComponent />
    </ScopedSentryErrorBoundary>
  );
};
