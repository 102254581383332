/**
 * Inserts the elements of one array into another array at a specified index,
 * removing the element at that index in the base array.
 *
 * @template T - The type of elements in the arrays.
 * @param {T[]} baseArray - The array to insert elements into.
 * @param {T[]} arrayToInject - The array containing the elements to insert.
 * @param {number} index - The index at which to insert the elements.
 * @returns {T[]} - The resulting array with the elements inserted.
 */
export function injectArray<T>(baseArray: T[], arrayToInject: T[], index: number): T[] {
  return [...baseArray.slice(0, index), ...arrayToInject, ...baseArray.slice(index + 1)];
}
