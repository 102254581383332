import get from 'lodash/get';
import { createSelector } from 'reselect';

import { VisitNoteType } from 'EntityTypes';
import { EntitiesById } from 'StoreTypes';

/**
 * Get all visit note types, sorted by rank.
 */
// eslint-disable-next-line import/prefer-default-export
export const getVisitNoteTypes = createSelector(
  (state): EntitiesById<VisitNoteType> => get(state, ['entities', 'visitNoteTypes', 'byId']),
  (visitNoteTypesById): VisitNoteType[] => {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'VisitNoteTy... Remove this comment to see the full error message
    if (!visitNoteTypesById) return null;

    return Object.values(visitNoteTypesById).sort((a, b): number => a.rank - b.rank);
  },
);
