import { useFormikSubmitForm } from 'utils/hooks';
import { FormikSubmitFormOptions } from 'utils/hooks/useFormikSubmitForm';

import { ValidationError } from 'utils/errors';
import { useJsonFormsContext } from './jsonFormsContext';

/**
 * Consolidates JSON Forms and Formik form validation into a single hook.
 */
export default function useJsonFormsFormikSubmit(
  options: FormikSubmitFormOptions = {},
): () => Promise<void> {
  const validateFormikForm = useFormikSubmitForm(options);
  const jsonFormsContext = useJsonFormsContext();
  const validateForm = async (): Promise<void> => {
    if ((jsonFormsContext?.errors?.length ?? 0) > 0) {
      throw new ValidationError('JSON Forms validation failed');
    }
    await validateFormikForm();
  };
  return validateForm;
}
