import React from 'react';
import { Position } from '@el8/vital';
import ConfirmPopover, { ConfirmPopoverProps } from './ConfirmPopover';

type Props = {
  text?: React.ReactNode;
  onDelete: () => void;
  deleteText?: string;
} & Omit<ConfirmPopoverProps, 'confirmKind' | 'confirmText' | 'content' | 'onConfirmClick'>;

const DeletePopover: React.FC<Props> = ({
  cancelText = 'Cancel',
  deleteText = 'Delete',
  onDelete,
  position = Position.BOTTOM,
  text = 'This is permanent and you will not be able to undo this operation.',
  title = 'Delete?',
  ...rest
}) => (
  <ConfirmPopover
    cancelText={cancelText}
    confirmKind="danger"
    confirmText={deleteText}
    content={text}
    onConfirmClick={onDelete}
    position={position}
    title={title}
    {...rest}
  />
);

export default DeletePopover;
