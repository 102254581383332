import omit from 'lodash/omit';
import { AppointmentTypeRaw, AppointmentTypeNormalized } from 'EntityTypes';

// A helper function that takes an partial appointment type in raw (server) format and transforms it
// to redux formated appointment type, that then can be used in optimistic update, etc.
export const pseudoNormalize = (
  appointmentType: AppointmentTypeRaw,
  includeMissingFields = true,
): Partial<AppointmentTypeNormalized> => {
  const normalizedAppointmentType: Partial<AppointmentTypeNormalized> = omit(
    appointmentType,
    'bookable_physician_ids',
    'patient_form_ids',
    'visit_note_template_ids',
    'visit_note_type_id',
  );

  if (
    includeMissingFields ||
    Object.prototype.hasOwnProperty.call(appointmentType, 'bookable_physician_ids')
  ) {
    normalizedAppointmentType.bookable_physicians = appointmentType.bookable_physician_ids;
  }

  if (
    includeMissingFields ||
    Object.prototype.hasOwnProperty.call(appointmentType, 'patient_form_ids')
  ) {
    normalizedAppointmentType.patient_forms = appointmentType.patient_form_ids;
  }

  if (
    includeMissingFields ||
    Object.prototype.hasOwnProperty.call(appointmentType, 'visit_note_template_ids')
  ) {
    normalizedAppointmentType.visit_note_templates = appointmentType.visit_note_template_ids;
  }

  if (
    includeMissingFields ||
    Object.prototype.hasOwnProperty.call(appointmentType, 'visit_note_type_id')
  ) {
    normalizedAppointmentType.visit_note_type = appointmentType.visit_note_type_id;
  }

  return normalizedAppointmentType;
};

// A helper function that takes an partial appointment type in normalized (redux) format and
// transforms it to raw (server) appointment type.
export const pseudoDenormalize = (
  appointmentType: AppointmentTypeNormalized,
  includeMissingFields = true,
): Partial<AppointmentTypeRaw> => {
  const denormalizedAppointmentType: Partial<AppointmentTypeRaw> = omit(
    appointmentType,
    'bookable_physicians',
    'patient_forms',
    'visit_note_templates',
    'visit_note_type',
  );

  if (
    includeMissingFields ||
    Object.prototype.hasOwnProperty.call(appointmentType, 'bookable_physician')
  ) {
    denormalizedAppointmentType.bookable_physician_ids = appointmentType.bookable_physicians;
  }

  if (
    includeMissingFields ||
    Object.prototype.hasOwnProperty.call(appointmentType, 'patient_forms')
  ) {
    denormalizedAppointmentType.patient_form_ids = appointmentType.patient_forms;
  }

  if (
    includeMissingFields ||
    Object.prototype.hasOwnProperty.call(appointmentType, 'visit_note_templates')
  ) {
    denormalizedAppointmentType.visit_note_template_ids = appointmentType.visit_note_templates;
  }

  if (
    includeMissingFields ||
    Object.prototype.hasOwnProperty.call(appointmentType, 'visit_note_type')
  ) {
    denormalizedAppointmentType.visit_note_type_id = appointmentType.visit_note_type;
  }

  return denormalizedAppointmentType;
};
