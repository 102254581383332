import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { createSelector } from 'reselect';
import {
  EntitiesById,
  EntitiesState,
  GenericEntity,
  GenericEntityState,
  RootState,
} from 'StoreTypes';
import { Reference, ReferenceEntity, ResourceType } from 'EntityTypes';
import { CommonReferenceEntityProperties } from './workspacesTypes.d';
import { ReferenceEdgeType } from './workspaceUtils';

export const getPracticeWorkspaces = createSelector(
  (state: RootState) => state.entities.workspaces?.byId,
  (workspaceById) => {
    if (!workspaceById) return undefined;

    return Object.values(workspaceById).sort((a, b): number => a.name.localeCompare(b.name));
  },
);

export const getUserCurrentWorkspaceId = (state: RootState): number | undefined | null =>
  state.entities.workspaces?.currentWorkspace;

export const selectUserCurrentWorkspace = createSelector(
  getUserCurrentWorkspaceId,
  (state: RootState) => state.entities.workspaces?.byId,
  (currentWorkspaceId, workspaceById) => {
    if (isNil(currentWorkspaceId)) return currentWorkspaceId;
    if (isNil(workspaceById)) return undefined;

    const currentWorkspace = workspaceById[currentWorkspaceId];
    return currentWorkspace;
  },
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const makeSelectWorkspace = () =>
  createSelector(
    (state: RootState) => state.entities.workspaces?.byId,
    (_state: RootState, workspaceId: number) => workspaceId,
    (workspacesById, workspaceId) => {
      return get(workspacesById, workspaceId, undefined);
    },
  );

type ReferenceSelector = (state: RootState, workspaceId: number) => Reference[] | undefined;

export const selectReferencesByWorkspaceId: ReferenceSelector = createSelector(
  (state: RootState) => state.entities.references?.byId,
  (state: RootState) => state.entities.references?.byWorkspaceId,
  (_state: RootState, workspaceId: number) => workspaceId,
  (refsById, refsByWorkspaceId, workspaceId) => {
    if (isNil(refsById) || isNil(refsByWorkspaceId)) return undefined;
    const referenceIds: number[] = refsByWorkspaceId[workspaceId] || [];
    const references: Reference[] = referenceIds
      .map((refId) => refsById[refId])
      .filter((ref) => !isNil(ref));
    return references;
  },
);

/**
 * * A Map from EntityType/ResourceType/ModelClass to the key inside
 * the EntitiesState object for which the corresponding slice of data
 * is to be found.
 *
 * **Only valid Workspace Reference Resource Types will be found as keys.
 * Not an exhaustive map of all of our models. DO NOT EXPORT THIS**
 */

const EntityTypeSliceMap = new Map<ResourceType, keyof EntitiesState>([
  ['ChecklistTemplate', 'checklistTemplates'],
  ['FacilityIdentifier', 'facilityIdentifiers'],
  ['LabOrderSet', 'labOrderSets'],
  ['LetterTemplate', 'letterTemplates'],
  ['PracticePrintHeader', 'printHeaders'],
  ['ServiceLocation', 'serviceLocations'],
  ['StaffGroup', 'staffGroups'],
  ['VisitNoteTemplate', 'visitNoteTemplates'],
  ['LabVendor', 'labVendors'],
  ['PracticeFax', 'practiceFaxes'],
]);

const selectSliceByEntityType = (
  state: RootState,
  entityType: ResourceType,
): EntitiesById<GenericEntity> | undefined => {
  const sliceKey = EntityTypeSliceMap.get(entityType);
  if (isNil(sliceKey)) return undefined;
  const entitiesSlice = state.entities[sliceKey] as GenericEntityState<GenericEntity>;
  return entitiesSlice?.byId;
};

type ReferenceSelectorOptions = {
  workspaceId: number;
  entityType: ResourceType;
  edgeType: ReferenceEdgeType;
};
type ReferenceEntitySelector<T extends CommonReferenceEntityProperties> = (
  state: RootState,
  args: ReferenceSelectorOptions,
) => ReferenceEntity<T>[] | undefined;

/**
 * Creates a typed and specific instance of a selector for selecting reference
 * entities (a wrapper around both the workspace reference and the source entity)
 * for a given workspaceId and resource/entity type.
 *
 * Will only work for EntityTypes that have a shape in the Redux store aligning
 * with the `byId` pattern (and not normalized by any other foreign key relationship
 * like `byWorkspaceId` for `references` for example).
 *
 */
export function makeSelectReferenceEntitiesByWorkspaceId<
  T extends CommonReferenceEntityProperties,
>(): ReferenceEntitySelector<T> {
  return createSelector(
    (state: RootState, { workspaceId }: ReferenceSelectorOptions) =>
      selectReferencesByWorkspaceId(state, workspaceId),
    (state: RootState, { entityType }: ReferenceSelectorOptions) =>
      selectSliceByEntityType(state, entityType),
    (_state: RootState, { entityType }: ReferenceSelectorOptions) => entityType,
    (_state: RootState, { edgeType }: ReferenceSelectorOptions) => edgeType,
    (references, entitiesById, entityType, edgeType) => {
      if (isNil(references) || isNil(entitiesById)) return undefined;

      const entities: ReferenceEntity<T>[] | undefined = references
        ?.filter((ref) => ref.source_type === entityType && ref.edge_type === edgeType)
        .map((ref) => ({
          reference: ref,
          // The cast should technically be T | undefined, but TS isn't smart
          // enough to infer that the undefined should be typeguarded against
          // with the filter excluding all nil values.
          sourceEntity: entitiesById[ref.source_id] as T,
        }))
        .filter((ref) => !isNil(ref.sourceEntity));

      return entities;
    },
  );
}
