import { Practice, PracticeRaw } from 'EntityTypes';
import { camelizeKeys } from 'humps';
import { AppQueryConfig, ListAPIResponseBody, UserPracticesQueryParams } from 'QueryTypes';
import { EntitiesSlice, PracticesEntitiesState, UserPracticesEntitiesState } from 'StoreTypes';
import { mapArrayToObject } from 'utils/arrays';
import { addQueryParams, updateUrlParameter } from 'utils/http';

const urls = {
  userPractices(userId: number, queryParams?: UserPracticesQueryParams): string {
    let url = `/user/${userId}/practices/`;

    if (queryParams) {
      url = addQueryParams(url, queryParams);
    }

    return url;
  },
  userPracticeContextSwitch(practiceId?: number, redirectTo?: string): string {
    let url = `/practice/${practiceId}/login/`;

    if (redirectTo) {
      url = updateUrlParameter(url, 'redirect_to', redirectTo);
    }

    return url;
  },
  userPracticesAuth(userId: number, queryParams?: UserPracticesQueryParams): string {
    let url = `/user/${userId}/practices/auth/`;

    if (queryParams) {
      url = addQueryParams(url, queryParams);
    }

    return url;
  },
  removeUserPractice(userId: number, practiceId: number): string {
    return `/user/${userId}/practice/${practiceId}/`;
  },
};

export const userPracticesQuery = (
  userId: number,
  queryParams?: UserPracticesQueryParams,
): AppQueryConfig => ({
  url: urls.userPractices(userId, queryParams),
  transform: (
    responseJson: ListAPIResponseBody<PracticeRaw>,
  ): EntitiesSlice<'userPractices' | 'practices'> => {
    const camelizedResponse = responseJson.results.map<Practice>(
      (p) => camelizeKeys(p) as Practice,
    );

    return {
      practices: {
        byId: mapArrayToObject(camelizedResponse, 'id'),
      },
      userPractices: {
        byUserId: {
          [userId]: responseJson.results.map((practice) => practice.id),
        },
      },
    };
  },
  update: {
    practices: (prev, next): PracticesEntitiesState => ({
      ...prev,
      byId: {
        ...prev?.byId,
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        ...next.byId,
      },
    }),
    userPractices: (prev, next): UserPracticesEntitiesState => ({
      ...prev,
      byUserId: {
        ...prev?.byUserId,
        ...next?.byUserId,
      },
    }),
  },
});

/**
 *  Query config for switching the context of the user to a specific practice.
 *
 *  @param practiceid - practice ID to switch the user to
 *  @param redirectTo - (optional) URL for redirection after performing context switch. Can be relative or absolute.
 */
export const userPracticeContextSwitchQuery = (
  practiceId: number,
  redirectTo?: string,
): AppQueryConfig => {
  return {
    url: urls.userPracticeContextSwitch(practiceId, redirectTo),
  };
};

/**
 * Query config that will attempt to authorize the credentials provided, and will associate all the practices the authorized credentials have access to.
 * Returns a list of all practices the current user has access to.
 */
export const userPracticesAuthQuery = (
  userId: number,
  body: { email: string; password: string },
  queryParams?: UserPracticesQueryParams,
): AppQueryConfig => ({
  url: urls.userPracticesAuth(userId, queryParams),
  body,
  transform: (
    responseJson: ListAPIResponseBody<Practice>,
  ): EntitiesSlice<'userPractices' | 'practices'> => {
    return {
      practices: {
        byId: mapArrayToObject(responseJson.results, 'id'),
      },
      userPractices: {
        byUserId: {
          [userId]: responseJson.results.map((practice) => practice.id),
        },
      },
    };
  },
  update: {
    practices: (prev, next): PracticesEntitiesState => ({
      ...prev,
      byId: {
        ...prev?.byId,
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        ...next.byId,
      },
    }),
    userPractices: (prev, next): UserPracticesEntitiesState => ({
      ...prev,
      byUserId: {
        ...prev?.byUserId,
        ...next?.byUserId,
      },
    }),
  },
});

/**
 * Query config that will remove the capability / association of the user being able to login for the specified practice id.
 */
export const removeUserPracticeQuery = (userId: number, practiceId: number): AppQueryConfig => ({
  url: urls.removeUserPractice(userId, practiceId),
  options: {
    method: 'DELETE',
  },
  update: {
    userPractices: (prev, _next): UserPracticesEntitiesState => ({
      ...prev,
      byUserId: {
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        ...prev.byUserId,
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        [userId]: prev.byUserId[userId].filter(
          (linkedPracticeId) => linkedPracticeId !== practiceId,
        ),
      },
    }),
  },
});
