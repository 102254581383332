import { useCurrentUser } from 'modules/practice-users';
import { useCallback } from 'react';
import { openPatientAccessDeniedDialog } from 'ui-modules/authorization';
import { useAppDispatch } from 'utils/hooks';

/**
 * returns a function that will route to a provider in a new tab if authorized, otherwise display a access denied message
 */
export const useNavigateToPatient = (): ((url: string) => void) => {
  const currentUser = useCurrentUser();
  const dispatch = useAppDispatch();
  const ACCESS_DENIED_ENABLED = el8Globals.FEATURES.ChartAccessEditable;
  return useCallback(
    (url: string) => {
      if (ACCESS_DENIED_ENABLED && currentUser?.hasChartAccess === false) {
        dispatch(
          openPatientAccessDeniedDialog(
            'Your account permissions limit access to this material. Contact your system administrator for more information.',
          ),
        );
      } else {
        window.open(url);
      }
    },
    [currentUser, dispatch, ACCESS_DENIED_ENABLED],
  );
};
