import React from 'react';

import { HeaderText } from '@el8/vital';

import { CaseIntakeStep } from 'modules/support/supportConstants';
import { StepConfig } from '../CaseIntakeStepConfig';

type Props = {
  isInModal: boolean;
  step: CaseIntakeStep;
  className?: string;
};

export const CaseIntakeStepHeader: React.FC<Props> = ({ isInModal, step, className }) => {
  if (isInModal) return null;
  return (
    <HeaderText sizing="heading-sm" tag="h1" className={className}>
      {StepConfig[step].title}
    </HeaderText>
  );
};

export default CaseIntakeStepHeader;
