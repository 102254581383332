import { camelizeKeys } from 'humps';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import merge from 'lodash/merge';
import { mapArrayToObject } from 'utils/arrays';
import { makeUpdateFn } from 'utils/redux-query';

import {
  AdminManageUserAccountRequestBody,
  AppQueryConfig,
  PhysicianUserAccountDetailsRequestBody,
  StaffUserAccountDetailsRequestBody,
  UserAccountDetailsResponseBody,
  PhysicianUserAccountDetailsRequestBodySingleState,
  AdminResendInviteRequestBody,
  UserAccountInviteStatusResponseBody,
  UserAccountInviteStatusesResponseBody,
  UserPermissionLogsResponseBody,
  UpdatePhysiciansTinRequestBody,
} from 'QueryTypes';
import { Physician, Practice, PracticeUser, ReferencedUser } from 'EntityTypes';
import { EntitiesById, EntitiesSlice, PracticeUsersEntitiesState } from 'StoreTypes';
import {
  makeUpdateWorkspaceFrontendFilter,
  transformToWorkspaceFrontendFilter,
  WorkspaceFrontendFiltersState,
} from 'modules/workspace-filters';
import practiceUsersHelpers from './practiceUsersHelpers';

const urls = {
  physicianUserAccountDetails(
    practiceId: Practice['id'],
    userId: PracticeUser['id'] | null,
  ): string {
    if (!userId) {
      return `/practice/${practiceId}/physician-user/`;
    }

    return `/practice/${practiceId}/physician-user/${userId}/`;
  },

  practiceUsers(userId?: number): string {
    if (!userId) {
      return '/users/json/';
    }

    return `/users/${userId}/`;
  },
  practiceUserProfilePhoto(userId: number): string {
    return `/user/${userId}/photo/profile/`;
  },
  staffUserAccountDetails(practiceId: Practice['id'], userId: PracticeUser['id']): string {
    if (!userId) {
      return `/practice/${practiceId}/staff-user/`;
    }

    return `/practice/${practiceId}/staff-user/${userId}/`;
  },
  adminManageUserAccount(practiceId: number, userId: number): string {
    return `/practice/${practiceId}/users/${userId}/admin/`;
  },
  resendInvite(practiceId: number): string {
    return `/practices/${practiceId}/actions/reinvite-user`;
  },
  userInviteStatus(practiceId: number, userId: number): string {
    return `/practices/${practiceId}/user-invite-statuses/${userId}`;
  },
  userInviteStatuses(practiceId: number): string {
    return `/practices/${practiceId}/user-invite-statuses`;
  },
  userPermissionLogs(practiceId: number, userId: number): string {
    return `/practices/${practiceId}/user-permission-logs/${userId}`;
  },
  updatePhysiciansTins(): string {
    return '/physicians/update-tins/';
  },
};

const queryKeys = {
  referencedUsers: (userId: number | null | undefined): QueryKey => ['referenced-users', userId],
};

/**
 * Query config for fetching either all users in the current practice, or a specific
 * user within the practice.
 *
 * @param userId - the ID of the user to fetch. If this is left unspecified,
 * the query will fetch all users in the practice.
 */
export const practiceUsersQuery = (userId?: number): AppQueryConfig => {
  const fetchingAllUsers = !userId;

  return {
    url: urls.practiceUsers(userId),
    transform: (
      responseJson: PracticeUser | PracticeUser[],
    ): {
      practiceUsers: EntitiesById<PracticeUser>;
      currentWorkspaceFilters: WorkspaceFrontendFiltersState;
    } => {
      return {
        practiceUsers: fetchingAllUsers
          ? mapArrayToObject(camelizeKeys(responseJson) as PracticeUser[], 'id')
          : { [(responseJson as PracticeUser).id]: camelizeKeys(responseJson) as PracticeUser },
        currentWorkspaceFilters: transformToWorkspaceFrontendFilter<PracticeUser>(responseJson),
      };
    },
    update: {
      practiceUsers: makeUpdateFn<PracticeUser>(),
      currentWorkspaceFilters: makeUpdateWorkspaceFrontendFilter('PracticeUser'),
    },
  };
};

type UserAccountDetailsTransformed = {
  practiceUsers: { [userId: number]: Partial<PracticeUser> };
};

/**
 * This query creates/updates a physician user's physician identifiers and user account details.  The request body gets wrapped in a FormData object.
 *
 * @returns User
 */
export const physicianUserAccountDetailsQuery = (
  practiceId: number,
  userId: number | null,
  body: PhysicianUserAccountDetailsRequestBody,
): AppQueryConfig => {
  return {
    body: practiceUsersHelpers.constructPhysicianAccountDetailsFormData(body),
    url: urls.physicianUserAccountDetails(practiceId, userId),
    transform: (responseJson: UserAccountDetailsResponseBody): UserAccountDetailsTransformed => ({
      // TODO --RP
      // probably need to refactor current user now
      // probably need to fix types for PracticeUser
      practiceUsers: { [responseJson.id]: camelizeKeys(responseJson) },
    }),
    update: {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(prev: PracticeUsersEntitiesState | undefine... Remove this comment to see the full error message
      practiceUsers: (
        prev,
        next: UserAccountDetailsTransformed['practiceUsers'],
      ): PracticeUsersEntitiesState => {
        if (userId) {
          return {
            ...prev,
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ [x: number]: PracticeUser | { allowStaffTo... Remove this comment to see the full error message
            byId: {
              ...prev?.byId,
              [userId]: {
                ...prev?.byId?.[userId],
                ...next[userId],
              },
            },
          };
        }

        return {
          ...prev,
          byId: {
            ...prev?.byId,
            ...(next as PracticeUser),
          },
        };
      },
    },
  };
};

export const physicianUserAccountDetailsSingleStateQuery = (
  practiceId: number,
  userId: number | null,
  body: PhysicianUserAccountDetailsRequestBodySingleState,
): AppQueryConfig => {
  return {
    body: practiceUsersHelpers.constructPhysicianAccountDetailsFormDataSingleState(body),
    url: urls.physicianUserAccountDetails(practiceId, userId),
    transform: (responseJson: UserAccountDetailsResponseBody): UserAccountDetailsTransformed => ({
      practiceUsers: { [responseJson.id]: camelizeKeys(responseJson) },
    }),
    update: {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(prevEntityState: GenericEntityState<Practic... Remove this comment to see the full error message
      practiceUsers: (
        prev,
        next: UserAccountDetailsTransformed['practiceUsers'],
      ): PracticeUsersEntitiesState => {
        if (userId) {
          return {
            ...prev,
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ [x: number]: PracticeUser | { allowStaffTo... Remove this comment to see the full error message
            byId: {
              ...prev?.byId,
              [userId]: {
                ...prev?.byId?.[userId],
                ...next[userId],
              },
            },
          };
        }

        return {
          ...prev,
          byId: {
            ...prev?.byId,
            ...(next as PracticeUser),
          },
        };
      },
    },
  };
};

export const staffUserAccountDetailsQuery = (
  practiceId: number,
  userId: number,
  body: StaffUserAccountDetailsRequestBody,
): AppQueryConfig => {
  return {
    body,
    url: urls.staffUserAccountDetails(practiceId, userId),
    transform: (responseJson: UserAccountDetailsResponseBody): UserAccountDetailsTransformed => ({
      // TODO --RP
      // probably need to refactor current user now
      // probably need to fix types for PracticeUser
      practiceUsers: { [responseJson.id]: camelizeKeys(responseJson) },
    }),
    update: {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(prev: PracticeUsersEntitiesState | undefine... Remove this comment to see the full error message
      practiceUsers: (
        prev,
        next: UserAccountDetailsTransformed['practiceUsers'],
      ): PracticeUsersEntitiesState => {
        if (userId) {
          return {
            ...prev,
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ [x: number]: PracticeUser | { allowStaffTo... Remove this comment to see the full error message
            byId: {
              ...prev?.byId,
              [userId]: {
                ...prev?.byId?.[userId],
                ...next[userId],
              },
            },
          };
        }

        return {
          ...prev,
          byId: {
            ...prev?.byId,
            ...(next as PracticeUser),
          },
        };
      },
    },
  };
};

/**
 * This query merges a cache-busting property into practiceUsers, which can
 * be used for getting an immediately updated profile photo url for displaying
 * photo updates without having to reload the component. The image file in the
 * body of the request gets wrapped in a FormData object.
 *
 * @param userId
 * @param image
 */
export const practiceUserProfilePhotoQuery = (
  userId: number,
  image?: File | null,
): AppQueryConfig => ({
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'File | undefined' is not assigna... Remove this comment to see the full error message
  body: practiceUsersHelpers.constructProfilePhotoFormData(image),
  url: urls.practiceUserProfilePhoto(userId),
  update: {
    practiceUsers: (prev): PracticeUsersEntitiesState => ({
      ...prev,
      profilePhotoLastUpdated: new Date().getTime(),
    }),
  },
});

/**
 * QueryConfig that is used by practice admins to enable/disable users and/or set users as admins
 *
 * @param practiceId
 * @param userId
 * @param body
 */
export const adminManageUserAccountQuery = (
  practiceId: number,
  userId: number,
  body: AdminManageUserAccountRequestBody,
): AppQueryConfig => ({
  url: urls.adminManageUserAccount(practiceId, userId),
  body,
  transform: (responseJson: UserAccountDetailsResponseBody): UserAccountDetailsTransformed => ({
    // TODO --RP
    // probably need to refactor current user now
    // probably need to fix types for PracticeUser
    practiceUsers: { [userId]: camelizeKeys(responseJson) },
  }),
  update: {
    // @ts-expect-error ts-migrate(2322) FIXME: Type '(prev: PracticeUsersEntitiesState | undefine... Remove this comment to see the full error message
    practiceUsers: (
      prev,
      next: UserAccountDetailsTransformed['practiceUsers'],
    ): PracticeUsersEntitiesState => ({
      ...prev,
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ [x: number]: PracticeUser | { allowStaffTo... Remove this comment to see the full error message
      byId: {
        ...prev?.byId,
        [userId]: {
          ...prev?.byId?.[userId],
          ...next[userId],
        },
      },
    }),
  },
});

/**
 * QueryConfig that is used by practice admins to trigger resending of invites to users
 */
export const adminResendInviteQuery = (
  practiceId: number,
  body: AdminResendInviteRequestBody,
): AppQueryConfig => ({
  url: urls.resendInvite(practiceId),
  body,
  transform: (
    responseJson: UserAccountInviteStatusResponseBody,
  ): EntitiesSlice<'practiceUsers'> => ({
    practiceUsers: {
      inviteStatusByUserId: {
        [body.user_id]: responseJson,
      },
    },
  }),
  update: {
    practiceUsers: (prev, next): PracticeUsersEntitiesState => ({
      ...prev,
      inviteStatusByUserId: {
        ...prev?.inviteStatusByUserId,
        ...next?.inviteStatusByUserId,
      },
    }),
  },
});

/**
 * QueryConfig that is used by practice admins to retrieve the invite status of a user
 */
export const adminInviteStatusQuery = (practiceId: number, userId: number): AppQueryConfig => ({
  url: urls.userInviteStatus(practiceId, userId),
  transform: (
    responseJson: UserAccountInviteStatusResponseBody,
  ): EntitiesSlice<'practiceUsers'> => ({
    practiceUsers: {
      inviteStatusByUserId: {
        [userId]: responseJson,
      },
    },
  }),
  update: {
    practiceUsers: (prev, next): PracticeUsersEntitiesState => ({
      ...prev,
      inviteStatusByUserId: {
        ...prev?.inviteStatusByUserId,
        ...next?.inviteStatusByUserId,
      },
    }),
  },
});

/**
 * Query config to retrieve the invite status of all users in the practice
 */
export const userInviteStatusesQuery = (practiceId: number): AppQueryConfig => ({
  url: urls.userInviteStatuses(practiceId),
  transform: (
    responseJson: UserAccountInviteStatusesResponseBody,
  ): EntitiesSlice<'practiceUsers'> => ({
    practiceUsers: {
      inviteStatusByUserId: mapArrayToObject(responseJson, 'user_id'),
    },
  }),
  update: {
    practiceUsers: (prev, next): PracticeUsersEntitiesState => ({
      ...prev,
      inviteStatusByUserId: {
        ...prev?.inviteStatusByUserId,
        ...next?.inviteStatusByUserId,
      },
    }),
  },
});

/**
 * QueryConfig that is used by practice admins to view a user's permission logs.
 */
export const userPermissionLogsQuery = (practiceId: number, userId: number): AppQueryConfig => ({
  force: true,
  url: urls.userPermissionLogs(practiceId, userId),
  transform: (responseJson: UserPermissionLogsResponseBody): EntitiesSlice<'practiceUsers'> => ({
    practiceUsers: {
      permissionLogsByUserId: {
        [userId]: responseJson,
      },
    },
  }),
  update: {
    practiceUsers: (prev, next): PracticeUsersEntitiesState => ({
      ...prev,
      permissionLogsByUserId: {
        ...prev?.permissionLogsByUserId,
        ...next?.permissionLogsByUserId,
      },
    }),
  },
});

/**
 * Fetches and returns a `ReferencedUser`.
 */
export const useGetReferencedUser = (
  userId: number | null | undefined,
): UseQueryResult<ReferencedUser> => {
  return useQuery({
    queryKey: queryKeys.referencedUsers(userId),
    enabled: Boolean(userId),
  });
};

/**
 * QueryConfig that is used by the CQM reports page to bulk update physician TINs
 *
 * Since the response body is used to update practiceUsers state, it resides in the
 * practice users module even though the url is /physicians/update-tins/
 *
 * @param body
 */
export const updateTinsQuery = (body: UpdatePhysiciansTinRequestBody): AppQueryConfig => ({
  url: urls.updatePhysiciansTins(),
  body,
  transform: (responseJson: { physicians: Physician[] }) => ({
    practiceUsers: mapArrayToObject(responseJson.physicians, 'user_id'),
  }),
  update: {
    practiceUsers: (prev, next) => ({ byId: merge({}, prev?.byId, next) }),
  },
});
