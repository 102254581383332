import React from 'react';
import { DataGridCell, DataGridRenderCellParams, Text2 } from '@el8/vital';
import { PatientDeletedDocument } from 'EntityTypes';
import { useDeletedDocumentTitle } from '../hooks/useDeletedDocumentTitle';

export const DeletedDocumentTitleCell = ({
  props,
  item,
}: DataGridRenderCellParams<PatientDeletedDocument>): JSX.Element => {
  const title = useDeletedDocumentTitle(item);

  return (
    <DataGridCell {...props}>
      <Text2 tag="a" role="button" onClick={(): void => el8.appchart.showItemDetail(item.id)}>
        {title}
      </Text2>
    </DataGridCell>
  );
};
