import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Flexbox } from 'components/layout';
import { required, email, createValidator } from 'shared/react/validators';
import { TextInput2, Button, Text } from '@el8/vital';
import { Formik } from 'formik';
import { VIEWS } from '../helpers';

import styles from './ExportEmailHome.less';

const validate = createValidator({
  destinationEmail: [required, email],
});

class ExportEmailHome extends Component {
  navigateToConfirmView = (destinationEmail) => {
    const { startDate, endDate } = this.props;
    this.props.changeView(VIEWS.confirmExportEmail, {
      destinationEmail,
      startDate,
      endDate,
    });
  };

  render() {
    return (
      <div>
        <Formik
          initialValues={{ destinationEmail: '' }}
          validate={validate}
          onSubmit={(values) => this.navigateToConfirmView(values.destinationEmail)}
          render={({
            values,
            handleSubmit,
            handleChange,
            touched,
            errors,
            isValid,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Flexbox direction="column" align="center">
                <Text> Email a copy of your data using the form below</Text>
                <TextInput2
                  style={{ display: 'block' }}
                  label="Destination Email Address"
                  name="destinationEmail"
                  value={values.destinationEmail || ''}
                  onChange={handleChange}
                  error={touched.destinationEmail && errors.destinationEmail}
                  aria-label="Destination Email Address"
                />
                <Button
                  className={styles.sendButton}
                  type="submit"
                  kind="primary"
                  disabled={!isValid || isSubmitting}
                >
                  Send
                </Button>
              </Flexbox>
            </form>
          )}
        />
      </div>
    );
  }
}

ExportEmailHome.propTypes = {
  changeView: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
};

export default ExportEmailHome;
