/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Feature Trial API
 * This API defines the endpoints for the Feature Trial service.

 * OpenAPI spec version: 1.0.0
 */

export type TrialState = typeof TrialState[keyof typeof TrialState];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TrialState = {
  NOT_STARTED: 'NOT_STARTED',
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  PURCHASED: 'PURCHASED',
  NOT_ELIGIBLE: 'NOT_ELIGIBLE',
} as const;
