/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Feature Trial API
 * This API defines the endpoints for the Feature Trial service.

 * OpenAPI spec version: 1.0.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type { Trial, ErrorMessage, AllTrials, ListTrialsParams } from './models';
import { customInstance } from '../core/customInstance';
import type { ErrorType } from '../core/customInstance';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * Get the state of a feature trial for the given context (e.g., practice, user).

 * @summary State of a feature trial
 */
export const getTrial = (
  trialName: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Trial>(
    { url: `/feature-trials/${trialName}`, method: 'get', signal },
    options,
  );
};

export const getGetTrialQueryKey = (trialName: string) => [`/feature-trials/${trialName}`] as const;

export const getGetTrialQueryOptions = <
  TData = Awaited<ReturnType<typeof getTrial>>,
  TError = ErrorType<ErrorMessage>,
>(
  trialName: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTrial>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<Awaited<ReturnType<typeof getTrial>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTrialQueryKey(trialName);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTrial>>> = ({ signal }) =>
    getTrial(trialName, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!trialName, ...queryOptions };
};

export type GetTrialQueryResult = NonNullable<Awaited<ReturnType<typeof getTrial>>>;
export type GetTrialQueryError = ErrorType<ErrorMessage>;

/**
 * @summary State of a feature trial
 */
export const useGetTrial = <
  TData = Awaited<ReturnType<typeof getTrial>>,
  TError = ErrorType<ErrorMessage>,
>(
  trialName: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTrial>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTrialQueryOptions(trialName, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Enable a feature trial for the given context (e.g., practice, user).

 * @summary Enable a feature trial
 */
export const enableTrial = (
  trialName: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Trial>(
    { url: `/feature-trials/${trialName}/actions/enable`, method: 'post' },
    options,
  );
};

export const getEnableTrialMutationOptions = <
  TError = ErrorType<ErrorMessage>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof enableTrial>>,
    TError,
    { trialName: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof enableTrial>>,
  TError,
  { trialName: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof enableTrial>>,
    { trialName: string }
  > = (props) => {
    const { trialName } = props ?? {};

    return enableTrial(trialName, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type EnableTrialMutationResult = NonNullable<Awaited<ReturnType<typeof enableTrial>>>;

export type EnableTrialMutationError = ErrorType<ErrorMessage>;

/**
 * @summary Enable a feature trial
 */
export const useEnableTrial = <TError = ErrorType<ErrorMessage>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof enableTrial>>,
    TError,
    { trialName: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getEnableTrialMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * List all feature trials for the given context (e.g., practice, user).

 * @summary Get a list of all feature trials.
 */
export const listTrials = (
  params?: ListTrialsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<AllTrials>(
    { url: `/feature-trials`, method: 'get', params, signal },
    options,
  );
};

export const getListTrialsQueryKey = (params?: ListTrialsParams) =>
  [`/feature-trials`, ...(params ? [params] : [])] as const;

export const getListTrialsQueryOptions = <
  TData = Awaited<ReturnType<typeof listTrials>>,
  TError = ErrorType<ErrorMessage>,
>(
  params?: ListTrialsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listTrials>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<Awaited<ReturnType<typeof listTrials>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListTrialsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listTrials>>> = ({ signal }) =>
    listTrials(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListTrialsQueryResult = NonNullable<Awaited<ReturnType<typeof listTrials>>>;
export type ListTrialsQueryError = ErrorType<ErrorMessage>;

/**
 * @summary Get a list of all feature trials.
 */
export const useListTrials = <
  TData = Awaited<ReturnType<typeof listTrials>>,
  TError = ErrorType<ErrorMessage>,
>(
  params?: ListTrialsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listTrials>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListTrialsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
