/**
 * Moves an element in an array from one index to another. Any elements (other than
 * the element being moved) at index >= destinationIndex are pushed back to (their current index + 1).
 *
 * This function creates a new array and does not mutate the input array.
 */
export function moveElement<T>(array: T[], sourceIndex: number, destinationIndex: number): T[] {
  const newArray = [...array];
  const [item] = newArray.splice(sourceIndex, 1);
  newArray.splice(destinationIndex, 0, item);
  return newArray;
}
