import { combineReducers } from 'redux';
import { entitiesReducer } from 'redux-query';
import { commonReducers } from 'utils/redux/common-reducers';
import queriesReducer from '../store/queriesReducer';

const staticReducer = (state: object = {}): object => state;

const rootReducer = combineReducers({
  ...commonReducers,
  queries: queriesReducer,
  entities: entitiesReducer,
  user: staticReducer,
  practice: staticReducer,
});

export default rootReducer;
