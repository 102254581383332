import { LegacyFriendlyDialog } from 'components/overlays';
import debounce from 'lodash/debounce';
import { useCurrentUser } from 'modules/practice-users';
import { PatientSearchResult } from 'QueryTypes';
import React, { useEffect, useState } from 'react';
import { logEvent } from 'utils/amplitude';
import {
  logGlobalPatientSearchInitiated,
  logGlobalPatientSearchLaunchedChart,
} from '../patient/amplitudeTaxonomy';
import GotoPatientMindreader from '../patient/GotoPatientMindreader';
import { logContextSwitchInitiated } from './amplitudeTaxonomy';

type FindChartDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (result: PatientSearchResult) => void;
};

const FindChartDialog: React.FC<FindChartDialogProps> = ({
  isOpen,
  onClose,
  onSelect,
  ...props
}) => {
  const [hasSearchInitiatedTracked, setHasSearchInitiatedTracked] = useState(false);
  const currentUser = useCurrentUser();

  const handleClose = (): void => {
    logEvent('cancel: navigation find chart');
    onClose();
  };

  const handlePatientSelect = (result: PatientSearchResult): void => {
    const isResultRemote: boolean =
      Boolean(result.practice_id) && result.practice_id !== el8Globals.PRACTICE_ID;

    logGlobalPatientSearchLaunchedChart(currentUser.staffType, 'find-chart-dialog', isResultRemote);

    if (isResultRemote) {
      logContextSwitchInitiated(
        currentUser.staffType,
        'find chart',
        el8Globals.PRACTICE_ID,
        result.practice_id,
      );
    }

    onSelect(result);
  };

  const onQueryChange = debounce(
    (): void => {
      if (!hasSearchInitiatedTracked) {
        logGlobalPatientSearchInitiated(currentUser.staffType, 'find-chart-dialog');
        setHasSearchInitiatedTracked(true);
      }
    },
    400,
    { leading: true },
  );

  useEffect(() => {
    if (isOpen) {
      logEvent('open: navigation find chart dialog');
    }
  }, [isOpen]);

  return (
    <LegacyFriendlyDialog
      hasDividers={false}
      isOpen={isOpen}
      onClose={handleClose}
      title="Find a Patient Chart"
      padded
      width={600}
      style={{ minHeight: 140 }}
      data-testid="find-chart-dialog-body"
      {...props}
    >
      <GotoPatientMindreader
        fullWidth
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
        label={null}
        onSelect={handlePatientSelect}
        onChange={onQueryChange}
        placeholder="Search by name, DOB, or phone number"
        data-testid="find-chart-patient-search"
      />
    </LegacyFriendlyDialog>
  );
};

export default FindChartDialog;
