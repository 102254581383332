import { StaffType } from 'constants/index';
import { logEvent } from 'utils/amplitude';

type GlobalPatientSearchSource = 'find-chart-dialog' | 'practice-home';

export const logGlobalPatientSearchInitiated = (
  staffType: StaffType,
  source: GlobalPatientSearchSource,
): void => {
  try {
    logEvent('Global Patient Search - search initiated', {
      source,
      'staff type': staffType,
    });
  } catch (err) {
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'
    console.warn(`Error while logging amplitude event: ${err.message}`);
  }
};

export const logGlobalPatientSearchLaunchedChart = (
  staffType: StaffType,
  source: GlobalPatientSearchSource,
  switchedContext: boolean,
): void => {
  try {
    logEvent('Global Patient Search - chart launched', {
      source,
      'staff type': staffType,
      'context switch initiated': switchedContext,
    });
  } catch (err) {
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'
    console.warn(`Error while logging amplitude event: ${err.message}`);
  }
};
