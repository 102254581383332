exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".EmergencyOnlyStatus__alert___dj1LJ {\n  background-color: #bb0202;\n  max-width: 400px;\n  z-index: 5001;\n  position: fixed;\n  bottom: 10px;\n  left: 30px;\n  border: 1px solid #fff;\n  text-align: center;\n  color: #fff;\n  padding: 10px;\n  border-radius: 3px;\n  font-size: 13px;\n  font-weight: 500;\n  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;\n  line-height: 1.42857;\n}\n", ""]);

// exports
exports.locals = {
	"alert": "EmergencyOnlyStatus__alert___dj1LJ"
};