import React, { useState } from 'react';

import { usePostSupportCreateCase } from '@elation/api-client/src/support/supportIntakeAndCommunication';
import { Group, Stack, Text2 } from '@el8/vital';
import { InputFormik } from '@el8/vital-formik';
import { useFormikContext } from 'formik';

import { CaseIntakeStep } from 'modules/support/supportConstants';
import toastError from 'utils/errors/toastError';
import { useFormikSubmitForm } from 'utils/hooks';

import { ValidationError } from 'utils/errors';
import { CaseCreation } from '@elation/api-client/src/support';
import { fileToBase64DataUrl } from 'utils/files/fileToBase64DataUrl';
import omit from 'lodash/omit';
import { CaseIntakeFormValues } from '../CaseIntakeForm';

import styles from './CaseIntakeFormContact.less';
import { logIntakeContactInfoSubmitted } from '../amplitudeTaxonomy';
import { CaseIntakeStepProps } from '../CaseIntakeTypes';
import CaseIntakeStepHeader from '../components/CaseIntakeStepHeader';
import { IntakeButton } from '../components/IntakeButton';

const CaseIntakeFormContact = ({
  goToNextStep,
  goToPrevStep,
  formRootRef,
  isInModal,
}: CaseIntakeStepProps): JSX.Element => {
  const { values, setSubmitting } = useFormikContext<CaseIntakeFormValues>();
  const validateForm = useFormikSubmitForm({ formRootRef });
  const { mutateAsync: createCase } = usePostSupportCreateCase();
  const [isLoading, setIsLoading] = useState(false);
  const submitForm = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await validateForm();
      if (!values.persona) {
        return;
      }
      await createCase({
        data: {
          ...omit(values, 'supportCaseFile'),
          question: values.question || values.details,
          file: values.supportCaseFile
            ? await fileToBase64DataUrl(values.supportCaseFile)
            : undefined,
          file_name: values.supportCaseFile?.name,
          url: window.location.href,
          replay_link: window.DD_RUM?.getSessionReplayLink() ?? 'No replay found',
        } as CaseCreation,
      });
      logIntakeContactInfoSubmitted(values);
      goToNextStep(CaseIntakeStep.CaseSubmitted);
    } catch (err) {
      if (!(err instanceof ValidationError)) {
        toastError(err);
      }
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Stack data-testid="support-case-form-contact-container" gap="md" className={styles.content}>
      <CaseIntakeStepHeader isInModal={isInModal} step={CaseIntakeStep.ContactInfo} />
      <Text2 sizing="lg">Please provide your contact info so that we can get back to you.</Text2>
      <div>
        <Stack gap="xs">
          <InputFormik
            label="Your name"
            labelPosition="column"
            data-testid="support-case-form-contact-name"
            name="name"
            required
          />
          <InputFormik
            label="Your email"
            labelPosition="column"
            data-testid="support-case-form-contact-email"
            name="email"
            required
          />
        </Stack>
      </div>
      <Group>
        <IntakeButton
          busy={isLoading}
          data-testid="support-case-form-contact-next-button"
          kind="primary"
          label="Submit"
          onClick={submitForm}
        />
        <IntakeButton
          disabled={isLoading}
          data-testid="support-case-form-back-button"
          label="Back"
          onClick={goToPrevStep}
        />
      </Group>
    </Stack>
  );
};

export default CaseIntakeFormContact;
