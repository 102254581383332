import createRenderFn from 'utils/legacy/createRenderFn';
import { Navbar } from 'views/shared/navbar';
import { createStoreConfigurer, getHydratedStore } from 'store/configureHydratedStore';
import { Store } from 'redux';
import { getStore } from 'store/createStore';
import rootReducer from './rootReducer';
import hydrate from './hydrate';

const configureBackupStore = createStoreConfigurer(rootReducer, hydrate);

/**
 * Attempts to find the redux store for a page that was created
 * either by calling `createStoreConfigurer` or `createStore`.
 */
const getAppStore = (): Store | undefined => {
  const hydratedStore = getHydratedStore();

  if (hydratedStore) return hydratedStore;

  const store = getStore();

  if (store) return store;

  console.error(
    'Unable to find redux store for the Global Navbar to use.' +
      'Be sure to call createStore() or createStoreConfigurer() in your page entry point.',
  );

  return undefined;
};

/**
 * Function to render to the reactified navbar
 */
const renderNavbar = createRenderFn(Navbar, getAppStore);

/**
 * Called in elation/templates/base-page.html
 */
window.renderNavbar = (): void => {
  renderNavbar('page-header');
};

/**
 * This checks to see if a redux store using `createStoreConfigurer` or `createStore`.
 *
 * If not, that means we are on a legacy page and we need to create our own store
 * for the navbar since the page does not have one.
 *
 * Called in elation/templates/base-page.html
 */
window.configureNavbarStore = (data: Record<string, unknown>): void => {
  const appStore = getAppStore();

  if (!appStore) {
    configureBackupStore(data);
  }
};
