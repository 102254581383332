import { Tooltip } from '@el8/vital';
import React from 'react';

type NavbarItemTooltipProps = {
  tooltip?: string;
};

export const NavbarItemTooltip = ({
  tooltip,
  children,
}: React.PropsWithChildren<NavbarItemTooltipProps>): JSX.Element => {
  if (tooltip) {
    return (
      <Tooltip content={tooltip} position="bottom">
        {children}
      </Tooltip>
    );
  }

  return <>{children}</>;
};
