exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ExportEmailConfirmation__text___1nEsm {\n  margin-top: 10px !important;\n}\n.ExportEmailConfirmation__checkboxField___2LofF {\n  margin-top: 40px;\n}\n.ExportEmailConfirmation__shareButton___JAOcu {\n  margin-top: 30px;\n}\n", ""]);

// exports
exports.locals = {
	"text": "ExportEmailConfirmation__text___1nEsm",
	"checkboxField": "ExportEmailConfirmation__checkboxField___2LofF",
	"shareButton": "ExportEmailConfirmation__shareButton___JAOcu"
};