import { useRequest } from 'redux-query-react';
import {
  getPhysician,
  getPracticePhysicians,
  getPracticePhysiciansById,
  practicePhysiciansQuery,
} from 'modules/physicians';
import { Physician } from 'EntityTypes';
import { EntitiesById } from 'StoreTypes';
import { UseDataInfo } from 'QueryTypes';
import { useAppSelector } from 'utils/hooks';

export const usePhysicians = (practiceId: number): UseDataInfo<Physician[] | null> => {
  const practicePhysicians = useAppSelector(getPracticePhysicians);
  const [queryState, refreshPracticePhysicians] = useRequest(practicePhysiciansQuery(practiceId));
  return [practicePhysicians, queryState, refreshPracticePhysicians];
};

export const usePhysiciansById = (
  practiceId: number,
): UseDataInfo<EntitiesById<Physician> | undefined> => {
  const practicePhysicians = useAppSelector(getPracticePhysiciansById);
  const [queryState, refreshPracticePhysicians] = useRequest(practicePhysiciansQuery(practiceId));
  return [practicePhysicians, queryState, refreshPracticePhysicians];
};

export const usePhysician = (
  practiceId: number,
  physicianId: number | null | undefined,
): UseDataInfo<Physician | null | undefined> => {
  const physician = useAppSelector((state) =>
    physicianId ? getPhysician(state, physicianId) : null,
  );
  const [queryState, refreshPracticePhysicians] = useRequest(practicePhysiciansQuery(practiceId));
  return [physician, queryState, refreshPracticePhysicians];
};
