import { createSelector } from 'reselect';

import { Physician } from 'EntityTypes';
import { EntitiesById, RootState } from 'StoreTypes';

/**
 * Gets physician object
 */
export const getPhysician = (state: RootState, physicianId: number): Physician | undefined =>
  state.entities?.physicians?.byId?.[physicianId];

/**
 * Get an object map of all active physicians in the practice, keyed by physician ID.
 * This selector currently assumes ONLY PHYSICIANS IN THE CURRENT USER'S PRACTICE will be
 * loaded into Redux.
 */
export const getPracticePhysiciansById = (state: RootState): EntitiesById<Physician> | undefined =>
  state.entities.physicians?.byId;

/**
 * Get an array of all active physicians in the practice. This selector currently assumes
 * ONLY PHYSICIANS IN THE CURRENT USER'S PRACTICE will be loaded into Redux.
 */
export const getPracticePhysicians = createSelector(
  getPracticePhysiciansById,
  (physiciansById): Physician[] | null => {
    if (!physiciansById) return null;

    return Object.values(physiciansById).sort((a, b) => a.full_name.localeCompare(b.full_name));
  },
);
