import { actionTypes } from 'redux-query';
import { pick } from 'lodash/object';

/**
 * Custom middleware to enable us to maintain indexes that facilitate
 * faster lookups for entities. Currently, this is used for displaying events in
 * the new scheduler, enabling us to quickly look up events by physician and day/week.
 *
 * In order to make use of this middleware, an `index` object should be included
 * in the request/mutation config's metadata. The index object's shape should be
 * as follows:
 *
 * @property {string} name - the name of the index, for example 'eventsByPhysician'
 * @property {string} entity - the entity being indexed, for example 'events'
 * @property {function} update - a function that accepts: (
 *   prevIndex (object, never null/undefined),
 *   prevEntities (object-map of key-entity pairs, never null/undefined),
 *   newEntities (object-map of key-entity pairs, never null/undefined),
 *   responseBody (object, may be null/undefined)
 * ) and returns the new index.
 */
const reduxQueryEntityIndexer =
  ({ getState }) =>
  (next) =>
  (action) => {
    const { meta, entities, responseBody, ...rest } = action;

    if (!meta || !meta.index) {
      // nothing for this middleware to do; just forward the action as-is
      return next(action);
    }

    const { entity, name, update } = meta.index;

    if (!entity) throw new Error('Missing `entity` string property in meta.index');
    if (!name) throw new Error('Missing `name` string property in meta.index');
    if (!update) throw new Error('Missing `update` function property in meta.index');

    switch (action.type) {
      case actionTypes.REQUEST_SUCCESS:
      case actionTypes.MUTATE_SUCCESS: {
        const state = getState();
        const prevIndex = state.indexes[name];
        const prevEntities = state.entities[entity];

        return next({
          ...rest,
          meta: {
            ...meta,
            // Don't include the 'update' property because it's a function and we
            // shouldn't have nonserializable actions reaching reducers.
            index: pick(meta.index, ['entity', 'name']),
          },
          entities,
          newIndex: update(
            prevIndex || {},
            prevEntities || {},
            entities[entity] || {},
            responseBody,
          ),
          responseBody,
        });
      }
      default:
        return next(action);
    }
  };

export default reduxQueryEntityIndexer;
