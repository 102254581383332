import React, { SVGProps } from 'react';

export const ElationLogoSVG = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="119"
      height="35"
      viewBox="0 0 119 35"
      fill="none"
      aria-labelledby="elationLogoTitle"
      {...props}
    >
      <title id="elationLogoTitle">Elation Health</title>
      <path
        fill="#ffffff"
        d="M14.06 7.17a.7.7 0 0 1-.7.69h-1.28a.7.7 0 0 1-.7-.7V.79c0-.38.31-.69.7-.69h1.29c.38 0 .7.31.7.7v6.38ZM28.84 34.5H11.42V11.26h17.42v4.25H15.77v5.35h12.6v4.08h-12.6v5.24h13.07v4.32ZM35.95 11.3h-4.02v23.2h4.02V11.3ZM42.67 26.3c0 3.12 2.16 4.98 4.86 4.98 6.38 0 6.38-9.92 0-9.92-2.7 0-4.86 1.82-4.86 4.94Zm9.84-8.16h3.9V34.5h-3.83l-.2-2.39c-.93 1.93-3.5 2.86-5.32 2.89-4.85.03-8.44-2.95-8.44-8.7 0-5.64 3.75-8.6 8.54-8.56 2.2 0 4.29 1.03 5.22 2.65l.13-2.25ZM65.54 13.53v4.64h4.53v3.49H65.5v7.07c0 1.56.87 2.32 2.13 2.32.63 0 1.36-.2 1.96-.5l1.13 3.46a9.07 9.07 0 0 1-3.36.7c-3.55.13-5.88-1.9-5.88-5.98v-7.07h-3.06v-3.49h3.06v-4.21l4.05-.43ZM73.1 18.07V34.5h4.05V18.07h-4.06Zm4.42-4.55c0 3.16-4.8 3.16-4.8 0 0-3.15 4.8-3.15 4.8 0ZM84.1 26.33c0 2.5 1.5 4.82 4.45 4.82 2.96 0 4.45-2.33 4.45-4.82 0-2.45-1.72-4.84-4.45-4.84-2.92 0-4.45 2.39-4.45 4.84Zm13 0c0 4.72-3.23 8.54-8.55 8.54s-8.51-3.82-8.51-8.54c0-4.68 3.26-8.53 8.48-8.53 5.22 0 8.57 3.85 8.57 8.53ZM111.96 34.5v-8.56c0-2.5-1.37-4.39-3.96-4.39-2.5 0-4.19 2.1-4.19 4.58v8.37H99.8V18.1h3.62l.27 2.23c1.66-1.63 3.32-2.46 5.38-2.46 3.86 0 6.95 2.89 6.95 8.03v8.6h-4.05ZM.79 13.89a.7.7 0 0 1-.7-.7v-1.28c0-.38.31-.69.7-.69h6.43c.38 0 .7.31.7.7v1.28a.7.7 0 0 1-.7.69H.79ZM17.47 9.72a.7.7 0 0 1-.98 0l-.91-.9a.69.69 0 0 1 0-.98l4.55-4.52a.7.7 0 0 1 .98 0l.91.9c.28.28.28.71 0 .98l-4.55 4.52ZM5.24 21.87a.7.7 0 0 1-.98 0l-.91-.91a.69.69 0 0 1 0-.98l4.55-4.51a.7.7 0 0 1 .98 0l.91.9c.28.27.28.71 0 .98l-4.55 4.52ZM3.35 5.14a.69.69 0 0 1 0-.98l.91-.9a.7.7 0 0 1 .99 0l4.55 4.5c.27.28.27.72 0 .99l-.92.9a.7.7 0 0 1-.98 0L3.35 5.14Z"
      />
    </svg>
  );
};
