import { useCallback } from 'react';
import { useAppDispatch } from 'utils/hooks';
import {
  closeInviteColleagueDialog,
  closeProviderSecurityCodeDialog,
  closeRestoreDeletedDocumentsDialog,
  openInviteColleagueDialog,
  openProviderSecurityCodeDialog,
  openRestoreDeletedDocumentsDialog,
} from './navbarSlice';

export function useOpenInviteColleagueDialog(): VoidFunction {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(openInviteColleagueDialog());
  }, [dispatch]);
}

export function useCloseInviteColleagueDialog(): VoidFunction {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(closeInviteColleagueDialog());
  }, [dispatch]);
}

export function useOpenProviderSecurityCodeDialog(): VoidFunction {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(openProviderSecurityCodeDialog());
  }, [dispatch]);
}

export function useCloseProviderSecurityCodeDialog(): VoidFunction {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(closeProviderSecurityCodeDialog());
  }, [dispatch]);
}

export function useOpenRestoreDeletedDocumentsDialog(): VoidFunction {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(openRestoreDeletedDocumentsDialog());
  }, [dispatch]);
}

export function useCloseRestoreDeletedDocumentsDialog(): VoidFunction {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(closeRestoreDeletedDocumentsDialog());
  }, [dispatch]);
}
