import { updateUrlParameter } from 'utils/http';

const urls = {
  practiceSearch(queryString) {
    const url = '/internal_tool/practices/search/';
    return updateUrlParameter(url, 'practice', encodeURIComponent(JSON.stringify(queryString)));
  },
  clinicalUserSearch(queryString) {
    const url = '/el8_admin/clinical-users/autocomplete/';
    return updateUrlParameter(url, 'term', encodeURIComponent(JSON.stringify(queryString)));
  },
};

export const practiceSearchQuery = (queryString, practiceTypes) => ({
  url: urls.practiceSearch(queryString, practiceTypes),
  update: () => {},
  force: true,
});

export const clinicalUserSearchQuery = (queryString) => ({
  url: urls.clinicalUserSearch(queryString),
  update: () => {},
  force: true,
});
