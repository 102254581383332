import { useCallback } from 'react';

type Options = {
  disabled?: boolean;
};

type ButtonLikeProps<T> = {
  onClick: React.MouseEventHandler<T> | undefined;
  onKeyPress: React.KeyboardEventHandler<T> | undefined;
  role: 'button';
  tabIndex: number | undefined;
};

/**
 * A hook for bestowing button-like qualities to elements that aren't actually
 * buttons. Provides the props needed to make the element focusable, have the proper
 * accessibility attributes, respond to clicks as well as the keyboard, etc.
 *
 * NOTE: to minimize component re-renders, keep the onClick reference you pass
 * into this hook stable, because this hook uses useCallback internally with `onClick`
 * in its dependency array.
 */
export default function usePseudoButton<T>(
  onClick: (event: React.SyntheticEvent<T>) => void,
  options: Options = {},
): ButtonLikeProps<T> {
  const { disabled = false } = options;

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<T>): void => {
      switch (event.key) {
        case 'Enter':
        case 'Space':
        case ' ':
          onClick(event);
          break;
        default:
          break;
      }
    },
    [onClick],
  );

  const isClickable = !disabled && Boolean(onClick);

  return {
    onClick: isClickable ? onClick : undefined,
    onKeyPress: isClickable ? handleKeyPress : undefined,
    role: 'button',
    tabIndex: isClickable ? 0 : undefined,
  };
}
