/**
 * Returns the sub-key under which entities of a given type are stored. A null
 * return value means the entities of that type are stored at the top level -
 * like having to access an event with ID 99 using:
 *     state.entities.events[99]
 *
 * rather than the typical structure where you'd need to use:
 *     state.entities.events.byId[99] (for this structure, this function returns 'byId')
 */
export function getStorageSubkey(entityName) {
  switch (entityName) {
    case 'events':
    case 'recurrings':
      return null;
    default:
      return 'byId';
  }
}
