import React from 'react';
import cn from 'classnames';
import styles from './Spacer.less';

type Props = {
  className?: string;
  size?: 'xsmall' | 'small' | 'medium' | 'large';
};

/**
 * A layout component used to provide vertical spacing in between elements. By default,
 * this component will NOT be displayed (via display: none) if it's the first or
 * last child of its parent. This is to facilitate easier spacing in between elements
 * that are conditionally rendered, e.g.:
 *
 * <div>
 *   {foo && <div>Foo</div>}
 *   <Spacer />
 *   {bar && <div>Bar</div>}
 * </div>
 *
 * The above example will only display Spacer if both `foo` and `bar` are truthy,
 * because otherwise there is no "between elements" to give spacing to.
 */
const Spacer: React.FC<Props> = ({ className, size = 'medium' }) => {
  return <div className={cn(styles.spacer, styles[size], className)} />;
};

export default Spacer;
