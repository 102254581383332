import { IconName, Position, Snackbar } from '@el8/vital';

const AppSnackbar = Snackbar.create({ position: Position.TOP });

/**
 * Props used for configuring a toast.
 *
 * Taken from `@blueprintjs/core`'s `IToastProps` interface. Does not include
 * inherited props from `IProps` or `IIntentProps`.
 */
export interface ToastProps {
  /** Name of a Blueprint UI icon (or an icon element) to render before the message. */
  icon?: IconName | JSX.Element;
  /** Message to display in the body of the toast. */
  message: string;
  /**
   * Callback invoked when the toast is dismissed, either by the user or by the timeout.
   * The value of the argument indicates whether the toast was closed because the timeout expired.
   */
  onDismiss?: (didTimeoutExpire: boolean) => void;
  /**
   * Milliseconds to wait before automatically dismissing toast.
   * Providing a value less than or equal to 0 will disable the timeout (this is discouraged).
   * @default 5000
   */
  timeout?: number;
}

/**
 * Shows a toast at the top-middle of the window to notify the user of something.
 *
 * **Note**: in the practice home and patient chart pages, this will display a legacy el8 notification
 * instead of a toast. This is for UI consistency so we don't mix notification styles due to there
 * still being a lot of legacy elements on those pages using legacy notifications. When we get
 * enough of those pages migrated to React, we can remove this behavior.
 *
 * @returns a unique key that identifies the toast being shown. This is
 * used and can be passed as the second parameter to allow updating currently-showing toasts.
 */
export default function toast(message: string | ToastProps, toastKey?: string): string {
  const toastProps = typeof message === 'string' ? { message } : message;

  if (el8Globals.APP === 'home' || el8Globals.APP === 'chart') {
    el8.appservice.notifier.notify(toastProps.message);
    return '_INVALID_TOAST_KEY_BECAUSE_USING_LEGACY_TOASTS_';
  }

  return AppSnackbar.show(toastProps, toastKey);
}
