import React, { useState } from 'react';
import FindChartDialog from 'views/shared/header/FindChartDialog';
import { NavbarItem } from '../../components/NavbarItem';

export const FindChartItem = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = (): void => setIsOpen(false);
  const handleToggleOpen = (): void => {
    setIsOpen((nextIsOpen) => !nextIsOpen);
  };

  return (
    <>
      <NavbarItem type="button" onClick={handleToggleOpen} data-testid="find-chart-dialog-trigger">
        Find Chart
      </NavbarItem>
      <FindChartDialog isOpen={isOpen} onClose={onClose} onSelect={onClose} />
    </>
  );
};
