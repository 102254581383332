import { ResourceType } from 'EntityTypes';
import { createLogEvent } from 'utils/amplitude';
import { CommonReferenceEntityProperties } from 'modules/workspaces/workspacesTypes.d';

/**
 * import { ResourceType } from 'EntityTypes' but add pe and
 * ros ChecklistTemplates to match output from the helper below,
 * buildWorkspaceReferenceAnalyticsProps. Amplitude is otherwise
 * unclear which type of template the user might have been using.
 */
export type WorkspacesReferenceGroupNames =
  | ResourceType
  | 'peChecklistTemplate'
  | 'rosChecklistTemplate';

/**
 * import { ResourceType } from 'EntityTypes' but remove types
 * that are not optional for each workspace
 */
export type WorkspacesOptionalReferenceEntityTypes = Omit<ResourceType, 'StaffGroup'>;

/**
 * A common type used throughout this file
 */
type WorkspaceIdentifier = {
  workspaceId: number;
  workspaceName?: string;
};
/**
 * Track users switching out of workspaces / into practice view
 */
export const logLeaveWorkspacesView =
  createLogEvent<WorkspaceIdentifier>('return to practice view');

/**
 * Track users switching into a workspace
 */
export const logSwitchIntoWorkspace = createLogEvent<WorkspaceIdentifier>('select workspace');

export type HelperLinkIdentifier = Partial<WorkspaceIdentifier> & {
  linkText: string;
  source:
    | 'workspaces settings card intro'
    | 'workspaces authorized users settings card'
    | 'workspaces people list settings card'
    | 'workspaces pe/ros card intro'
    | 'workspaces letter template card intro'
    | 'workspaces detail page title'
    | 'workspaces list page card title';
};

/**
 * Track users who use links on the workspaces settings pages
 */
export const logClickHelperLink = createLogEvent<HelperLinkIdentifier>('click settings page link');

/**
 * Track users who click the edit button for a workspace
 */
export const logGoToWorkspaceDetails = createLogEvent<HelperLinkIdentifier>('click edit workspace');

type AccordionActions = 'expand' | 'collapse'; // verbs seen in amplitude event name
type WorkspacesSettingsAccordionClick = WorkspaceIdentifier & {
  action: AccordionActions; // placed in event name on amplitude
  title: string; // the main title text for the accordion aka what the user clicked on
  source: string; // what page the accordion is on
};
/**
 * Track users who expand or collapse a settings accordion (currently only used for templates)
 */
export const logSettingsAccordionStateChange = createLogEvent<WorkspacesSettingsAccordionClick>(
  'settings accordion',
  'action',
);

/**
 * A modal dialog is used for create workspace and name edits
 */
export type WorkspacePopupButtonLocations =
  | 'workspaces list page main title'
  | 'workspaces list page card title'
  | 'workspaces list page intro'
  | 'workspaces detail page title';
export type WorkspacePopupProcessStages = 'start' | 'cancel' | 'complete'; // verbs seen in amplitude event name
export type WorkspacePopupTracker = Partial<WorkspaceIdentifier> & {
  step: WorkspacePopupProcessStages; // placed in event name on amplitude
  source: WorkspacePopupButtonLocations;
};

/**
 * Track Users Creating a Workspace
 */
export const logCreateWorkspace = createLogEvent<WorkspacePopupTracker>('create workspace', 'step');
/**
 * Track Users Renaming a Workspace
 */
export const logEditWorkspaceName = createLogEvent<WorkspacePopupTracker>(
  'rename workspace',
  'step',
);
/**
 * Track users Deleting a Workspace
 */
export const logDeleteWorkspace = createLogEvent<WorkspacePopupTracker>('delete workspace', 'step');

/**
 * The user picks timezone by name
 */
type UpdateTzProperties = WorkspaceIdentifier & {
  timezone: string;
};

/**
 * Track Users Updating Workspace Timezone
 */
export const logEditWorkspaceTimezone = createLogEvent<UpdateTzProperties>('change timezone');

type UpdateReferencesActions = 'add' | 'remove'; // verbs seen in amplitude event name

// see also buildWorkspaceReferenceAnalyticsProps
type UpdateReferencesProperties = WorkspaceIdentifier & {
  action: UpdateReferencesActions; // placed in event name on amplitude
  type: WorkspacesReferenceGroupNames;
  name: string;
  source: string; // something indicating the page the event comes from
};

/**
 * Track Users Updating Workspace Reference Lists
 */
export const logEditWorkspaceReferences = createLogEvent<UpdateReferencesProperties>(
  'reference',
  'action',
);

type ReferenceGroupFlagActions = 'enable' | 'disable'; // verbs seen in amplitude event name
export type WorkspacesReferenceTypeOnOffChange = WorkspaceIdentifier & {
  action: ReferenceGroupFlagActions; // placed in event name on amplitude
  type: WorkspacesOptionalReferenceEntityTypes;
  source: string; // indicates the page or location the event comes from
};

/**
 * Track users who enable or disable a reference type for the workspace
 */
export const logWorkspaceReferencesTypeEnabledOrDisabled =
  createLogEvent<WorkspacesReferenceTypeOnOffChange>('reference type', 'action');

/**
 * Create the object that will be accepted by logEditWorkspaceReferences
 * @param item an entity
 * @param sourceType the ResourceType of item
 * @returns workspacesReferenceGroupNames aka generally sourceType unless ChecklistTemplate
 * then peChecklistTemplate or rosChecklistTemplate as identified by item.type
 */
export function buildWorkspaceReferenceAnalyticsProps<T extends CommonReferenceEntityProperties>(
  workspaceId: number,
  action: UpdateReferencesActions,
  item: T,
  sourceType: ResourceType,
  analyticsLocation: string,
): UpdateReferencesProperties {
  return {
    workspaceId,
    action,
    type:
      sourceType === 'ChecklistTemplate' && 'type' in item
        ? (`${item.type}${sourceType}` as WorkspacesReferenceGroupNames)
        : sourceType,
    name: item.displayName || item.name || item.description || '',
    source: analyticsLocation,
  };
}
