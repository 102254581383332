import { useState, useCallback } from 'react';

type UseHover = [
  boolean,
  {
    onMouseEnter: () => void;
    onMouseLeave: () => void;
  },
];

const useHover = (): UseHover => {
  const [isHovering, setHovering] = useState(false);
  const handleMouseEnter = useCallback((): void => setHovering(true), [setHovering]);
  const handleMouseLeave = useCallback((): void => setHovering(false), [setHovering]);
  return [
    isHovering,
    {
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
    },
  ];
};

export default useHover;
