enum UserPermissionLogsEventType {
  UserDisabled = 'disable',
  UserEnabled = 'enable',
  AdminEnabled = 'admin_enabled',
  AdminDisabled = 'admin_disabled',
  CommentAdded = 'comment',
  BusinessAgreementSigned = 'ba_signed',
  UserAuthenticationCompleted = 'auth_completed',
  DocumentUploaded = 'doc_uploaded',
  UserSignedUp = 'sign_up',
  EmailVerificationSent = 'email_verif_sent',
  EmailNotificationSent = 'email_notification',
}

export default UserPermissionLogsEventType;
