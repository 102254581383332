import { createSelector } from 'reselect';

const _getDocuments = (state) => state.entities.documents;

export const getAuditLogEntries = (state) =>
  state.entities.auditLogEntries ? Object.values(state.entities.auditLogEntries) : [];

/**
 * Selector to get documents for the patietn documents view. Signature:
 * (state, { startDate: js Date, endDate: js Date }) => documents[]
 */
export const getDocuments = createSelector(
  _getDocuments,
  (_state, options) => (options ? options.startDate : undefined),
  (_state, options) => (options ? options.endDate : undefined),
  (documents, startDate, endDate) => {
    if (!documents) {
      return [];
    }
    const documentsArray = Object.values(documents);
    if (!startDate && !endDate) {
      return documentsArray;
    }

    const startTime = startDate && startDate instanceof Date ? startDate.getTime() : undefined;
    const endTime = endDate && endDate instanceof Date ? endDate.getTime() : undefined;

    if (!(startTime && endTime)) {
      return documentsArray;
    }

    return documentsArray.filter(({ entityDate }) => {
      const documentTime = new Date(entityDate).getTime();
      if (startTime && endTime) {
        return startTime <= documentTime && documentTime <= endTime;
      }
      if (startTime) {
        return startTime <= documentTime;
      }
      if (endTime) {
        return documentTime <= endTime;
      }
      return true;
    });
  },
);

export const getEntity = (state, { entityId }) =>
  state.entities.entities ? state.entities.entities[entityId] : undefined;
