import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { connectRequest } from 'redux-query-react';
import { Text } from '@el8/vital';
import { Flexbox } from 'components/layout';
import { getDocumentsQuery, getDocuments, getEntityQuery } from 'modules/passport';

import styles from './DocumentsList.less';

class DocumentsList extends PureComponent {
  static propTypes = {
    openClinicalSummary: PropTypes.func.isRequired,
  };

  render() {
    const { openClinicalSummary } = this.props;
    return (
      <div className={styles.documents}>
        <Flexbox className={styles.header} align="start">
          <div className={styles.summary}>
            <Text tag="div" className={styles.headerSummary}>
              Document
            </Text>
          </div>
          <div className={styles.description}>
            <Text tag="div" className={styles.headerDescription}>
              Type
            </Text>
          </div>
        </Flexbox>
        {/* I did not sign up to fix this, I'm just trying to upgrade react-redux. Fix this later
        at a better time */}
        {/* eslint-disable-next-line */}
        <a onClick={openClinicalSummary} className={styles.documentLink} rel="noopener noreferrer">
          <Flexbox className={styles.document} align="start">
            <div className={styles.summary}>
              <Text tag="div">Clinical Summary</Text>
            </div>
            <div className={styles.description}>
              <Text tag="div" appearance="system" className={styles.documentDescription}>
                CCDA
              </Text>
            </div>
          </Flexbox>
        </a>
      </div>
    );
  }
}

const mapPropsToQueries = ({ startDate, endDate, documents }) => {
  const queries = [
    getDocumentsQuery(startDate, endDate),
    ...documents.map(({ entityId }) => ({
      ...getEntityQuery(entityId),
      force: true,
    })),
  ];

  return queries;
};

const mapStateToProps = (state, { startDate, endDate }) => ({
  documents: getDocuments(state, { startDate, endDate }),
});

export default compose(connect(mapStateToProps), connectRequest(mapPropsToQueries))(DocumentsList);
