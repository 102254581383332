import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DateRangeInput, Button, ButtonGroup } from '@el8/vital';
import { Flexbox } from 'components/layout';
import { encodeObjToUrlParams } from 'utils/urls/url-utils';

import { VIEWS } from '../helpers';
import DocumentsList from './DocumentsList';
import styles from './Documents.less';

class Documents extends Component {
  state = {
    startDate: null,
    endDate: null,
    selectedDocuments: { clinicalSummary: true },
  };

  handleDateRangeChange = ([startDate, endDate]) => {
    this.setState({ startDate, endDate });
  };

  navigateToEmailDataView = () => {
    const { startDate, endDate } = this.state;
    this.props.changeView(VIEWS.emailHome, { startDate, endDate });
  };

  openClinicalSummary = () => {
    const { startDate, endDate } = this.state;
    const params = {};
    if (startDate) {
      params.startDate = startDate.toISOString();
    }
    if (endDate) {
      params.endDate = endDate.toISOString();
    }
    window.open(`/passport/ccda/view?${encodeObjToUrlParams(params)}`, '_blank');
  };

  downloadSelectedDocuments = () => {
    const { selectedDocuments, startDate, endDate } = this.state;
    const params = { ...selectedDocuments };
    if (startDate) {
      params.startDate = startDate.toISOString();
    }
    if (endDate) {
      params.endDate = endDate.toISOString();
    }
    window.location.href = `documents/export?${encodeObjToUrlParams(params)}`;
  };

  render() {
    const { startDate, endDate } = this.state;
    return (
      <div className={styles.documents}>
        <Flexbox justify="space-between">
          <DateRangeInput
            allowSingleDayRange
            onChange={this.handleDateRangeChange}
            maxDate={new Date()}
            value={[startDate, endDate]}
            endInputProps={{ 'aria-label': 'End Date' }}
            startInputProps={{ 'aria-label': 'Start Date' }}
          />

          <ButtonGroup>
            <Button onClick={this.downloadSelectedDocuments}>Download Data</Button>
            <Button onClick={this.navigateToEmailDataView}>Email Data</Button>
          </ButtonGroup>
        </Flexbox>
        <DocumentsList {...this.state} openClinicalSummary={this.openClinicalSummary} />
      </div>
    );
  }
}

Documents.propTypes = {
  changeView: PropTypes.func.isRequired,
};

export default Documents;
