import React from 'react';
import { Stack, Text2 } from '@el8/vital';
import { useEffectOnce } from 'utils/hooks';
import { useFormikContext } from 'formik';
import { CaseIntakeStep } from 'modules/support/supportConstants';
import { CaseIntakeFormValues } from '../CaseIntakeForm';
import { logIntakeViewTicketConfirmation } from '../amplitudeTaxonomy';
import { CaseIntakeStepProps } from '../CaseIntakeTypes';
import CaseIntakeStepHeader from '../components/CaseIntakeStepHeader';

import styles from './CaseIntakeFormConfirmation.less';

const SupportCaseFormConfirmation: React.FC<CaseIntakeStepProps> = ({ isInModal }): JSX.Element => {
  const { values } = useFormikContext<CaseIntakeFormValues>();
  useEffectOnce(() => logIntakeViewTicketConfirmation(values));
  return (
    <Stack
      data-testid="support-case-form-confirmation-container"
      gap="md"
      className={styles.content}
    >
      <CaseIntakeStepHeader isInModal={isInModal} step={CaseIntakeStep.CaseSubmitted} />
      <Text2 sizing="lg">
        Thank you for your submission. An Elation team member will investigate this issue and
        respond to you via your email within two business days.
      </Text2>
    </Stack>
  );
};

export default SupportCaseFormConfirmation;
