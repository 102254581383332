import { LegacyFriendlyDialog } from 'components/overlays';
import { PatientSearchResult } from 'QueryTypes';
import React, { useCallback, useState } from 'react';
import { Button, ButtonGroup, Checkbox, Text } from '@el8/vital';

import styles from './GlobalPatientSearchFTUEDialog.less';

type Props = {
  practiceName: string;
  isOpen: boolean;
  selectedResult?: PatientSearchResult;
  onClose: () => void;
  onConfirm: () => void;
  onCancel: () => void;
  dismissExperience: () => void;
};

const formatPracticeNameWithNbsp = (str: string): string => {
  const lastIndex = str.lastIndexOf(' ');

  // If there's a space, replace the space with a non-breaking space
  // so that any widows are at least 2 words long
  return lastIndex > -1 ? `${str.substr(0, lastIndex)}\u00a0${str.substr(lastIndex + 1)}` : str;
};

export const GlobalPatientSearchFTUEDialog: React.FC<Props> = ({
  practiceName,
  isOpen,
  selectedResult,
  onClose,
  onCancel,
  onConfirm,
  dismissExperience,
}): JSX.Element => {
  const [willDismissFTUE, setWillDismissFTUE] = useState(false);
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  const destinationPracticeName = selectedResult.practice_name ?? 'Other Practice';

  const handleCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    setWillDismissFTUE(event.target.checked);
  }, []);

  const handleConfirm = () => {
    if (willDismissFTUE) {
      dismissExperience();
    }

    onConfirm();
    onClose();
  };

  const handleCancel = () => {
    onCancel();
    onClose();
  };

  const dialogTitle = (
    <span>Switch to patient chart in {formatPracticeNameWithNbsp(destinationPracticeName)}?</span>
  );

  return (
    <LegacyFriendlyDialog
      title={dialogTitle}
      padded
      width={400}
      hasDividers={false}
      isOpen={isOpen}
      data-testid="global-patient-search-ftue-dialog"
    >
      <div className={styles.dialogContent}>
        <Text tag="p">
          This patient chart is in {destinationPracticeName}. Switching to this chart means any{' '}
          {practiceName} browser tabs will be logged out. You can always switch back to{' '}
          {practiceName} to recover an open tab.
        </Text>

        <Text tag="p">
          <Checkbox
            checked={willDismissFTUE}
            label="Don't show this dialog again"
            onChange={handleCheckboxChange}
          />
        </Text>

        <ButtonGroup spaced>
          <Button kind="primary" onClick={handleConfirm} rightIconName="arrow-top-right">
            Yes, switch to {destinationPracticeName}
          </Button>

          <Button onClick={handleCancel}>Cancel</Button>
        </ButtonGroup>
      </div>
    </LegacyFriendlyDialog>
  );
};
