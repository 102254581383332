import { useEffect } from 'react';
import messenger, { MessengerEvent } from 'utils/legacy/messenger';
import { AnyMessengerMessage } from 'utils/legacy/messengerTypes';
import { AnyWebsocketMessage } from 'utils/websockets';

/**
 * A custom hook for adding subscriptions to the el8-to-React messenger. Use this for
 * client-to-client messenger events - for websocket events, use `useWebsocketListener` instead.
 */
export default function useMessengerListener(
  event: MessengerEvent,
  handlerFn: (payload?: AnyMessengerMessage) => void,
): void {
  useEffect(() => {
    messenger.on<AnyMessengerMessage>(event, handlerFn);
    return (): void => {
      messenger.off<AnyMessengerMessage>(event, handlerFn);
    };
  }, [event, handlerFn]);
}

/**
 * A custom hook for listening to websocket messages for a specific document type.
 */
export function useWebsocketListener(
  handlerFn: (message?: AnyWebsocketMessage) => void,
  channel?: string,
): void {
  useEffect(() => {
    messenger.on<AnyWebsocketMessage>(MessengerEvent.WebsocketEvent, handlerFn, channel);
    return (): void => {
      messenger.off<AnyWebsocketMessage>(MessengerEvent.WebsocketEvent, handlerFn, channel);
    };
  }, [channel, handlerFn]);
}
