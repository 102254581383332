import { useEffect } from 'react';
import { useFormikContext } from 'formik';

type Options = {
  isNullValid?: boolean;
};

/**
 * @DEPRECATED DO NOT USE because it doesn't work properly with Formik's `dirty`, `initialValues` and `resetForm`
 * functionality (they won't interact properly with updates made via this hook)
 *
 * A hook for initializing form values that may not necessarily be ready immediately.
 * This is most common when the desired initial value of a field depends on the result
 * of some data that needs to be fetched, so it can't be assumed that the data is
 * present during form initialization. It also allows form components to not need to be
 * wired up to entity data used by its inputs, instead allowing the child input to initialize
 * the value in its parent form as the sole user of that particular entity's data.
 *
 * Sample usage: useInitializeValueWhenReady(null as FormValues, 'user_id', author?.user_id)
 *
 * The above code will set the 'user_id' field to author's user ID whenever author becomes
 * available (non-undefined), IFF the `user_id` field still has a null or undefined value at that time.
 * This should also work for cases where the author has already been loaded on initial render.
 *
 * @param _formValues - this is a dummy argument used to help Typescript completely
 * infer all type arguments. This is because Typescript doesn't support partial type
 * inference (yet?), and explicitly specifying all type arguments would require
 * redundantly specifying fieldName/FieldName and value/TValue.
 * See: https://stackoverflow.com/a/57362442
 * @param fieldName - the name of the form field to initialize
 * @param value - the value to initialize the field with. Passing a null or undefined value here
 * will do nothing, unless the `isNullValid` option is enabled, in which case a null value will
 * be treated as a valid incoming value to initialize the field with.
 * @param [options.isNullValid] - if true, a field with a null value will be considered already initialized
 * and this hook won't attempt to initialize it with `value`.
 */
export default function useInitializeValueWhenReady<
  TFormValues extends Record<FieldName, TValue>,
  FieldName extends string,
  TValue,
>(_formValues: TFormValues, fieldName: FieldName, value: TValue, options: Options = {}): void {
  const { isNullValid = false } = options;
  const { setFieldValue, values } = useFormikContext<TFormValues>();

  const currentFieldValue = values[fieldName];

  useEffect(() => {
    const fieldIsInitialized = isNullValid
      ? currentFieldValue !== undefined
      : currentFieldValue != null;
    const valueIsReady = isNullValid ? value !== undefined : value != null;
    if (!fieldIsInitialized && valueIsReady) {
      setFieldValue(fieldName, value);
    }
    // Can't use dependency array here or the effect will not work reliably. Something weird
    // going on with ordering between Formik's form initialization, Formik's setFieldValue calls,
    // and calls of this hook by components inside of the form, which can lead to the setFieldValue
    // call being "swallowed" and then never running again because dependencies in the array
    // haven't changed. This results in the field staying uninitialized.
  });
}
