import React from 'react';
import PropTypes from 'prop-types';
import { Button, HeaderText, Icon } from '@el8/vital';
import { Flexbox } from 'components/layout';
import styles from './ExportEmailSuccess.less';

const ExportEmailSuccess = ({ destinationEmail }) => (
  <Flexbox direction="column" align="center">
    <HeaderText legacyVariant="legacyHeaderText" tag="h1">
      Success
    </HeaderText>
    <Icon icon="tick-circle" color="#8EC243" iconSize="32" />
    <HeaderText legacyVariant="legacyHeaderText" tag="h1">
      Your information has been shared with
    </HeaderText>
    <b>
      <HeaderText className={styles.email} legacyVariant="legacyHeaderText" tag="h1">
        {destinationEmail}
      </HeaderText>
    </b>
    <a href="/passport/?p=export">
      <Button>Home</Button>
    </a>
  </Flexbox>
);

ExportEmailSuccess.propTypes = {
  destinationEmail: PropTypes.string.isRequired,
};

export default ExportEmailSuccess;
