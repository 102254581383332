import { camelizeKeys } from 'humps';
import { normalize } from 'normalizr';
import { mutateAsync } from 'redux-query';
import { documentSchema, auditLogEntrySchema, entitySchema } from '../../shared/schema';

export const getDocumentsQuery = (startDate, endDate) => {
  const query = {
    body: {},
    url: '/passport/documents',
    transform: (responseJson) => normalize(camelizeKeys(responseJson), [documentSchema]).entities,
    update: {
      documents: (prevDocuments, fetchedDocuments) => ({
        ...prevDocuments,
        ...fetchedDocuments,
      }),
    },
  };
  if (startDate && startDate instanceof Date) {
    query.body.startDate = startDate.toISOString();
  }
  if (endDate && endDate instanceof Date) {
    query.body.endDate = endDate.toISOString();
  }
  return query;
};

export const getAuditLogEntriesQuery = () => {
  const query = {
    body: {},
    url: '/passport/audit-log',
    transform: (responseJson) =>
      normalize(camelizeKeys(responseJson), [auditLogEntrySchema]).entities,
    update: {
      auditLogEntries: (prevAuditLogEntries, fetchedAuditLogEntries) => ({
        ...prevAuditLogEntries,
        ...fetchedAuditLogEntries,
      }),
    },
  };
  return query;
};

export const getEntityQuery = (id) => ({
  url: `/passport/items/?id%5B%5D=${id}`,
  transform: (responseJson) => normalize(camelizeKeys(responseJson), [entitySchema]).entities,
  update: {
    entities: (prevEntities, fetchedEntities) => ({
      ...prevEntities,
      ...fetchedEntities,
    }),
  },
});

export const shareDocumentsQuery = (email, startDate, endDate, encrypted) => {
  const query = {
    method: 'POST',
    body: {
      email,
      startDate,
      endDate,
      encrypted,
      clinicalSummary: true,
    },
    url: '/passport/documents/share',
  };
  return mutateAsync(query);
};
