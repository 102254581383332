import isArray from 'lodash/isArray';
import pick from 'lodash/pick';
import { UpdateStrategy } from 'redux-query';
import {
  WorkspaceFeFilteredResourceTypeNames,
  WorkspaceFeFilteredResourceTypes,
  WorkspaceFrontendFiltersState,
} from './currentWorkspaceFiltersTypes';
import { buildWorkspaceFiltersInitialState } from './currentWorkspaceFiltersUtils';

/**
 * For use in YOUR queries file, this generates a transormer that will extract
 * from response json in a way that allows you to update the workspace filters.
 *
 * Aka use in conjunction with makeUpdateWorkspaceFrontendFilter
 */
export function transformToWorkspaceFrontendFilter<T extends WorkspaceFeFilteredResourceTypes>(
  jsonData: T | T[],
): WorkspaceFrontendFiltersState {
  const arr = isArray(jsonData)
    ? (jsonData as { id: number; isInWorkspace?: boolean }[])
    : [jsonData as { id: number; isInWorkspace?: boolean }];
  return {
    currentlyUpdating: arr.map((item) => pick(item, ['id', 'isInWorkspace'])),
  } as WorkspaceFrontendFiltersState;
}

/**
 * For use in YOUR queries file, this generates an updater that will use data
 * passed from your transformer in your query to the updater generated here.
 *
 * Aka use in conjunction with transformToWorkspaceFrontendFilter
 */
export function makeUpdateWorkspaceFrontendFilter(
  entityName: WorkspaceFeFilteredResourceTypeNames,
): UpdateStrategy<WorkspaceFrontendFiltersState | undefined> {
  return (
    prevEntityState: WorkspaceFrontendFiltersState = {} as WorkspaceFrontendFiltersState,
    currentlyUpdatingData: WorkspaceFrontendFiltersState | undefined,
  ): WorkspaceFrontendFiltersState => {
    const ret = prevEntityState?.filterSets ? prevEntityState : buildWorkspaceFiltersInitialState();
    const items = currentlyUpdatingData?.currentlyUpdating as {
      id: number;
      isInWorkspace?: boolean;
    }[];
    if (items.findIndex((item) => item?.isInWorkspace !== undefined) > -1) {
      ret.filterToggles[entityName] = true;
      items.forEach((item) => {
        if (item?.isInWorkspace) {
          ret.filterSets[entityName].add(item.id);
        } else if (item?.isInWorkspace === false) {
          ret.filterSets[entityName].delete(item.id);
        }
      });
    }
    return ret;
  };
}
