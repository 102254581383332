export enum InvoiceStatus {
  Finalized = 'finalized_at',
  Uncollectible = 'marked_uncollectible_at',
  Paid = 'paid_at',
  Voided = 'voided_at',
  Refunded = 'refunded_at',
}

export const InvoiceStatusNameMapping: { [key in InvoiceStatus]: string } = {
  [InvoiceStatus.Finalized]: 'finalized',
  [InvoiceStatus.Uncollectible]: 'uncollectible',
  [InvoiceStatus.Paid]: 'paid',
  [InvoiceStatus.Voided]: 'voided',
  [InvoiceStatus.Refunded]: 'refunded',
};

export enum InvoiceReason {
  Copay = 'copay',
  Deductible = 'deductible',
  Coinsurance = 'coinsurance',
  DirectPayment = 'direct_payment',
  MembershipFee = 'membership_fee',
  Other = 'other',
}

export const InvoiceReasonNameMapping: { [key in InvoiceReason]: string } = {
  [InvoiceReason.Copay]: 'Copay',
  [InvoiceReason.Deductible]: 'Deductible',
  [InvoiceReason.Coinsurance]: 'Coinsurance',
  [InvoiceReason.DirectPayment]: 'Direct payment',
  [InvoiceReason.MembershipFee]: 'Membership fee - medical services',
  [InvoiceReason.Other]: 'Other',
};
