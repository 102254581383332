import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { connectRequest } from 'redux-query-react';
import { Spinner, Text } from '@el8/vital';
import { Flexbox } from 'components/layout';
import moment from 'moment-timezone';
import { getAuditLogEntriesQuery, getAuditLogEntries } from 'modules/passport';

import styles from './AuditLogEntriesList.less';

const formatDate = (dateStr) =>
  moment(dateStr, 'MM/D/YYYY HH:mm:ss Z').format('MM/DD/YYYY h:mm:ss A');

const AUDIT_LOG_ACTION_LABELS = {
  view: 'Viewed Record',
  download_human: 'Download human-readable copy',
  download_machine: 'Download machine-friendly copy',
  transmit_human: 'Transmit human-readable copy',
  transmit_machine: 'Transmit machine-friendly copy',
  update_login: 'Updated login email',
  change_password: 'Changed login password',
};

const AuditLogEntriesList = ({ entries }) => (
  <div className={styles.container}>
    {!entries.length && (
      <div className={styles.loading}>
        <Spinner />
      </div>
    )}
    {!!entries.length &&
      entries.map(({ recordDate, action, userName, userId, id, transmitRecipient }) => (
        <Flexbox align="start" key={`entry-${id}`} className={styles.row}>
          <div className={styles.date}>
            <Text tag="div">{formatDate(recordDate)}</Text>
          </div>
          <div className={styles.actionBody}>
            <div>
              <Text tag="div">
                <strong>Action: </strong>
                <span>{AUDIT_LOG_ACTION_LABELS[action]}</span>
              </Text>
            </div>
            {transmitRecipient && (
              <div>
                <Text tag="div">
                  <strong>Transmitted to: </strong>
                  <span>{transmitRecipient}</span>
                </Text>
              </div>
            )}
            <div>
              <Text tag="div">
                <strong>User: </strong>
                <span>{`${userName} (${userId})`}</span>
              </Text>
            </div>
          </div>
        </Flexbox>
      ))}
  </div>
);

AuditLogEntriesList.propTypes = {
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      userName: PropTypes.string.isRequired,
      recordDate: PropTypes.string.isRequired,
      action: PropTypes.string.isRequired,
      userId: PropTypes.number.isRequired,
      transmitRecipient: PropTypes.string,
    }),
  ),
};

const mapPropsToQueries = () => getAuditLogEntriesQuery();

const mapStateToProps = (state) => ({
  entries: getAuditLogEntries(state),
});

export default compose(
  connect(mapStateToProps),
  connectRequest(mapPropsToQueries),
)(AuditLogEntriesList);
