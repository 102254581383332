/**
 * This is redux middleware used to sync entity updates from redux to our legacy
 * el8 store. Entities are synced on a whitelist basis, so you need to explicitly
 * register each entity type you want to sync to enable syncing for it, using
 * the `addEntityModelMapping` function found in this file.
 *
 * NOTE/FUTURE: Using redux-query's optimistic updates currently BREAKS this syncing,
 * because `diffEntitySets` will not work properly due to action.entities in the
 * middleware containing the already-optimistically-updated version of entities,
 * rather than the intended previous state of entities. We should fix this if we
 * intend to make regular use of optimistic updates.
 */
import { get } from 'lodash/object';
import cloneDeep from 'lodash/cloneDeep';
import { actionTypes } from 'redux-query';
import { pseudoDenormalize } from 'modules/appt-types';
import { getStorageSubkey } from './helpers';

const entityToModelMap = {};

let initialized = false;
/**
 * Initializes this middleware if it hasn't already been initialized.
 *
 * This is called inside the middleware, lazily initializing it the first time
 * an action of the appropriate type is received. The lazy initialization makes
 * testing easier (otherwise you may have missing entity-model mappings in tests
 * because the `el8` object wasn't defined when this file was imported)
 */
function initializeIfNeeded() {
  if (initialized) return;

  /**
   * @param {string} entityName - the name of the entity, as stored by redux-query
   * @param {string[]} modelPath - the path for where to find the entity's
   * corresponding legacy model on the el8 object. For example, for the model
   * el8.practicedata.appts, modelPath is ['practicedata', 'appts'].
   * @param {function} [processEntity] - an optional function that should transform
   * the entity from redux shape to legacy-shape (sometimes we have some discrepancies
   * in props names between those two)
   */
  const addEntityModelMapping = (entityName, modelPath, processEntity) => {
    if (modelPath.length !== 2) {
      throw new Error(`modelPath length must be exactly 2, got: ${modelPath}`);
    }

    try {
      entityToModelMap[entityName] = {
        model: el8[modelPath[0]][modelPath[1]],
        processEntity,
      };
    } catch (err) {
      // ignore, is a ReferenceError trying to access el8[modelPath[0]][modelPath[1]],
      // which just means that the model doesn't exist in the current page and we
      // don't need to worry about syncing data for that model.
    }
  };
  // addEntityModelMapping('events', ['practicedata', 'appts']);
  // addEntityModelMapping('pharmacyPrescriptionRequests', [
  //   'ptdata',
  //   'pharmacyPrescriptionRequests',
  // ]);
  addEntityModelMapping('clinicalForms', ['ptdata', 'clinicalForms']);
  addEntityModelMapping('clinicalFormCollections', ['ptdata', 'clinicalFormCollections']);
  addEntityModelMapping('prescriptions', ['ptdata', 'medOrders']);
  addEntityModelMapping('prescriptionThreads', ['ptdata', 'medOrderThreads']);
  addEntityModelMapping('appointmentTypes', ['data', 'apptTypes'], pseudoDenormalize);
  addEntityModelMapping('physicians', ['data', 'physicians']);
  addEntityModelMapping('patientPreferences', ['ptdata', 'ptPreferences']);
  addEntityModelMapping('patientProviders', ['ptdata', 'ptProviders']);
  addEntityModelMapping('reports', ['ptdata', 'reports']);

  initialized = true;
}

/**
 * @returns {object} - an object with the keys "created", "updated", and "deleted".
 * The value corresponding to each key is an array of IDs.
 */
function diffEntitySets(prevEntitiesById, newEntitiesById) {
  const diff = {
    created: [],
    updated: [],
    deleted: [],
  };

  // Used to track the creation of entities (if an entity exists in newEntitiesById
  // but not in this set, it must have been created)
  const preexistingIds = new Set();

  Object.keys(prevEntitiesById).forEach((entityId) => {
    if (prevEntitiesById[entityId]) {
      preexistingIds.add(entityId);

      if (!newEntitiesById[entityId]) {
        diff.deleted.push(entityId);
      } else if (prevEntitiesById[entityId] !== newEntitiesById[entityId]) {
        diff.updated.push(entityId);
      }
    }
  });
  Object.keys(newEntitiesById).forEach((entityId) => {
    if (newEntitiesById[entityId] && !preexistingIds.has(entityId)) {
      diff.created.push(entityId);
    }
  });

  return diff;
}

/**
 * Syncs the state of a given entity type to the el8 legacy store.
 *
 * @param {string} entityKey - the key of the entity in state.entities, e.g.
 * "clinicalFormCollections"
 * @param {object} prevEntitiesById - an object containing all entities of the
 * specified type PRIOR to the update being handled, mapped by ID
 * @param {object} newEntitiesById - an object containing all entities of the
 * specified type AFTER the update being handled, mapped by ID
 */
function syncEntity(entityKey, prevEntitiesById, newEntitiesById) {
  const modelConfig = entityToModelMap[entityKey];
  if (!modelConfig) return;

  const { model, processEntity } = modelConfig;

  // Clone entities before giving them to legacy to sync, because legacy code likes to mutate
  // objects. If we don't give legacy totally separate object references, any mutations
  // done by legacy code will impact our Redux store.
  const cloneEntityForSync = (entity) => {
    if (processEntity) {
      return cloneDeep(processEntity(entity));
    }
    return cloneDeep(entity);
  };

  if (!prevEntitiesById) {
    const newEntities = Object.values(newEntitiesById);
    if (model.create) {
      newEntities.forEach((entity) => {
        model.create(cloneEntityForSync(entity));
      });
    } else if (newEntities.length > 0) {
      console.warn(`Unable to sync creation of ${entityKey}: missing \`create\` fn on el8 model`);
    }
  } else {
    const entityChangeMap = diffEntitySets(prevEntitiesById, newEntitiesById);
    if (model.create) {
      entityChangeMap.created.forEach((entityId) => {
        model.create(cloneEntityForSync(newEntitiesById[entityId]));
      });
    } else if (entityChangeMap.created.length > 0) {
      console.warn(`Unable to sync creation of ${entityKey}: missing \`create\` fn on el8 model`);
    }

    if (model.updateFromReact || model.populate) {
      entityChangeMap.updated.forEach((entityId) => {
        if (model.updateFromReact) {
          model.updateFromReact(cloneEntityForSync(newEntitiesById[entityId]));
        } else {
          model.populate(cloneEntityForSync(newEntitiesById[entityId]));
        }
      });
    } else if (entityChangeMap.updated.length > 0) {
      console.warn(
        `Unable to sync update of ${entityKey}: missing \`updateFromReact\` or \`populate\` fn on el8 model`,
      );
    }

    if (model.remove) {
      entityChangeMap.deleted.forEach((entityId) => {
        model.remove(entityId);
      });
    } else if (entityChangeMap.deleted.length > 0) {
      console.warn(`Unable to sync deletion of ${entityKey}: missing \`remove\` fn on el8 model`);
    }
  }
}

const reduxToLegacySynchronizer = (store) => (next) => (action) => {
  const syncEntities = (newEntities) => {
    try {
      if (!newEntities) return;

      initializeIfNeeded();

      Object.keys(newEntities).forEach((entityKey) => {
        const modelConfig = entityToModelMap[entityKey];
        const newEntitySlice = newEntities[entityKey];
        if (!modelConfig || !newEntitySlice) return;

        const storageSubkey = getStorageSubkey(entityKey);
        const prevEntitiesById = storageSubkey
          ? get(store.getState(), ['entities', entityKey, storageSubkey])
          : get(store.getState(), ['entities', entityKey]);
        const newEntitiesById = storageSubkey ? newEntitySlice[storageSubkey] : newEntitySlice;

        if (newEntitiesById) {
          syncEntity(entityKey, prevEntitiesById, newEntitiesById);
        }
      });
    } catch (err) {
      console.error(`\`reduxToLegacySynchronizer\` failed to sync entities: ${err.message}`);
    }
  };

  switch (action.type) {
    case actionTypes.MUTATE_START: {
      syncEntities(action.optimisticEntities);
      return next(action);
    }
    case actionTypes.MUTATE_FAILURE: {
      syncEntities(action.rolledBackEntities);
      return next(action);
    }
    case actionTypes.REQUEST_SUCCESS:
    case actionTypes.MUTATE_SUCCESS: {
      syncEntities(action.entities);
      return next(action);
    }
    default:
      return next(action);
  }
};

export default reduxToLegacySynchronizer;
