import toast, { ToastProps } from 'utils/notify/toast';

import getErrorMessage from './getErrorMessage';

/**
 * Shows an alert toast for the given error.
 * Accepts `unknown` for first argument to streamline usage with catch blocks,
 * which have their errors typed as `unknown` for newer versions of Typescript
 */
export default function alertError(
  error: Error | unknown,
  toastProps?: Omit<ToastProps, 'message'>,
): void {
  if (!(error instanceof Error)) return;
  toast({
    ...toastProps,
    message: getErrorMessage(error),
  });
}
