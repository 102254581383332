import PatientMindreader, {
  PatientMindreaderProps,
} from 'components/mindreaders/PatientMindreader';
import UserExperience from 'constants/UserExperience';
import { WithDismissUserExperience } from 'modules/user-experiences';
import { userPracticeContextSwitchQuery } from 'modules/user-practices';
import { PatientSearchResult } from 'QueryTypes';
import React, { useCallback, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'StoreTypes';
import { useNavigateToPatient } from 'utils/hooks/useNavigateToPatient';
import toast from 'utils/notify/toast';
import { GlobalPatientSearchFTUEDialog } from './GlobalPatientSearchFTUEDialog';

const mapStateToProps = (state: RootState) => ({
  practiceName: state.practice?.name,
});

const reduxConnector = connect(mapStateToProps);
type GotoPatientMindreaderProps = ConnectedProps<typeof reduxConnector> &
  MergeWithPriority<
    {
      onSelect?: (result: PatientSearchResult) => void;
      onChange?: (query: string) => void;
    },
    PatientMindreaderProps
  >;

const GotoPatientMindreader: React.FC<GotoPatientMindreaderProps> = ({
  onSelect,
  practiceName,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dispatch,
  onChange,
  ...props
}): JSX.Element => {
  // Local State
  const includeGlobal = el8Globals.FEATURES.GlobalPatientSearch;
  const [isFtueOpen, setFtueOpen] = useState(false);
  const navigateToPatient = useNavigateToPatient();
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const [selectedResult, setSelectedResult] = useState<PatientSearchResult>(null);
  const [queryValue, setQueryValue] = useState('');

  // Handlers
  const handleQueryChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setQueryValue(e.target.value);
      if (onChange) {
        onChange(e.target.value);
      }
    },
    [setQueryValue, onChange],
  );

  const handleBeforeCreatePatient = useCallback(() => setQueryValue(''), [setQueryValue]);

  const openFtue = (): void => setFtueOpen(true);
  const closeFtue = (): void => setFtueOpen(false);

  const clearSelection = useCallback(() => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    setSelectedResult(null);
    setQueryValue('');

    if (onChange) {
      onChange('');
    }
  }, [setQueryValue, setSelectedResult, onChange]);

  const openLocalResult = useCallback(
    (res: PatientSearchResult) => {
      navigateToPatient(res.url);
      clearSelection();
    },
    [clearSelection, navigateToPatient],
  );

  const openRemoteResult = useCallback(
    (res: PatientSearchResult) => {
      const cxQuery = userPracticeContextSwitchQuery(res.practice_id, res.url);
      const switchUrl = cxQuery.url;

      // Notify that we are initiating a switch
      toast(`Switching to ${res.practice_name}...`);

      window.open(switchUrl);
      clearSelection();
    },
    [clearSelection],
  );

  return (
    <WithDismissUserExperience userExperience={UserExperience.GlobalPatientSearchPopup}>
      {({ dismissExperience, isExperienceDismissed }): JSX.Element => {
        const openPatientChart = (result: PatientSearchResult): void => {
          const isResultRemote: boolean =
            Boolean(result.practice_id) && result.practice_id !== el8Globals.PRACTICE_ID;

          if (includeGlobal && isResultRemote) {
            if (!isExperienceDismissed) {
              // We only care about storing the result if we are opening
              // the FTUE; otherwise we aren't going to need it again.
              setSelectedResult(result);
              openFtue();

              // We're done, all other behavior will be handled by the
              // FTUE dialog
              return;
            }

            openRemoteResult(result);
          } else {
            openLocalResult(result);
          }

          if (onSelect) {
            onSelect(result);
          }
        };

        const handleFTUEConfirm = (): void => {
          openRemoteResult(selectedResult);

          if (onSelect) {
            onSelect(selectedResult);
          }
        };

        const handleFTUECancel = (): void => {
          clearSelection();
        };

        return (
          <>
            <PatientMindreader
              autoFocus
              allowFreeText
              includeEnterprise
              showEmptySections
              value={queryValue}
              includeGlobal={includeGlobal}
              onBeforeCreatePatient={handleBeforeCreatePatient}
              onSelect={openPatientChart}
              onQueryChange={handleQueryChange}
              {...props}
            />

            {includeGlobal && !isExperienceDismissed && selectedResult && (
              <GlobalPatientSearchFTUEDialog
                selectedResult={selectedResult}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                practiceName={practiceName}
                isOpen={isFtueOpen}
                onConfirm={handleFTUEConfirm}
                onCancel={handleFTUECancel}
                onClose={closeFtue}
                dismissExperience={dismissExperience}
              />
            )}
          </>
        );
      }}
    </WithDismissUserExperience>
  );
};

export default reduxConnector(GotoPatientMindreader);
