import isPlainObject from 'lodash/isPlainObject';

// el8-migrate(rm-31221): DO NOT COPY AND PASTE THESE LINES TO SILENCE YOUR OWN LINTING ERRORS
// This comment is here solely to flag all of the existing `no-explicit-any` offenders
// when the rule was set to "error"
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EventData = Record<string, any>;

export const logEventViaLegacy = (eventName: string, eventData: EventData = {}): void => {
  /**
   * This method is a necessary evil to get around how we currently build our amplitude event properties
   * logEvent below only works as expected ( getting all user properties ) if a legacy amplitude event gets called which
   * will set up a properly speced amplitude instance. If you go directly to log an event using react a nearly blank
   * instance of amplitude gets used
   *
   * @see static/js/legacy/el8/core/util/amplitude/amplitudeHelpers.js
   * @see el8.util.amplitude.initialize
   *
   * DO NOT use this outside static/js/react/apps/emr/src/views/onboarding/amplitudeTaxonomy.ts
   */
  try {
    // @ts-ignore
    if (el8.util.amplitude) {
      // @ts-ignore
      el8.util.amplitude.logEvent(eventName, eventData);
    }
  } catch (err) {
    // not going to do anything if it fails
  }
};

export const logEvent = (eventName: string, eventData: EventData = {}): void => {
  try {
    if (window.amplitude) {
      const instance = window.amplitude.getInstance();
      const amplitudeEventId = instance.logEvent(eventName, eventData);

      // Forwarding Amplitude Events to DataDog RUM gives us a way
      // to beef up the Event Timeline in Session Replays (as we use
      // DataDog RUM for replays at the moment).
      window.DD_RUM?.addAction(`Amplitude:${eventName} `, {
        type: 'amplitude',
        eventData,
        amplitudeEventId,
        amplitudeSessionId: instance.getSessionId(),
      });
    }
  } catch (err) {
    // not going to do anything if it fails
  }
};

/**
 * Helper function to update multiple user props.
 * Uses amplitude.Identify().set() which will overwrite existing properties
 * Use addUserProperty() if you want to increment on a user property!
 * @param {Object} userProps - object with key/values that we want to update
 * on user
 */
export const setUserProperties = (userProps: { [key: string]: EventData }): void => {
  try {
    if (!isPlainObject(userProps)) {
      throw new Error(`Incorrect user props. Expected a plain object`);
    }
    if (window.amplitude) {
      const identify = Object.keys(userProps).reduce(
        (resultIdentity, key) => resultIdentity.set(key, userProps[key]),
        new window.amplitude.Identify(),
      );
      window.amplitude.getInstance().identify(identify);
    }
  } catch (err) {
    // not going to do anything if it fails
  }
};

/**
 * Helper function to increment user property.
 * Use setUserProperties() if you want to set user properties!
 * @param propertyName - key that we want to update
 * @param amount - value that we want to increment by
 * on user
 */
export const addUserProperty = (propertyName: string, amount: number): void => {
  try {
    if (window.amplitude) {
      const identify = new window.amplitude.Identify().add(propertyName, amount);
      window.amplitude.getInstance().identify(identify);
    }
  } catch (err) {
    // not going to do anything if it fails
  }
};

/**
 * Helper function to update group properties. Group props are not supported
 * by the JS SDK, so we are hitting their API using fetch
 * @param groupType - Group type eg. 'practice' or 'enterprise'
 * @param groupValue - Identifier for the specific group eg. the practice's or enterprise's id
 * @param groupProps - object with key/values that we want to update eg. {color: 'blue'}

 */
export const updateGroup = (
  groupType: string,
  groupValue: string | number,
  // el8-migrate(rm-31221): DO NOT COPY AND PASTE THESE LINES TO SILENCE YOUR OWN LINTING ERRORS
  // This comment is here solely to flag all of the existing `no-explicit-any` offenders
  // when the rule was set to "error"
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  groupProps: { [key: string]: any },
): void => {
  try {
    const url = 'https://api.amplitude.com/groupidentify';
    const apiKey = 'a59032e26e455d570adefcdd6cc5ca6e';
    const identification = {
      group_properties: groupProps,
      group_value: groupValue,
      group_type: groupType,
    };

    fetch(url, {
      method: 'POST',
      body: `api_key=${apiKey}&identification=${JSON.stringify(identification)}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  } catch (err) {
    // don't do anything if it fails
  }
};
