import React from 'react';
import { HeaderText } from '@el8/vital';
import AuditLogEntriesList from './AuditLogEntries/AuditLogEntriesList';

const AuditLogPage = () => (
  <div>
    {el8Globals.PATIENT_JSON && (
      <HeaderText legacyVariant="legacyHeaderText" tag="h1">
        {`For patient ${el8Globals.PATIENT_JSON.firstName} ${el8Globals.PATIENT_JSON.lastName} (${el8Globals.PATIENT_JSON.id})`}
      </HeaderText>
    )}
    <AuditLogEntriesList />
  </div>
);

export default AuditLogPage;
