import { useMemo } from 'react';
import { RunMutation } from 'redux-query-react';
import isNil from 'lodash/isNil';
import { ReferenceEntity, ResourceType, Workspace } from 'EntityTypes';
import { CreateWorkspaceRequestBody, UseDataInfo } from 'QueryTypes';
import { EntitiesState } from 'StoreTypes';
import { useCurrentPractice } from 'modules/practice';
import { useAppSelector } from 'utils/hooks';
import { aggregateQueryState } from 'utils/redux-query/queryHelpers';
import { checkSuccess } from 'utils/http';
import { AmplitudeError } from 'utils/amplitude';
import {
  logWorkspaceReferencesTypeEnabledOrDisabled,
  WorkspacesOptionalReferenceEntityTypes,
} from 'views/settings/WorkspacesSettings/AmplitudeTaxonomy';
import { makeSelectReferenceEntitiesByWorkspaceId, useWorkspaceReferences } from '.';
import { CommonReferenceEntityProperties } from './workspacesTypes.d';

export type UseReferenceDataHook<T extends CommonReferenceEntityProperties> = () => UseDataInfo<
  T[] | undefined
>;
export type UseWorkspaceReferenceDataHook<T extends CommonReferenceEntityProperties> = (
  workspaceId: number,
  edgeType?: number,
) => UseDataInfo<ReferenceEntity<T>[] | undefined>;

/**
 * Fetches and Selects the type T templates that have References to a specific
 * Workspace. An example would be ChecklistTemplate though ChecklistTemplate
 * can have "type: pe" and "type: ros" so in that type of case the entitiesFilter
 * can be passed in and applied via roughly [].filter(entitiesFilter)
 *
 * Internally this performs two fetches. One for the type T templates and
 * one for the Workspace References. The returned queryState aggregates
 * both of these internal queries. i.e. `isPending` is true while either
 * of the internal queries are in a pending state just as one would expect
 * from isPending in other cases (where only a single query is being run).
 */
export function factoryUseReferencesInWorkspace<T extends CommonReferenceEntityProperties>(
  entityType: ResourceType,
  useDataInfo: UseReferenceDataHook<T>,
  defaultEdgeType: number,
  entitiesFilter?: (elem1: ReferenceEntity<T>) => boolean,
): UseWorkspaceReferenceDataHook<T> {
  return (
    workspaceId: number,
    edgeType?: number,
  ): UseDataInfo<ReferenceEntity<T>[] | undefined> => {
    const aggEdgeType = !edgeType ? defaultEdgeType : edgeType;

    const selectReferenceEntities = useMemo(makeSelectReferenceEntitiesByWorkspaceId, []);
    const currentPractice = useCurrentPractice();
    const [, refEntityQueryState] = useDataInfo();
    const [, referenceQueryState, refresh] = useWorkspaceReferences(
      currentPractice.id,
      workspaceId,
    );

    const refEntityInWorkspace = useAppSelector((state) => {
      const refEntityInWorkspaceUnfiltered = selectReferenceEntities(state, {
        workspaceId,
        entityType,
        edgeType: aggEdgeType,
      }) as ReferenceEntity<T>[] | undefined;
      return entitiesFilter && refEntityInWorkspaceUnfiltered
        ? refEntityInWorkspaceUnfiltered.filter(entitiesFilter)
        : refEntityInWorkspaceUnfiltered;
    });

    const queryState = aggregateQueryState([refEntityQueryState, referenceQueryState]);
    return [refEntityInWorkspace, queryState, refresh];
  };
}
/**
 * if optionalEntityTypeName was passed in and newVal (child function) is a boolean,
 * log the action with amplitude otherwise do nothing
 */
function makeAnalyticsLoggerForTypeEnabledOrDisabled<T>(
  workspaceId: number,
  analyticsSource: string,
  optionalEntityTypeName?: WorkspacesOptionalReferenceEntityTypes,
): (newVal: T, err?: unknown) => void {
  return (newVal, err) => {
    if (optionalEntityTypeName && typeof newVal === 'boolean') {
      const action: 'enable' | 'disable' = newVal ? 'enable' : 'disable';
      logWorkspaceReferencesTypeEnabledOrDisabled(
        err
          ? new AmplitudeError(err, analyticsSource, undefined, { action })
          : {
              workspaceId,
              action,
              type: optionalEntityTypeName,
              source: analyticsSource,
            },
      );
    }
  };
}

/**
 * A curry function that eventually updates a workspace
 * @param editWorkspace
 * @param onSuccess
 * @param onError
 * @returns Second curry level of three
 */
export function createUpdateWorkspaceColumn<Col extends string>(
  editWorkspace: RunMutation<
    EntitiesState,
    [Partial<Omit<CreateWorkspaceRequestBody, 'practice_id'>>, number]
  >,
  workspaceId: number,
): <T>(
  colName: Col,
  optionalEntityTypeName?: WorkspacesOptionalReferenceEntityTypes,
) => (
  onSuccess?: ((workspace: Workspace) => Promise<void>) | undefined,
  onError?: ((err: unknown) => void) | undefined,
  analyticsSource?: string,
) => (newVal: T) => Promise<void> {
  /**
   * A curry function that builds a workspace updater, it takes column
   * name and an optional workspacesOptionalReferenceEntityTypes that is
   * used to indicate if (and which) resource group is being
   * enabled/disabled and when present this triggers the analytics
   * using analyticsSource
   */
  return <T>(colName: Col, optionalEntityTypeName?: WorkspacesOptionalReferenceEntityTypes) =>
    (
      onSuccess?: (workspace: Workspace) => Promise<void>,
      onError?: (err: unknown) => void,
      analyticsSource = 'workspace detail page',
    ) => {
      const bodyObj = {} as { [key: string]: T };

      const analyticsLogger = makeAnalyticsLoggerForTypeEnabledOrDisabled(
        workspaceId,
        analyticsSource,
        optionalEntityTypeName,
      );

      return async (newVal: T): Promise<void> => {
        try {
          bodyObj[colName] = newVal;
          const resp = await editWorkspace(bodyObj, workspaceId);
          // TypeGuard around the `ActionPromise` returned by a `RunMutation`
          // being potentially undefined. It is unclear when that would actually
          // be possible.
          if (isNil(resp))
            throw new Error(
              `saveWorkspace returned undefined while updating ResourceTypeToggle for ${colName} to ${newVal}`,
            );

          checkSuccess(resp);
          analyticsLogger(newVal);
          if (!isNil(onSuccess)) {
            const workspaceBody = resp?.body as Workspace;
            onSuccess(workspaceBody);
          }
        } catch (err) {
          console.error(err);
          analyticsLogger(newVal, err);
          if (!isNil(onError)) {
            onError(err);
          }
        }
      };
    };
}
