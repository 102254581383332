import { supportReducer } from 'ui-modules/support';
import { navbarReducer } from 'ui-modules/navbar';
import { trialActivationReducer } from 'ui-modules/trial-activation';
/**
 * Reducers that are common to every page.
 */
export const commonReducers = {
  navbar: navbarReducer,
  support: supportReducer,
  trialActivation: trialActivationReducer,
};
