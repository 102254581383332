import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { FocusStyleManager } from '@el8/vital';
import { SentryErrorBoundary } from 'utils/sentry';
import ExportPage from 'views/passport/ExportPage/ExportPage';
import AuditLogPage from 'views/passport/AuditLogPage/AuditLogPage';
import rootReducer from './rootReducer';
import createStore from '../store/createStore';

FocusStyleManager.onlyShowFocusOnTabs();

const store = createStore(rootReducer);

/* eslint-disable react/jsx-filename-extension */
const renderApp = (AppComponent) => {
  ReactDOM.render(
    <SentryErrorBoundary>
      <Provider store={store}>
        <AppComponent />
      </Provider>
    </SentryErrorBoundary>,
    document.getElementById('root'),
  );
};
/* eslint-enable react/jsx-filename-extension */

if (module.hot) {
  module.hot.accept('views/passport/ExportPage/ExportPage', () => {
    const NextApp = require('views/passport/ExportPage/ExportPage').default;
    renderApp(NextApp);
  });
  module.hot.accept('views/passport/AuditLogPage/AuditLogPage', () => {
    const NextApp = require('views/passport/AuditLogPage/AuditLogPage').default;
    renderApp(NextApp);
  });
}

export const renderExportPage = () => renderApp(ExportPage);
export const renderAuditLogPage = () => renderApp(AuditLogPage);
