import { useEffect, useState } from 'react';

/**
 * Returns a debounced version of the given value that will not update
 * more than once within the debounce time interval.
 *
 * Adapted from: https://usehooks-ts.com/react-hook/use-debounce
 */
function useDebouncedValue<T>(value: T, delay = 500): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default useDebouncedValue;
