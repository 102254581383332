import { useCallback } from 'react';
import { useAppDispatch } from 'utils/hooks';
import { OpenSupportDialogPayload, closeSupportDialog, openSupportDialog } from './supportSlice';

export function useOpenSupportDialog(): (payload: OpenSupportDialogPayload) => void {
  const dispatch = useAppDispatch();

  return useCallback(
    (payload: OpenSupportDialogPayload) => {
      dispatch(openSupportDialog(payload));
    },
    [dispatch],
  );
}

export function useCloseSupportDialog(): VoidFunction {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(closeSupportDialog());
  }, [dispatch]);
}
