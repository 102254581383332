import { CopyText, Spinner2, Stack, Text2, UnavailableContent } from '@el8/vital';
import { useProviderSecurityCode } from 'modules/provider-security-code';
import React from 'react';
import { logEvent } from 'utils/amplitude';
import styles from './ProviderSecurityCodeDialogContent.less';

export const ProviderSecurityCodeDialogContent = (): JSX.Element => {
  const { data, isFetching, error } = useProviderSecurityCode();

  if (isFetching) {
    return (
      <UnavailableContent description="Retrieving security code" icon={<Spinner2 sizing="lg" />} />
    );
  }

  if (error || !data) {
    return (
      <UnavailableContent
        description="If this problem persists please contact Elation Support"
        heading="Unable to retrieve security code"
      />
    );
  }

  return (
    <Stack data-testid="provider-security-code-dialog">
      <CopyText
        textToCopy={data.password}
        className={styles.securityCodeCopyText}
        onDidCopy={(): void => logEvent('copy: security code')}
      >
        <Text2 textSize="lg" tag="strong" className={styles.securityCode} align="center">
          {data.password}
        </Text2>
      </CopyText>
      <Text2>
        Give the Security Code to providers registering for an{' '}
        <Text2
          tag="a"
          href="https://help.elationhealth.com/s/article/accessing-interactive-charts-online"
          target="_blank"
          rel="noopener noreferrer"
        >
          Elation Connect
        </Text2>{' '}
        account.
      </Text2>
      <Text2 tag="em">The Security Code expires in two weeks.</Text2>
    </Stack>
  );
};
