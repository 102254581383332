/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Support Intake and Communication
 * This API defines the endpoints dealing with Customer feedback/support requests.

 * OpenAPI spec version: 0.1.0
 */

export type QuestionCategories = typeof QuestionCategories[keyof typeof QuestionCategories];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QuestionCategories = {
  feedback: 'feedback',
  feature_request: 'feature_request',
  something_else: 'something_else',
  issue: 'issue',
  access_issue: 'access_issue',
  integration_request: 'integration_request',
  setup_environment: 'setup_environment',
} as const;
