export const imageFileExtensionAllowlist: string[] = [
  'jpg',
  'jpeg',
  'gif',
  'png',
  'tiff',
  'tif',
  'bmp',
  'svg',
  'webp',
];
export const documentFileExtensionAllowlist: string[] = [
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'txt',
  'csv',
  'rtf',
];
export const audioFileExtensionAllowlist: string[] = ['mp3', 'wav'];
export const videoFileExtensionAllowlist: string[] = ['mp4', 'mov', 'avi'];

// https://help.salesforce.com/s/articleView?id=sf.collab_files_attach_records.htm&type=5
// https://help.salesforce.com/s/articleView?id=000386421&type=1
// https://help.salesforce.com/s/articleView?id=sf.pardot_content_files_supported.htm&type=5
// Subset of supported file types. Salesforce really only restricts a few types.
export const sfdcFileExtensionAllowlist: string[] = [
  ...imageFileExtensionAllowlist,
  ...documentFileExtensionAllowlist,
  ...audioFileExtensionAllowlist,
  ...videoFileExtensionAllowlist,
];

/**
 * Used to populate the accept attribute for file input element
 * so that the browser can filter out unsupported file types.
 */
export const fileInputAccept: string = sfdcFileExtensionAllowlist.map((ext) => `.${ext}`).join(',');

/**
 * Check if a file name has a valid file extension.
 */
export function isValidFileType(fileName: string): boolean {
  const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
  return sfdcFileExtensionAllowlist.includes(fileExtension);
}

// https://help.salesforce.com/s/articleView?id=000381295&type=1
export const SFDC_MAX_FILE_SIZE = 1024 * 1024 * 10; // 10MB
