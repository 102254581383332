import { Practice, PracticeUser } from 'EntityTypes';
import { camelCaseKeys } from 'utils/objects';

/**
 * Converts el8 preloaded data into preloaded state for our store.
 */
export default function hydrate(data: { currPractice: Practice; currUser: PracticeUser }): {
  entities: {};
  user: Record<string, unknown>;
  practice: Record<string, unknown>;
} {
  const { currUser, currPractice } = data;

  return {
    entities: {},
    user: camelCaseKeys(currUser),
    practice: camelCaseKeys(currPractice),
  };
}
