import { useRef, useEffect, MutableRefObject } from 'react';

/**
 * Simple hook straight from React's docs. According to the docs, this may be provided
 * by React out of the box in the future, at which point we should get rid of this.
 *
 * See: https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 */
export default function usePrevious<T>(value: T): MutableRefObject<T | undefined>['current'] {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
