import React, { useCallback } from 'react';
import { logDemoLinkClicked } from '../amplitudeTaxonomy';
import { CaseIntakeInfoProps } from '../CaseIntakeTypes';
import { IntakeButton } from './IntakeButton';

export const DemoRequest: React.FC<CaseIntakeInfoProps> = () => {
  const onDemoLinkClicked = useCallback(() => {
    logDemoLinkClicked();
    window.location.href = 'https://www.elationhealth.com/demo/';
  }, []);

  return (
    <IntakeButton
      kind="link"
      label="Submit your demo request"
      icon="external-link"
      onClick={onDemoLinkClicked}
      style={{ alignSelf: 'flex-start' }}
    />
  );
};

export default DemoRequest;
