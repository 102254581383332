import {
  QueryKey,
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import Cookie from 'js-cookie';

import { SendFeedbackRequestBody, StatusResponseBody } from 'QueryTypes';

import { getQueryUrl, jsonFetch } from 'utils/react-query';

const statusUrl = 'https://5962740739716733.hostedstatus.com/1.0/status/57df7189b0a7b27e32000999';
const queryKeys = {
  sendFeedback: (): QueryKey => ['user-feedback'],
  getStatuses: (): QueryKey => [statusUrl],
};

export const useSendFeedback = (): UseMutationResult<
  unknown,
  unknown,
  SendFeedbackRequestBody,
  unknown
> => {
  return useMutation({
    mutationFn: async (body: SendFeedbackRequestBody) => {
      const form = new FormData();
      form.append('message', body.message);
      if (body.category) {
        form.append('category', body.category);
      }
      form.append('category_long', body.category_long);
      form.append('urgent', String(body.urgent));
      form.append('url', body.url);
      if (body.supportingFile) {
        form.append('supportingFile', body.supportingFile);
      }
      form.append('replay_link', window.DD_RUM?.getSessionReplayLink() ?? 'No replay found');

      return fetch(getQueryUrl(queryKeys.sendFeedback()), {
        method: 'POST',
        credentials: 'include',
        headers: {
          'X-CSRFToken': Cookie.get('csrftoken') || '',
          'X-EL8-PAGE-SESSION': el8Globals.PAGE_SESSION_ID,
        },
        body: form,
      });
    },
  });
};

export const useFetchStatus = (): UseQueryResult<StatusResponseBody> => {
  return useQuery({
    queryKey: queryKeys.getStatuses(),
    queryFn: async () => {
      return jsonFetch(statusUrl, {
        isInternalApi: false,
        credentials: 'omit',
        headers: { 'Content-Type': 'text/plain' },
      });
    },
  });
};
