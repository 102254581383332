import React from 'react';
import ExportEmailSuccess from 'views/passport/ExportPage/ExportEmail/ExportEmailSuccess';
import ExportEmailHome from './ExportEmail/ExportEmailHome';
import ExportEmailConfirmation from './ExportEmail/ExportEmailConfirmation';
import Documents from './Documents/Documents';
import { VIEWS } from './helpers';

class ExportPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentView: VIEWS.documents, currentViewProps: {} };
  }

  changeView = (viewID, props = {}) => {
    this.setState({ currentView: viewID, currentViewProps: props });
  };

  render() {
    const { currentView, currentViewProps } = this.state;

    return (
      <div>
        {currentView === VIEWS.documents && (
          <Documents changeView={this.changeView} {...currentViewProps} />
        )}
        {currentView === VIEWS.emailHome && (
          <ExportEmailHome changeView={this.changeView} {...currentViewProps} />
        )}
        {currentView === VIEWS.confirmExportEmail && (
          <ExportEmailConfirmation changeView={this.changeView} {...currentViewProps} />
        )}
        {currentView === VIEWS.successExportEmail && (
          <ExportEmailSuccess changeView={this.changeView} {...currentViewProps} />
        )}
      </div>
    );
  }
}

export default ExportPage;
