import React, { useEffect } from 'react';
import { Marquee, Text2 } from '@el8/vital';
import { CheckboxFormik } from '@el8/vital-formik';
import { useFormikContext } from 'formik';
import { useAppDispatch } from 'utils/hooks';
import { setSupportDialogUrgency } from 'ui-modules/support';
import { QuestionCategories } from '@elation/api-client/src/support';
import { CaseIntakeFormValues } from '../CaseIntakeForm';

import styles from './UrgentCase.less';
import { useCurrentUser } from '../../../../modules/practice-users';

const UrgentCase = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const { values, setFieldValue, setFieldTouched } = useFormikContext<CaseIntakeFormValues>();
  const currenUser = useCurrentUser();
  const isLoggedIn = !!currenUser?.id;

  const showUrgent =
    isLoggedIn &&
    (el8Globals.IS_ADMIN || el8Globals.USER_STAFF_TYPE === 'Physician') &&
    values.category === QuestionCategories.issue;

  useEffect(() => {
    setFieldValue('is_urgent', false);
    setFieldTouched('is_urgent', false);
    dispatch(setSupportDialogUrgency(false));
  }, [dispatch, setFieldTouched, setFieldValue, values.category]);

  if (!showUrgent) {
    return null;
  }

  return (
    <>
      <CheckboxFormik
        className={styles.alignCheckbox}
        label={
          <Text2>
            <strong>Urgent:</strong> a patient cannot receive care
          </Text2>
        }
        name="is_urgent"
        data-testid="support-intake-form-urgent"
        onChange={(event) => {
          const checkboxEvent = event as React.ChangeEvent<HTMLInputElement>;
          dispatch(setSupportDialogUrgency(checkboxEvent.target.checked));
        }}
      />
      {values.is_urgent && (
        <Marquee intent="neutral" title="You are marking this case as urgent.">
          An urgent case at Elation means a patient cannot get care without immediate attention from
          Elation. For example, the system is down, a provider cannot login, or you cannot access a
          patient chart.
        </Marquee>
      )}
    </>
  );
};

export default UrgentCase;
