import { useEffect, useRef } from 'react';

/**
 * Set an interval to call a function every [delay] milliseconds
 * This is basically a copy of https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
export default function useInterval(callback: () => void, delay: number | null): void {
  const savedCallback = useRef<(() => void) | undefined>(undefined);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    /**
     * Function to be invoked on each interval
     */
    function tick(): void {
      if (savedCallback.current !== undefined) savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      // clear interval when unmounting
      return (): void => clearInterval(id);
    }

    return undefined;
  }, [delay]);
}
