import React from 'react';
import cn from 'classnames';
import styles from './NavbarItem.less';
import { NavbarItemLink, NavbarItemLinkProps } from './NavbarItemLink';
import { NavbarItemButton, NavbarItemButtonProps } from './NavbarItemButton';
import { NavbarItemDropdown, NavbarItemDropdownProps } from './NavbarItemDropdown';

type NavbarItemProps = (NavbarItemLinkProps | NavbarItemButtonProps | NavbarItemDropdownProps) & {
  /**
   * Applied to the root li element's id html attribute
   */
  rootId?: string;
  /**
   * Applied to the root li element
   */
  rootClassname?: string;
};

export const NavbarItem = ({
  rootId,
  rootClassname,
  ...restProps
}: React.PropsWithChildren<NavbarItemProps>): JSX.Element => {
  return (
    <li className={cn(styles.li, rootClassname)} id={rootId} data-dd-privacy="allow">
      {restProps.type === 'link' && <NavbarItemLink {...restProps} />}
      {restProps.type === 'button' && <NavbarItemButton {...restProps} />}
      {restProps.type === 'dropdown' && <NavbarItemDropdown {...restProps} />}
    </li>
  );
};
