import { normalize } from 'normalizr';

import { AppointmentTypeRaw, AppointmentTypeNormalized } from 'EntityTypes';
import { AppQueryConfig } from 'QueryTypes';
import { EntitiesById, AppointmentTypesState } from 'StoreTypes';
import { mapArrayToObject } from 'utils/arrays';
import {
  makeDeleteFns,
  makeOptimisticFns,
  makeSortingOptimisticFns,
  makeUpdateFn,
} from 'utils/redux-query';

import { appointmentTypeSchema } from './appointmentTypesSchemas';
import { pseudoNormalize } from './utils';

const urls = {
  appointmentTypes(practiceId: number, appointmentTypeId?: number): string {
    let url = `/book/api/admin/${practiceId}/appointment_types`;
    if (appointmentTypeId) {
      url += `/${appointmentTypeId}`;
    }
    return url;
  },
  appointmentTypesLegacy(practiceId: number, appointmentTypeId: number): string {
    let url = `/practice/${practiceId}/settings/scheduler/appointment-types/`;
    if (appointmentTypeId) {
      url += `${appointmentTypeId}/`;
    }
    return url;
  },
  sortAppointmentTypes(practiceId: number): string {
    return `/practice/${practiceId}/settings/scheduler/appointment-types/sort/`;
  },
};

/**
 * Query to get/create/update a single appointment type.
 *
 * Note that this query CAN fetch deleted appointment types.
 *
 * - Specify `practiceId` and `appointmentTypeId` to fetch an appointment type
 * - Specify `practiceId` and `body` with a null appointmentTypeId to create a new appointment type
 * - Specify all parameters to update an appointment type
 */
export const appointmentTypeQuery = (
  practiceId: number,
  appointmentTypeId: number | null,
  body?: Partial<AppointmentTypeRaw>,
): AppQueryConfig => {
  const isUpdating = Boolean(appointmentTypeId && body);

  const queryConfig: AppQueryConfig = {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
    url: urls.appointmentTypes(practiceId, appointmentTypeId),
    body,
    transform: (responseJson: {
      data: AppointmentTypeRaw;
    }): { appointmentTypes: EntitiesById<AppointmentTypeNormalized> } => {
      return normalize(responseJson.data, appointmentTypeSchema).entities;
    },
    update: {
      appointmentTypes: makeUpdateFn<AppointmentTypeNormalized>(),
    },
  };

  if (isUpdating) {
    queryConfig.options = {
      method: 'PUT',
    };
    const { optimisticUpdate, rollback } = makeOptimisticFns<AppointmentTypeNormalized>(
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
      appointmentTypeId,
      pseudoNormalize(body as AppointmentTypeRaw, false),
    );
    queryConfig.optimisticUpdate = {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(entityState: GenericEntityState<Appointment... Remove this comment to see the full error message
      appointmentTypes: optimisticUpdate,
    };
    queryConfig.rollback = {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(initialEntityState: GenericEntityState<Appo... Remove this comment to see the full error message
      appointmentTypes: rollback,
    };
  }

  return queryConfig;
};

/**
 * Query to fetch the list of appointment types for a practice.
 */
export const appointmentTypesQuery = (practiceId: number): AppQueryConfig => {
  return {
    url: urls.appointmentTypes(practiceId),
    transform: (responseJson: {
      data: AppointmentTypeRaw | AppointmentTypeRaw[];
    }): { appointmentTypes: EntitiesById<AppointmentTypeNormalized> } => {
      return normalize(responseJson.data, [appointmentTypeSchema]).entities;
    },
    update: {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(prevState: AppointmentTypesState | undefine... Remove this comment to see the full error message
      appointmentTypes(
        prevState,
        fetchedApptTypes: EntitiesById<AppointmentTypeNormalized>,
      ): AppointmentTypesState {
        let deletedApptTypes: AppointmentTypeNormalized[] = [];
        if (prevState?.byId) {
          deletedApptTypes = Object.values(prevState.byId).filter(
            (apptType) => apptType.delete_date,
          );
        }

        return {
          ...prevState,
          byId: {
            ...mapArrayToObject(deletedApptTypes, 'id'),
            ...fetchedApptTypes,
          },
        };
      },
    },
  };
};

export const deleteAppointmentTypeQuery = (
  practiceId: number,
  appointmentTypeId: number,
): AppQueryConfig => {
  const { update, optimisticUpdate, rollback } =
    makeDeleteFns<AppointmentTypeNormalized>(appointmentTypeId);

  return {
    url: urls.appointmentTypesLegacy(practiceId, appointmentTypeId),
    options: {
      method: 'DELETE',
    },
    update: {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(entityState: GenericEntityState<Appointment... Remove this comment to see the full error message
      appointmentTypes: update,
    },
    optimisticUpdate: {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(entityState: GenericEntityState<Appointment... Remove this comment to see the full error message
      appointmentTypes: optimisticUpdate,
    },
    rollback: {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(initialEntityState: GenericEntityState<Appo... Remove this comment to see the full error message
      appointmentTypes: rollback,
    },
  };
};

export const sortAppointmentTypesQuery = (
  practiceId: number,
  appointmentTypeIds: number[],
): AppQueryConfig => {
  const { optimisticUpdate, rollback } = makeSortingOptimisticFns<AppointmentTypeNormalized>(
    appointmentTypeIds,
    'sequence',
  );
  return {
    url: urls.sortAppointmentTypes(practiceId),
    body: {
      sequence: appointmentTypeIds,
    },
    transform: (responseJson: AppointmentTypeRaw[]): EntitiesById<AppointmentTypeNormalized> =>
      normalize(responseJson, [appointmentTypeSchema]).entities,
    update: {
      appointmentTypes: makeUpdateFn(),
    },
    optimisticUpdate: {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(entityState: GenericEntityState<Appointment... Remove this comment to see the full error message
      appointmentTypes: optimisticUpdate,
    },
    rollback: {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(initialEntityState: GenericEntityState<Appo... Remove this comment to see the full error message
      appointmentTypes: rollback,
    },
  };
};
