/* eslint-disable no-console */
import React, { Component } from 'react';
import alert from 'utils/alert';

type Props = {
  children: React.ReactNode;

  /** If provided, displays error message to user when exception is caught */
  errorMessage?: string;
};

class SentryErrorBoundary extends Component<Props> {
  componentDidCatch(error: Error): void {
    window.Sentry?.captureException(error);

    if (this.props.errorMessage) {
      alert(this.props.errorMessage);
    }
  }

  render(): React.ReactNode {
    return this.props.children;
  }
}

export default SentryErrorBoundary;
