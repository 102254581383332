import { useEffect, useRef } from 'react';

/**
 * A variant of `useEffect` that only runs on unmount.
 */
export default function useUnmountEffect(onUnmount: () => void): void {
  // Use ref to ensure the latest version of `onUnmount` runs on unmount.
  // If we try to call `onUnmount` directly in the effect cleanup, it
  // will call the initial (at time of mount) version of `onUnmount` due
  // to `onUnmount` being intentionally omitted from the dependency array.
  const callbackRef = useRef(onUnmount);

  useEffect(() => {
    callbackRef.current = onUnmount;
  }, [onUnmount]);

  useEffect(() => {
    return () => {
      callbackRef.current();
    };
    // *Do not* include any dependencies, so the cleanup function will
    // only run on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
