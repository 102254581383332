// TODO --RP figure out how to generate with codegen
export enum LiabilityChargePaymentType {
  Card = 'card',
  CardExternal = 'card_external',
  Cash = 'cash' /* actually is combined cash/check */,
  CashOnly = 'cash_only',
  Check = 'check',
}

export enum LiabilityAction {
  CashCheck = 'mark_paid_offline',
  CopyRequestLink = 'copy_request_link',
  MarkFailedUnsavedPaymentMethod = 'mark_failed_unsaved_payment_method',
  MarkRefunded = 'mark_refund',
  Refund = 'refund',
  ResendCharge = 'resend_charge',
  ResendReceipt = 'resend_receipt',
  ResendRequest = 'resend_request',
  ViewPendingCharge = 'view_pending_charge',
  ViewReceipt = 'view_receipt',
  ViewRequest = 'view_request',
  Void = 'void',
}

export enum LiabilityStatus {
  ChargeFailedUnsavedPaymentMethod = 'charge_failed_unsaved_method',
  ChargePending = 'charge_pending',
  Failed = 'failed',
  NoCollectionAttempt = 'no_collection',
  Paid = 'paid',
  PaidOffline = 'paid_offline',
  PatientHold = 'patient_hold',
  ReadOnlyPaid = 'read_only_paid',
  ReadOnlyUnpaid = 'read_only_unpaid',
  Refunded = 'refunded',
  RequestSent = 'request_sent',
  Voided = 'voided',
  WrittenOff = 'written_off',
}

export enum PaymentDateComparison {
  Before = 'created_at__lt',
  OnOrBefore = 'created_at__lte',
  On = 'created_at__',
  OnOrAfter = 'created_at__gte',
  After = 'created_at__gt',
}

// TODO --RP figure out how to generate with codegen
export const LiabilityStatusLabelMap = {
  [LiabilityStatus.ChargePending]: 'Charge pending',
  [LiabilityStatus.Failed]: 'Payment declined',
  [LiabilityStatus.NoCollectionAttempt]: 'No collection attempt made',
  [LiabilityStatus.Paid]: 'Paid in full',
  [LiabilityStatus.PaidOffline]: 'Paid via cash/check',
  [LiabilityStatus.PatientHold]: 'Patient has placed charge on hold',
  [LiabilityStatus.ReadOnlyPaid]: 'Paid (historical record)',
  [LiabilityStatus.ReadOnlyUnpaid]: 'Unpaid (historical record)',
  [LiabilityStatus.Refunded]: 'Refunded',
  [LiabilityStatus.RequestSent]: 'Payment request sent',
  [LiabilityStatus.Voided]: 'Voided',
  [LiabilityStatus.WrittenOff]: 'Written off; no further collection attempts',
};

export enum PaymentChargeStatus {
  Completed = 'completed',
  CompletedCash = 'completed_cash',
  CompletedCashOnly = 'completed_cash_only',
  DoNotComplete = 'do_not_complete',
  Failed = 'failed',
  Pending = 'pending',
  Refunded = 'refunded',
}

export const PaymentChargeStatusLabelMap = {
  [PaymentChargeStatus.Completed]: 'Charge completed',
  [PaymentChargeStatus.CompletedCash]: 'Charge completed via cash or check',
  [PaymentChargeStatus.DoNotComplete]: 'Charge will not be completed',
  [PaymentChargeStatus.Failed]: 'Charge failed',
  [PaymentChargeStatus.Pending]: 'Charge pending',
  [PaymentChargeStatus.Refunded]: 'Charge refunded',
};

export enum PaymentRequestStatus {
  AttemptingCollection = 'attempting_collection',
  Collected = 'collected',
  NotCollecting = 'not_collecting',
}

export const PaymentRequestStatusLabelMap = {
  [PaymentRequestStatus.AttemptingCollection]: 'Attempting to collect payment',
  [PaymentRequestStatus.Collected]: 'Payment collected',
  [PaymentRequestStatus.NotCollecting]: 'Not attempting to collect',
};
