export const INVALIDATE_QUERY_GROUP = 'INVALIDATE_QUERY_GROUP';
/**
 * A special action used to invalidate request query groups in the queriesReducer
 * cache, so relevant requests will trigger again as needed.
 */
export function invalidateQueryGroup(group: string): {
  type: typeof INVALIDATE_QUERY_GROUP;
  group: string;
} {
  return {
    type: INVALIDATE_QUERY_GROUP,
    group,
  };
}

export const INVALIDATE_QUERY = 'INVALIDATE_QUERY';
/**
 * A special action used to invalidate single queries in the queriesReducer
 * cache so that query can trigger again naturally when needed.
 */
export function invalidateQuery(queryKey: string): {
  type: typeof INVALIDATE_QUERY;
  queryKey: string;
} {
  return {
    type: INVALIDATE_QUERY,
    queryKey,
  };
}
