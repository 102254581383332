import React from 'react';
import { DialogCloseButton, HeaderText, Stack, Text2 } from '@el8/vital';
import { Flexbox } from 'components/layout';
import { TrialViewProps } from '../../TrialActivationDialog';
import styles from './NoteAssistActivatedView.less';

export const NoteAssistActivatedView = ({ onClose }: TrialViewProps): JSX.Element => {
  return (
    <div className={styles.container}>
      <Flexbox justify="space-between" className={styles.header}>
        <HeaderText tag="h1">
          Your free trial of <strong>Note Assist</strong> is active - start using it today!
        </HeaderText>
        <DialogCloseButton onClick={onClose} />
      </Flexbox>
      <br />
      <Stack className={styles.content}>
        <Text2>
          <strong>Note Assist</strong> is Elation’s AI-powered medical scribing and note generation
          solution, accessed from the &quot;Scribe&quot; button within the visit note workflow.
          Watch our short video below and review our{' '}
          <a
            href="https://help.elationhealth.com/s/article/Note-Assist-Guide"
            target="_blank"
            rel="noopener noreferrer"
          >
            Note&nbsp;Assist&nbsp;guide
          </a>{' '}
          for helpful instructions and tips.
        </Text2>
        <Text2>
          Benefits of <strong>Note Assist</strong>:
          <ul className={styles.list}>
            <li>
              Saves up to <strong>two hours</strong> daily
            </li>
            <li>Seamlessly embedded in your workflow</li>
            <li>
              Crafts accurate, high-quality notes using chart context and existing note content
            </li>
            <li>Works with Elation note templates</li>
          </ul>
        </Text2>
        <div className={styles.video}>
          <iframe
            src="https://player.vimeo.com/video/1018103439?h=f0f14cd6e6&badge=0&autopause=0&player_id=0&app_id=58479"
            allow="fullscreen"
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            title="Getting started with Note Assist Video"
            sandbox="allow-scripts allow-same-origin allow-presentation"
            referrerPolicy="strict-origin"
          />
        </div>
        <Text2>
          This free trial offer will automatically expire after 5 days. At the end of the free trial
          period, Elation will contact you to discuss next steps. There is no obligation to
          purchase.
        </Text2>
        <Text2>
          Please refresh the page, if needed, to see the orange &quot;Scribe&quot; button. For
          technical support, contact Elation Support using the in-app &quot;I need help&quot; menu
          on the Elation toolbar.
        </Text2>
      </Stack>
    </div>
  );
};
