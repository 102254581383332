exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AccountNavItem__inAppOnboarding___1xXMy {\n  pointer-events: none;\n}\n", ""]);

// exports
exports.locals = {
	"inAppOnboarding": "AccountNavItem__inAppOnboarding___1xXMy"
};