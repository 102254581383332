import PropTypes from 'prop-types';
import { physicianShape } from 'modules/physicians';
import { visitNoteTemplateShape } from 'modules/visit-note-templates';
import { visitNoteTypeShape } from 'modules/visit-note-types';

// eslint-disable-next-line import/prefer-default-export
export const appointmentTypeShape = PropTypes.shape({
  patient_bookable: PropTypes.bool,
  delete_date: PropTypes.string,
  name: PropTypes.string,
  sequence: PropTypes.number,
  color: PropTypes.string,
  abbreviation: PropTypes.string,
  bookable_physicians: PropTypes.arrayOf(physicianShape),
  // model_class: 'AppointmentType',
  visit_note_template: visitNoteTemplateShape,
  visit_note_type: visitNoteTypeShape,
  id: PropTypes.number.isRequired,
  practice_id: PropTypes.number.isRequired,
  default_duration: PropTypes.number,
});
