/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Support Intake and Communication
 * This API defines the endpoints dealing with Customer feedback/support requests.

 * OpenAPI spec version: 0.1.0
 */

export type Persona = typeof Persona[keyof typeof Persona];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Persona = {
  passport_user: 'passport_user',
  ehr_user: 'ehr_user',
  connect_user: 'connect_user',
  vendor: 'vendor',
  prospect: 'prospect',
} as const;
