import moment from 'moment-timezone';

/**
 * This function accepts the same arguments as the `moment` constructor, and
 * then performs a two-step process:
 *
 * 1. The constructed moment is converted to the practice's timezone
 * 2. The converted moment is transposed to the browser's timezone, so the user
 *    can see times as if they were viewing from the practice's timezone.
 *
 * For example, `practiceTime()` would return a Date object representing the current
 * time in the practice's timezone. "Current time" in this context means same year,
 * month, date, hour, minute, second, and millisecond. Timezone offset is ignored,
 * which is why if the browser and practice timezones differ, `practiceTime()`
 * will return a time that is not strictly equal (UTC-wise) to the current time
 * in the browser's timezone.
 *
 * @param {...args} args - see documentation for momentjs's constructor:
 * https://momentjs.com/docs/#/parsing/
 */
export default function practiceTime(...args: any[]): Date {
  const practiceDate = moment(...args).tz(el8Globals.PRACTICE_LOCALE);
  return new Date(
    practiceDate.year(),
    practiceDate.month(),
    practiceDate.date(),
    practiceDate.hour(),
    practiceDate.minute(),
    practiceDate.second(),
    practiceDate.millisecond(),
  );
}
