/**
 * Transforms an array of items into an object-map where each property key-value
 * pair is an item key and the item itself.
 *
 * For example, mapArrayToObject([
 *   { id: 3, name: 'Bob' },
 *   { id: 4, name: 'Rob' }
 * ], 'id')
 *
 * would return:
 * {
 *   3: { id: 3, name: 'Bob' },
 *   4: { id: 4, name: 'Rob' }
 * }
 *
 * @param array - the array of items to transform
 * @param keyName - the name of the property key that uniquely identifies each item
 */
// eslint-disable-next-line import/prefer-default-export, require-jsdoc
export function mapArrayToObject<T extends Record<K, string | number>, K extends keyof T>(
  array: T[] = [],
  keyName: K,
): { [key: string]: T } {
  const obj: { [key: string]: T } = {};
  array.forEach((item): void => {
    obj[item[keyName]] = item;
  });
  return obj;
}
