import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import {
  AppointmentType,
  AppointmentTypeNormalized,
  Physician,
  VisitNoteTemplate,
  VisitNoteType,
  PatientFormNormalized,
} from 'EntityTypes';
import { EntitiesById, RootState } from 'StoreTypes';

import { appointmentTypeSchema } from './appointmentTypesSchemas';

/**
 * Returns true if the given appointment type is NOT a deleted appointment - we sometimes
 * fetch deleted appointment types in order to show complete and accurate historical
 * data. But we should not be actively using deleted appointments for anything beyond
 * that. Note that this is written as isNotDeleted instead of isDeleted so we can pass it
 * directly into `Array.filter` calls.
 */
function isNotDeleted(appointmentType: AppointmentTypeNormalized): boolean {
  return !appointmentType.delete_date;
}

/**
 * Fully denormalizes a normalized appointment type.
 */
function denormalizeAppointmentType(
  appointmentType: AppointmentTypeNormalized,
  entities: {
    patientForms: EntitiesById<PatientFormNormalized>;
    physicians: EntitiesById<Physician>;
    visitNoteTemplates: EntitiesById<VisitNoteTemplate>;
    visitNoteTypes: EntitiesById<VisitNoteType>;
  },
): AppointmentType {
  const denormalized: AppointmentType = denormalize(
    appointmentType,
    appointmentTypeSchema,
    entities,
  );

  // Could end up with `undefined` values if normalizr couldn't find a matching
  // entity for the ID. Clean those out, they're not usable for anything.
  denormalized.bookable_physicians = denormalized.bookable_physicians.filter(Boolean);

  // Could end up with `undefined` values if normalizr couldn't find a matching
  // entity for the ID. Clean those out, they're not usable for anything.
  denormalized.patient_forms = denormalized.patient_forms.filter(Boolean);

  // Could end up with `undefined` values if normalizr couldn't find a matching
  // entity for the ID. Clean those out, they're not usable for anything.
  denormalized.visit_note_templates = denormalized.visit_note_templates.filter(Boolean);
  return denormalized;
}

export const getAppointmentType = createSelector(
  (state: RootState, ownProps: { appointmentTypeId: number }): AppointmentTypeNormalized =>
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'AppointmentTypeNormalized | undefined' is no... Remove this comment to see the full error message
    state.entities.appointmentTypes?.byId?.[ownProps.appointmentTypeId],
  (state): EntitiesById<Physician> => state.entities.physicians?.byId ?? {},
  (state): EntitiesById<VisitNoteTemplate> => state.entities.visitNoteTemplates?.byId ?? {},
  (state): EntitiesById<VisitNoteType> => state.entities.visitNoteTypes?.byId ?? {},
  (state): EntitiesById<PatientFormNormalized> => state.entities.patientForms?.byId ?? {},
  (
    appointmentType,
    physiciansById,
    visitNoteTemplatesById,
    visitNoteTypesById,
    patientFormsById,
  ): AppointmentType => {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Appointment... Remove this comment to see the full error message
    if (!appointmentType) return null;

    return denormalizeAppointmentType(appointmentType, {
      patientForms: patientFormsById,
      physicians: physiciansById,
      visitNoteTemplates: visitNoteTemplatesById,
      visitNoteTypes: visitNoteTypesById,
    });
  },
);

/**
 * Gets the sorted list of appointment types.
 *
 * NOTE: assumes that no appointment types other than those for the current
 * practice will ever be loaded into Redux. Will have to add a filter in the
 * future if that changes.
 */
export const getAppointmentTypes = createSelector(
  (state: RootState): EntitiesById<AppointmentTypeNormalized> =>
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ [id: number]: AppointmentTypeNormalized; }... Remove this comment to see the full error message
    state.entities.appointmentTypes?.byId,
  (state): EntitiesById<Physician> => state.entities.physicians?.byId ?? {},
  (state): EntitiesById<VisitNoteTemplate> => state.entities.visitNoteTemplates?.byId ?? {},
  (state): EntitiesById<VisitNoteType> => state.entities.visitNoteTypes?.byId ?? {},
  (state): EntitiesById<PatientFormNormalized> => state.entities.patientForms?.byId ?? {},
  (
    normalizedAppointmentTypesById,
    physiciansById,
    visitNoteTemplatesById,
    visitNoteTypesById,
    patientFormsById,
  ): AppointmentType[] => {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Appointment... Remove this comment to see the full error message
    if (!normalizedAppointmentTypesById) return null;

    return Object.values(normalizedAppointmentTypesById)
      .filter(isNotDeleted)
      .sort((a, b): number => a.sequence - b.sequence)
      .map(
        (appointmentType): AppointmentType =>
          denormalizeAppointmentType(appointmentType, {
            patientForms: patientFormsById,
            physicians: physiciansById,
            visitNoteTemplates: visitNoteTemplatesById,
            visitNoteTypes: visitNoteTypesById,
          }),
      );
  },
);

/**
 * Selector checks whether or not any physician is associated with any appointment type
 *
 * @returns {boolean}
 */
export const isAnyPhysicianAssociatedToAnyApptType = createSelector(
  (state: RootState): EntitiesById<AppointmentTypeNormalized> =>
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ [id: number]: AppointmentTypeNormalized; }... Remove this comment to see the full error message
    state.entities.appointmentTypes?.byId,
  (normalizedAppointmentTypesById): boolean =>
    normalizedAppointmentTypesById
      ? Object.values(normalizedAppointmentTypesById)
          .filter(isNotDeleted)
          .some((appointmentType): boolean => appointmentType.bookable_physicians.length > 0)
      : false,
);

type GetLinkedAppointmentTypesForPatientForm = (
  state: RootState,
  props: { patientFormId: number },
) => AppointmentTypeNormalized[];

export const makeGetLinkedAppointmentTypesForPatientForm =
  (): GetLinkedAppointmentTypesForPatientForm => {
    return createSelector(
      (state: RootState): EntitiesById<AppointmentTypeNormalized> =>
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ [id: number]: AppointmentTypeNormalized; }... Remove this comment to see the full error message
        state.entities.appointmentTypes?.byId,
      (_state: RootState, props: { patientFormId: number }): number => props.patientFormId,
      (appointmentTypesById, patientFormId): AppointmentTypeNormalized[] => {
        if (!(appointmentTypesById && patientFormId)) {
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'Appoin... Remove this comment to see the full error message
          return undefined;
        }
        return Object.values(appointmentTypesById)
          .filter(
            (appointmentType: AppointmentTypeNormalized): boolean =>
              isNotDeleted(appointmentType) &&
              appointmentType.patient_forms.includes(patientFormId),
          )
          .sort((a, b): number => a.sequence - b.sequence);
      },
    );
  };
