import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { RootState } from 'StoreTypes';

/**
 * A custom version of `useSelector` that uses our app's custom `RootState` type.
 * This should be preferred over the bare `useSelector`.
 */
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default useAppSelector;
