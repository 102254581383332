export default {
  DATE: 'MM/DD/YYYY',
  /**
   * A format to display a string like "07/01/2020 1:15 pm".
   */
  DATETIME: 'MM/DD/YYYY h:mm a',
  DATETIME_WITH_TZ: 'MM/DD/YYYY h:mm a z',
  DATE_WORDY: 'MMM D, YYYY',
  SERVER_DATETIME_FORMAT: 'MM/DD/YYYY HH:mm:ss ZZ',
  MEDIUM_DATE: 'MMM Do',
  TIME_INPUT_FORMAT: 'HH:mm',
};
