import { useCallback } from 'react';
import { useFormikContext } from 'formik';

import { ValidationError } from 'utils/errors';

import { getErrorFieldName } from './useSubmitErrorFocus';

type Options = {
  formRootRef?: React.MutableRefObject<HTMLElement | undefined | null>;
};

/**
 * An enhanced version of Formik's `submitForm`. A type argument can be specified to indicate
 * what the form's `handleSubmit` function returns; make sure not to use `Promise` for this type
 * argument because it will already be wrapped in a `Promise`.
 *
 * The submit function will throw a special `ValidationError` if the submission fails due to there being form errors.
 * You can check for `ValidationError` in catch blocks to know if it's unnecessary to show error toasts/alerts,
 * since form validation errors will already display inline next to the invalid fields.
 *
 * @param [options.formRootRef] - a ref that holds the root element of the form. If this is
 * passed in, the submit function returned by this hook will attempt to handle submit validation
 * errors by automatically focusing the first field with an error. Fields will be searched by
 * querying descendants of this root element (currently, this functionality only works for
 * `input` and `textarea` elements, but should be expanded to other input types in the future).
 *
 * **Note**: This version of `submitForm` fixes a Formik bug where `submitForm` does not reject the
 * promise if there are validation errors in the form.
 *
 * @see https://github.com/formium/formik/issues/1580
 */
export default function useFormikSubmitForm<T = void>(options: Options = {}): () => Promise<T> {
  const { setFieldTouched, submitForm, validateForm } = useFormikContext();
  const { formRootRef } = options;

  const typedSubmitForm = submitForm as () => Promise<T>;

  const fixedSubmitForm = useCallback(async () => {
    try {
      const submitValue = await typedSubmitForm();

      const formikErrors = await validateForm();

      if (Object.keys(formikErrors).length > 0) {
        if (formRootRef?.current) {
          const errorFieldName = getErrorFieldName(formikErrors);
          if (errorFieldName) {
            const fieldToFocus = formRootRef.current.querySelector<HTMLInputElement>(
              `input[name="${errorFieldName}"], textarea[name="${errorFieldName}"]`,
            );
            if (fieldToFocus) {
              setFieldTouched(errorFieldName, true);
              fieldToFocus.focus();
            }
          }
        }
        throw new ValidationError();
      } else {
        return submitValue;
      }
    } catch (err) {
      if (!(err instanceof ValidationError)) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to parameter of type 'Error'
        console.warn(`Form submission error: ${err.message}`);
      }
      throw err;
    }
  }, [formRootRef, setFieldTouched, typedSubmitForm, validateForm]);

  return fixedSubmitForm;
}

export type FormikSubmitFormOptions = Options;
