exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RestoreDeletedDocumentsDialogContent__datagrid___1uk8e.RestoreDeletedDocumentsDialogContent__datagrid___1uk8e {\n  border-top: 0px;\n  border-left: 0px;\n  border-right: 0px;\n  border-radius: var(--vds-border-radius-0);\n}\n", ""]);

// exports
exports.locals = {
	"datagrid": "RestoreDeletedDocumentsDialogContent__datagrid___1uk8e"
};