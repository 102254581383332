import omit from 'lodash/omit';
import { AmplitudeError, logEvent } from '.';

// el8-migrate(rm-31221): DO NOT COPY AND PASTE THESE LINES TO SILENCE YOUR OWN LINTING ERRORS
// This comment is here solely to flag all of the existing `no-explicit-any` offenders
// when the rule was set to "error"
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LogEventBase = Record<string, any>;

/**
 * This puts the required try/catch into one place and lets amplitude implementers
 *  focus on defining a type for their event properties but with flexibility to
 *  have a verb stashed in the property in cases where verbs will be shared between
 *  many events. As an example see WorkspacePopupTracker and places it is used.
 * @type T is the type of the properties object passed to Amplitude
 * @param title either a "verb noun" or just a noun and you should override getExtraVerb based
 *  on your type of eventProperties (e.g. dialog popup needs to be passed in the source of
 *  popping up but the correct verb (complete vs cancel) is known only withing the dialog)
 * @param verbPropOnType if applicable, the name of a string property on type T containing a
 *  verb to put in the title sent to Amplitude. Removed from eventProperties sent to Amplitude.
 * @returns (eventProperties:T)=>Promise<void>
 */
export function createLogEvent<T extends LogEventBase>(title: string, verbPropOnType?: string) {
  /**
   * Ignore getExtraVerb if verbPropOnType is not passed in. For other situations, this can
   * convert things (where title is only a noun) to event names that are verb noun
   * @param eventProperties an object intended to be Amplitude's event properties, possibly
   *   containing a param by the string in verbPropOnType
   *
   * @returns "" or (if types and log calls are built as intended) a verb
   */
  const getExtraVerb = (eventProperties: T | (AmplitudeError & LogEventBase)) => {
    if (
      verbPropOnType &&
      eventProperties instanceof AmplitudeError &&
      eventProperties[verbPropOnType] === undefined
    ) {
      // If the log event is supposed to pass a verb in but the event errored
      //  and no verb was added in the expected place to AmplitudeError, add verbage
      return 'erred on ';
    }
    if (verbPropOnType && eventProperties[verbPropOnType]) {
      return `${eventProperties[verbPropOnType]} `;
    }
    return '';
  };

  return async (eventProperties: T | (AmplitudeError & LogEventBase)): Promise<void> => {
    const useProps =
      verbPropOnType && eventProperties[verbPropOnType]
        ? omit(eventProperties, [verbPropOnType])
        : eventProperties;
    logEvent(`${getExtraVerb(eventProperties)}${title}`, useProps);
    return Promise.resolve();
  };
}
