import { useMemo, ReactText } from 'react';
import { Workspace } from 'EntityTypes';
import { useMutation } from 'redux-query-react';
import { checkSuccess } from 'utils/http';
import { AmplitudeError } from 'utils/amplitude';
import isNil from 'lodash/isNil';
import {
  PRACTICE_VIEW_WORKSPACE_ID,
  usePracticeWorkspaces,
  useUserCurrentWorkspace,
} from 'modules/workspaces';
import { workspaceContextSwitchQuery } from 'modules/workspaces/workspacesQueries';
import { getIsUserAdmin } from 'modules/practice-users';
import { useAppSelector } from 'utils/hooks';
import { alertError } from '../../../../utils/errors';
import {
  logLeaveWorkspacesView,
  logSwitchIntoWorkspace,
} from '../../../settings/WorkspacesSettings/AmplitudeTaxonomy';

export interface UseWorkspaceContextSwitcherResult {
  workspaces: Workspace[] | undefined;
  isUserAdmin: boolean;
  handleSwitchWorkspace: (workspace: Workspace) => Promise<void>;
  returnToPracticeView: () => Promise<void>;
  isPending: boolean;
  dropdownLabel: string;
  currentWorkspace: Workspace | null | undefined;
}

/**
 * Generic hook that returns a series of utilities for switching between workspaces.
 */
export function useWorkspaceContextSwitcher(): UseWorkspaceContextSwitcherResult {
  const [workspaces, workspacesQueryState, _refreshWorkspaces] = usePracticeWorkspaces(
    el8Globals.PRACTICE_ID,
  );
  const [currentWorkspace, currentWorkspaceQueryState, _refreshCurrentWorkspace] =
    useUserCurrentWorkspace();

  const isUserAdmin = useAppSelector(getIsUserAdmin);

  const [, doWorkspaceSwitch] = useMutation((workspaceId: number | null) =>
    workspaceContextSwitchQuery(workspaceId),
  );

  const handleSwitchWorkspace = async (workspace: Workspace): Promise<void> => {
    if (workspace.id === currentWorkspace?.id) return;

    el8.appservice.notifier.notify(`Switching to ${workspace.name}...`);
    try {
      const response = await doWorkspaceSwitch(workspace.id);
      if (isNil(response)) return;
      checkSuccess(response);
      el8.appservice.notifier.notify(
        `Switching to ${workspace.name} successful, the page is redirecting`,
      );
      logSwitchIntoWorkspace({ workspaceId: workspace.id });
      window.location.reload();
    } catch (err) {
      el8.appservice.notifier.notify(`Failed to switch to ${workspace.name}`);
      alertError(err);
      logSwitchIntoWorkspace(new AmplitudeError(err, 'workspace context switcher', false));
    }
  };

  const returnToPracticeView = async (): Promise<void> => {
    el8.appservice.notifier.notify(`Returning to practice view...`);
    try {
      const response = await doWorkspaceSwitch(null);
      if (isNil(response)) return;
      checkSuccess(response);
      el8.appservice.notifier.notify(
        `Returning to practice view successful, the page is redirecting`,
      );
      logLeaveWorkspacesView({ workspaceId: currentWorkspace?.id || PRACTICE_VIEW_WORKSPACE_ID });
      window.location.reload();
    } catch (err) {
      el8.appservice.notifier.notify(`Failed to return to practice view`);
      alertError(err);
      logLeaveWorkspacesView(
        new AmplitudeError(
          err,
          'workspace context switcher',
          false,
          currentWorkspace?.id ? { workspaceId: currentWorkspace?.id } : {},
        ),
      );
    }
  };

  const isPending = workspacesQueryState.isPending || currentWorkspaceQueryState.isPending;
  const dropdownLabel: ReactText = useMemo(() => {
    if (isNil(currentWorkspace)) return 'Workspaces';

    return currentWorkspace.name;
  }, [currentWorkspace]);

  return {
    workspaces,
    isUserAdmin,
    handleSwitchWorkspace,
    returnToPracticeView,
    isPending,
    dropdownLabel,
    currentWorkspace,
  };
}
