import React, { useCallback } from 'react';
import cn from 'classnames';
import { Mindreader2, MindreaderProps, MenuItem, MindreaderResultItemProps } from '@el8/vital';

import { Flexbox } from 'components/layout';

import styles from './BaseMindreader.less';

export type BaseMindreaderItemDetails = {
  leftElement?: React.ReactNode;
  title: React.ReactNode;
  description?: string | string[];
  key: string | number;
  tags?: string[];
};

export type BaseMindreaderProps<TResultItem, TQueryResults> = {
  getItemDetails: (item: TResultItem, isActive: boolean) => BaseMindreaderItemDetails;
  'data-testid'?: string;
} & Omit<MindreaderProps<TResultItem, TQueryResults>, 'renderResult'>;

/**
 * A base Mindreader component that standardizes the look of Mindreaders within
 * the EMR app, by handling the `renderResult` prop.
 */
const BaseMindreader = <TResultItem, TQueryResults>({
  getItemDetails,
  'data-testid': dataTestId,
  ...props
}: BaseMindreaderProps<TResultItem, TQueryResults>): JSX.Element => {
  const renderResult = useCallback(
    ({ result, isActive, onClick }: MindreaderResultItemProps<TResultItem>): JSX.Element => {
      const { description, key, leftElement, tags, title } = getItemDetails(result, isActive);

      const titleStyle: React.CSSProperties = { flex: 1 };
      let tagsContainerStyle: React.CSSProperties;
      // If there are multiple tags, we can't guarantee being able to show all of
      // them without wrapping (this probably means the tag count is dynamic).
      // So prioritize showing the title in full, and any leftover space in the
      // first row will go to showing as many tags as possible.
      const hasMultipleTags = Boolean(tags && tags.length > 1);
      if (hasMultipleTags) {
        titleStyle.whiteSpace = 'nowrap';
        tagsContainerStyle = { whiteSpace: 'nowrap' };
      }

      const itemTestId = dataTestId ? `${dataTestId}-${key}` : undefined;

      return (
        <MenuItem
          key={key}
          isActive={isActive}
          onClick={onClick}
          className={styles.menuItem}
          data-testid={itemTestId}
        >
          <Flexbox align="start">
            {leftElement}
            <div style={{ flex: 1 }}>
              <Flexbox align="center">
                <div style={titleStyle}>{title}</div>
                {tags && (
                  // @ts-expect-error ts-migrate(2454) FIXME: Variable 'tagsContainerStyle' is used before being... Remove this comment to see the full error message
                  <div className={styles.tagsContainer} style={tagsContainerStyle}>
                    {tags.map((tag) => (
                      <span key={tag} className={styles.tag}>
                        {tag}
                      </span>
                    ))}
                  </div>
                )}
              </Flexbox>
              {description && (
                <div className={cn(styles.description, isActive && styles.active)}>
                  {Array.isArray(description)
                    ? // eslint-disable-next-line react/no-array-index-key
                      description.map((line, index) => <div key={index}>{line}</div>)
                    : description}
                </div>
              )}
            </div>
          </Flexbox>
        </MenuItem>
      );
    },
    [getItemDetails],
  );

  return (
    <Mindreader2<TResultItem, TQueryResults>
      renderResult={renderResult}
      data-testid={dataTestId}
      {...props}
    />
  );
};

export default BaseMindreader;
