import React from 'react';
import Linkify from 'linkify-react';
import { useFormikContext } from 'formik';

import { Group, Marquee, Stack, Text2 } from '@el8/vital';
import { NextStep } from '@elation/api-client/src/support';

import { CaseIntakeStep } from 'modules/support/supportConstants';

import { CaseIntakeFormValues } from '../CaseIntakeForm';
import { logIntakeAIAnswerResolved, logIntakeCreateTicket } from '../amplitudeTaxonomy';

import styles from './CaseIntakeAIAnswer.less';
import { CaseIntakeStepProps } from '../CaseIntakeTypes';
import CaseIntakeStepHeader from '../components/CaseIntakeStepHeader';
import { IntakeButton } from '../components/IntakeButton';

const VALID_HELP_URL = /(https:\/\/\w+\.(elationemr|elationhealth|elationpassport)\.com)/;

const CaseIntakeAIAnswer = ({ goToNextStep, isInModal }: CaseIntakeStepProps): JSX.Element => {
  const { values } = useFormikContext<CaseIntakeFormValues>();
  const formattedAnswer = values.answer
    ?.trim()
    .split('\n')
    .filter((str) => str !== '');

  return (
    <Stack data-testid="support-ai-answer-container" gap="md">
      <CaseIntakeStepHeader
        isInModal={isInModal}
        step={CaseIntakeStep.answered}
        className={styles.AIAnswerHeader}
      />
      <div className={styles.content}>
        <Marquee intent="info" className={styles.answer}>
          {formattedAnswer?.map((line) => {
            return (
              <Linkify
                as="div"
                key={line}
                options={{
                  target: '_blank',
                  rel: 'noopener noreferrer',
                  validate: (value, type): boolean => {
                    return type === 'url' && VALID_HELP_URL.test(value);
                  },
                }}
              >
                {line}
              </Linkify>
            );
          })}
        </Marquee>
      </div>
      <hr />
      <div className={styles.footer}>
        <Stack gap="xs">
          <Text2 sizing="lg">
            We are testing answering questions using AI. Were we able to address your ask?
          </Text2>
          <Group>
            <IntakeButton
              data-testid="support-aianswer-resolved-button"
              label="Yes"
              onClick={(): void => {
                logIntakeAIAnswerResolved(values, NextStep.answered);
                goToNextStep(CaseIntakeStep.IssueResolved);
              }}
            />
            <IntakeButton
              data-testid="support-aianswer-need-help-button"
              label="No, I still need help"
              onClick={(): void => {
                logIntakeCreateTicket(values, NextStep.answered);
                goToNextStep(CaseIntakeStep.create_case);
              }}
            />
          </Group>
        </Stack>
      </div>
    </Stack>
  );
};

export default CaseIntakeAIAnswer;
