import * as yup from 'yup';

import { future, pastOrToday } from './yup-extensions/dateExtensions';
import {
  maxAmount,
  phoneNumber,
  ssn,
  usState,
  zipCode,
  npi,
} from './yup-extensions/stringExtensions';
import { max } from './yup-extensions/stringOverrides';

let initialized = false;

/**
 * Monkey patches and extends yup by adding validators useful for our app specifically.
 */
export default function augmentYup() {
  if (initialized) return;

  // -- extensions -- See yup.d.ts for where we define extended Typescript types for these
  yup.addMethod(yup.date, 'pastOrToday', pastOrToday);
  yup.addMethod(yup.date, 'future', future);

  yup.addMethod(yup.string, 'maxAmount', maxAmount);
  yup.addMethod(yup.string, 'phoneNumber', phoneNumber);
  yup.addMethod(yup.string, 'ssn', ssn);
  yup.addMethod(yup.string, 'usState', usState);
  yup.addMethod(yup.string, 'zipCode', zipCode);
  yup.addMethod(yup.string, 'npi', npi);

  // -- overrides --
  yup.addMethod(yup.string, 'max', max);

  initialized = true;
}
