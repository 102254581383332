import { Button2, Dialog, Group } from '@el8/vital';
import React, { forwardRef, ReactNode, useImperativeHandle, useState } from 'react';

interface AlertOptions {
  content?: ReactNode;
  title?: string;
  onClose?: () => void;
}

const initOptions: AlertOptions = {
  title: 'ALERT',
  content:
    'An error has occurred. Please refresh the page. If this persists, ' +
    'contact Support using the "I need help" menu above.',
};

const AlertPopup = forwardRef((_, ref): JSX.Element => {
  const [options, setOptions] = useState<AlertOptions>(initOptions);
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    // This is called from `utils/alert` to provide easy access to alert dialogs.
    onShow: (
      content: AlertOptions['content'] = initOptions.content,
      title: AlertOptions['title'] = initOptions.title,
      onClose: AlertOptions['onClose'],
    ): void => {
      setOptions({ content, title, onClose });
      setIsOpen(true);
    },
  }));

  const handleClose = (): void => {
    setIsOpen(false);
    options.onClose?.();
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={options.title}
      footer={
        <Group>
          <Button2 kind="primary" label="Okay" onClick={handleClose} />
        </Group>
      }
      isModal
      canClickOutsideToClose={false}
      padded
      hasDividers={false}
      width="30rem"
    >
      {options.content}
    </Dialog>
  );
});

export default AlertPopup;
