import {
  MutationKey,
  QueryKey,
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import {
  PatientDeletedDocumentsRepsonseBody,
  RestorePatientDeletedDocumentRequestVariables,
  RestorePatientDeletedDocumentResponseBody,
} from 'QueryTypes';
import { getQueryUrl, jsonFetch } from 'utils/react-query';

const queryKeys = {
  getPatientDeletedDocuments: (patientId: MaybeNullishId): QueryKey => [
    'patient',
    patientId,
    'deleted-documents',
  ],
  restorePatientDeletedDocument: (patientId: number, documentId: number): MutationKey => [
    'patient',
    patientId,
    'deleted-documents',
    documentId,
  ],
};

export function usePatientDeletedDocuments(
  patientId: MaybeNullishId,
): UseQueryResult<PatientDeletedDocumentsRepsonseBody> {
  return useQuery({
    queryKey: queryKeys.getPatientDeletedDocuments(patientId),
    enabled: !!patientId,
  });
}

export function useRestorePatientDeletedDocument(): UseMutationResult<
  RestorePatientDeletedDocumentResponseBody,
  unknown,
  RestorePatientDeletedDocumentRequestVariables,
  unknown
> {
  return useMutation({
    mutationFn: ({ patientId, documentId }: RestorePatientDeletedDocumentRequestVariables) => {
      return jsonFetch<RestorePatientDeletedDocumentResponseBody>(
        getQueryUrl(queryKeys.restorePatientDeletedDocument(patientId, documentId)),
        {
          method: 'POST',
        },
      );
    },
  });
}
