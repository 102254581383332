/**
 * FYI this directory is a mess because our API has no consistency with error messages,
 * so we ended up with multiple different functions that attempt to parse error messages
 * from responses. When the API is fixed, we can consolidate to a single function.
 */
export { default as alertError } from './alertError';
// TODO export is commented out due to name collision for `getErrorMessage`, the
// commented out version is what we want to use moving forward, but we have to
// understand the constraints / alter the name collision for `getErrorMessage` from
// `./errorMessage` to be named differently

// export { default as getErrorMessage } from './getErrorMessage';
export { default as ResponseError } from './ResponseError';
export { maybeHandleRequestErrors } from './maybeHandleRequestError';
export { default as toastError } from './toastError';
export { default as getErrorTypes, ErrorType } from './getErrorTypes';
export { default as ValidationError } from './ValidationError';
