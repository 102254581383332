import React from 'react';

import { Stack, Text2 } from '@el8/vital';

import { CaseIntakeStep } from 'modules/support/supportConstants';

import { useFormikContext } from 'formik';
import { NextStep } from '@elation/api-client/src/support';
import { CaseIntakeFormValues } from '../CaseIntakeForm';

import styles from './CaseIntakeDoctorRedirect.less';
import { logIntakeCreateTicket } from '../amplitudeTaxonomy';
import { CaseIntakeStepProps } from '../CaseIntakeTypes';
import CaseIntakeStepHeader from '../components/CaseIntakeStepHeader';
import { IntakeButton } from '../components/IntakeButton';

const CaseIntakeDoctorRedirect = ({
  goToNextStep,
  isInModal,
}: CaseIntakeStepProps): JSX.Element => {
  const { values } = useFormikContext<CaseIntakeFormValues>();
  return (
    <Stack data-testid="support-doctor-redirect-container" gap="md" className={styles.content}>
      <CaseIntakeStepHeader isInModal={isInModal} step={CaseIntakeStep.direct_to_physician} />
      <div>
        <Text2 sizing="lg">
          This page gives you general info and help with the Elation Patient Passport application.
          If you need to talk to your doctor or have specific medical questions, you can do it in
          these ways:
        </Text2>
        <ul className={styles.list}>
          <Text2 tag="li" sizing="lg">
            Send a message to your doctor using{' '}
            <Text2
              data-testid="support-doctor-redirect-patient-passport-link"
              href="https://www.elationhealth.com/patient-passport/"
              rel="noopener noreferrer"
              tag="a"
              target="_blank"
              sizing="lg"
            >
              Patient Passport
            </Text2>
            .
          </Text2>
          <Text2 tag="li" sizing="lg">
            Call your doctor at their practice phone number.
          </Text2>
        </ul>
      </div>
      <hr />
      <div>
        <Stack gap="xs">
          <Text2 sizing="lg">Still need our help?</Text2>
          <IntakeButton
            data-testid="support-doctor-redirect-continue-button"
            className={styles.btn}
            label="Continue Sending Message to Elation Support"
            onClick={(): void => {
              logIntakeCreateTicket(values, NextStep.direct_to_physician);
              goToNextStep(CaseIntakeStep.create_case);
            }}
          />
        </Stack>
      </div>
    </Stack>
  );
};

export default CaseIntakeDoctorRedirect;
