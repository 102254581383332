import React from 'react';
import { Group } from '@el8/vital';
import cn from 'classnames';
import styles from './NavbarItem.less';
import { NavbarItemTooltip } from './NavbarItemTooltip';

export interface NavbarItemLinkProps
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  type: 'link';
  href: string;
  text?: string;
  'data-testid': string;
  icon?: JSX.Element;
  rightAccessory?: JSX.Element;
  disabled?: boolean;
  tooltip?: string;
}

export const NavbarItemLink = ({
  href,
  children,
  text,
  className,
  rightAccessory,
  icon,
  tooltip,
  target = '_blank',
  rel = 'noopener',
  ...restProps
}: React.PropsWithChildren<Omit<NavbarItemLinkProps, 'type'>>): JSX.Element => {
  return (
    <NavbarItemTooltip tooltip={tooltip}>
      <a
        {...restProps}
        target={target}
        rel={rel}
        href={href}
        className={cn(styles.link, className)}
      >
        <Group verticallyAlign="center" gap="2xs" className={styles.noWrap}>
          {icon}
          <span className={styles.truncate}>{text ?? children}</span>
          {rightAccessory}
        </Group>
      </a>
    </NavbarItemTooltip>
  );
};
