import omit from 'lodash/omit';
import { PatientFormNormalized, PatientForm, PracticeUser } from 'EntityTypes';
import { EntitiesById } from 'StoreTypes';

type Entities = {
  practiceUsers: EntitiesById<PracticeUser>;
};

// We use our own denormalize function rather than the on efrom normalizr library,
// because we have a special handling for `created_by` property, which can be not
// only the practice user, but an 'elation' as well
export const denormalizePatientForm = (
  patientForm: PatientFormNormalized,
  entities: Entities,
): PatientForm => {
  const denormalizedPatientForm: Partial<PatientForm> = omit(patientForm, [
    'created_by',
    'last_modified_by',
  ]) as Omit<PatientFormNormalized, 'created_by' | 'last_modified_by'>;

  denormalizedPatientForm.created_by =
    patientForm.created_by === 'elation'
      ? 'elation'
      : entities.practiceUsers[patientForm.created_by];

  denormalizedPatientForm.last_modified_by = entities.practiceUsers[patientForm.last_modified_by];

  return denormalizedPatientForm as PatientForm;
};
