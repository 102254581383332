import React from 'react';
import cn from 'classnames';
import styles from './Button.less';

type Props = {
  active?: boolean;
  children: React.ReactNode;
  className?: string;
  kind?: 'default' | 'primary';
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

/**
 * Standard button component.
 */
const Button: React.FC<Props> = ({
  active = false,
  children,
  className = '',
  kind = 'default',
  ...props
}) => (
  <button
    type="button"
    className={cn(styles.base, styles[kind], active && styles.active, className)}
    {...props}
  >
    {children}
  </button>
);
export default Button;
