import snakeCase from 'lodash/snakeCase';
import camelCase from 'lodash/camelCase';

/**
 * Converts the keys of an object to camelCase.
 * This function creates and returns a new object.
 *
 * @param {object} obj - the object to camelCase keys for
 * @return {object} a copy of the input with keys camelCased
 */
export function camelCaseKeys<
  TIn extends Record<string, unknown>,
  TOut extends Record<string, unknown>,
>(obj: TIn): TOut {
  const newObject: Record<string, unknown> = {};
  Object.keys(obj).forEach((key) => {
    newObject[camelCase(key)] = obj[key];
  });
  return newObject as TOut;
}

type RecursiveCamelCase<T> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [K in keyof T as K extends string ? CamelCase<K> : K]: T[K] extends (...args: any[]) => any
    ? (...args: Parameters<T[K]>) => RecursiveCamelCase<ReturnType<T[K]>>
    : T[K] extends object
    ? RecursiveCamelCase<T[K]>
    : T[K];
};

type CamelCase<S extends string> = S extends `${infer P1}_${infer P2}${infer P3}`
  ? `${Lowercase<P1>}${Capitalize<Lowercase<P2>>}${CamelCase<P3>}`
  : S;

/**
 * Converts the keys of an object from snake_case to camelCase.
 * Recursively converts keys of nested objects and arrays as well.
 * This function creates and returns a new object.
 *
 * @param {object} obj - The object to recursively camelCase keys for.
 * @return {object} A copy of the input with keys recursively camelCased.
 */
export function recursiveCamelCaseKeys<T>(obj: T): RecursiveCamelCase<T> {
  if (typeof obj !== 'object' || obj === null) {
    return obj as unknown as RecursiveCamelCase<T>;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => recursiveCamelCaseKeys(item)) as unknown as RecursiveCamelCase<T>;
  }

  const newObject: Record<string, unknown> = {};
  Object.keys(obj as Record<string, unknown>).forEach((key) => {
    const camelCasedKey = camelCase(key);
    const value = (obj as Record<string, unknown>)[key];
    newObject[camelCasedKey] = recursiveCamelCaseKeys(value);
  });

  return newObject as RecursiveCamelCase<T>;
}

/**
 * Converts the keys of an object to snake_case.
 * This function creates and returns a new object.
 *
 * @param {object} obj - the object to snake_case keys for
 * @return {object} a copy of the input with keys snake_cased
 */
export function snakeCaseKeys<
  TIn extends Record<string, unknown>,
  TOut extends Record<string, unknown>,
>(obj: TIn): TOut {
  const newObject: Record<string, unknown> = {};
  Object.keys(obj).forEach((key) => {
    newObject[snakeCase(key)] = obj[key];
  });
  return newObject as TOut;
}
