import PropTypes from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const visitNoteTemplateShape = PropTypes.shape({
  content: PropTypes.string,
  // model_class: 'VisitNoteTemplate',
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  shared: PropTypes.bool,
});
