import { PracticeUser, StaffGroup } from 'EntityTypes';

// Runtime expects to have an array of the type names
export const WorkspaceFeFilteredResourceTypenameArray = ['PracticeUser', 'StaffGroup'];
// Type checking likes to have the types themselves
export type WorkspaceFeFilteredResourceTypes = PracticeUser | StaffGroup;
// Import your type and add it the the type union above before adding the string to the type below -- it may be automatically added
export type WorkspaceFeFilteredResourceTypeNames =
  | ExtractModelClasses<WorkspaceFeFilteredResourceTypes>
  | 'PracticeUser';

export interface WorkspaceFrontendFiltersState {
  filterSets: ListToType<WorkspaceFeFilteredResourceTypeNames, Set<number>>;
  filterToggles: ListToType<WorkspaceFeFilteredResourceTypeNames, boolean>;
  // This is here so the query transform can pass to the query update,
  //  but it is never saved to state (see makeSetupWorkspaceFrontendFilter)
  currentlyUpdating?: { id: number; isInWorkspace?: boolean }[];
}
