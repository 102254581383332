import { createSelector } from 'reselect';
import get from 'lodash/get';
import { PatientForm, PatientFormNormalized, PracticeUser } from 'EntityTypes';
import { EntitiesById, RootState } from 'StoreTypes';
import { denormalizePatientForm } from './utils';

/**
 * Gets the sorted list of patient forms.
 */
export const getPatientForms = createSelector(
  (state: RootState): EntitiesById<PatientFormNormalized> =>
    get(state, ['entities', 'patientForms', 'byId']),
  (state: RootState): EntitiesById<PracticeUser> => get(state.entities, ['practiceUsers', 'byId']),
  (
    patientFormsById: EntitiesById<PatientFormNormalized>,
    practiceUsersById: EntitiesById<PracticeUser>,
  ): PatientForm[] => {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'Patien... Remove this comment to see the full error message
    if (!(patientFormsById && practiceUsersById)) return undefined;

    return Object.values(patientFormsById)
      .map(
        (patientForm: PatientFormNormalized): PatientForm =>
          denormalizePatientForm(patientForm, { practiceUsers: practiceUsersById }),
      )
      .sort((a, b): number => a.sequence - b.sequence);
  },
);

export const getPatientForm = createSelector(
  (state: RootState, props: { patientFormId: number | string }): PatientFormNormalized =>
    get(state.entities, ['patientForms', 'byId', props.patientFormId]),
  (state: RootState): EntitiesById<PracticeUser> => get(state.entities, ['practiceUsers', 'byId']),
  (
    patientForm: PatientFormNormalized,
    practiceUsersById: EntitiesById<PracticeUser>,
  ): PatientForm => {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'Patien... Remove this comment to see the full error message
    if (!(patientForm && practiceUsersById)) return undefined;
    return denormalizePatientForm(patientForm, { practiceUsers: practiceUsersById });
  },
);
