/**
 * Validator for National Provider Identifier (NPI) numbers.
 * Ported from Physician.npi_luhn_check() in elation/practicians/models.py
 */
class NpiValidator {
  /**
   * Removes all non-digit characters from a string.
   * @param input The string to remove non-digit characters from.
   * @returns The input string with all non-digit characters removed.
   */
  public static digitsOnly(input: string): string {
    return input.replace(/\D/g, '');
  }

  /**
   * Validates an NPI number using the Luhn algorithm.
   * @param npi The NPI to validate.
   * @returns True if the given NPI is valid, false otherwise.
   */
  public static npiLuhnCheck(npi: string): boolean {
    if (!npi || npi.length !== 10 || NpiValidator.digitsOnly(npi).length !== 10) {
      return false;
    }

    let temp = 0;
    let sumT = 24;
    let j = 0;
    let i = 10;

    while (i !== 0) {
      temp = parseInt(npi.charAt(i - 1), 10);
      if (j % 2 !== 0) {
        temp *= 2;
        if (temp > 9) {
          // Original code probably does this because
          // it's faster than temp = temp % 10 + 1
          temp -= 10;
          temp += 1;
        }
      }
      j += 1;
      sumT += temp;
      i -= 1;
    }
    return sumT % 10 === 0;
  }
}

export default NpiValidator;
