import { querySelectors, QueryConfig } from 'redux-query';
import { isSuccessCode } from 'utils/http';

import { RootState } from 'StoreTypes';
import { AppQueryConfig } from 'QueryTypes';

/**
 * Gets the custom request query key for a given URL.
 */
export function getRequestKey(url: string): string {
  return `request:${url}`;
}

/**
 * Gets the custom mutation query key for a given URL.
 */
export function getMutationKey(url: string): string {
  return `mutation:${url}`;
}

/**
 * Returns true if there is a pending request query to the given URL.
 *
 * @param state = the Redux store state
 * @param queryConfig - the query config to check with
 */
export function isRequestPending(
  state: RootState,
  queryConfig: Pick<AppQueryConfig, 'queryKey' | 'url'>,
): boolean {
  return querySelectors.isPending(state.queries, {
    queryKey: queryConfig.queryKey,
    url: queryConfig.url,
  } as QueryConfig);
}

/**
 * Returns true if there is a pending mutation query to the given URL.
 *
 * @param state = the Redux store state
 * @param queryConfig - the query config to check with
 */
export function isMutationPending(
  state: RootState,
  queryConfig: Pick<AppQueryConfig, 'queryKey'>,
): boolean {
  const isPending: boolean = querySelectors.isPending(state.queries, {
    queryKey: queryConfig.queryKey,
  } as QueryConfig);
  return isPending == null ? false : isPending;
}

/**
 * A selector for checking whether a query made by redux-query resulted in an
 * error.
 *
 * @param state - the Redux store state
 * @param queryConfig - the query config to check with
 */
export function isQueryError(state: RootState, queryConfig: AppQueryConfig): boolean {
  return (
    Boolean(querySelectors.isFinished(state.queries, queryConfig)) &&
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
    !isSuccessCode(querySelectors.status(state.queries, queryConfig))
  );
}
