import React, { SVGProps } from 'react';

export const ElationLogoSVG = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="elationLogoTitle"
      {...props}
    >
      <title id="elationLogoTitle">Elation Health</title>
      <path
        d="M36.5 72C16.6176 72 0.5 55.8823 0.5 36.0001C0.5 16.1179 16.6176 0 36.5 0C56.3822 0 72.5 16.1179 72.5 36.0001C72.5 55.8823 56.3822 72 36.5 72Z"
        fill="var(--vds-elation-badge-surface, var(--vds-elation-blue))"
      />
      <path
        d="M33.7721 22.9258C33.7721 23.3583 33.4194 23.7106 32.9857 23.7106H31.5264C31.0943 23.7106 30.7408 23.3583 30.7408 22.9258V15.6618C30.7408 15.2278 31.0943 14.8753 31.5264 14.8753H32.9857C33.4194 14.8753 33.7721 15.2278 33.7721 15.6618V22.9258Z"
        fill="white"
      />
      <path
        d="M50.4605 54.0193H30.7892V27.5872H50.4605V32.4204H35.7071V38.4999H49.9351V43.1443H35.7071V49.1106H50.4605V54.0193Z"
        fill="white"
      />
      <path
        d="M18.7808 30.5701C18.3483 30.5701 17.9958 30.2174 17.9958 29.7837V28.3244C17.9958 27.8925 18.3483 27.5387 18.7808 27.5387H26.0448C26.479 27.5387 26.8313 27.8925 26.8313 28.3244V29.7837C26.8313 30.2174 26.479 30.5701 26.0448 30.5701H18.7808Z"
        fill="white"
      />
      <path
        d="M37.6229 25.8352C37.3172 26.1411 36.8186 26.1409 36.5118 25.8341L35.48 24.8023C35.1745 24.4968 35.1737 23.9976 35.4794 23.6917L40.6159 18.5553C40.9229 18.2483 41.4221 18.2494 41.7276 18.5547L42.7594 19.5867C43.0662 19.8935 43.0664 20.3918 42.7594 20.699L37.6229 25.8352Z"
        fill="white"
      />
      <path
        d="M23.8151 39.6399C23.5094 39.9458 23.0108 39.9456 22.704 39.6388L21.6722 38.607C21.3667 38.3015 21.3656 37.8023 21.6716 37.4964L26.8081 32.36C27.1151 32.053 27.6141 32.0539 27.9196 32.3594L28.9516 33.3914C29.2584 33.698 29.2586 34.1965 28.9516 34.5035L23.8151 39.6399Z"
        fill="white"
      />
      <path
        d="M21.6751 20.6186C21.3692 20.3129 21.3694 19.8143 21.6762 19.5075L22.7082 18.4755C23.0137 18.1702 23.5129 18.1691 23.8186 18.4751L28.9552 23.6114C29.2621 23.9184 29.2611 24.4176 28.9558 24.7231L27.9238 25.7549C27.617 26.0617 27.1184 26.0619 26.8115 25.7549L21.6751 20.6186Z"
        fill="white"
      />
    </svg>
  );
};
