import React, { useEffect, useState } from 'react';

import { Spinner2, Stack, Text2 } from '@el8/vital';
import { Select3Formik } from '@el8/vital-formik';
import { NextStep, Persona, QuestionCategories } from '@elation/api-client/src/support';
import { usePostSupportAskQuestion } from '@elation/api-client/src/support/supportIntakeAndCommunication';
import { useFormikContext } from 'formik';

import { alertError, ValidationError } from 'utils/errors';

import { useCurrentUser } from 'modules/practice-users';
import { CaseIntakeStep } from 'modules/support/supportConstants';
import { useFormikSubmitForm, usePrevious } from 'utils/hooks';
import { CaseIntakeFormValues } from '../CaseIntakeForm';

import styles from './CaseIntakeInitialForm.less';
import {
  logIntakeAIResponded,
  logIntakeCreateTicket,
  logIntakeDetailsSubmitted,
} from '../amplitudeTaxonomy';

import DefaultIntakeInfo from '../components/DefaultIntakeInfo';
import { CaseIntakeIssueOption, CaseIntakeStepProps } from '../CaseIntakeTypes';
import CaseIntakeStepHeader from '../components/CaseIntakeStepHeader';
import CaseIntakeDialogStatusMarquee from '../components/CaseIntakeStatusMarquee';
import useJsonFormsFormikSubmit from '../hooks/useJsonFormsFormikSubmit';
import {
  nextStepMap,
  ROLES,
  SupportIntakeInitialInfoMap,
  toIntakeIssues,
  toStepKey,
} from './CaseIntakeInitialForm.utils';
import { useResetJsonFormsContext } from '../hooks/jsonFormsContext';

type IssueSupportedPersona = Extract<Persona, 'ehr_user' | 'vendor'>;

const showIssueSelector = (persona: Persona | undefined): persona is IssueSupportedPersona =>
  persona === Persona.ehr_user || persona === Persona.vendor;

const useGetNextStep = (): ((persona: Persona) => Promise<CaseIntakeStep>) => {
  const { mutateAsync: getAnswer } = usePostSupportAskQuestion();
  const { values, setFieldValue } = useFormikContext<CaseIntakeFormValues>();
  return async (persona: Persona): Promise<CaseIntakeStep> => {
    const response = await getAnswer({
      data: {
        question: values.details,
        persona,
        category: values.category,
      },
    });

    if (response.next_step === NextStep.create_case) {
      logIntakeCreateTicket(values, response.next_step);
    } else {
      logIntakeAIResponded(values, response);
    }

    setFieldValue('answer', response.answer);
    setFieldValue('question', values.details);
    setFieldValue('directed_to_step', response.next_step);
    return response.next_step;
  };
};

const CaseIntakeInitialForm = ({
  formRootRef,
  goToNextStep,
  isInModal,
}: CaseIntakeStepProps): JSX.Element => {
  const validateCombinedForm = useJsonFormsFormikSubmit({ formRootRef });
  const validateFormikForm = useFormikSubmitForm({ formRootRef });
  const [isLoading, setIsLoading] = useState(false);
  const resetJsonFormsContext = useResetJsonFormsContext();
  const { values, setValues, setFieldValue, setSubmitting, setFieldTouched } =
    useFormikContext<CaseIntakeFormValues>();
  const previousCategory = usePrevious(values.category);
  const getNextStep = useGetNextStep();
  const authUser = useCurrentUser()?.staffType;
  const showPersonaSelector = !authUser;
  const issues = toIntakeIssues(values.persona, !!authUser).sort((a, b) =>
    a.label.localeCompare(b.label),
  );

  useEffect(() => {
    if (values.category !== previousCategory) {
      // Clear json forms and form values when switching from integration_request to another category
      if (previousCategory === QuestionCategories.integration_request) {
        setValues({
          ...values,
          details: '',
          question: '',
        });
        resetJsonFormsContext?.();
      }
    }
  }, [values.category, previousCategory, values, resetJsonFormsContext, setValues]);

  const handleNext = async (): Promise<void> => {
    setIsLoading(true);
    try {
      // We only validate both JSON Forms and Formik if the category is
      // integration_request, otherwise we will run into validation issues
      // with the JSON Forms when no dynamic form is present.
      if (values?.category === QuestionCategories.integration_request) {
        await validateCombinedForm();
      } else {
        await validateFormikForm();
      }
      if (!values.persona) {
        throw new ValidationError('Role selection required');
      }
      logIntakeDetailsSubmitted(values.persona, values.category);
      const key = toStepKey([values.persona, values.category]);
      goToNextStep(
        key in nextStepMap ? nextStepMap[key](values) : await getNextStep(values.persona),
      );
    } catch (err) {
      if (!(err instanceof ValidationError)) {
        alertError(err as Error);
      }
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    setFieldValue('category', undefined);
    setFieldTouched('category', false);
  }, [setFieldTouched, setFieldValue, values.persona]);

  const IntakeInfo = values.persona
    ? SupportIntakeInitialInfoMap[toStepKey([values.persona, values.category])] || DefaultIntakeInfo
    : DefaultIntakeInfo;

  return (
    <Stack gap="md" data-testid="support-intake-form-container" className={styles.content}>
      <CaseIntakeStepHeader isInModal={isInModal} step={CaseIntakeStep.Intake} />
      <CaseIntakeDialogStatusMarquee />
      {isLoading ? (
        <Stack className={styles.loadingContainer}>
          <Spinner2 sizing="lg" className={styles.spinner} />
          <Text2 align="center" appearance="muted" sizing="sm">
            We&apos;re searching our documentation for an answer. This may take up to 15 seconds.
          </Text2>
        </Stack>
      ) : (
        <>
          {showPersonaSelector && (
            <Select3Formik
              label="What is your role?"
              labelPosition="column"
              data-testid="support-intake-form-user-type"
              items={ROLES}
              name="persona"
              getItemKey={(item): string => item.value}
              getItemLabel={(item): string => item.label}
              getItemValue={(item): string => item.value}
              required
            />
          )}
          {showIssueSelector(values.persona) ? (
            <Select3Formik<QuestionCategories, CaseIntakeIssueOption>
              label="Select an issue"
              labelPosition="column"
              data-testid="support-intake-form-issue"
              getItemKey={(item): QuestionCategories => item.value}
              getItemLabel={(item): string => item.label}
              getItemValue={(item): QuestionCategories => item.value}
              items={issues}
              name="category"
              required
            />
          ) : null}
          <IntakeInfo isLoading={isLoading} onNext={handleNext} />
        </>
      )}
    </Stack>
  );
};

export default CaseIntakeInitialForm;
