import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';

import { ProviderSecurityCodeResponseBody } from 'QueryTypes';
import { getQueryUrl, jsonFetch } from 'utils/react-query';

/** Returns the query key for getting the provider security code. */
const queryKeys = {
  providerSecurityCode: (): QueryKey => ['current-registration-password'],
};

/**
 * Returns a password used as the provider security code.
 */
export function useProviderSecurityCode(): UseQueryResult<ProviderSecurityCodeResponseBody> {
  return useQuery({
    queryFn: async () => {
      return jsonFetch<ProviderSecurityCodeResponseBody>(
        getQueryUrl(queryKeys.providerSecurityCode()),
      );
    },
  });
}
