import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Group, Spinner2, Stack, Text2, UnavailableContent } from '@el8/vital';
import { Select3Formik } from '@el8/vital-formik';
import { VitalJsonForm } from '@el8/vital-jsonforms-poc';
import {
  Persona,
  QuestionCategories,
  useGetIntegrationVendorForm,
  useGetIntegrationVendors,
  VendorFormUiSchema,
} from '@elation/api-client/src/support';
import { UISchemaElement } from '@jsonforms/core';
import { useFormikContext } from 'formik';
import { CaseIntakeFormValues } from '../CaseIntakeForm';
import { CaseIntakeInfoProps } from '../CaseIntakeTypes';
import { JsonFormsContext, useJsonFormsContextUpdater } from '../hooks/jsonFormsContext';
import NextStepButton from './NextStepButton';

import styles from './IntegrationRequest.less';
import { IntakeButton } from './IntakeButton';

type VendorItem = { label: string; value: string };

/**
 * Extracts the dynamic forms state from the question field. Useful on remounts
 * when the form data is lost.
 *
 * @param question - The question field from the form
 *
 * @returns The state object from the question field
 */
const getState = (question: string): {} => {
  try {
    const questionTokens = question.split(' integration request: ');
    if (questionTokens.length < 2) {
      return {};
    }
    return JSON.parse(questionTokens[1]);
  } catch {
    return {};
  }
};

const getVendorValueFromLabel = (vendors: VendorItem[], label: string | undefined): string => {
  const vendor = vendors.find((v) => v.label === label);
  return vendor?.value || 'other';
};

const IntegrationRequest = ({
  isLoading: isLoadingProp,
  onNext,
}: CaseIntakeInfoProps): JSX.Element | null => {
  const { values, setValues } = useFormikContext<CaseIntakeFormValues>();
  const updateJsonFormsContext = useJsonFormsContextUpdater();
  const [formData, setFormData] = useState(getState(values.question));

  const [schema, setSchema] = useState({});
  const [previousIntegrationVendor, setPreviousIntegrationVendor] = useState<string | undefined>(
    undefined,
  );
  const [uiSchema, setUiSchema] = useState<VendorFormUiSchema | undefined>(undefined);
  const { data: integrationVendors } = useGetIntegrationVendors();

  const vendors = useMemo(
    () => [
      { label: 'Other Vendor', value: 'other' },
      ...(integrationVendors?.vendors || []).sort((a, b) => a.label.localeCompare(b.label)),
    ],
    [integrationVendors?.vendors],
  );

  const vendorNames: Record<string, string> = vendors.reduce(
    (records, vendor) => ({ ...records, [vendor.value]: vendor.label }),
    {},
  );

  const { data: formSchemas, isLoading: isVendorFormLoading } = useGetIntegrationVendorForm(
    getVendorValueFromLabel(vendors, values.integrationVendor),
  );
  const showForm = !!values.integrationVendor;
  const isLoading = isVendorFormLoading || isLoadingProp;

  const onIntegrationRequestLinkClicked = useCallback(() => {
    window.location.href =
      'https://www.elationhealth.com/contact-us/developer-sandbox-partnership/';
  }, []);

  useEffect(() => {
    if (!values.integrationVendor) {
      return;
    }

    if (previousIntegrationVendor && values.integrationVendor !== previousIntegrationVendor) {
      // Reset the form data when the integration vendor changes
      setFormData({});
    }

    if (formSchemas?.schema) {
      // Set the schema and uiSchema based on the selected vendor
      setSchema(formSchemas.schema);
      setUiSchema(formSchemas?.uiSchema);
    }

    setPreviousIntegrationVendor(values.integrationVendor);
  }, [values.integrationVendor, formSchemas, formSchemas?.schema, previousIntegrationVendor]);

  const updateFormValues = useCallback(
    (data: Record<string, unknown>): void => {
      const details = `${values.integrationVendor} integration request: ${JSON.stringify(
        { ...data, vendor: getVendorValueFromLabel(vendors, values.integrationVendor) },
        null,
        2,
      )}`;
      setValues({
        ...values,
        question: details,
        details,
      });
      setFormData(data);
    },
    [setValues, setFormData, values, vendors],
  );

  const showIntegration = values.category === QuestionCategories.integration_request;

  if (!showIntegration) {
    return null;
  }

  // If the user is a vendor, then we only display the link to setup a sandbox environment
  if (values.persona === Persona.vendor) {
    return (
      <IntakeButton
        kind="link"
        label="Fill out integration request form"
        icon="external-link"
        onClick={onIntegrationRequestLinkClicked}
        style={{ alignSelf: 'flex-start' }}
      />
    );
  }

  return (
    <>
      <Text2 sizing="lg">
        You can access Elation&apos;s full list of integrations on our website. Please select the
        integration you are interested in. If you do not see the integration you&apos;re looking for
        then please select &quot;Other Vendor&quot;.
      </Text2>
      <Select3Formik<string, VendorItem>
        label="Select an integration"
        labelPosition="column"
        data-testid="support-intake-form-integration"
        getItemKey={(item) => item.label}
        getItemLabel={(item) => item.label}
        getItemValue={(item) => item.label}
        items={vendors}
        isSearchable
        searchFunction={(items, search) =>
          items.filter((item) => {
            const label = typeof item === 'string' ? item : item.label;
            // ensure other is always an option alongside matching items
            return label === 'Other Vendor' || label.toLowerCase().includes(search.toLowerCase());
          })
        }
        name="integrationVendor"
        required
      />
      {showForm && (
        <>
          {isLoading ? (
            <Stack className={styles.loadingContainer}>
              <UnavailableContent
                description={`Loading ${vendorNames[values.integrationVendor ?? 'vendor']} form`}
                icon={<Spinner2 className={styles.spinner} sizing="lg" />}
              />
            </Stack>
          ) : (
            <VitalJsonForm
              data={formData}
              onChange={({ data, errors }: JsonFormsContext): void => {
                updateFormValues(data);
                updateJsonFormsContext?.({ schema, data, errors });
              }}
              schema={schema}
              uischema={uiSchema as UISchemaElement}
            />
          )}
        </>
      )}
      <Group>
        <NextStepButton isLoading={isLoading} onNext={onNext} />
      </Group>
    </>
  );
};

export default IntegrationRequest;
