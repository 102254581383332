export enum PatientFormsExportLocation {
  Demographics = 'Demographics',
  Habits = 'Habits',
  Exercise = 'Exercise',
  Diet = 'Diet',
  FH = 'FH',
  PMH = 'PMH',
  PSH = 'PSH',
  Maintenance = 'Maintenance',
  SH = 'SH',
  Allergy = 'Allergy',
  Medication = 'Medication',
  ClinicalForm = 'ClinicalForm',
}

export enum PatientFormsNoteExportLocation {
  Habits = 'Habits',
  Exercise = 'Exercise',
  Diet = 'Diet',
  FH = 'FH',
  PMH = 'PMH',
  PSH = 'PSH',
  Maintenance = 'Maintenance',
  SH = 'SH',
  Allgy = 'Allgy',
  Meds = 'Meds',
  Psych = 'Psych',
}

export type PatientFormExportLocationData = {
  label: string;
  tooltip: string;
  profileExportLocation: PatientFormsExportLocation;
  visitNoteExportLocation: PatientFormsNoteExportLocation;
  isAvailableForUser: boolean;
};

// Ideally we would have a one source of truth for this data somewhere (prefarably on the backend).
// But at the moment it is not clear how we're going to use it and what our edge cases are.
// For example we have `Allergy` export location, but it can not be used with the `other` topic.
// Because it is not clear what API do we need and how it will change in the nearest future
// (the patient forms feture is under active development, so it might change a lot),
// for now e can just have this hardcoded constant here.
export const PATIENT_FORMS_EXPORT_LOCATION_DATA: {
  [key in PatientFormsExportLocation]: PatientFormExportLocationData;
} = {
  [PatientFormsExportLocation.Habits]: {
    isAvailableForUser: true,
    label: 'Habits',
    tooltip:
      "In the patient's chart, this will be exportable to the Habits section in the Clinical Profile, and to the Subjective section of the Visit Note.",
    profileExportLocation: PatientFormsExportLocation.Habits,
    visitNoteExportLocation: PatientFormsNoteExportLocation.Habits,
  },
  [PatientFormsExportLocation.Exercise]: {
    isAvailableForUser: true,
    label: 'Exercise',
    tooltip:
      "In the patient's chart, this will be exportable to the Exercise section in the Clinical Profile, and to the Subjective section of the Visit Note.",
    profileExportLocation: PatientFormsExportLocation.Exercise,
    visitNoteExportLocation: PatientFormsNoteExportLocation.Habits,
  },
  [PatientFormsExportLocation.Diet]: {
    isAvailableForUser: true,
    label: 'Diet',
    tooltip:
      "In the patient's chart, this will be exportable to the Diet section in the Clinical Profile, and to the Subjective section of the Visit Note.",
    profileExportLocation: PatientFormsExportLocation.Diet,
    visitNoteExportLocation: PatientFormsNoteExportLocation.Habits,
  },
  [PatientFormsExportLocation.FH]: {
    isAvailableForUser: true,
    label: 'FH',
    tooltip:
      "In the patient's chart, this will be exportable to the Family History (FH) section in the Clinical Profile, and to the Subjective section of the Visit Note.",
    profileExportLocation: PatientFormsExportLocation.FH,
    visitNoteExportLocation: PatientFormsNoteExportLocation.FH,
  },
  [PatientFormsExportLocation.PMH]: {
    isAvailableForUser: true,
    label: 'PMH',
    tooltip:
      "In the patient's chart, this will be exportable to the Past Medical History (PMH) section in the Clinical Profile, and to the Subjective section of the Visit Note.",
    profileExportLocation: PatientFormsExportLocation.PMH,
    visitNoteExportLocation: PatientFormsNoteExportLocation.PMH,
  },
  [PatientFormsExportLocation.PSH]: {
    isAvailableForUser: true,
    label: 'PSH',
    tooltip:
      "In the patient's chart, this will be exportable to the Past Surgical History (PSH) section in the Clinical Profile, and to the Subjective section of the Visit Note.",
    profileExportLocation: PatientFormsExportLocation.PSH,
    visitNoteExportLocation: PatientFormsNoteExportLocation.PSH,
  },
  [PatientFormsExportLocation.Maintenance]: {
    isAvailableForUser: true,
    label: 'Health Maintenance',
    tooltip:
      "In the patient's chart, this will be exportable to the Health Maintenance section in the Clinical Profile, and to the Subjective section of the Visit Note.",
    profileExportLocation: PatientFormsExportLocation.Maintenance,
    visitNoteExportLocation: PatientFormsNoteExportLocation.PMH,
  },
  [PatientFormsExportLocation.SH]: {
    isAvailableForUser: true,
    label: 'SH',
    tooltip:
      "In the patient's chart, this will be exportable to the Social History (SH) section in the Clinical Profile, and to the Subjective section of the Visit Note.",
    profileExportLocation: PatientFormsExportLocation.SH,
    visitNoteExportLocation: PatientFormsNoteExportLocation.SH,
  },
  [PatientFormsExportLocation.Allergy]: {
    isAvailableForUser: false,
    label: "Doc'd Allergy",
    tooltip:
      "In the patient's chart, this will be exportable as a documented allergy in the Allergies section of the Clinical Profile, and to the Subjective section of the Visit Note.",
    profileExportLocation: PatientFormsExportLocation.Allergy,
    visitNoteExportLocation: PatientFormsNoteExportLocation.Allgy,
  },
  [PatientFormsExportLocation.Demographics]: {
    isAvailableForUser: false,
    label: 'Demographics',
    tooltip: "In the patient's chart, this will be exportable to the patient's demographics.",
    profileExportLocation: PatientFormsExportLocation.Demographics,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'PatientForm... Remove this comment to see the full error message
    visitNoteExportLocation: null,
  },
  [PatientFormsExportLocation.Medication]: {
    isAvailableForUser: false,
    label: "Doc'd Medication",
    tooltip:
      "In the patient's chart, this will be exportable as a documented medication in the Permanent OTC and Rx Meds sections of the Clinical Profile, and to the Subjective section of the Visit Note.",
    profileExportLocation: PatientFormsExportLocation.Medication,
    visitNoteExportLocation: PatientFormsNoteExportLocation.Meds,
  },
  [PatientFormsExportLocation.ClinicalForm]: {
    isAvailableForUser: false,
    label: 'Psych',
    tooltip:
      "In the patient's chart, this will be exportable to the Psych section in the Clinical Profile, and to the Data section of the Visit Note.",
    profileExportLocation: PatientFormsExportLocation.ClinicalForm,
    visitNoteExportLocation: PatientFormsNoteExportLocation.Psych,
  },
};
