/* eslint-disable no-console */
import type { Scope } from '@sentry/browser';
import React, { Component } from 'react';
import alert from 'utils/alert';

// DO NOT EXPORT THIS
// This is a very naive clone of an error simply to  satisfy sentry as
// being a "unique" error.
function cloneError(error: Error): Error {
  const err = new Error(error.toString());

  if (error.stack) {
    err.stack = error.stack;
  }

  return err;
}

export interface ScopedSentryErrorBoundaryProps {
  children: React.ReactNode;
  /** If provided, displays error message to user when exception is caught */
  errorMessage?: string;
  /**
   * Callback handler with the Sentry Scope for caught exceptions.
   * Can be used to augment the scope, and enrich any exceptions
   * thrown from within this component sub tree with extra tags,
   * or other context data.
   */
  withScope?: (scope: Scope) => void;
}

/**
 * Intended to be a replacement for the SentryErrorBoundary
 * as it will ACTUALLY report issues to Sentry.
 *
 * The existing SentryErrorBoundary WILL NOT send issues
 * to Sentry because the exact same errors are already sent
 * via function wrapping and event handling. Sentry avoids sending
 * duplicate events for errors that are referentially equal.
 *
 * DO NOT USE THIS COMPONENT UNLESS YOU NEED TO ADD ADDITIONAL
 * DATA TO SENTRY EXCEPTIONS WITHIN THIS COMPONENT TREE. ANY
 * EXCEPTIONS CAPTURED THROUGH THIS ERROR BOUNDARY WILL BE EFFECTIVELY
 * DUPLICATED.
 */
export class ScopedSentryErrorBoundary extends Component<ScopedSentryErrorBoundaryProps> {
  // TODO: Replace SentryErrorBoundary with this component
  // Can't do this until we can figure out how to dedupe the errors
  // from the other end (i.e. not send the function-wrapped, or event-based exceptions
  // and ony send the ErrorBoundary ones)
  componentDidCatch(error: Error): void {
    window.Sentry?.withScope((scope) => {
      this.props.withScope?.(scope);
      // This exact Error is already captured by Sentry with
      // automatic instrumentation. If we don't create a new one
      // here then Sentry won't send another event.
      window.Sentry?.captureException(cloneError(error));
    });

    if (this.props.errorMessage) {
      alert(this.props.errorMessage);
    }
  }

  render(): React.ReactNode {
    return this.props.children;
  }
}
