import React from 'react';
import { ElationLogoSVG } from './ElationLogoSVG';
import styles from './ElationLogoCircle.less';

export const ElationLogoCircle = (): JSX.Element => {
  return (
    <li className={styles.li}>
      <a href="/patients" className={styles.a}>
        <ElationLogoSVG className={styles.svg} />
      </a>
    </li>
  );
};
