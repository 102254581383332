import useResizeObserver from '@react-hook/resize-observer';
import isNil from 'lodash/isNil';
import { RefObject, useLayoutEffect, useState } from 'react';

interface Defaults {
  width: number;
  height: number;
}

interface ClientRect extends Partial<Omit<DOMRect, 'width' | 'height'>> {
  width: number;
  height: number;
}

/**
 * Hook that returns the Client Rectangle of a provided Ref.
 * Can be used to easily size child elements to the fill the container
 * element when CSS can't be used, like a Canvas element
 */
export default function useRemainingSpace(
  target: RefObject<HTMLElement>,
  defaults: Defaults = { width: 0, height: 0 },
): ClientRect {
  const [size, setSize] = useState<ClientRect>({
    width: defaults.width,
    height: defaults.height,
  });
  useLayoutEffect(() => {
    if (isNil(target?.current)) return;
    setSize(target.current.getBoundingClientRect());
  }, [target]);
  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
}
