import React from 'react';
import { Dialog, DialogProps } from '@el8/vital';
import cn from 'classnames';

import styles from './LegacyFriendlyDialog.less';

export type LegacyFriendlyDialogProps = Omit<DialogProps, 'isModal' | 'getMaxZ'> & {
  isOpen: boolean;
};

/**
 * An extension of Vital's Dialog component that has functionality baked into it
 * that will allow it to coexist with any legacy jQuery UI dialogs on the same page.
 * In other words, this means you can click a LegacyFriendlyDialog and bring it
 * in front of legacy dialogs, and also click on legacy dialogs to bring it in
 * front of LegacyFriendlyDialogs.
 *
 * Generally, for any page that has a mix of React dialogs and legacy dialogs,
 * all the non-modal dialogs should be LegacyFriendlyDialogs as opposed to plain
 * Dialogs.
 */
export default class LegacyFriendlyDialog extends React.Component<LegacyFriendlyDialogProps> {
  // Increments jQuery UI's internal maxZ variable so when the user clicks on this
  // dialog, it gets brought to the front of any other non-modal dialogs that are
  // currently also open.
  //
  // This is dependent on internals of our current version of jQuery UI, so it
  // might break if we upgrade our jQuery UI version, but we probably shouldn't
  // be doing that anyways...no reason to do that when we're trying to migrate
  // to React.
  incrementAndGetMaxZ = (): number => {
    const maxZ = $.ui.dialog.maxZ ? $.ui.dialog.maxZ : 0;

    if (maxZ === 0) {
      $.ui.dialog.maxZ = 1000;
    } else {
      $.ui.dialog.maxZ++;
    }

    return $.ui.dialog.maxZ;
  };

  render(): JSX.Element {
    const { bodyClassName, children, ...props } = this.props;

    return (
      <Dialog
        bodyClassName={cn(bodyClassName, styles.dialogBody)}
        {...props}
        isModal={false}
        getMaxZ={this.incrementAndGetMaxZ}
      >
        {children}
      </Dialog>
    );
  }
}
