import React from 'react';
import { Group, Text2, MenuItem2, DropdownItem, MenuItem2Props, DropdownProps } from '@el8/vital';
import cn from 'classnames';
import styles from './NavbarItemDropdownItem.less';
import { NavbarItemLinkProps } from './NavbarItemLink';
import { NavbarItemButtonProps } from './NavbarItemButton';
import { NavbarItemTooltip } from './NavbarItemTooltip';

export type NavbarItemLinkDropdownItem = MakeRequired<
  NavbarItemLinkProps,
  'text' | 'data-testid'
> & {
  targetProps?: MenuItem2Props<'a'>['targetProps'];
};

export type NavbarItemButtonDropdownItem = MakeRequired<
  NavbarItemButtonProps,
  'text' | 'onClick' | 'data-testid'
> & {
  targetProps?: MenuItem2Props<'button'>['targetProps'];
};

export type NavbarItemDropdownItemData = (
  | NavbarItemLinkDropdownItem
  | NavbarItemButtonDropdownItem
) & {
  appearance?: 'danger' | 'warning';
  bold?: boolean;
};

// todo: vital does not export RenderMenuItemProps
type NavbarItemDropdownItemProps = Parameters<
  NonNullable<DropdownProps<{}, NavbarItemDropdownItemData>['renderMenuItem']>
>[0];

const MenuItemLabel = ({
  item,
}: {
  item: DropdownItem<NavbarItemDropdownItemData, {}>;
}): JSX.Element => {
  if (!item.data) return <></>;

  const { bold = false } = item.data;

  return (
    <NavbarItemTooltip tooltip={item.data.tooltip}>
      <Group verticallyAlign="center">
        {item.data?.icon}
        <Text2 tag={bold ? 'strong' : 'span'}>{item.label}</Text2>
        {item.data?.rightAccessory}
      </Group>
    </NavbarItemTooltip>
  );
};

export const NavbarItemDropdownItem = ({
  item,
  props,
}: NavbarItemDropdownItemProps): JSX.Element => {
  if (typeof item === 'string') return <></>;
  if (!item.data) return <></>;

  const { ref, ...restItemProps } = props;

  const label = <MenuItemLabel item={item} />;

  const { 'data-testid': dataTestId, id, appearance } = item.data;

  const menuItemClassname = cn(restItemProps.className, styles.menuitem, {
    [styles.default]: appearance === undefined,
    [styles.danger]: appearance === 'danger',
    [styles.warning]: appearance === 'warning',
    [styles.disabled]: props.disabled,
  });

  if (item.data.type === 'link') {
    const { targetProps, onClick } = item.data;

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
      props.onClick();
      if (onClick) onClick(event);
    };

    return (
      <MenuItem2<'a'>
        {...restItemProps}
        className={menuItemClassname}
        href={item.data.href}
        id={id}
        label={label}
        targetProps={{
          ...targetProps,
          'data-testid': dataTestId,
          'data-dd-privacy': 'allow',
          onClick: handleClick,
        }}
        ref={ref as React.Ref<HTMLAnchorElement>}
      />
    );
  }

  const { targetProps, onClick } = item.data;

  const handleClick = (): void => {
    props.onClick();
    if (onClick) onClick();
  };

  return (
    <MenuItem2<'button'>
      {...restItemProps}
      className={menuItemClassname}
      id={id}
      label={label}
      targetProps={{
        ...targetProps,
        'data-testid': dataTestId,
        'data-dd-privacy': 'allow',
      }}
      ref={ref}
      onClick={handleClick}
    />
  );
};
