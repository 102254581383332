import { useEffect } from 'react';
import toPairs from 'lodash/toPairs';

interface MetaTagAttributes {
  name: string;
  content: string;
}

/**
 * Adds a `<meta>` tag to the page for as long as the component
 * using this hook is mounted. When the component is unmounted
 * the metatag is removed.
 * @param data
 */
export default function useMetaTag(data: MetaTagAttributes): void {
  useEffect(() => {
    const metaTag = document.createElement('meta');
    const dataPairs = toPairs(data);
    dataPairs.forEach(([name, value]) => {
      metaTag.setAttribute(name, value);
    });
    document.head.appendChild(metaTag);
    return (): void => {
      document.head.removeChild(metaTag);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
