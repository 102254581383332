import { useCallback } from 'react';

type Options = {
  disabled?: boolean;
};

type CheckboxLikeProps<T> = {
  'aria-checked': 'true' | 'false';
  onClick: React.MouseEventHandler<T> | undefined;
  onKeyPress: React.KeyboardEventHandler<T> | undefined;
  role: 'checkbox';
  tabIndex: number | undefined;
};

/**
 * A hook for bestowing checkbox-like qualities to elements that aren't actually
 * checkbox. Provides the props needed to make the element focusable, have the proper
 * accessibility attributes, respond to clicks as well as the keyboard, etc.
 *
 * NOTE: to minimize component re-renders, keep the onChange reference you pass
 * into this hook stable, because this hook uses useCallback internally with `onChange`
 * in its dependency array.
 *
 * @param options.disabled - whether or not the checkbox is disabled
 */
export default function usePseudoCheckbox<T>(
  checked: boolean,
  onChange: (checked: boolean, event: React.SyntheticEvent<T>) => void,
  options: Options = {},
): CheckboxLikeProps<T> {
  const { disabled = false } = options;

  const handleClick = useCallback(
    (event: React.MouseEvent<T>) => {
      onChange(!checked, event);
    },
    [checked, onChange],
  );

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<T>): void => {
      switch (event.key) {
        case 'Space':
        case ' ':
          onChange(!checked, event);
          break;
        default:
          break;
      }
    },
    [checked, onChange],
  );

  return {
    'aria-checked': checked ? 'true' : 'false',
    onClick: disabled ? undefined : handleClick,
    onKeyPress: disabled ? undefined : handleKeyPress,
    role: 'checkbox',
    tabIndex: disabled ? undefined : 0,
  };
}
