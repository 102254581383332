import { actionTypes } from 'redux-query';
import { isPlainObject } from 'lodash/lang';
import { get } from 'lodash/object';
import { snakeCaseKeys } from 'utils/objects';
import Cookie from 'js-cookie';

/**
 * Custom middleware to simplify redux-query config creation.
 * It reduces boilerplate by moving some common configuration logic into a
 * centralized location. Note that the caller-specified configs have override
 * precedence over the default configurations provided by this middleware.
 *
 * Primarily, this middleware allows us to not care about: choosing a query key,
 * setting `options.fetch_credentials` to 'include', and snake casing keys in body objects
 * so they match naming conventions on the Python backend.
 *
 * NOTE for mutations, as this middleware removes the `body` component from their
 * query keys:
 * The custom query key makes it easier to query for the state of a given
 * mutation request, in order to show pending spinners or similar behavior.
 * However, now that all mutations that hit the same endpoint have the same key
 * regardless of their body, we should be consistent when it comes to disabling
 * the appropriate UI elements while mutation requests are pending so the user
 * can't rapid-fire two similar mutations at the same time.
 *
 * At worst though, even if we forget to do so, there should just be a bit of
 * temporary inaccuracy in the pending state of some mutations.
 *
 * Also note that the query keys assigned by this middleware match the ones used
 * by utilities in `utils/redux-query`. If this middleware is removed from the store,
 * those utilities will no longer be able to find queries in the state.
 */
const reduxQueryConfigEnhancer = (/* store */) => (next) => (action) => {
  const { url, body, options = {}, meta, ...configs } = action;

  const csrfToken = Cookie.get('csrftoken');

  // Intention here is to allow query configs to specify meta.autoCaseKeys: false
  // if they want to disable auto-snake casing of the request body. Really, we
  // should not be auto-snake casing the body at all but it was a mistake made in
  // the initial implementation of this middleware and we're not sure if queries
  // are depending on that functionality now.
  //
  // Eventually we should get rid of the need for this and just not do anything
  // to the request body.
  const autoCaseKeys = get(meta, ['autoCaseKeys'], true);

  const forceCsrfToken = get(meta, ['forceCsrfToken'], false);

  switch (action.type) {
    case actionTypes.REQUEST_ASYNC:
      return next({
        url,
        body: autoCaseKeys && isPlainObject(body) ? snakeCaseKeys(body) : body,
        options: {
          fetch_credentials: 'include',
          ...options,
          headers: {
            ...options.headers,
            ...(forceCsrfToken ? { 'X-CSRFToken': csrfToken } : {}),
            'X-EL8-PAGE-SESSION': el8Globals.PAGE_SESSION_ID,
          },
        },
        meta,
        ...configs,
      });
    case actionTypes.MUTATE_ASYNC:
      return next({
        url,
        body: autoCaseKeys && isPlainObject(body) ? snakeCaseKeys(body) : body,
        options: {
          fetch_credentials: 'include',
          ...options,
          headers: {
            ...options.headers,
            'X-CSRFToken': csrfToken,
            'X-EL8-PAGE-SESSION': el8Globals.PAGE_SESSION_ID,
          },
        },
        meta,
        ...configs,
      });
    default:
      return next(action);
  }
};

export default reduxQueryConfigEnhancer;
