import React from 'react';
import { Group, Stack, Text2 } from '@el8/vital';
import { TextareaFormik } from '@el8/vital-formik';
import { useFormikContext } from 'formik';
import { Persona } from '@elation/api-client/src/support';
import styles from './FeatureRequest.less';
import NextStepButton from './NextStepButton';
import { CaseIntakeInfoProps } from '../CaseIntakeTypes';
import { CaseIntakeFormValues } from '../CaseIntakeForm';

const FeatureRequest: React.FC<CaseIntakeInfoProps> = ({ isLoading, onNext }) => {
  const { values } = useFormikContext<CaseIntakeFormValues>();

  return (
    <>
      <Stack gap="xs">
        <Text2>Helpful information to include:</Text2>
        <Text2 className={styles.bullet}>&#8226; What feature you would like to see</Text2>
        <Text2 className={styles.bullet}>&#8226; How this feature would impact your practice</Text2>
        <div className={styles.inputContainer}>
          <TextareaFormik
            label="Details"
            labelPosition="column"
            data-testid="support-intake-form-details"
            name="details"
            required
            rows={5}
          />
          {values.persona === Persona.ehr_user && (
            <Text2 appearance="muted" sizing="sm">
              Do not include the patient&apos;s name or other PHI in this form.
            </Text2>
          )}
        </div>
      </Stack>
      <Group>
        <NextStepButton isLoading={isLoading} onNext={onNext} />
      </Group>
    </>
  );
};

export default FeatureRequest;
