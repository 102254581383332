import { unitOfTime } from 'moment-timezone';

import { Appointment } from 'EntityTypes';
import { practiceMoment } from 'utils/timezone';

import { logEvent } from './base';

const getAppointmentTimeDiff = (
  apptTime: string,
  diffConversionType: unitOfTime.Diff = 'minutes',
): number => {
  const currentTime = practiceMoment(new Date());
  return currentTime.diff(practiceMoment(apptTime), diffConversionType);
};

const hasLink = (value: string): boolean => {
  return /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=-]{2,256}\.[a-z_-]{2,50}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/.test(
    value,
  );
};

export const logEventOpenCreateNewApptDialog = (source: string): void => {
  logEvent('open new appointment dialog', {
    source,
  });
};

export const logEventSentTelehealthInstructions = (
  appointment: Appointment,
  res: { sent_via_email: boolean; sent_via_sms: boolean },
): void => {
  logEvent('send appointment instructions', {
    'appointment id': appointment.id,
    'link exists': hasLink(appointment.telehealthInstructions),
    'minutes from appointment': getAppointmentTimeDiff(appointment.apptTime),
    place: appointment.isTelehealth ? 'Telehealth' : 'In-Person',
    'sent to mobile': res.sent_via_sms,
    'sent to email': res.sent_via_email,
  });
};

export const logEventSendTelehealthInstructionsError = (
  appointment: Appointment,
  err: Error,
): void => {
  logEvent('send appointment instructions', {
    'appointment id': appointment.id,
    error: err,
    'minutes from appointment': getAppointmentTimeDiff(appointment.apptTime),
  });
};
