import { PatientFormNormalized } from 'EntityTypes';
import {
  AppQueryConfig,
  SendPatientFormsRequestBody,
  SendPatientFormsQueryVariables,
  GetPatientFormRequestResponseBody,
  FormRequestDetailsQueryVariables,
  FormRequestUpdateQueryVariables,
  UpdateFormRequestOptions,
} from 'QueryTypes';
import { EntitiesById } from 'StoreTypes';
import { normalize } from 'normalizr';
import { makeUpdateFn, makeDeleteFns, makeSortingOptimisticFns } from 'utils/redux-query';
import {
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { getQueryUrl, jsonFetch } from 'utils/react-query';
import { patientFormSchema } from './patientFormsSchemas';

const urls = {
  patientForms(practiceId: number, patientFormId?: number): string {
    let url = `/patient-forms/api/${practiceId}/forms`;
    if (patientFormId) {
      url += `/${patientFormId}`;
    }
    return url;
  },
  sortPatientForms(practiceId: number): string {
    return `/patient-forms/api/${practiceId}/forms/sequence`;
  },
};

/**
 * Query to fetch all patient forms for a particular practice.
 */
export const patientFormsQuery = (practiceId: number): AppQueryConfig => ({
  url: urls.patientForms(practiceId),
  transform: (responseJson): { patientForms: EntitiesById<PatientFormNormalized> } =>
    normalize(responseJson.data, [patientFormSchema]).entities,
  update: {
    patientForms: makeUpdateFn<PatientFormNormalized>({ merge: false }),
  },
});

/**
 * Query to fetch a particular form from server or create/update a form
 */
export const patientFormQuery = (
  practiceId: number,
  patientFormId: number | null,
  body?: Partial<PatientFormNormalized>,
): AppQueryConfig => {
  const query: AppQueryConfig = {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
    url: urls.patientForms(practiceId, patientFormId),
    transform: (responseJson): { patientForms: EntitiesById<PatientFormNormalized> } =>
      normalize(responseJson.data, patientFormSchema).entities,
    update: {
      patientForms: makeUpdateFn<PatientFormNormalized>({ merge: true }),
    },
  };

  if (body) {
    query.body = body;

    if (patientFormId) {
      query.options = {
        method: 'PUT',
      };
    }
  }

  return query;
};

export const deletePatientFormQuery = (
  practiceId: number,
  patientFormId: number,
): AppQueryConfig => {
  const { update, optimisticUpdate, rollback } =
    makeDeleteFns<PatientFormNormalized>(patientFormId);

  return {
    url: urls.patientForms(practiceId, patientFormId),
    options: {
      method: 'DELETE',
    },
    update: {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(entityState: GenericEntityState<PatientForm... Remove this comment to see the full error message
      patientForms: update,
    },
    optimisticUpdate: {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(entityState: GenericEntityState<PatientForm... Remove this comment to see the full error message
      patientForms: optimisticUpdate,
    },
    rollback: {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(initialEntityState: GenericEntityState<Pati... Remove this comment to see the full error message
      patientForms: rollback,
    },
  };
};

/**
 * Query to sort patient forms
 */
export const sortPatientFormsQuery = (
  practiceId: number,
  patientFormIds: number[],
): AppQueryConfig => {
  const { optimisticUpdate, rollback } = makeSortingOptimisticFns<PatientFormNormalized>(
    patientFormIds,
    'sequence',
  );
  return {
    url: urls.sortPatientForms(practiceId),
    body: {
      sequence: patientFormIds,
    },
    options: {
      method: 'PUT',
    },
    transform: (responseJson: {
      data: PatientFormNormalized[];
    }): EntitiesById<PatientFormNormalized> =>
      normalize(responseJson.data, [patientFormSchema]).entities,
    update: {
      patientForms: makeUpdateFn(),
    },
    optimisticUpdate: {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(entityState: GenericEntityState<PatientForm... Remove this comment to see the full error message
      patientForms: optimisticUpdate,
    },
    rollback: {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(initialEntityState: GenericEntityState<Pati... Remove this comment to see the full error message
      patientForms: rollback,
    },
  };
};

const queryKeys = {
  /**
   * Returns the query key for sending patient forms
   */
  sendForms: (patientId: MaybeNullishId): QueryKey => [
    'patient',
    patientId,
    'patient-form-requests',
  ],

  /**
   * Returns the query key for a single patient form request
   */
  formRequestDetails: (
    patientId: MaybeNullishId,
    patientFormRequestId: MaybeNullishId,
  ): QueryKey => ['patient', patientId, 'patient-form-requests', patientFormRequestId],

  /**
   * Returns the query key to update a single patient form request
   */
  updateFormRequest: (
    patientId: MaybeNullishId,
    patientFormRequestId: MaybeNullishId,
    options?: UpdateFormRequestOptions,
  ): QueryKey => ['patient', patientId, 'patient-form-requests', patientFormRequestId, options],
};

/**
 * Returns a mutation to send a patient form.
 */
export function useSendPatientForms() {
  return useMutation({
    mutationFn: async (variables: SendPatientFormsQueryVariables) => {
      const { patientId, body } = variables;
      return jsonFetch<SendPatientFormsRequestBody>(getQueryUrl(queryKeys.sendForms(patientId)), {
        body,
        method: 'POST',
      });
    },
  });
}

/**
 * Returns a mutation to delete a patient form request
 */
export function useDeletePatientFormRequest() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (variables: FormRequestDetailsQueryVariables) => {
      const { patientId, patientFormRequestId } = variables;
      return jsonFetch<void>(
        getQueryUrl(queryKeys.formRequestDetails(patientId, patientFormRequestId)),
        {
          method: 'DELETE',
        },
      );
    },
    onSuccess: (_data, { patientId, patientFormRequestId }) => {
      queryClient.removeQueries({
        queryKey: queryKeys.formRequestDetails(patientId, patientFormRequestId),
      });
    },
  });
}

/**
 * Fetches and returns a specified patient form request
 */
export function useGetPatientFormRequest(
  patientId: MaybeNullishId,
  patientFormRequestId: MaybeNullishId,
  options?: UseQueryOptions<GetPatientFormRequestResponseBody>,
): UseQueryResult<GetPatientFormRequestResponseBody> {
  return useQuery({
    queryKey: queryKeys.formRequestDetails(patientId, patientFormRequestId),
    enabled: Boolean(patientId && patientFormRequestId),
    ...options,
  });
}

/**
 * Resends a specified patient form request
 */
export function useUpdatePatientFormRequest() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (variables: FormRequestUpdateQueryVariables) => {
      const { patientId, patientFormRequestId, body, options } = variables;
      return jsonFetch(
        getQueryUrl(queryKeys.updateFormRequest(patientId, patientFormRequestId, options)),
        {
          body,
          method: 'PATCH',
        },
      );
    },
    onSuccess: (data, { patientId, patientFormRequestId }) => {
      queryClient.setQueryData(queryKeys.updateFormRequest(patientId, patientFormRequestId), data);
    },
  });
}
