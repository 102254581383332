import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, HeaderText, Text, Button } from '@el8/vital';
import { Formik } from 'formik';
import { Flexbox } from 'components/layout';
import { connect } from 'react-redux';
import { shareDocumentsQuery } from 'modules/passport/passportQueries';
import styles from './ExportEmailConfirmation.less';
import { VIEWS } from '../helpers';

class ExportEmailConfirmation extends Component {
  navigateToSuccessView = () => {
    const { destinationEmail } = this.props;
    this.props.changeView(VIEWS.successExportEmail, {
      destinationEmail,
    });
  };

  sendEmail = (encrypted) => {
    const { destinationEmail, startDate, endDate } = this.props;
    return this.props.shareDocumentsQuery(destinationEmail, startDate, endDate, encrypted);
  };

  handleSubmit = (values) => {
    this.sendEmail(values.encrypted).then(this.navigateToSuccessView);
  };

  render() {
    const { destinationEmail } = this.props;
    return (
      <Formik
        initialValues={{ encrypted: false }}
        render={({ values, handleSubmit, handleChange, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Flexbox direction="column" align="center">
              <HeaderText legacyVariant="legacyHeaderText" tag="h1">
                You are almost done!
              </HeaderText>
              <Text className={styles.text}>
                Your data will be sent to <b>{destinationEmail}</b>.
              </Text>
              <Checkbox
                label="Click here to encrypt your data."
                name="encrypted"
                className={styles.checkboxField}
                value={values.encrypted}
                onChange={handleChange}
              />
              <Button
                className={styles.shareButton}
                type="submit"
                kind="primary"
                disabled={isSubmitting}
              >
                Share
              </Button>
            </Flexbox>
          </form>
        )}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

const mapDispatchToProps = { shareDocumentsQuery };

ExportEmailConfirmation.propTypes = {
  destinationEmail: PropTypes.string.isRequired,
  changeView: PropTypes.func.isRequired,
  shareDocumentsQuery: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
};

export default connect(null, mapDispatchToProps)(ExportEmailConfirmation);
