import { LegacyFriendlyDialog } from 'components/overlays';
import React from 'react';
import useAppSelector from 'utils/hooks/useAppSelector';
import { useCloseRestoreDeletedDocumentsDialog } from 'ui-modules/navbar';
import { RestoreDeletedDocumentsDialogContent } from './RestoreDeletedDocumentsDialogContent';
import styles from './RestoreDeletedDocumentsDialog.less';

export const RestoreDeletedDocumentsDialog = (): JSX.Element => {
  const isOpen = useAppSelector((state) => state.navbar.restoreDeletedDocumentsDialog.isOpen);
  const closeRestoreDeletedDocumentsDialog = useCloseRestoreDeletedDocumentsDialog();

  return (
    <LegacyFriendlyDialog
      isOpen={isOpen}
      title="Restore Deleted Notes and Reports"
      width={750}
      className={styles.dialog}
      onClose={closeRestoreDeletedDocumentsDialog}
    >
      <RestoreDeletedDocumentsDialogContent />
    </LegacyFriendlyDialog>
  );
};
