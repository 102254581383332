exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Documents__documents___1u5hq {\n  margin-top: 32px;\n}\n", ""]);

// exports
exports.locals = {
	"documents": "Documents__documents___1u5hq"
};