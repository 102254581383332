import { ResponseError } from '.';

export enum ErrorType {
  ErxPatientAddressIncomplete = 'erx_patient_address_error',
  ErxPracticeAddressIncomplete = 'erx_practice_address_error',
  Notification = 'notification_error',
  PracticeAuthLogin = 'practice_auth_login_error',
}

/**
 * Accepts an error object and returns an array of all the error types
 *
 * @param error - an error object. Can be a built-in Error or custom Error.
 */
export default function getErrorTypes(error: Error | ResponseError): string[] | undefined {
  if (error instanceof ResponseError) {
    const standardizedErrors = error.body?.errors;
    if (Array.isArray(standardizedErrors)) {
      return standardizedErrors.filter((err) => Boolean(err.error)).map((err) => err.error);
    }
  }
  return undefined;
}
