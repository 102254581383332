import { QueryKey, useMutation, UseMutationResult } from '@tanstack/react-query';

import { InviteColleagueRequestVariables } from 'QueryTypes';
import { getQueryUrl, jsonFetch } from 'utils/react-query';

const queryKeys = {
  /** Returns the query key for inviting colleagues */
  inviteColleague: (): QueryKey => ['physician', 'invitation', 'recipient'],
};

/**
 * Returns a boolean indicating whether or not a colleague was successfully invited.
 */
export function useInviteColleague(): UseMutationResult<
  boolean,
  unknown,
  InviteColleagueRequestVariables
> {
  return useMutation<boolean, unknown, InviteColleagueRequestVariables>({
    mutationFn: async (variables: InviteColleagueRequestVariables) => {
      return jsonFetch<boolean>(getQueryUrl(queryKeys.inviteColleague()), {
        method: 'POST',
        body: variables,
      });
    },
  });
}
