/* eslint-disable no-param-reassign */
import { API_AXIOS_INSTANCE } from '@elation/api-client';
import Cookie from 'js-cookie';

/**
 * Initializes common request headers required to make API
 * calls from our application. This is performed via interceptor
 * because only the application source code has this knowledge.
 * The @elation/api-client workspace code doesn't have this
 * knowledge and it needs to already be initialized.
 */
API_AXIOS_INSTANCE.interceptors.request.use((config) => {
  config.headers['X-EL8-PAGE-SESSION'] = el8Globals?.PAGE_SESSION_ID;
  config.headers['X-CSRFToken'] = Cookie.get('csrftoken') || '';
  return config;
});
