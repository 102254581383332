/**
 * @deprecated DO NOT USE THIS, use `addQueryParams` instead. This is a less flexible duplicate.
 */
export const encodeObjToUrlParams = (obj) => {
  let str = '';

  Object.keys(obj).forEach((key) => {
    if (str !== '') {
      str += '&';
    }
    if (obj[key]) {
      str += `${key}=${encodeURIComponent(obj[key])}`;
    }
  });
  return str;
};
