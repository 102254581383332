import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

import type { ErrorObject } from 'ajv';

export type JsonFormsContext = {
  schema: Record<string, unknown>;
  data: Record<string, unknown>;
  errors: ErrorObject[] | undefined;
};

const JsonFormsDataContext = createContext<JsonFormsContext | null>(null);

const JsonFormsDispatchContext = createContext<Dispatch<SetStateAction<JsonFormsContext>> | null>(
  null,
);

const initialObject: JsonFormsContext = {
  schema: {},
  data: {},
  errors: undefined,
};

/**
 * Provider for the custom JSON Forms context.
 */
export function JsonFormsProvider({ children }: { children: ReactNode }): JSX.Element {
  const [{ schema, data, errors }, update] = useState(initialObject);

  return (
    <JsonFormsDataContext.Provider value={{ schema, data, errors }}>
      <JsonFormsDispatchContext.Provider value={update}>
        {children}
      </JsonFormsDispatchContext.Provider>
    </JsonFormsDataContext.Provider>
  );
}

export function useJsonFormsContext(): JsonFormsContext | null {
  return useContext(JsonFormsDataContext);
}

export function useJsonFormsContextUpdater(): Dispatch<SetStateAction<JsonFormsContext>> | null {
  return useContext(JsonFormsDispatchContext);
}

export function useResetJsonFormsContext(): () => void {
  const update = useJsonFormsContextUpdater();
  const callback = useCallback(() => {
    update?.(initialObject);
  }, [update]);
  return callback;
}
