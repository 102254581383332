/** Auto-generated by the constants_model_class.py script */
enum ModelClass {
  AOEAnswer = 'AOEAnswer',
  AOEQuestion = 'AOEQuestion',
  AOEQuestionChoice = 'AOEQuestionChoice',
  APIVendor = 'APIVendor',
  APIVendorEnterpriseAuthorization = 'APIVendorEnterpriseAuthorization',
  APIVendorPracticeAuthorization = 'APIVendorPracticeAuthorization',
  AccessAccountPreferences = 'AccessAccountPreferences',
  AccessRegistrationAction = 'AccessRegistrationAction',
  AccessRegistrationLink = 'AccessRegistrationLink',
  AccessRegistrationLinkEvent = 'AccessRegistrationLinkEvent',
  AccessRegistrationLinkReadLog = 'AccessRegistrationLinkReadLog',
  AccessRegistrationSignup = 'AccessRegistrationSignup',
  AccessRegistrationSignupFaxVerification = 'AccessRegistrationSignupFaxVerification',
  Allergy = 'Allergy',
  AmendmentRequest = 'AmendmentRequest',
  AncillaryCompany = 'AncillaryCompany',
  Appointment = 'Appointment',
  AppointmentBillingDetails = 'AppointmentBillingDetails',
  AppointmentReminder = 'AppointmentReminder',
  AppointmentRoom = 'AppointmentRoom',
  AppointmentStatus = 'AppointmentStatus',
  AppointmentTelehealthDetails = 'AppointmentTelehealthDetails',
  AppointmentType = 'AppointmentType',
  AppointmentTypeToProviderAvailabilityBlock = 'AppointmentTypeToProviderAvailabilityBlock',
  AssortedCode = 'AssortedCode',
  AvailableDocumentTag = 'AvailableDocumentTag',
  AvailableHandoutTag = 'AvailableHandoutTag',
  AvailablePatientTag = 'AvailablePatientTag',
  Bill = 'Bill',
  BillCpt = 'BillCpt',
  BillCptDx = 'BillCptDx',
  BillReferringProvider = 'BillReferringProvider',
  BodySiteCode = 'BodySiteCode',
  BodySiteCodeModifier = 'BodySiteCodeModifier',
  BulkLetter = 'BulkLetter',
  CCDASectionImportPreferences = 'CCDASectionImportPreferences',
  CMSPhysician = 'CMSPhysician',
  CPTCode = 'CPTCode',
  CampaignCode = 'CampaignCode',
  CanonicalOverrideLog = 'CanonicalOverrideLog',
  CanonicalPhysician = 'CanonicalPhysician',
  CanonicalPhysicianSalesInfo = 'CanonicalPhysicianSalesInfo',
  CardiacCenter = 'CardiacCenter',
  CardiacOrder = 'CardiacOrder',
  CardiacOrderContent = 'CardiacOrderContent',
  CardiacOrderTest = 'CardiacOrderTest',
  CardiacOrderTestCategory = 'CardiacOrderTestCategory',
  CareTeamInvitation = 'CareTeamInvitation',
  CareTeamPhysicianNote = 'CareTeamPhysicianNote',
  CareTeamPhysicianParticipation = 'CareTeamPhysicianParticipation',
  CareTeamPhysicianParticipationPeriod = 'CareTeamPhysicianParticipationPeriod',
  CareTeamPracticeParticipation = 'CareTeamPracticeParticipation',
  ChallengeQuestion = 'ChallengeQuestion',
  ChallengeQuestionAnswer = 'ChallengeQuestionAnswer',
  ChartFeedReview = 'ChartFeedReview',
  ChartMergeLog = 'ChartMergeLog',
  ChartMergeLogItem = 'ChartMergeLogItem',
  ChecklistTemplate = 'ChecklistTemplate',
  ClinicalForm = 'ClinicalForm',
  ClinicalFormCollection = 'ClinicalFormCollection',
  ClinicalProfileReview = 'ClinicalProfileReview',
  CodedDocumentTag = 'CodedDocumentTag',
  Comment = 'Comment',
  Condition = 'Condition',
  ConfidentialItem = 'ConfidentialItem',
  ConfidentialityRecord = 'ConfidentialityRecord',
  County = 'County',
  Coverage = 'Coverage',
  CustomObservation = 'CustomObservation',
  CustomObservationResult = 'CustomObservationResult',
  DataImportRequest = 'DataImportRequest',
  DefaultSharingSettings = 'DefaultSharingSettings',
  DeferrableNotification = 'DeferrableNotification',
  DeferrableUserNotification = 'DeferrableUserNotification',
  DelegateGroupPermission = 'DelegateGroupPermission',
  DelegatePermission = 'DelegatePermission',
  DenialMedOrder = 'DenialMedOrder',
  Directions = 'Directions',
  DiscontinueMedOrder = 'DiscontinueMedOrder',
  DocumentAddendum = 'DocumentAddendum',
  DocumentFile = 'DocumentFile',
  DocumentGrant = 'DocumentGrant',
  DocumentImage = 'DocumentImage',
  DocumentReminder = 'DocumentReminder',
  DocumentReminderNotification = 'DocumentReminderNotification',
  DocumentRequiringAction = 'DocumentRequiringAction',
  DocumentResolutionState = 'DocumentResolutionState',
  ESignature = 'ESignature',
  Eligibility = 'Eligibility',
  EmailVerificationCode = 'EmailVerificationCode',
  Employer = 'Employer',
  Enterprise = 'Enterprise',
  Ethnicity = 'Ethnicity',
  ExternalAPIUserProfile = 'ExternalAPIUserProfile',
  ExternalAPIUserToPractice = 'ExternalAPIUserToPractice',
  FacilityIdentifier = 'FacilityIdentifier',
  HCCCategory = 'HCCCategory',
  HCCCountFactor = 'HCCCountFactor',
  HCCDemographics = 'HCCDemographics',
  HCCDisabilityInteraction = 'HCCDisabilityInteraction',
  HCCDiseaseInteraction = 'HCCDiseaseInteraction',
  HCCInteractionGroup = 'HCCInteractionGroup',
  HCCMedicaidDisabilityStatus = 'HCCMedicaidDisabilityStatus',
  HCCOriginallyDisabledStatus = 'HCCOriginallyDisabledStatus',
  Handout = 'Handout',
  Hl7Message = 'Hl7Message',
  Hospital = 'Hospital',
  ICD10Code = 'ICD10Code',
  ICD10CodeVersion = 'ICD10CodeVersion',
  ICD9Code = 'ICD9Code',
  ICD9CodeSet = 'ICD9CodeSet',
  IMOCode = 'IMOCode',
  ImagingCenter = 'ImagingCenter',
  ImagingOrder = 'ImagingOrder',
  ImagingOrderContent = 'ImagingOrderContent',
  ImagingOrderTest = 'ImagingOrderTest',
  ImagingOrderTestCategory = 'ImagingOrderTestCategory',
  ImmRegSubmission = 'ImmRegSubmission',
  ImmRegTxIncoming = 'ImmRegTxIncoming',
  ImmRegTxOutgoing = 'ImmRegTxOutgoing',
  Immunization = 'Immunization',
  ImmunizationRegistry = 'ImmunizationRegistry',
  ImportedFile = 'ImportedFile',
  InboundReportMessage = 'InboundReportMessage',
  IncomingDirectMessage = 'IncomingDirectMessage',
  IncomingEmail = 'IncomingEmail',
  IncomingEmailContent = 'IncomingEmailContent',
  IncomingEmailRecipient = 'IncomingEmailRecipient',
  IncomingFileGroup = 'IncomingFileGroup',
  InsuranceCompany = 'InsuranceCompany',
  InsurancePlan = 'InsurancePlan',
  InternalMessage = 'InternalMessage',
  InternalMessageAttachment = 'InternalMessageAttachment',
  InternalMessageRecipient = 'InternalMessageRecipient',
  InternalPracticeNote = 'InternalPracticeNote',
  LabCenter = 'LabCenter',
  LabIntegratorVendor = 'LabIntegratorVendor',
  LabOrder = 'LabOrder',
  LabOrderContent = 'LabOrderContent',
  LabOrderSet = 'LabOrderSet',
  LabOrderSettings = 'LabOrderSettings',
  LabOrderSpecimen = 'LabOrderSpecimen',
  LabOrderSpecimenCollection = 'LabOrderSpecimenCollection',
  LabOrderSpecimenSiteModifier = 'LabOrderSpecimenSiteModifier',
  LabOrderTest = 'LabOrderTest',
  LabOrderTestCompendium = 'LabOrderTestCompendium',
  LabOrderToOrderSet = 'LabOrderToOrderSet',
  LabResult = 'LabResult',
  LabResultGrid = 'LabResultGrid',
  LabTest = 'LabTest',
  LabTestCategory = 'LabTestCategory',
  LabTestCenter = 'LabTestCenter',
  LabTestGroup = 'LabTestGroup',
  LabTestPreferences = 'LabTestPreferences',
  LabTestUnits = 'LabTestUnits',
  LabVendor = 'LabVendor',
  LabVendorInstructions = 'LabVendorInstructions',
  LabVendorIntegration = 'LabVendorIntegration',
  LabVendorPatientSite = 'LabVendorPatientSite',
  Language = 'Language',
  Letter = 'Letter',
  LetterAttachment = 'LetterAttachment',
  LetterCategory = 'LetterCategory',
  LetterReceipt = 'LetterReceipt',
  LetterReceiptAttachment = 'LetterReceiptAttachment',
  LetterRoutingRule = 'LetterRoutingRule',
  LetterTemplate = 'LetterTemplate',
  LoginLockout = 'LoginLockout',
  LoincCode = 'LoincCode',
  MDToolboxTxIncoming = 'MDToolboxTxIncoming',
  MDToolboxTxOutgoing = 'MDToolboxTxOutgoing',
  MVXCode = 'MVXCode',
  MarketingCollateral = 'MarketingCollateral',
  MasterPatient = 'MasterPatient',
  MeaningfulUseInfo = 'MeaningfulUseInfo',
  MedOrder = 'MedOrder',
  MedOrderCancelRequestResponse = 'MedOrderCancelRequestResponse',
  MedOrderDrugInteraction = 'MedOrderDrugInteraction',
  MedOrderEPresActReportTrans = 'MedOrderEPresActReportTrans',
  MedOrderEPresActivity = 'MedOrderEPresActivity',
  MedOrderFill = 'MedOrderFill',
  MedOrderFulfillment = 'MedOrderFulfillment',
  MedOrderTemplate = 'MedOrderTemplate',
  MedOrderThread = 'MedOrderThread',
  MedOrderToTemplate = 'MedOrderToTemplate',
  MedicalGroup = 'MedicalGroup',
  MedicalSpecialty = 'MedicalSpecialty',
  MedicalSpecialtyCategory = 'MedicalSpecialtyCategory',
  Medication = 'Medication',
  MedispanMedicationUpdate = 'MedispanMedicationUpdate',
  MembershipGroup = 'MembershipGroup',
  MembershipPlan = 'MembershipPlan',
  MessageThread = 'MessageThread',
  NCPDPTerminology = 'NCPDPTerminology',
  NCPDPTerminologyAlias = 'NCPDPTerminologyAlias',
  NonvisitNote = 'NonvisitNote',
  NonvisitNoteBullet = 'NonvisitNoteBullet',
  NonvisitNoteDocument = 'NonvisitNoteDocument',
  NonvisitNotePatientItem = 'NonvisitNotePatientItem',
  NonvisitNoteType = 'NonvisitNoteType',
  NoteSettings = 'NoteSettings',
  OfficeStaff = 'OfficeStaff',
  OfficeStaffInvitationRequest = 'OfficeStaffInvitationRequest',
  OfficeStaffInvitationResponse = 'OfficeStaffInvitationResponse',
  OtherContact = 'OtherContact',
  OutgoingDirectMessage = 'OutgoingDirectMessage',
  OutgoingEmail = 'OutgoingEmail',
  OutgoingEmailAttachment = 'OutgoingEmailAttachment',
  OutgoingFax = 'OutgoingFax',
  OutgoingFaxNumber = 'OutgoingFaxNumber',
  PBM = 'PBM',
  PackagedMedication = 'PackagedMedication',
  PackagedMedicationLabeler = 'PackagedMedicationLabeler',
  PassportFaxOut = 'PassportFaxOut',
  Patient = 'Patient',
  PatientAddress = 'PatientAddress',
  PatientAllergy = 'PatientAllergy',
  PatientAllergyDocumentation = 'PatientAllergyDocumentation',
  PatientBillingDetails = 'PatientBillingDetails',
  PatientCareGap = 'PatientCareGap',
  PatientChartImport = 'PatientChartImport',
  PatientCollaboratorNotification = 'PatientCollaboratorNotification',
  PatientConsent = 'PatientConsent',
  PatientDemogImportRequest = 'PatientDemogImportRequest',
  PatientDemographics = 'PatientDemographics',
  PatientDemographicsUpload = 'PatientDemographicsUpload',
  PatientDesignate = 'PatientDesignate',
  PatientDrugIntolerance = 'PatientDrugIntolerance',
  PatientDrugIntoleranceDocumentation = 'PatientDrugIntoleranceDocumentation',
  PatientEmail = 'PatientEmail',
  PatientEmployer = 'PatientEmployer',
  PatientExternalReference = 'PatientExternalReference',
  PatientFamilyHistoryItem = 'PatientFamilyHistoryItem',
  PatientFamilyRelationship = 'PatientFamilyRelationship',
  PatientFileImport = 'PatientFileImport',
  PatientFormRequest = 'PatientFormRequest',
  PatientFormSubmission = 'PatientFormSubmission',
  PatientHistoryItem = 'PatientHistoryItem',
  PatientHistoryItemPhysician = 'PatientHistoryItemPhysician',
  PatientImmunization = 'PatientImmunization',
  PatientImmunizationDecline = 'PatientImmunizationDecline',
  PatientImplantableDevice = 'PatientImplantableDevice',
  PatientImport = 'PatientImport',
  PatientInsurance = 'PatientInsurance',
  PatientInsuranceGuarantor = 'PatientInsuranceGuarantor',
  PatientInvite = 'PatientInvite',
  PatientLiability = 'PatientLiability',
  PatientMaintItem = 'PatientMaintItem',
  PatientMaintItemRule = 'PatientMaintItemRule',
  PatientMembership = 'PatientMembership',
  PatientMembershipSubscription = 'PatientMembershipSubscription',
  PatientOutstandingBalance = 'PatientOutstandingBalance',
  PatientPayment = 'PatientPayment',
  PatientPhone = 'PatientPhone',
  PatientPhoto = 'PatientPhoto',
  PatientPreviousName = 'PatientPreviousName',
  PatientProblem = 'PatientProblem',
  PatientProblemDocumentation = 'PatientProblemDocumentation',
  PatientProblemXWalkNotifier = 'PatientProblemXWalkNotifier',
  PatientProperties = 'PatientProperties',
  PatientProviderTeam = 'PatientProviderTeam',
  PatientProviderTeamMember = 'PatientProviderTeamMember',
  PatientProviderTeamMemberActivity = 'PatientProviderTeamMemberActivity',
  PatientSmokingStatus = 'PatientSmokingStatus',
  PatientStatus = 'PatientStatus',
  PatientStripeCustomer = 'PatientStripeCustomer',
  PatientTag = 'PatientTag',
  PharmacyPrescriptionRequest = 'PharmacyPrescriptionRequest',
  Physician = 'Physician',
  PhysicianAccount = 'PhysicianAccount',
  PhysicianCredentialDocument = 'PhysicianCredentialDocument',
  PhysicianCreditCard = 'PhysicianCreditCard',
  PhysicianEPCSState = 'PhysicianEPCSState',
  PhysicianIdentifier = 'PhysicianIdentifier',
  PhysicianInvitation = 'PhysicianInvitation',
  PhysicianInvitationRequest = 'PhysicianInvitationRequest',
  PhysicianInvitationResponse = 'PhysicianInvitationResponse',
  PhysicianInvite = 'PhysicianInvite',
  PhysicianMUManualMeasure = 'PhysicianMUManualMeasure',
  PhysicianMedicalLicense = 'PhysicianMedicalLicense',
  PhysicianMedicationMetadata = 'PhysicianMedicationMetadata',
  PhysicianOnboardingSurvey = 'PhysicianOnboardingSurvey',
  PhysicianOnboardingSurveyItem = 'PhysicianOnboardingSurveyItem',
  PhysicianPracticeIdentifier = 'PhysicianPracticeIdentifier',
  PhysicianRequestedLab = 'PhysicianRequestedLab',
  PhysicianTelehealthSettings = 'PhysicianTelehealthSettings',
  PhysicianZoomSettings = 'PhysicianZoomSettings',
  PmpCheckNotification = 'PmpCheckNotification',
  PmpRegistry = 'PmpRegistry',
  PotentialLead = 'PotentialLead',
  Practice = 'Practice',
  PracticeAgreement = 'PracticeAgreement',
  PracticeAgreementSignature = 'PracticeAgreementSignature',
  PracticeContactDetails = 'PracticeContactDetails',
  PracticeFax = 'PracticeFax',
  PracticeFolder = 'PracticeFolder',
  PracticeGroup = 'PracticeGroup',
  PracticeIdentifier = 'PracticeIdentifier',
  PracticeMembershipPlan = 'PracticeMembershipPlan',
  PracticePatientPreferences = 'PracticePatientPreferences',
  PracticePmpRegistryMapping = 'PracticePmpRegistryMapping',
  PracticePrintHeader = 'PracticePrintHeader',
  PracticeRegistryIdentifiers = 'PracticeRegistryIdentifiers',
  PracticeRegistryMapping = 'PracticeRegistryMapping',
  PracticeReportType = 'PracticeReportType',
  PracticeSandBox = 'PracticeSandBox',
  PracticeSettings = 'PracticeSettings',
  PracticeSpecialInsurance = 'PracticeSpecialInsurance',
  PracticeStripeAccount = 'PracticeStripeAccount',
  PracticeToPatient = 'PracticeToPatient',
  PracticeToPatientEmployer = 'PracticeToPatientEmployer',
  PracticeToPhysician = 'PracticeToPhysician',
  PracticeToStaff = 'PracticeToStaff',
  PracticeVaccinePreferences = 'PracticeVaccinePreferences',
  PremadeMessage = 'PremadeMessage',
  PrenatalFlowsheet = 'PrenatalFlowsheet',
  PrenatalFlowsheetVisitMeasurements = 'PrenatalFlowsheetVisitMeasurements',
  PrescriptionFill = 'PrescriptionFill',
  ProviderSecurityCode = 'ProviderSecurityCode',
  PulmonaryCenter = 'PulmonaryCenter',
  PulmonaryOrder = 'PulmonaryOrder',
  PulmonaryOrderContent = 'PulmonaryOrderContent',
  PulmonaryOrderTest = 'PulmonaryOrderTest',
  PulmonaryOrderTestCategory = 'PulmonaryOrderTestCategory',
  Race = 'Race',
  RecurringEventGroup = 'RecurringEventGroup',
  RecurringEventSchedule = 'RecurringEventSchedule',
  Reference = 'Reference',
  ReferralOrder = 'ReferralOrder',
  RenderingRequest = 'RenderingRequest',
  Report = 'Report',
  ReportNoteTemplate = 'ReportNoteTemplate',
  RxHistoryDownload = 'RxHistoryDownload',
  RxHistoryDownloadMedOrder = 'RxHistoryDownloadMedOrder',
  RxHistoryDownloadMedOrderFill = 'RxHistoryDownloadMedOrderFill',
  RxHistoryDownloadMedOrderThread = 'RxHistoryDownloadMedOrderThread',
  RxHistoryDownloadSummary = 'RxHistoryDownloadSummary',
  RxItem = 'RxItem',
  RxItemDiagnosis = 'RxItemDiagnosis',
  RxItemPrescriber = 'RxItemPrescriber',
  RxItemRefill = 'RxItemRefill',
  RxNorm = 'RxNorm',
  RxRefillMedication = 'RxRefillMedication',
  RxRefillRequest = 'RxRefillRequest',
  RxRefillRequestDiagnosis = 'RxRefillRequestDiagnosis',
  RxRefillRequestPatient = 'RxRefillRequestPatient',
  RxRefillRequestPatientPhone = 'RxRefillRequestPatientPhone',
  RxRefillRequestPharmacy = 'RxRefillRequestPharmacy',
  RxRefillRequestPharmacyPhone = 'RxRefillRequestPharmacyPhone',
  RxRefillRequestPrescriber = 'RxRefillRequestPrescriber',
  RxRefillRequestPrescriberPhone = 'RxRefillRequestPrescriberPhone',
  RxRefillRequestSupervisor = 'RxRefillRequestSupervisor',
  RxRefillRequestSupervisorPhone = 'RxRefillRequestSupervisorPhone',
  RxRefillResponse = 'RxRefillResponse',
  RxReportOHPrintRecord = 'RxReportOHPrintRecord',
  RxSig = 'RxSig',
  SMS = 'SMS',
  SMSConversation = 'SMSConversation',
  SSPharmacy = 'SSPharmacy',
  SSPrescriber = 'SSPrescriber',
  SandboxUserMessage = 'SandboxUserMessage',
  SandboxUserMessageRecipient = 'SandboxUserMessageRecipient',
  ScribingSession = 'ScribingSession',
  ServiceLocation = 'ServiceLocation',
  SharedFile = 'SharedFile',
  SharedFileCategory = 'SharedFileCategory',
  SharedFileRecipient = 'SharedFileRecipient',
  SleepCenter = 'SleepCenter',
  SleepOrder = 'SleepOrder',
  SleepOrderContent = 'SleepOrderContent',
  SleepOrderTest = 'SleepOrderTest',
  SleepOrderTestCategory = 'SleepOrderTestCategory',
  SnomedCode = 'SnomedCode',
  StaffGroup = 'StaffGroup',
  StaffInvite = 'StaffInvite',
  SupervisedRxAuthority = 'SupervisedRxAuthority',
  SupportMessage = 'SupportMessage',
  SurescriptsTxIncoming = 'SurescriptsTxIncoming',
  SurescriptsTxOutgoing = 'SurescriptsTxOutgoing',
  Survey = 'Survey',
  SurveyQuestion = 'SurveyQuestion',
  TaskTracker = 'TaskTracker',
  TelephonyConversation = 'TelephonyConversation',
  TestAccountDetails = 'TestAccountDetails',
  ThreadMember = 'ThreadMember',
  ThreadMessage = 'ThreadMessage',
  ThreadPatient = 'ThreadPatient',
  TrialPhysician = 'TrialPhysician',
  TrialPractice = 'TrialPractice',
  UnAssignedLabReport = 'UnAssignedLabReport',
  UnAssignedLabReportImport = 'UnAssignedLabReportImport',
  UnAssignedLabResultGrid = 'UnAssignedLabResultGrid',
  UnauthenticatedAccessRegistrationLinkUser = 'UnauthenticatedAccessRegistrationLinkUser',
  UnprocessedIncomingFile = 'UnprocessedIncomingFile',
  UnreadLetterNotification = 'UnreadLetterNotification',
  UploadedFile = 'UploadedFile',
  UploadedImage = 'UploadedImage',
  UploadedPhoto = 'UploadedPhoto',
  User = 'User',
  UserDirectAddress = 'UserDirectAddress',
  UserFeedback = 'UserFeedback',
  UserLoginAttempt = 'UserLoginAttempt',
  UserPhoto = 'UserPhoto',
  VNIAssessment = 'VNIAssessment',
  VNIBP = 'VNIBP',
  VNIDataDocument = 'VNIDataDocument',
  VNIDataLabReport = 'VNIDataLabReport',
  VNIDataThread = 'VNIDataThread',
  VNIHC = 'VNIHC',
  VNIHR = 'VNIHR',
  VNIHeight = 'VNIHeight',
  VNIMed = 'VNIMed',
  VNIOxygen = 'VNIOxygen',
  VNIPain = 'VNIPain',
  VNIProblem = 'VNIProblem',
  VNIProblemHpi = 'VNIProblemHpi',
  VNIRR = 'VNIRR',
  VNITemperature = 'VNITemperature',
  VNITest = 'VNITest',
  VNITx = 'VNITx',
  VNIWeight = 'VNIWeight',
  Vaccine = 'Vaccine',
  VaccinePackagedMedication = 'VaccinePackagedMedication',
  VaccinePackagedMedicationDose = 'VaccinePackagedMedicationDose',
  VendorPatientIdentifier = 'VendorPatientIdentifier',
  VipAccessNotification = 'VipAccessNotification',
  VisitNote = 'VisitNote',
  VisitNote2 = 'VisitNote2',
  VisitNoteAlert = 'VisitNoteAlert',
  VisitNoteBullet = 'VisitNoteBullet',
  VisitNoteChecklist = 'VisitNoteChecklist',
  VisitNoteChecklistField = 'VisitNoteChecklistField',
  VisitNoteCpt = 'VisitNoteCpt',
  VisitNoteDocument = 'VisitNoteDocument',
  VisitNoteDx = 'VisitNoteDx',
  VisitNoteEdit = 'VisitNoteEdit',
  VisitNoteItem = 'VisitNoteItem',
  VisitNotePatientItem = 'VisitNotePatientItem',
  VisitNoteSummary = 'VisitNoteSummary',
  VisitNoteTemplate = 'VisitNoteTemplate',
  VisitNoteTranscriptItem = 'VisitNoteTranscriptItem',
  VisitNoteType = 'VisitNoteType',
  VitalsCollection = 'VitalsCollection',
  Workspace = 'Workspace',
  XmlPatientSummary = 'XmlPatientSummary',
  ZipCode = 'ZipCode',
}

export default ModelClass;
