exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".GlobalPatientSearchFTUEDialog__dialogContent___2HUYe {\n  padding-bottom: 16px;\n}\n", ""]);

// exports
exports.locals = {
	"dialogContent": "GlobalPatientSearchFTUEDialog__dialogContent___2HUYe"
};