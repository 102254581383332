import { DataGrid, DataGridCell, DataGridColumnSpec, Text2 } from '@el8/vital';
import { PatientDeletedDocument } from 'EntityTypes';
import DATE_FORMATS from 'constants/DATE_FORMATS';
import {
  usePatientDeletedDocuments,
  useRestorePatientDeletedDocument,
} from 'modules/patient-deleted-documents';
import React, { useCallback, useEffect, useMemo } from 'react';
import alert from 'utils/alert';
import { practiceMoment } from 'utils/timezone';
import toast from 'utils/notify/toast';
import { RestoreDeletedDocumentCell } from './cells/RestoreDeletedDocumentCell';
import { DeletedDocumentTitleCell } from './cells/DeletedDocumentTitleCell';
import { RestoreDeletedDocumentsDialogUnavailableContent } from './RestoreDeletedDocumentsDialogUnavailableContent';
import styles from './RestoreDeletedDocumentsDialogContent.less';

const createColumns: (props: {
  restoreDocument: (id: number, title: string) => Promise<void>;
}) => DataGridColumnSpec<PatientDeletedDocument, string>[] = ({ restoreDocument }) => [
  {
    key: 'date',
    title: 'Date',
    valueGetter: (item) => new Date(item.documentDate).getTime(),
    renderCell: ({ props, item }): JSX.Element => {
      return (
        <DataGridCell {...props}>
          <Text2 tag="strong">{practiceMoment(item.documentDate).format(DATE_FORMATS.DATE)}</Text2>
        </DataGridCell>
      );
    },
    width: 130,
    sortable: true,
  },
  {
    key: 'title',
    title: 'Title',
    valueGetter: (item) => item.id,
    renderCell: (params): JSX.Element => {
      return <DeletedDocumentTitleCell {...params} key={params.props.key} />;
    },
  },
  {
    key: 'description',
    title: 'Description',
    valueGetter: (item) =>
      `Deleted on ${practiceMoment(item.delete_date).format(DATE_FORMATS.DATETIME)}`,
    width: 250,
  },
  {
    key: 'restore',
    title: '',
    valueGetter: (item) => item.id,
    renderCell: (params): JSX.Element => {
      return (
        <RestoreDeletedDocumentCell
          {...params}
          restoreDocument={restoreDocument}
          key={params.props.key}
        />
      );
    },
  },
];

export const RestoreDeletedDocumentsDialogContent = (): JSX.Element | null => {
  const {
    data: deletedDocuments = [],
    refetch: refetchDeletedDocuments,
    isFetching,
    isError,
  } = usePatientDeletedDocuments(el8Globals.PATIENT_ID);

  const { mutateAsync: restoreDeletedDocument } = useRestorePatientDeletedDocument();

  const unavailable = isFetching || deletedDocuments.length === 0 || isError;

  const restoreDocument = useCallback(
    async (id: number, title: string) => {
      try {
        toast(`Restoring ${title}...`);

        const restoredDocument = await restoreDeletedDocument({
          documentId: id,
          patientId: el8Globals.PATIENT_ID,
        });

        if (el8?.ptdata?.getEntitySet) {
          // We need to refresh the Requiring actions feed
          const entitySet = el8.ptdata.getEntitySet(restoredDocument.model_class);
          entitySet.create(restoredDocument);
        }

        toast(`${title} restored`);

        await refetchDeletedDocuments();
      } catch (error) {
        alert('Unable to restore deleted document');
      }
    },
    [restoreDeletedDocument, refetchDeletedDocuments],
  );

  const columns = useMemo(() => {
    return createColumns({ restoreDocument });
  }, [restoreDocument]);

  // We are subscribing to the delete events emitted the visitNote and reports
  // entity domain model and refetching the deleted documents
  // whenever a document is deleted.
  useEffect(() => {
    if (el8?.ptdata?.visitNotes?.bind) {
      el8.ptdata.visitNotes.bind(EVENTS.DELETED, refetchDeletedDocuments);
    }
    if (el8?.ptdata?.reports?.bind) {
      el8.ptdata.reports.bind(EVENTS.DELETED, refetchDeletedDocuments);
    }

    return function cleanup() {
      if (el8?.ptdata?.visitNotes?.unbind) {
        el8.ptdata.visitNotes.unbind(EVENTS.DELETED, refetchDeletedDocuments);
      }
      if (el8?.ptdata?.reports?.unbind) {
        el8.ptdata.reports.unbind(EVENTS.DELETED, refetchDeletedDocuments);
      }
    };
  }, [refetchDeletedDocuments]);

  if (!el8Globals.PATIENT_ID) return null;

  return (
    <DataGrid<PatientDeletedDocument>
      keyExtractor={(document): number => document.id}
      data={deletedDocuments}
      columns={columns}
      appearance="list"
      density="compact"
      className={styles.datagrid}
      unavailableContent={
        unavailable ? (
          <RestoreDeletedDocumentsDialogUnavailableContent
            loading={isFetching}
            empty={deletedDocuments.length === 0}
            error={isError}
          />
        ) : undefined
      }
      data-testid="restore-deleted-documents-datagrid"
    />
  );
};
