import cn from 'classnames';
import { Sex } from 'constants/patients';
import { Patient } from 'EntityTypes';
import { searchPatientsQuery } from 'modules/search';
import {
  PatientSearchResponse,
  PatientSearchResult,
  QueryResponse,
  ReduxQueryDispatch,
} from 'QueryTypes';
import React, { useCallback, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { requestAsync } from 'redux-query';
import { RootState } from 'StoreTypes';
import { openCreatePatientDialog } from 'ui-modules/demographics/demographicsSlice';
import { practiceMoment } from 'utils/timezone';
import { Badge, MindreaderOnSelectOptions, MindreaderProcessedResults } from '@el8/vital';
import BaseMindreader, { BaseMindreaderItemDetails } from './BaseMindreader';
import { AdjustedBaseMindreaderProps } from './mindreaderHelpers';
import styles from './PatientMindreader.less';

export type LegacyCreatePatientCallbackObject = {
  firstName: string;
  id: number;
  lastName: string;
  middleName: string;
};

type LegacyPatientModel = {
  getDisplayAge: () => string;
  getDob: () => {
    asString: () => string;
  };
  getId: () => number;
  getPrimaryPhysicianName: () => string;
  getPrimaryPhysicianUserId: () => number;
  getReverseName: () => string;
  getSex: () => Sex;
  getTags: () => string[];
  isVerified: () => boolean;
  isVip: () => boolean;
};

/**
 * Makes a best effort to convert a legacy patient model into a PatientSearchResult.
 * Borrows most of the logic from the legacy model's `asAutocompleteMap` function.
 *
 * @param model
 */
function convertLegacyPatientModelToSearchResult(model: LegacyPatientModel): PatientSearchResult {
  return {
    age: model.getDisplayAge(),
    dob: model.getDob().asString(),
    id: model.getId(),
    is_vip: model.isVip(),
    name: model.getReverseName(),
    practice_id: el8Globals.PRACTICE_ID,
    primaryPhysicianName: model.getPrimaryPhysicianName(),
    primaryPhysicianUserId: model.getPrimaryPhysicianUserId(),
    sex: model.getSex(),
    tags: model.getTags(),
    url: `/patient/${model.getId()}/`,
    verified: model.isVerified(),
    // ??? no idea. Doesn't seem like these can be obtained from the legacy model
    contact: '',
    interesting: false,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
    last_seen: null,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
    master_id: null,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
    next_appt: null,
    practice_name: '',
    practice_short_name: '',
  };
}

export type PatientMindreaderProps = {
  /**
   * Whether or not allow users to create new patient. `true` by default
   */
  allowCreatePatients?: boolean;

  /**
   * The location of this Mindreader, used only for Amplitude tracking purposes.
   */
  amplitudeSource?: 'appt';

  /**
   * Whether to include patients in the current practice's enterprise in the search
   * results. This has no effect if the current practice is not in an enterprise.
   */
  includeEnterprise?: boolean;

  /**
   * Whether to include patients from linked accounts in the search results. This has
   * no effect if the current user has no linked accounts.
   */
  includeGlobal?: boolean;

  /**
   * Whether or not to append a * to label value
   */
  isRequired?: boolean;

  /**
   * Whether to show empty sections in the underlying Mindreader component
   */
  showEmptySections?: boolean;

  /**
   * Optional callback triggered when the "Create new patient" action item is
   * clicked.
   */
  onBeforeCreatePatient?: (value?: string) => void;

  /**
   * Optional callback for when a patient is created using the "Create new patient"
   * action item at the bottom of the results dropdown. This will only be used with
   * the new demographics dialog. To specify a callback for the old legacy demographics
   * dialog, use `onCreatePatientLegacy` instead.
   */
  onCreatePatient?: (patient: Patient) => void;

  /**
   * Optional callback for when a patient is created using the "Create new patient"
   * action item at the bottom of the results dropdown. This will only be used with
   * the old legacy demographics dialog. To specify a callback for the new React demographics
   * dialog, use `onCreatePatient` instead.
   */
  onCreatePatientLegacy?: (patient: LegacyCreatePatientCallbackObject) => void;

  label?: string;
} & AdjustedBaseMindreaderProps<PatientSearchResult, QueryResponse<PatientSearchResponse>>;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
  enterpriseName: state.practice?.enterpriseName,
  practiceName: state.practice?.name,
  pagePatientId: state.patient?.id,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: ReduxQueryDispatch, ownProps: PatientMindreaderProps) => ({
  queryPatients(query: string, suppressId?: number): Promise<QueryResponse<PatientSearchResponse>> {
    return dispatch(
      requestAsync(
        searchPatientsQuery(query, undefined, {
          enterpriseAware: ownProps.includeEnterprise,
          globalSearchAware: ownProps.includeGlobal,
          suppressId,
        }),
      ),
    );
  },
  startCreatePatient(initialName: string, onSuccess: (patient: Patient) => void): void {
    dispatch(openCreatePatientDialog({ initialName, onSuccess }));
  },
});

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof reduxConnector> & PatientMindreaderProps;

const getItemDetails = (
  patient: PatientSearchResult,
  isActive: boolean,
): BaseMindreaderItemDetails => {
  const descriptionParts = [`DOB: ${patient.dob || 'n/a'}`];
  if (patient.last_seen) {
    descriptionParts.push(`Last Seen: ${practiceMoment(patient.last_seen).format('MM/DD/YYYY')}`);
  }
  if (patient.next_appt) {
    descriptionParts.push(`Next Appt: ${practiceMoment(patient.next_appt).format('MM/DD/YYYY')}`);
  }
  descriptionParts.push(`Attributed to: ${patient.primaryPhysicianName || 'n/a'}`);

  const patientStatusBadge =
    el8Globals.FEATURES.PatientStatus && patient.patient_status !== 'Active' ? (
      <Badge
        appearance="reverse"
        intent="warning"
        label={patient.patient_status}
        size="small"
        style={{ marginRight: 4 }}
      />
    ) : null;

  const vipBadge = patient.is_vip && (
    <Badge
      appearance="reverse"
      intent="critical"
      label="VIP"
      size="small"
      style={{ marginRight: 4 }}
    />
  );

  const title = patient.practice_short_name ? (
    <span>
      {patientStatusBadge}
      {vipBadge}
      {patient.name}
      <span className={cn(styles.practiceShortnameLabel, isActive && styles.active)}>
        {patient.practice_short_name}
      </span>
    </span>
  ) : (
    <span>
      {patientStatusBadge}
      {vipBadge}
      {patient.name}
    </span>
  );

  return {
    title,
    description: descriptionParts.join(', '),
    key: patient.id,
    tags: patient.tags,
  };
};

const PatientMindreader: React.FC<Props> = ({
  allowCreatePatients = true,
  amplitudeSource,
  enterpriseName,
  practiceName,
  isRequired = false,
  includeEnterprise,
  onBeforeCreatePatient,
  includeGlobal,
  onCreatePatient,
  onCreatePatientLegacy,
  onSelect,
  pagePatientId,
  queryPatients,
  startCreatePatient,
  value,
  label = 'Patient',
  ...props
}) => {
  const queryFn = useCallback(
    (query: string): Promise<QueryResponse<PatientSearchResponse>> => {
      return queryPatients(query, pagePatientId);
    },
    [pagePatientId, queryPatients],
  );

  const processResults = useCallback(
    (
      results: QueryResponse<PatientSearchResponse>,
    ): MindreaderProcessedResults<PatientSearchResult> => {
      // Hide enterprise results if not showing all local results - this
      // logic previously existed on the backend but is now done on the
      // frontend to keep the API clean.
      const externalResultsFeatureEnabled =
        el8Globals.FEATURES.PLR || el8Globals.FEATURES.GlobalPatientSearch;
      const showingAllLocalResults = results.body.local.length === results.body.total_local_count;
      const onlyLocalResults = results.body.enterprise == null && results.body.global == null;

      if (!externalResultsFeatureEnabled || onlyLocalResults || !showingAllLocalResults) {
        return results.body.local;
      }

      if (includeGlobal && el8Globals.FEATURES.GlobalPatientSearch) {
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ [x: string]: { index: number; results: Pat... Remove this comment to see the full error message
        return {
          'Results within your practice': {
            index: 0,
            results: results.body.local,
          },
          [`Outside ${practiceName}`]: {
            index: 1,
            results: results.body.global,
          },
        };
      }

      if (includeEnterprise && el8Globals.FEATURES.PLR) {
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ [x: string]: { index: number; results: Pat... Remove this comment to see the full error message
        return {
          'Results within your practice': {
            index: 0,
            results: results.body.local,
          },
          [`Results from ${enterpriseName}`]: {
            index: 1,
            results: results.body.enterprise,
          },
        };
      }

      // Final fallback
      return results.body.local;
    },
    [enterpriseName, practiceName],
  );

  const handleSelect = useCallback(
    (
      patient: PatientSearchResult,
      options: MindreaderOnSelectOptions<QueryResponse<PatientSearchResponse>>,
    ): void => {
      const globalEnabled = includeGlobal && el8Globals.FEATURES.GlobalPatientSearch;
      const enterpriseEnabled = includeEnterprise && el8Globals.FEATURES.PLR;

      if (
        !globalEnabled &&
        enterpriseEnabled &&
        patient.practice_id &&
        patient.practice_id !== el8Globals.PRACTICE_ID
      ) {
        // @ts-ignore
        el8.app.shared.dialog.enterpriseImportPatient.show({
          patientId: patient.id,
          yesCallbackFn(_newPatientId: never, _importedLhs: never, newPatient: LegacyPatientModel) {
            onSelect(convertLegacyPatientModelToSearchResult(newPatient), options);
          },
        });
      } else {
        onSelect(patient, options);
      }
    },
    [includeEnterprise, onSelect],
  );

  const actions = useMemo(() => {
    if (!allowCreatePatients) {
      return [];
    }
    // Exclude action from the population AND settings page...
    // and maybe the reports page too because creating patient doesn't work there either.
    const paths = window.location.pathname.split('/').filter(Boolean);
    if (paths[0] && ['billing', 'population', 'settings'].includes(paths[0])) return [];

    return [
      {
        label: 'Create a new patient chart with this name...',
        onClick: (): void => {
          if (onBeforeCreatePatient) {
            onBeforeCreatePatient(value);
          }

          if (el8Globals.FEATURES.NewDemographics) {
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
            startCreatePatient(value, onCreatePatient);
          } else {
            // @ts-ignore
            el8.appshared.dialog.createPatient.show({
              name: value,
              amplitudeSource: amplitudeSource || null,
              saveCallbackFn: onCreatePatientLegacy || null,
            });
          }
        },
      },
    ];
  }, [
    allowCreatePatients,
    amplitudeSource,
    onBeforeCreatePatient,
    onCreatePatient,
    onCreatePatientLegacy,
    startCreatePatient,
    value,
  ]);

  const labelString = `${label}${isRequired ? '*' : ''}`;
  const showLabel = !!label;

  return (
    <BaseMindreader<PatientSearchResult, QueryResponse<PatientSearchResponse>>
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      label={showLabel ? labelString : null}
      minQueryLength={2}
      data-testid="patient-mind-reader-patient-name"
      getItemDetails={getItemDetails}
      resultsWidth={550}
      value={value}
      onSelect={handleSelect}
      queryFn={queryFn}
      processResults={processResults}
      actions={actions}
      {...props}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientMindreader);
