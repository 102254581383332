import React, { useMemo } from 'react';

import { Marquee, Text2 } from '@el8/vital';

import { useFetchStatus } from 'modules/support/supportQueries';
import { AppStatus } from 'QueryTypes';

import styles from './CaseIntakeStatusMarquee.less';

// see: https://kb.status.io/developers/status-codes/
const STATUS_CODE_DEGRADED_PERFORMANCE = 300;
const STATUS_CODE_PARTIAL_DISRUPTION = 400;
const STATUS_CODE_SERVICE_DISRUPTION = 500;

export const toFilteredIncidents = (
  statuses: AppStatus[] | undefined,
): { name: string }[] | undefined => {
  const incidentsFiltered = statuses?.filter((status) => {
    switch (status?.status_code) {
      case STATUS_CODE_DEGRADED_PERFORMANCE:
      case STATUS_CODE_PARTIAL_DISRUPTION:
      case STATUS_CODE_SERVICE_DISRUPTION:
        return { name: status.name };
      default:
        return false;
    }
  });

  return incidentsFiltered;
};

const CaseIntakeDialogStatusMarquee = (): JSX.Element | null => {
  const { data } = useFetchStatus();
  const statuses = data?.result?.status;
  const incidents = useMemo(() => toFilteredIncidents(statuses), [statuses]);

  if (!incidents || (incidents && incidents?.length === 0)) return null;

  return (
    <Marquee intent="critical">
      <Text2>We are currently working to address an issue related to:</Text2>
      <ul className={styles.listIncidents}>
        {incidents?.map((incident) => (
          <li key={`li-item${incident?.name}`}>{incident?.name}</li>
        ))}
      </ul>
      <Text2>
        If the issue you are reporting is related to this disruption, please instead refer to{' '}
        <strong>
          <a href="https://elation.status.io/" target="_blank" rel="noopener noreferrer">
            elation.status.io
          </a>
        </strong>{' '}
        for the latest updates.
      </Text2>
    </Marquee>
  );
};

export default CaseIntakeDialogStatusMarquee;
