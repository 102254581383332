exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".INeedHelpNavItem__helpIcon___2etHm {\n  color: var(--vds-gold-300);\n}\n", ""]);

// exports
exports.locals = {
	"helpIcon": "INeedHelpNavItem__helpIcon___2etHm"
};