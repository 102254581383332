import { useCallback } from 'react';

/**
 * Gets the search value for an item.
 *
 * @returns {string}
 */
function getItemValue(item, searchBy) {
  const searchByType = typeof searchBy;
  if (searchByType === 'function') {
    return searchBy(item).toLowerCase();
  }
  if (searchByType === 'string') {
    return item[searchBy].toLowerCase();
  }
  throw new Error(`Unsupported type for searchBy option: ${searchByType}`);
}

/**
 * Tests the search value using the provided searchFn
 */
function testValue(searchValue, query, searchFn) {
  return searchValue[searchFn](query);
}

/**
 * A hook that provides local (client-side) search functionality. Usually used
 * with Mindreaders.
 *
 * @param {any[]} data - the data to query
 * @param {object} queryConfig - specifies what type of search should be done.
 * Currently, only searching by a specific field is supported, but additional
 * types may be added in the future.
 * @param {string|function} queryConfig.searchBy - either the name of the property
 * to search by, or a function that takes a data item and returns the string value
 * to search by.
 * @param {string} [queryConfig.searchFn = 'startsWith'] - the name of a string
 * prototype function (like 'startsWith' or 'includes') to test values with.
 * @returns {function} a query function that accepts a query string and returns
 * an array of results found in the given data.
 */
export default function useLocalQuery(data, { searchBy, searchFn = 'startsWith' }) {
  const queryFn = useCallback(
    (query) => {
      try {
        let results;

        if (query) {
          const lowercasedQuery = query.toLowerCase();
          results = data.filter((item) =>
            testValue(getItemValue(item, searchBy), lowercasedQuery, searchFn),
          );
        } else {
          results = data;
        }

        return Promise.resolve(results);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    [data, searchBy, searchFn],
  );

  return queryFn;
}
