import { LegacyFriendlyDialog } from 'components/overlays';
import React, { useEffect } from 'react';
import { Button2, DialogCloseButton, Group } from '@el8/vital';
import useAppSelector from 'utils/hooks/useAppSelector';
import { useCloseProviderSecurityCodeDialog } from 'ui-modules/navbar';
import { logEvent } from 'utils/amplitude';
import { ProviderSecurityCodeDialogContent } from './ProviderSecurityCodeDialogContent';

export const ProviderSecurityCodeDialog = (): JSX.Element => {
  const isOpen = useAppSelector((state) => state.navbar.providerSecurityCodeDialog.isOpen);
  const closeProviderSecurityCodeDialog = useCloseProviderSecurityCodeDialog();

  useEffect(() => {
    if (isOpen) {
      logEvent('open: security code dialog');
    }
  }, [isOpen]);

  return (
    <LegacyFriendlyDialog
      isOpen={isOpen}
      footer={
        <Group>
          <Button2 kind="default" label="Close" onClick={closeProviderSecurityCodeDialog} />
        </Group>
      }
      onClose={closeProviderSecurityCodeDialog}
      padded
      title="Provider security code"
      width={350}
      renderCloseButton={(info): JSX.Element => (
        <DialogCloseButton
          {...info}
          data-testid="provider-security-code-dialog-x-button"
          onClick={closeProviderSecurityCodeDialog}
        />
      )}
    >
      <ProviderSecurityCodeDialogContent />
    </LegacyFriendlyDialog>
  );
};
