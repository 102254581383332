import { useCallback } from 'react';
import { useAppDispatch } from 'utils/hooks';
import { OpenTrialActivationDialogPayload } from './TrialActivationTypes';
import { closeTrialDialog, openTrialDialog } from './trialActivationSlice';

export function useOpenTrialActivationDialog(): (
  payload: OpenTrialActivationDialogPayload,
) => void {
  const dispatch = useAppDispatch();

  return useCallback(
    (payload) => {
      dispatch(openTrialDialog(payload));
    },
    [dispatch],
  );
}

export function useCloseTrialActivationDialog(): VoidFunction {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(closeTrialDialog());
  }, [dispatch]);
}
