import React from 'react';

import { Group, IconSize, Stack, Text2, Tooltip, VdsIcon } from '@el8/vital';
import { FileInputFormik, TextareaFormik } from '@el8/vital-formik';
import { useFormikContext } from 'formik';

import { CaseIntakeStep } from 'modules/support/supportConstants';
import { useFormikSubmitForm } from 'utils/hooks';

import { CaseIntakeFormValues } from '../CaseIntakeForm';

import styles from './CaseIntakeCaseForm.less';
import { logIntakeAddAttachment, logIntakeTicketSubmitted } from '../amplitudeTaxonomy';
import {
  audioFileExtensionAllowlist,
  documentFileExtensionAllowlist,
  fileInputAccept,
  imageFileExtensionAllowlist,
  videoFileExtensionAllowlist,
} from '../supportedFileTypes';
import { CaseIntakeStepProps } from '../CaseIntakeTypes';
import CaseIntakeStepHeader from '../components/CaseIntakeStepHeader';
import { IntakeButton } from '../components/IntakeButton';

const renderTooltipContent = (): JSX.Element => {
  return (
    <div>
      <Text2>File must be under 10 MB and in a valid format.</Text2>
      <Text2>
        Acceptable image formats: {imageFileExtensionAllowlist.join(', ').toUpperCase()}
      </Text2>
      <Text2>Document formats: {documentFileExtensionAllowlist.join(', ').toUpperCase()}</Text2>
      <Text2>Audio formats: {audioFileExtensionAllowlist.join(', ').toUpperCase()}</Text2>
      <Text2>Video formats: {videoFileExtensionAllowlist.join(', ').toUpperCase()}</Text2>
    </div>
  );
};

const CaseIntakeCaseForm = ({
  formRootRef,
  goToPrevStep,
  goToNextStep,
  isInModal,
}: CaseIntakeStepProps): JSX.Element => {
  const validateForm = useFormikSubmitForm({ formRootRef });
  const { values, errors, setSubmitting } = useFormikContext<CaseIntakeFormValues>();

  const handleNext = async (): Promise<void> => {
    try {
      await validateForm();
      logIntakeTicketSubmitted(values);
      goToNextStep(CaseIntakeStep.ContactInfo);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Stack data-testid="support-case-form-container" gap="md" className={styles.content}>
      <CaseIntakeStepHeader isInModal={isInModal} step={CaseIntakeStep.create_case} />
      <Text2 sizing="lg">
        Confirm the information we already have is accurate and add more detail if possible.
      </Text2>
      <TextareaFormik
        label="What can we help you with?"
        labelPosition="column"
        name="question"
        rows={5}
        required
      />
      <TextareaFormik
        label="Copy and paste any specific error messages"
        labelPosition="column"
        name="error_messages"
        rows={2}
      />
      <TextareaFormik
        label="List the step by step process that led to the behavior"
        labelPosition="column"
        name="reproduction_steps"
        rows={2}
      />
      <Stack gap="xs">
        <Group gap="2xs">
          <Text2>Attach a relevant file (screenshot, video, etc.)</Text2>
          <Tooltip content={renderTooltipContent()}>
            <VdsIcon
              icon="info"
              size={IconSize.XXSmall}
              /**
               * Using inline styles to overwrite Vital CSS while we wait for a fix.
               * https://github.com/elationemr/hippo/pull/15818#discussion_r1542128239
               */
              style={{ display: 'inline-block', verticalAlign: 'middle' }}
            />
          </Tooltip>
        </Group>
        <FileInputFormik
          aria-describedby="support-case-form-file-input-max-size"
          aria-errormessage="support-case-form-file-input-error"
          aria-invalid={errors.supportCaseFile ? 'true' : undefined}
          accept={fileInputAccept}
          id="support-case-form-file-attachment"
          name="supportCaseFile"
          target={({ onClick }): JSX.Element => (
            <IntakeButton
              data-testid="support-case-form-attachment"
              kind="link"
              label="Add Attachment"
              onClick={(mouseEvent): void => {
                logIntakeAddAttachment(values);
                onClick(mouseEvent);
              }}
              icon="attach"
              sizing="md"
            />
          )}
        />
        {errors.supportCaseFile ? (
          <Text2 sizing="sm" id="support-case-form-file-input-error" appearance="error">
            {errors.supportCaseFile}
          </Text2>
        ) : (
          <Text2 sizing="sm" id="support-case-form-file-input-max-size" appearance="muted">
            Max file size is 10MB
          </Text2>
        )}
      </Stack>
      <Group>
        <IntakeButton
          data-testid="support-case-form-next-button"
          label="Next"
          onClick={handleNext}
          kind="primary"
        />
        <IntakeButton
          data-testid="support-case-form-back-button"
          label="Back"
          onClick={goToPrevStep}
        />
      </Group>
    </Stack>
  );
};

export default CaseIntakeCaseForm;
