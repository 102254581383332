import { Practice } from 'EntityTypes';
import { useCurrentPractice } from 'modules/practice';
import { useCurrentUser } from 'modules/practice-users';
import { useCurrentUserPractices, userPracticeContextSwitchQuery } from 'modules/user-practices';
import { useMemo } from 'react';
import { useMutation } from 'redux-query-react';
import { alertError } from 'utils/errors';
import { useInterval } from 'utils/hooks';
import { checkSuccess } from 'utils/http';
import { logContextSwitchInitiated } from 'views/shared/header/amplitudeTaxonomy';

const TEN_MINUTES_IN_MS = 10 * 60 * 1000;

export interface UsePracticeContextSwitcherResult {
  anyUnreadItems: boolean;
  currentPractice: Practice;
  handleSelect: (item: { id: number | string; label: string }) => void;
  isPending: boolean;
  userPractices?: Practice[];
}

/**
 * Generic hook that returns a series of utilities for switching between practice contexts.
 */
export function usePracticeContextSwitcher(): UsePracticeContextSwitcherResult {
  const currentUser = useCurrentUser();
  const currentPractice = useCurrentPractice();

  // we are setting the limit to 1000 and the offset to 0
  // so that we show all practices in the context switcher, not just
  // the first page according to the server
  const [userPractices, { isPending }, refreshUserPractices] = useCurrentUserPractices({
    limit: 1000,
    offset: 0,
  });

  const [, doPracticeLogin] = useMutation((practiceId: number) =>
    userPracticeContextSwitchQuery(practiceId),
  );

  const handleSelect = (item: { id: number | string; label: string }): void => {
    if (currentPractice.id === item.id || !item.id) return;

    logContextSwitchInitiated(
      currentUser.staffType,
      'context switcher',
      currentPractice.id,
      Number(item.id),
    );

    el8.appservice.notifier.notify(`Switching to ${item.label}...`);

    const actionPromise = doPracticeLogin(Number(item.id));

    if (actionPromise) {
      actionPromise
        .then(checkSuccess)
        .then(() => {
          el8.appservice.notifier.notify(
            `Switching to ${item.label} successful, the page is redirecting`,
          );
          window.location.replace('/patients/');
        })
        .catch((err) => {
          el8.appservice.notifier.notify(`Failed to switch to ${item.label}`);
          alertError(err);
        });
    }
  };

  const anyUnreadItems = useMemo(
    () => Boolean(userPractices?.some((practice) => practice.isUnread)),
    [userPractices],
  );

  useInterval(refreshUserPractices, TEN_MINUTES_IN_MS);

  return {
    anyUnreadItems,
    currentPractice,
    handleSelect,
    isPending,
    userPractices,
  };
}
