import noop from 'lodash/noop';
import React, { useEffect, useState } from 'react';

const useEffectOnce = (callback: React.EffectCallback): void => {
  const [isInitialRender, setIsInitialRender] = useState(true);
  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return callback();
    }
    return noop;
  }, [callback, isInitialRender]);
};

export default useEffectOnce;
