import { ResponseError } from 'utils/errors';

// Based on taxonomy from Error Handling section of Elation's Amplitude Confluence page
// https://elationhealth.atlassian.net/wiki/spaces/PROD/pages/171377292/Amplitude+Event+Taxonomy
export class AmplitudeError {
  /**
   * Helper function to build an AmplitudeError object (based on taxonomy from
   * Elation's "Amplitude Event Taxonomy" Confluence page). This function does
   * best if handed a ResponseError, and should be modified/overloaded if you
   * are expecting significantly different errors (or you can pass in an
   * overrides object). Elation's Amplitude error event handling suggests you
   * should ask if the passed in error and other arguments can answer:
   *  What has failed? (e.g. ISE 500, exceptions, etc...)
   *  What information can make prioritization and fixing more
   *   actionable when the feature is in production? (e.g. Error codes,
   *   reason for ISE 500, etc...)
   *  What is the customer experience during this issue? (e.g. error text displayed?)
   *
   * @param err takes any Error and logs as "other" error and "unknown" error code, but
   *  for ResponseError it will extract err.text and err.status
   * @param source something to help find what operation or code was running when the
   *  error was encountered
   * @param success (default false) an error was encountered, but whether the user's
   *  objective was completed first is necessary to identify outside of this function
   * @param overridesOrExtras (default {}) in case you want to override error_code esp. when
   *  handing in someting besides ResponseError - example { error_code: "my preferred"}
   *  or if you're using the createLogEvent with an extra verb and need to stash to a
   *  field with verb on the AmplitudeError e.g. {action:'add'}
   * @returns AmplitudeError
   */
  constructor(
    // el8-migrate(rm-31221): DO NOT COPY AND PASTE THESE LINES TO SILENCE YOUR OWN LINTING ERRORS
    // This comment is here solely to flag all of the existing `no-explicit-any` offenders
    // when the rule was set to "error"
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    err: ResponseError | Error | string | any,
    source: string,
    success = false,
    // el8-migrate(rm-31221): DO NOT COPY AND PASTE THESE LINES TO SILENCE YOUR OWN LINTING ERRORS
    // This comment is here solely to flag all of the existing `no-explicit-any` offenders
    // when the rule was set to "error"
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    overridesOrExtras: Partial<AmplitudeError & { [key: string]: any }> = {},
  ) {
    let errorArrayHopeful: string | string[] | undefined;
    try {
      // try to parse the json we expect, or optimistically grab at message
      //  NOTE: not guaranteed to set errorArrayHopeful (thrown error, err.text.errors undefined)
      errorArrayHopeful =
        'text' in err ? JSON.stringify(JSON.parse(err.text)?.errors) : err?.message;
    } catch {
      // Assume JSON threw, but do nothing because we also need when JSON outputs undefined
    }
    if ('text' in err && !errorArrayHopeful) {
      errorArrayHopeful = err.text;
    }
    this.error = typeof err === 'string' ? err : errorArrayHopeful || typeof err;
    this.error_code = err instanceof ResponseError ? err.status : 'unknown';
    this.error_source = source;
    this.success = success;
    Object.assign(this, overridesOrExtras);
  }

  error: string | string[];

  // Non-compliant with linter, out of scope to refactor right now
  // eslint-disable-next-line @typescript-eslint/naming-convention
  error_source: string;

  // Non-compliant with linter, out of scope to refactor right now
  // eslint-disable-next-line @typescript-eslint/naming-convention
  error_code: string;

  success: boolean;
}
