import { LegacyFriendlyDialog } from 'components/overlays';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { InviteColleagueRequestVariables } from 'QueryTypes';
import { useInviteColleague } from 'modules/invite-colleague';
import toast from 'utils/notify/toast';
import { useAppSelector } from 'utils/hooks';
import { useCloseInviteColleagueDialog } from 'ui-modules/navbar';
import { logEvent } from 'utils/amplitude';
import { InviteColleagueDialogFooter } from './InviteColleagueDialogFooter';
import { InviteColleagueDialogContent } from './InviteColleagueDialogContent';

const validationSchema = Yup.object().shape<InviteColleagueRequestVariables>(
  {
    name: Yup.string().required('A colleague name is required'),
    email: Yup.string()
      .email()
      .when(['number'], {
        is: (phoneNumber) => !phoneNumber,
        then: (schema: Yup.StringSchema) => {
          return schema.required('An email is required if a phone number is not specified.');
        },
      }),
    specialty: Yup.string(),
    number: Yup.string()
      .phoneNumber()
      .when(['email'], {
        is: (email) => !email,
        then: (schema: Yup.StringSchema) => {
          return schema.required('A phone number is required if an email is not specified.');
        },
      }),
  },
  [['email', 'number']],
);

export const InviteColleagueDialog = (): JSX.Element => {
  const isOpen = useAppSelector((state) => state.navbar.inviteColleagueDialog.isOpen);
  const closeInviteColleagueDialog = useCloseInviteColleagueDialog();

  const { mutateAsync: inviteColleague } = useInviteColleague();

  const handleFormSubmit = async (values: InviteColleagueRequestVariables): Promise<void> => {
    try {
      await inviteColleague(values);
      toast('Thank you for your referral!');
      closeInviteColleagueDialog();
      logEvent('submit: invite a colleague dialog');
    } catch (error) {
      toast(
        'We were unable to send your referral. Please contact Elation Support if the issue persists.',
      );
    }
  };

  const handleClose = (): void => {
    logEvent('cancel: invite a colleague dialog');
    closeInviteColleagueDialog();
  };

  useEffect(() => {
    if (isOpen) {
      logEvent('open: invite colleague dialog');
    }
  }, [isOpen]);

  return (
    <Formik<InviteColleagueRequestVariables>
      initialValues={{ name: '', specialty: '', email: '', number: '' }}
      onSubmit={handleFormSubmit}
      validationSchema={validationSchema}
    >
      <LegacyFriendlyDialog
        isOpen={isOpen}
        onClose={handleClose}
        title="Know a colleague who'd love Elation?"
        width={625}
        padded
        footer={<InviteColleagueDialogFooter />}
      >
        <Form>
          <InviteColleagueDialogContent />
        </Form>
      </LegacyFriendlyDialog>
    </Formik>
  );
};
