import { StringSchema } from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import USState from 'constants/USState';
import { SSN_MASKED_REGEX, SSN_REGEX, ZIP_CODE_REGEX } from 'utils/regex';
import NpiValidator from 'utils/validators/npi';

/**
 * A max amount extension for yup's StringSchema. Best used with text inputs that are
 * NOT of type="number" but still represent numbers - for example, `MoneyInputFormik`.
 *
 * Note that if a custom `message` argument is provided, it will not be used if the
 * string being validated does not represent a number (for example, "foo"). The `message`
 * argument is for cases where the string represents a number that exceeds the given maximum.
 *
 * Sample usage: `yup.string().maxAmount(999.99).required()`
 */
export function maxAmount(max: number, message?: string): StringSchema {
  // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  return this.test('maxAmount', message, function validateMaxAmount(value: string) {
    let isValid;
    let errorMessage;

    if (value) {
      const valueAsNumber = Number(value);
      if (Number.isNaN(valueAsNumber)) {
        isValid = false;
        errorMessage = 'Must be a number';
      } else {
        isValid = valueAsNumber <= max;
        errorMessage = message || `Max amount is ${max}`;
      }
    } else {
      isValid = true;
    }

    // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
    return isValid || this.createError({ path: this.path, message: errorMessage });
  });
}

/**
 * A phone number extension for yup's StringSchema. Best used with the PhoneNumberInput
 * component.
 *
 * Sample usage: `yup.string().phoneNumber().required()`
 */
export function phoneNumber(message = 'Please enter a valid phone number'): StringSchema {
  // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  return this.test('phoneNumber', message, function validatePhoneNumber(value: string) {
    let isValid = false;

    if (value) {
      const parsed = parsePhoneNumberFromString(value, 'US');
      // See this for why we don't use parsed.isValid():
      // https://github.com/catamphetamine/libphonenumber-js#using-phone-number-validation-feature
      //
      // In short, isValid can be overly strict as real life phone number rules change
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      isValid = Boolean(parsed) && parsed.isPossible();
    } else {
      isValid = true;
    }

    // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
    return isValid || this.createError({ path: this.path });
  });
}

/**
 * An SSN extension for yup's StringSchema. Best used with the SSNInput
 * component.
 *
 * Sample usage: `yup.string().ssn().required()`
 */
export function ssn(message = 'Please enter a valid SSN'): StringSchema {
  // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  return this.test('ssn', message, function validateSSN(value: string) {
    let isValid = true;

    if (value) {
      isValid = SSN_REGEX.test(value) || SSN_MASKED_REGEX.test(value);
    }

    // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
    return isValid || this.createError({ path: this.path });
  });
}

const STATES = new Set(Object.values(USState));

/**
 * An extension for yup's StringSchema to validate that a string is a valid US state.
 * Best used with the StateInput component.
 *
 * Sample usage: `yup.string().usState().required()`
 */
export function usState(message = 'Must be a valid state'): StringSchema {
  // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  return this.test('state', message, function validateState(value: string) {
    let isValid = true;

    if (value) {
      isValid = STATES.has(value.toUpperCase() as USState);
    }

    // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
    return isValid || this.createError({ path: this.path });
  });
}

/**
 * A zip code extension for yup's StringSchema. Best used with the ZipCodeInput
 * component.
 *
 * Sample usage: `yup.string().zipCode().required()`
 */
export function zipCode(message = 'Please enter a valid zip code'): StringSchema {
  // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  return this.test('zipCode', message, function validateZipCode(value: string) {
    let isValid = true;

    if (value) {
      isValid = ZIP_CODE_REGEX.test(value);
    }

    // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
    return isValid || this.createError({ path: this.path });
  });
}

/**
 * Validator for National Provider Identifier (NPI) numbers.
 *
 * Sample usage: `yup.string().npi().required()`
 */
export function npi(this: StringSchema, message = 'Please enter a valid NPI'): StringSchema {
  return this.test('npi', message, function validateNpi(value: string) {
    let isValid = true;

    if (value) {
      isValid = NpiValidator.npiLuhnCheck(value);
    }

    return (
      isValid ||
      this.createError({
        path: this.path,
        message,
      })
    );
  });
}
