import { StringSchema, string } from 'yup';

const originalMax = string().max;

/**
 * An override for yup's `StringSchema.max` validator that provides a better default error
 * message, but otherwise behaves the same as `StringSchema.max`.
 */
export function max(
  limit: Parameters<StringSchema['max']>[0],
  message: Parameters<StringSchema['max']>[1],
): StringSchema {
  let overrideDefaultMessage;
  if (typeof limit === 'number') {
    overrideDefaultMessage = `Max length is ${limit}`;
  }

  // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  return originalMax.call(this, limit, message || overrideDefaultMessage);
}
