import { logEventViaLegacy } from 'utils/amplitude';

export const logStartOnboardingStep = (currentStep: string): void => {
  logEventViaLegacy(`Landed on ${currentStep} in Onboarding`, { 'Current Step': currentStep });
};

export const logSubmitProviderESignature = (): void => {
  logEventViaLegacy('Submit Provider Esignature');
};

export const logSaveESigSeparateDevice = (): void => {
  logEventViaLegacy('Save Provider Esig via Separate Device');
};

export const logViewESigQRCode = (): void => {
  logEventViaLegacy('View Esig QR Code Onboarding');
};

export const logClearOnboardingSignature = (): void => {
  logEventViaLegacy('Clear Onboarding Signature');
};

export const logReturnToPrevOnboardingStep = (currentStep: string): void => {
  logEventViaLegacy('Return to Previous Onboarding Step', { 'Current Step': currentStep });
};

export const logInviteTeamMembersDuringOnboarding = (): void => {
  logEventViaLegacy('Invite Team Members During Onboarding');
};

export const logCloseOnboardingSuccessModal = (): void => {
  logEventViaLegacy('Close Onboarding Success Message');
};

export const logCloseStripeIDPModal = (): void => {
  logEventViaLegacy('Closed Stripe IDP Modal');
};

export const logIdentityProofingConsentDeclined = (): void => {
  logEventViaLegacy('Does Not Consent to Identity Proofing');
};

export const logCompleteIdentityProofing = (): void => {
  logEventViaLegacy('Complete Identity Proofing');
};

export const logSubmitCredentials = (): void => {
  logEventViaLegacy('Submit Credentials for Verification');
};

export const logVisitTrainingCenter = (source: string): void => {
  logEventViaLegacy('Visit Training Center', {
    source,
  });
};

export const logVisitElationUniversity = (): void => {
  logEventViaLegacy('Visit Elation University During Onboarding');
};

export const logStartedIdentifyProofingDocs = (): void => {
  logEventViaLegacy('Start IDP Process in Onboarding');
};

export const logSubmitIdentifyProofingDocs = (): void => {
  logEventViaLegacy('Submit Identity Proofing documents');
};

export const logCreatePasswordOnboarding = (): void => {
  logEventViaLegacy('Create Password in Onboarding');
};
