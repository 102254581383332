import { applyMiddleware, createStore as baseCreateStore } from 'redux';
import { queryMiddleware } from 'redux-query';
import superagentInterface from 'redux-query-interface-superagent';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxQueryConfigEnhancer from './reduxQueryConfigEnhancer';
import reduxQueryEntityIndexer from './reduxQueryEntityIndexer';

export const getQueries = (state) => state.queries;
export const getEntities = (state) => state.entities;

const middlewares = [
  reduxQueryConfigEnhancer,
  queryMiddleware(superagentInterface, getQueries, getEntities),
  reduxQueryEntityIndexer,
];

// feel free to uncomment while debugging locally but do not commit
// if (process.env.NODE_ENV === 'development') {
// const createLogger = require('redux-logger').createLogger;
// const logger = createLogger();
// middlewares.push(logger);
// }

let store = null;

/**
 * Creates a Redux store configured with a common set of middleware our EMR
 * pages use. This should be used for new React-based pages that don't use legacy
 * el8. Pages still using legacy el8 (but incorporating React) should use
 * configureHydratedStore or configureStore instead, for now.
 *
 * @param {function} rootReducer - the root reducer returned from a call to redux's
 * combineReducers function
 * @param {object} preloadedState - a plain object with the same shape
 * as the keys passed to combineReducers to create `rootReducer`
 * @returns a Redux store configured with various middleware
 */
export default function createStore(rootReducer, preloadedState) {
  store = baseCreateStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );

  return store;
}

/**
 * Get the store.
 *
 * @returns {import('redux').Store}
 */
export function getStore() {
  return store;
}
