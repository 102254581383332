import React from 'react';
import { Button2, DataGridCell, DataGridRenderCellParams } from '@el8/vital';
import { PatientDeletedDocument } from 'EntityTypes';
import { useDeletedDocumentTitle } from '../hooks/useDeletedDocumentTitle';

export const RestoreDeletedDocumentCell = ({
  props,
  item,
  restoreDocument,
}: DataGridRenderCellParams<PatientDeletedDocument> & {
  restoreDocument: (id: number, title: string) => Promise<void>;
}): JSX.Element => {
  const title = useDeletedDocumentTitle(item);

  return (
    <DataGridCell {...props}>
      <Button2
        label="Restore"
        icon="undo"
        sizing="xs"
        onClick={(): void => {
          restoreDocument(item.id, title);
        }}
        data-id={item.id}
      />
    </DataGridCell>
  );
};
