import { Button2, Group } from '@el8/vital';
import { useFormikContext } from 'formik';
import React from 'react';
import { useCloseInviteColleagueDialog } from 'ui-modules/navbar';

export const InviteColleagueDialogFooter = (): JSX.Element => {
  const { submitForm } = useFormikContext();
  const closeInviteColleagueDialog = useCloseInviteColleagueDialog();

  return (
    <Group>
      <Button2 label="Submit" kind="primary" type="submit" onClick={submitForm} />
      <Button2 label="Cancel" onClick={closeInviteColleagueDialog} />
    </Group>
  );
};
