import isBoolean from 'lodash/isBoolean';
import kebabCase from 'lodash/kebabCase';
import toPairs from 'lodash/toPairs';
import useMetaTag from './useMetaTag';

interface ViewportSettings {
  width: number | 'device-width';
  height?: number | 'device-height';
  /**
   * Controls the zoom level when the page is first loaded
   */
  initialScale: number;
  maximumScale?: number;
  minimumScale?: number;
  userScalable?: boolean;
  viewportFit?: string;
}

/**
 * Adds a `<meta name="viewport'>` tag to the page. Interface is preloaded with
 * appropriate values for content.
 *
 * @see useMetaTag
 *
 * @param data
 */
export default function useViewport(data: ViewportSettings): void {
  const dataPairs = toPairs(data).map(([key, val]) => [
    kebabCase(key),
    // eslint-disable-next-line no-nested-ternary
    isBoolean(val) ? (val ? 'yes' : 'no') : val,
  ]);
  const content = dataPairs.map(([key, val]) => `${key}=${val}`).join(', ');
  useMetaTag({ name: 'viewport', content });
}
