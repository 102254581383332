export enum PatientFormType {
  Authorization = 'authorization',
  Demographics = 'demographics',
  MedicalHistory = 'medical_history',
  Printout = 'printout',
}

export enum PatientFormTemplate {
  Blank = 'blank',
}

export enum PatientFormExportTarget {
  VisitNote = 'VISIT_NOTE',
  ClinicalProfile = 'CLINICAL_PROFILE',
}
