import some from 'lodash/some';

/**
 * Converts a browser performance timestamp to a DataDog compatible
 * time, i.e. a Date.
 * @param perfTime
 */
function perfTimeToDatadogTime(perfTime: number): number {
  return perfTime + performance.timeOrigin;
}

function isMark(entry: PerformanceEntry): entry is PerformanceMark {
  return entry.entryType === 'mark';
}

function isMeasure(entry: PerformanceEntry): entry is PerformanceMeasure {
  return entry.entryType === 'measure';
}

/**
 * Every performance.measure will be exported to DataDog as a Custom
 * Action with the following payload.
 *
 * If you need to send custom attributes to DataDog, don't use a
 * performance.mark. Just send a custom action directly with `DD_RUM.addAction()`
 */
interface DatadogMeasureToActionPayload {
  type: 'measure';
  duration: number;
  startTime: number;
}

/**
 * Mark names that should be completely ignored, and not sent to Datadog
 */
const MARK_BLACKLIST = ['__v3'];
/**
 * Marks that end with the following suffixes should be ignored,
 * and not sent to DataDog. These suffixes are those that are
 * added automatically by the perfMarks util to record some
 * duration based measure.
 */
const MARK_SUFFIX_BLACKLIST = [':start', ':stop', ':exporting'];

async function exportMarkToDatadog(mark: PerformanceMark): Promise<void> {
  if (MARK_BLACKLIST.includes(mark.name)) return;
  // Default Installed Chrome Extension started including hundreds of these performance
  // marks and measures and it's now littering our console.logs and datadog actions
  if (mark.name.includes('browser::collect_frame')) return;
  if (
    some(MARK_SUFFIX_BLACKLIST, (suffix) => {
      return mark.name.endsWith(suffix);
    })
  ) {
    return;
  }

  window.DD_RUM?.addTiming(mark.name, perfTimeToDatadogTime(mark.startTime));
}

async function exportMeasureToDatadog(measure: PerformanceMeasure): Promise<void> {
  // Default Installed Chrome Extension started including hundreds of these performance
  // marks and measures and it's now littering our console.logs and datadog actions
  if (measure.name.includes('browser::collect_frame')) return;

  const payload: DatadogMeasureToActionPayload = {
    type: 'measure',
    duration: measure.duration,
    startTime: perfTimeToDatadogTime(measure.startTime),
  };
  window.DD_RUM?.addAction(measure.name, payload);
}

function datadogPerformanceObserver(list: PerformanceObserverEntryList): void {
  list.getEntries().forEach((entry) => {
    if (isMark(entry)) {
      exportMarkToDatadog(entry);
    } else if (isMeasure(entry)) {
      exportMeasureToDatadog(entry);
    }
  });
}

const observer = new PerformanceObserver(datadogPerformanceObserver);
observer.observe({ entryTypes: ['mark', 'measure'] });
