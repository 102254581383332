import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum FeedbackType {
  RegularFeedbackType = 'regular',
  SuggestionFeedbackType = 'suggestion',
  UrgentFeedbackType = 'urgent',
  NewFlowFeedbackType = 'new flow',
}

export interface OpenSupportDialogPayload {
  feedbackType: FeedbackType;
}

export interface SupportDialogState {
  isOpen: boolean;
  feedbackType: FeedbackType | null;
  isUrgent: boolean;
}

export interface SupportSliceState {
  supportDialog: SupportDialogState;
}

export const supportInitialState: SupportSliceState = {
  supportDialog: {
    isOpen: false,
    feedbackType: null,
    isUrgent: false,
  },
};

const supportSlice = createSlice({
  name: 'Support',
  initialState: supportInitialState,
  reducers: {
    openSupportDialog(state, action: PayloadAction<OpenSupportDialogPayload>): void {
      state.supportDialog.isOpen = true;
      state.supportDialog.feedbackType = action.payload.feedbackType;
    },
    closeSupportDialog(state): void {
      state.supportDialog.isOpen = false;
    },
    setSupportDialogUrgency(state, action: PayloadAction<boolean>): void {
      state.supportDialog.isUrgent = action.payload;
    },
  },
});

const { actions, reducer: supportReducer } = supportSlice;
const { openSupportDialog, closeSupportDialog, setSupportDialogUrgency } = actions;

export { supportReducer, openSupportDialog, closeSupportDialog, setSupportDialogUrgency };
