import { MutableRefObject, useEffect, useRef } from 'react';

/**
 * Hook that synchronizes a ref with the most recent version of the given value.
 *
 * This is useful when you need to access the latest value of something in callbacks,
 * event handlers, or other async code where the most current value may not be readily
 * available due to closures.
 *
 * @example
 * function MyComponent() {
 *   const [count, setCount] = useState(0);
 *   const countRef = useSyncedRef(value);
 *   useEffect(() => {
 *     setTimeout(() => {
 *       console.log('The initial count is ', count);
 *       console.log('The latest count is ', countRef.current);
 *     }, 5000);
 *   }, [countRef]);
 *   return (
 *     <button onClick={() => setCount(count + 1)}>Increment</button>
 *   );
 * }
 */
function useSyncedRef<T>(value: T): MutableRefObject<T> {
  const ref = useRef(value);

  useEffect(
    function syncRef() {
      ref.current = value;
    },
    [value],
  );

  return ref;
}

export default useSyncedRef;
