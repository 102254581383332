import PropTypes from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const physicianShape = PropTypes.shape({
  access_enterprise_id: PropTypes.number,
  cms_npi: PropTypes.string,
  // insurance_accepted: null,
  sex: PropTypes.string,
  city_state_zip: PropTypes.string,
  canonical_id: PropTypes.number,
  // model_class: 'Physician',
  full_name: PropTypes.string,
  id: PropTypes.number,
  city: PropTypes.string,
  user_id: PropTypes.number,
  zip: PropTypes.string,
  middleName: PropTypes.string,
  license_number: PropTypes.string,
  access_user_id: PropTypes.number,
  access_practice_practicing_emr: PropTypes.bool,
  state: PropTypes.string,
  dea_number: PropTypes.string,
  // accepting_insurance: null,
  access_practice_id: PropTypes.number,
  suite: PropTypes.string,
  email: PropTypes.string,
  // rx_auth_number: null,
  back_office_phone: PropTypes.string,
  fax: PropTypes.string,
  // dps_number: null,
  full_address: PropTypes.string,
  e_confirmed: PropTypes.bool,
  phone: PropTypes.string,
  dr_name: PropTypes.string,
  contact_email: PropTypes.string,
  access_practice_plr: PropTypes.bool,
  address: PropTypes.string,
  // direct_address: null,
  credentials: PropTypes.string,
  reverse_name: PropTypes.string,
  npi_number: PropTypes.string,
  firstName: PropTypes.string,
  mapping_state: PropTypes.string,
  dob: PropTypes.string,
  lastName: PropTypes.string,
  notes: PropTypes.string,
  contact_type: PropTypes.string,
  specialties: PropTypes.arrayOf(PropTypes.any),
  address_line: PropTypes.string,
  cell_phone: PropTypes.string,
  // accepting_new_patients: null,
});
