import React, { useCallback } from 'react';
import { IconSize, VdsIcon } from '@el8/vital';
import { FeedbackType, OpenSupportDialogPayload, useOpenSupportDialog } from 'ui-modules/support';
import { useOpenRestoreDeletedDocumentsDialog } from 'ui-modules/navbar';
import { logEvent } from 'utils/amplitude';
import CaseIntakeModal from 'views/support/case-intake/CaseIntakeModal';
import { logVisitTrainingCenter } from 'views/onboarding/amplitudeTaxonomy';
import { NavbarItem, NavbarItemDropdownProps } from '../../components/NavbarItem';
import styles from './INeedHelpNavItem.less';
import { RestoreDeletedDocumentsDialog } from '../../dialogs/RestoreDeletedDocumentsDialog';

const getMenuItems = ({
  openTourguide,
  openSupportDialog,
  openRestoreDeletedDocumentsDialog,
}: {
  openTourguide: VoidFunction;
  openRestoreDeletedDocumentsDialog: VoidFunction;
  openSupportDialog: (payload: OpenSupportDialogPayload) => void;
}): NavbarItemDropdownProps['items'] => {
  const isPracticeHome = el8Globals.APP === 'home';
  const isPatientChart = el8Globals.APP === 'chart';
  const isOnboarding = el8Globals.PAGE_NAME === 'onboarding';
  const isPassport = el8Globals.EMR_TYPE === 'passport';
  const isTrial = el8Globals.EMR_TYPE === 'trial';
  const isPhysician = el8Globals.USER_STAFF_TYPE === 'Physician';
  const isAdmin = el8Globals.IS_ADMIN;
  const isFullUser = !isPassport && !isTrial && !isOnboarding;

  const result: NavbarItemDropdownProps['items'] = [
    {
      'data-testid': 'navbar-ineedhelp-training-center-link',
      type: 'link',
      href: 'https://www.elationhealth.com/training-center/',
      text: 'Explore our Training Center',
      onClick: () => logVisitTrainingCenter('I need help dropdown'),
      icon: <VdsIcon icon="elation" size={IconSize.XSmall} />,
    },
    {
      'data-testid': 'navbar-ineedhelp-search-help-center-link',
      type: 'link',
      href: 'https://help.elationhealth.com/s/',
      text: 'Search our Help Center',
      icon: <VdsIcon icon="search" size={IconSize.XSmall} />,
    },
  ];

  if (!isOnboarding) {
    result.push({
      'data-testid': 'navbar-ineedhelp-elation-university-link',
      type: 'link',
      href: 'https://www.elationhealth.com/elation-university/',
      text: 'Learn on Elation University',
      icon: <VdsIcon icon="elation-university" size={IconSize.XSmall} />,
    });
  }

  if (isPatientChart || isPracticeHome) {
    result.push({
      'data-testid': 'navbar-ineedhelp-show-map-button',
      type: 'button',
      text: 'Show me a map of this page',
      icon: <VdsIcon icon="location" size={IconSize.XSmall} />,
      onClick: openTourguide,
      id: 'info-splash-trigger',
    });
  }

  if (el8Globals.FEATURES.SupportIntakeWithAI) {
    result.push({
      'data-testid': 'navbar-ineedhelp-regular-feedback-button',
      type: 'button',
      icon: <VdsIcon icon="message" size={IconSize.XSmall} />,
      text: 'Contact Elation Support',
      onClick: () => openSupportDialog({ feedbackType: FeedbackType.NewFlowFeedbackType }),
    });
  } else {
    result.push(
      {
        'data-testid': 'navbar-ineedhelp-suggestion-feedback-button',
        type: 'button',
        text: 'Send feedback or a suggestion to the Elation team',
        icon: <VdsIcon icon="favorite" size={IconSize.XSmall} />,
        onClick: () => openSupportDialog({ feedbackType: FeedbackType.SuggestionFeedbackType }),
        targetProps: {
          'data-feedback-type': FeedbackType.SuggestionFeedbackType,
        },
      },
      {
        'data-testid': 'navbar-ineedhelp-regular-feedback-button',
        type: 'button',
        text: 'I need help from an Elation Team Member',
        icon: <VdsIcon icon="user-account-filled" size={IconSize.XSmall} />,
        appearance: 'warning',
        bold: true,
        onClick: () => openSupportDialog({ feedbackType: FeedbackType.RegularFeedbackType }),
        targetProps: {
          'data-feedback-type': FeedbackType.RegularFeedbackType,
        },
      },
    );
  }

  if (el8Globals.FEATURES.CustomerCasePortal) {
    result.push({
      'data-testid': 'navbar-ineedhelp-case-portal-link',
      type: 'link',
      href: 'https://sso.app.elationemr.com/home/salesforce/0oai9au58pBfRE2H15d7/46',
      text: 'View existing support cases',
      icon: <VdsIcon icon="external-link" size={IconSize.XSmall} />,
    });
  }

  if (isPatientChart) {
    result.push({
      'data-testid': 'navbar-ineedhelp-deleted-note-button',
      type: 'button',
      text: 'I accidentally deleted a note or report',
      icon: <VdsIcon icon="warning-filled" size={IconSize.XSmall} />,
      bold: true,
      onClick: openRestoreDeletedDocumentsDialog,
    });
  }

  if (isFullUser && !el8Globals.FEATURES.SupportIntakeWithAI) {
    const disabled = !isAdmin && !isPhysician;
    const tooltip = disabled
      ? 'Only providers and staff admins can send urgent requests for help. Please contact your account admin.'
      : undefined;

    result.push({
      'data-testid': 'navbar-ineedhelp-urgent-feedback-button',
      type: 'button',
      text: "Urgent! I can't care for my patient",
      icon: <VdsIcon icon="warning-filled" size={IconSize.XSmall} />,
      appearance: 'danger',
      disabled,
      tooltip,
      bold: true,
      onClick: () => openSupportDialog({ feedbackType: FeedbackType.UrgentFeedbackType }),
      targetProps: {
        'data-feedback-type': FeedbackType.UrgentFeedbackType,
      },
    });
  }

  return result;
};

export const INeedHelpNavItem = (): JSX.Element | null => {
  const openSupportDialog = useOpenSupportDialog();

  const openRestoreDeletedDocumentsDialog = useOpenRestoreDeletedDocumentsDialog();

  const openTourguide = useCallback(() => {
    if (el8Globals.APP === 'home' && el8.tourguide?.homeInfoSplash) {
      el8.tourguide.homeInfoSplash.show();
    } else if (el8Globals.APP === 'chart' && el8.tourguide?.chartInfoSplash) {
      el8.tourguide.chartInfoSplash.show();
    }
  }, []);

  if (!el8Globals.IS_LAB_VENDOR && el8Globals.EMR_TYPE === 'passport') {
    return (
      <NavbarItem
        type="link"
        href="https://help.elationhealth.com/s/contactsupport"
        icon={<VdsIcon icon="help-filled" size={IconSize.XXSmall} className={styles.helpIcon} />}
        data-testid="navbar-ineedhelp-button"
      >
        I need help
      </NavbarItem>
    );
  }

  if (el8Globals.IS_LAB_VENDOR) {
    return null;
  }

  return (
    <>
      <NavbarItem
        type="dropdown"
        items={getMenuItems({
          openTourguide,
          openSupportDialog,
          openRestoreDeletedDocumentsDialog,
        })}
        icon={<VdsIcon icon="help-filled" size={IconSize.XXSmall} className={styles.helpIcon} />}
        data-testid="navbar-ineedhelp-button"
        onClick={(): void => logEvent('open: navigation help menu')}
      >
        I need help
      </NavbarItem>
      <CaseIntakeModal />
      <RestoreDeletedDocumentsDialog />
    </>
  );
};
