import React, { useEffect } from 'react';
import { FormikErrors, FormikContextType } from 'formik';
import usePrevious from './usePrevious';

type Options = {
  formRootRef?: React.MutableRefObject<HTMLElement>;
};

/**
 * Helper function that search for the field with error in an arbitrary, nested errors object
 */
export function getErrorFieldName<TValues>(
  errors: FormikErrors<Partial<TValues>>,
  basePath?: string,
): string {
  // eslint-disable-next-line no-restricted-syntax
  for (const fieldName in errors) {
    if (Object.prototype.hasOwnProperty.call(errors, fieldName)) {
      const error = errors[fieldName];

      if (error) {
        const fullFieldName = basePath ? `${basePath}.${fieldName}` : fieldName;

        if (Array.isArray(error) && error.length > 0) {
          for (let index = 0; index < error.length; index++) {
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | FormikErrors<Partial<TV... Remove this comment to see the full error message
            const _fieldName = getErrorFieldName(error[index], `${fullFieldName}[${index}]`);

            if (_fieldName) {
              return _fieldName;
            }
          }
        } else if (typeof error === 'object') {
          const _fieldName = getErrorFieldName(error as object, fullFieldName);

          if (_fieldName) {
            return _fieldName;
          }
        } else {
          return fullFieldName;
        }
      }
    }
  }

  // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
  return undefined;
}

/**
 * A custom hook for implementing functionality where, when a form that has errors
 * is submitted, the first input that has an error will be focused.
 *
 * In order for this hook to work with nested forms with arrays of fields, make sure, you use
 * `array[index]` notation as array fields name
 *
 * @param formikContext - the formik context
 * @param isValidating - the `isValidating` value directly from Formik
 * @param [options.formRootRef] - by default, when triggering focus, this hook performs a
 * selector query using `document` as the root, to find the input with the correct name.
 * You can pass in an HTML element ref here to use that as the selector root instead of
 * `document`, which is HIGHLY recommended when you have a page with many forms,
 * because the chance of duplicate field names becomes high.
 */
export default function useSubmitErrorFocus<TValues>(
  formikContext: FormikContextType<TValues>,
  isValidating: boolean,
  options: Options = {},
): void {
  const { formRootRef } = options;

  const prevIsValidating = usePrevious(isValidating);

  useEffect(() => {
    if (prevIsValidating && !isValidating) {
      const errorFieldName = getErrorFieldName(formikContext.errors);
      if (!errorFieldName) return;

      const fieldToFocus = (formRootRef?.current || document).querySelector<HTMLInputElement>(
        `input[name="${errorFieldName}"], textarea[name="${errorFieldName}"]`,
      );
      if (fieldToFocus) {
        formikContext.setFieldTouched(errorFieldName, true);
        fieldToFocus.focus();
      } else {
        console.warn(
          `Tried to focus field \`${errorFieldName}\`, but no input with that name was found.`,
        );
      }
    }
  }, [formRootRef, formikContext, isValidating, prevIsValidating]);
}
