exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  --vds-navigation-height: 2rem;\n}\n.ElationLogoCircle__li___1hDLT {\n  align-self: flex-start;\n  align-items: stretch;\n  display: flex;\n  margin-inline-end: var(--vds-space-xs);\n  height: calc(var(--vds-navigation-height) * 1.125);\n  width: calc(var(--vds-navigation-height) * 1.125);\n  z-index: 2;\n}\na.ElationLogoCircle__a___3Q4ep {\n  --vds-elation-badge-surface: transparent;\n  border-radius: var(--vds-border-radius-max);\n  background: var(--vds-navigation-surface);\n  background-color: var(--vds-navigation-surface);\n}\na.ElationLogoCircle__a___3Q4ep:visited,\na.ElationLogoCircle__a___3Q4ep a:link {\n  background-color: var(--vds-navigation-surface);\n}\na.ElationLogoCircle__a___3Q4ep:focus-visible {\n  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.24);\n  outline: 3px solid transparent;\n}\n.ElationLogoCircle__svg___2Yx_h {\n  height: calc(var(--vds-navigation-height) * 1.125);\n  width: calc(var(--vds-navigation-height) * 1.125);\n}\n", ""]);

// exports
exports.locals = {
	"li": "ElationLogoCircle__li___1hDLT",
	"a": "ElationLogoCircle__a___3Q4ep",
	"svg": "ElationLogoCircle__svg___2Yx_h"
};