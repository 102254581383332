import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Patient } from 'EntityTypes';
import { DemographicsScrollSection } from './demographicsSliceConstants';

export type DemographicsSliceState = {
  createPatientDialog: {
    initialName: string | null;
    isOpen: boolean;
    onSuccess: ((patient: Patient) => void) | null;
  };
  editPatientDialog: {
    checkinAppointmentId: number | null;
    initialScrollTo: DemographicsScrollSection | null;
    isOpen: boolean;
    onSuccess: ((patient: Patient) => void) | null;
    patientId: number | null;
  };
};

const initialState: DemographicsSliceState = {
  createPatientDialog: {
    initialName: null,
    isOpen: false,
    onSuccess: null,
  },
  editPatientDialog: {
    checkinAppointmentId: null,
    initialScrollTo: null,
    isOpen: false,
    onSuccess: null,
    patientId: null,
  },
};

const slice = createSlice({
  name: 'demographics',
  initialState,
  reducers: {
    openCreatePatientDialog(
      state,
      // `initialName` is not actually required, but we need to make it required for now or
      // Typescript will barf attempting to correctly use redux toolkit's typings (the type of
      // the resulting action creator's payload will be `unknown`). Turning on Typescript's
      // strict mode will fix this issue, but that's a large effort and will be done separately
      // (but very hopefully soon).
      action?: PayloadAction<{ initialName: string; onSuccess?: (patient: Patient) => void }>,
    ): void {
      state.createPatientDialog.isOpen = true;
      state.createPatientDialog.initialName = action?.payload?.initialName || null;
      state.createPatientDialog.onSuccess = action?.payload?.onSuccess || null;
    },
    closeCreatePatientDialog(state): void {
      state.createPatientDialog.isOpen = false;
      state.createPatientDialog.initialName = null;
      state.createPatientDialog.onSuccess = null;
    },
    openEditPatientDialog(
      state,
      action: PayloadAction<{
        patientId: number;
        checkinAppointmentId?: number | null;
        onSuccess?: (patient: Patient) => void;
        initialScrollTo?: DemographicsScrollSection;
      }>,
    ): void {
      state.editPatientDialog.checkinAppointmentId = action.payload.checkinAppointmentId || null;
      state.editPatientDialog.initialScrollTo = action.payload.initialScrollTo || null;
      state.editPatientDialog.isOpen = true;
      state.editPatientDialog.onSuccess = action.payload.onSuccess || null;
      state.editPatientDialog.patientId = action.payload.patientId;
    },
    closeEditPatientDialog(state): void {
      state.editPatientDialog.checkinAppointmentId = null;
      state.editPatientDialog.initialScrollTo = null;
      state.editPatientDialog.isOpen = false;
      state.editPatientDialog.onSuccess = null;
      state.editPatientDialog.patientId = null;
    },
  },
});

const { actions, reducer: demographicsReducer } = slice;
const {
  openCreatePatientDialog,
  closeCreatePatientDialog,
  openEditPatientDialog,
  closeEditPatientDialog,
} = actions;

export {
  demographicsReducer,
  openCreatePatientDialog,
  closeCreatePatientDialog,
  openEditPatientDialog,
  closeEditPatientDialog,
};
