exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  --vds-navigation-surface: var(--vds-elation-blue);\n  --vds-navigation-surface-hover: rgba(255, 255, 255, 0.16);\n  --vds-navigation-color: rgba(255, 255, 255, 0.88);\n  --vds-navigation-font-size: 0.8125rem;\n  --vds-navigation-height: 2rem;\n}\n.Navbar__base___27HCS {\n  background-color: var(--vds-navigation-surface);\n  display: flex;\n  height: var(--vds-navigation-height);\n  padding-inline: var(--vds-space-xs);\n  position: relative;\n  z-index: 5;\n}\n.Navbar__ul___2TzxL {\n  display: flex;\n  list-style: none;\n  margin: 0;\n  padding: 0;\n  justify-content: space-between;\n  width: 100%;\n  min-width: 0px;\n}\n.Navbar__ulSection___6Obg5 {\n  align-items: center;\n  display: flex;\n  list-style: none;\n  margin: 0;\n  padding: 0;\n  min-width: 0px;\n}\n", ""]);

// exports
exports.locals = {
	"base": "Navbar__base___27HCS",
	"ul": "Navbar__ul___2TzxL",
	"ulSection": "Navbar__ulSection___6Obg5"
};