import { Spinner2, UnavailableContent } from '@el8/vital';
import React from 'react';

export const RestoreDeletedDocumentsDialogUnavailableContent = ({
  loading,
  empty,
  error,
}: {
  loading: boolean;
  empty: boolean;
  error: boolean;
}): JSX.Element | null => {
  if (loading) {
    return (
      <UnavailableContent
        description="Retrieving deleted documents..."
        icon={<Spinner2 sizing="lg" />}
      />
    );
  }

  if (error) {
    return (
      <UnavailableContent
        description="There was an issue retrieving this patient's deleted documents. Please contact Elation Support if the issue persists."
        icon="unavailable"
      />
    );
  }

  if (empty) {
    return (
      <UnavailableContent
        description="This patient has no deleted notes or reports."
        icon="unavailable"
      />
    );
  }

  return null;
};
