import PropTypes from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const practiceUserShape = PropTypes.shape({
  lastName: PropTypes.string,
  isRegistered: PropTypes.bool,
  // defaultErxServiceLocationId: null,
  // defaultLabFacilityId: null,
  primaryPrintHeaderId: PropTypes.number,
  unreadReferralNotifications: PropTypes.number,
  isActive: PropTypes.bool,
  cdsEnabled: PropTypes.bool,
  isElationStaff: PropTypes.bool,
  defaultPhysicianUserId: PropTypes.number,
  staffType: PropTypes.oneOf(['Physician', 'OfficeStaff']),
  isAdmin: PropTypes.bool,
  delegatingPhysicianIdsMap: PropTypes.shape({
    rxs: PropTypes.arrayOf(PropTypes.number),
    bills: PropTypes.arrayOf(PropTypes.number),
    orders: PropTypes.arrayOf(PropTypes.number),
  }),
  isCredentialDocumentsUploaded: PropTypes.bool,
  isPracticeAuthenticated: PropTypes.bool,
  // directAddress: null,
  fullName: PropTypes.string,
  isCredentialed: PropTypes.bool,
  id: PropTypes.number,
  unreadProviderLetterNotifications: PropTypes.number,
  firstName: PropTypes.string,
  showDualCodingEnabled: PropTypes.bool,
  defaultStaffGroupId: PropTypes.number,
  emailVerified: PropTypes.bool,
  showCdsDrugAllergyAlert: PropTypes.bool,
  cdsDisplaySupDiag: PropTypes.bool,
  cdsMeasuresEnabled: PropTypes.arrayOf(PropTypes.string),
  structuredAssessmentsEnabled: PropTypes.bool,
  emailNotifications: PropTypes.string,
  allowStaffToSendReferrals: PropTypes.bool,
  unreadPatientLetterNotifications: PropTypes.number,
  isPracticeAdmin: PropTypes.bool,
  staffId: PropTypes.number,
  // defaultLabVendorId: null,
  // modelClass: 'User',
  pushNotifications: PropTypes.bool,
  cdsDrugAllergyAlertLevel: PropTypes.number,
  email: PropTypes.string,
  cdsDrugDrugAlertLevel: PropTypes.number,
});
