import { STAFF_TYPES } from 'constants/index';
import { PracticeUser } from 'EntityTypes';
import {
  PhysicianUserAccountDetailsRequestBody,
  PhysicianUserAccountDetailsRequestBodySingleState,
} from 'QueryTypes';

export default {
  /**
   * Returns true if the given practice user is a physician, and false otherwise.
   *
   */
  isPhysician(practiceUser: PracticeUser): boolean {
    return practiceUser.staffType === STAFF_TYPES.PHYSICIAN;
  },

  isStaff(practiceUser: PracticeUser): boolean {
    return practiceUser.staffType === STAFF_TYPES.STAFF;
  },

  getProfilePhotoURL(currentUserId: number): string {
    let url = '/user/';
    url += currentUserId;
    url += '/photo/profile/125/125/';

    return url;
  },

  constructProfilePhotoFormData(image: File): FormData {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'FormDa... Remove this comment to see the full error message
    if (!image) return undefined;
    const formData = new FormData();
    formData.append('image', image);
    return formData;
  },

  constructPhysicianAccountDetailsFormData(body: PhysicianUserAccountDetailsRequestBody): FormData {
    const formData = new FormData();

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(key: keyof PhysicianUserAccount... Remove this comment to see the full error message
    Object.keys(body).forEach((key: keyof PhysicianUserAccountDetailsRequestBody) => {
      if (key === 'form') {
        formData.append('form', JSON.stringify(body.form));
      } else {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'File | undefined' is not assigna... Remove this comment to see the full error message
        formData.append(key, body[key]);
      }
    });

    return formData;
  },
  constructPhysicianAccountDetailsFormDataSingleState(
    body: PhysicianUserAccountDetailsRequestBodySingleState,
  ): FormData {
    const formData = new FormData();

    Object.keys(body).forEach((key) => {
      if (key === 'form') {
        formData.append('form', JSON.stringify(body.form));
      } else {
        formData.append(
          key,
          body[key as Exclude<keyof PhysicianUserAccountDetailsRequestBodySingleState, 'form'>] ||
            '',
        );
      }
    });

    return formData;
  },
};
