import { schema } from 'normalizr';

import { physicianSchema } from 'modules/physicians';
import { visitNoteTemplateSchema } from 'modules/visit-note-templates';
import { visitNoteTypeSchema } from 'modules/visit-note-types';
import { AppointmentTypeRaw, AppointmentTypeNormalized } from 'EntityTypes';

import { patientFormSchema } from 'modules/patient-forms';
import { pseudoNormalize } from './utils';

export const appointmentTypeSchema = new schema.Entity(
  'appointmentTypes',
  {
    bookable_physicians: [physicianSchema],
    patient_forms: [patientFormSchema],
    visit_note_templates: [visitNoteTemplateSchema],
    visit_note_type: visitNoteTypeSchema,
  },
  {
    processStrategy(entity: AppointmentTypeRaw): AppointmentTypeNormalized {
      return pseudoNormalize(entity) as AppointmentTypeNormalized;
    },
  },
);
