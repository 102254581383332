import isEmail from 'validator/lib/isEmail';
import isString from 'lodash/isString';

export const email = (value, errorMessage = 'Invalid email address') =>
  value && !isEmail(String(value)) ? errorMessage : undefined;

export const required = (value, errorMessage = true) =>
  value === '' ||
  value === null ||
  value === undefined ||
  value === false ||
  (isString(value) && !value.trim())
    ? errorMessage
    : undefined;

const isValidationFunctionArray = (rule) => rule instanceof Array;

export const createValidator = (rules) => (values) => {
  const errors = Object.keys(rules).reduce((cumulativeErrors, key) => {
    const value = values[key];
    const keyRule = rules[key];
    if (isValidationFunctionArray(keyRule)) {
      const ruleIndex = keyRule.findIndex((rule) => !!rule(value));
      if (ruleIndex !== -1) {
        /* eslint-disable no-param-reassign */
        cumulativeErrors[key] = keyRule[ruleIndex](value);
      }
    } else {
      /* eslint-disable no-param-reassign */
      cumulativeErrors[key] = keyRule(value);
    }
    return cumulativeErrors;
  }, {});

  return Object.values(errors).some((error) => !!error) ? errors : {};
};
