exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ExportEmailHome__sendButton___3PMxE {\n  margin-top: 12px;\n}\n", ""]);

// exports
exports.locals = {
	"sendButton": "ExportEmailHome__sendButton___3PMxE"
};