import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { normalize } from 'normalizr';

import { NoteTemplate, VisitNoteTemplate } from 'EntityTypes';
import {
  AppQueryConfig,
  CreateNoteTemplateRequestVariables,
  CreateNoteTemplateResponseBody,
  DeleteNoteTemplateRequestVariables,
  GetNoteTemplateResponseBody,
  GetNoteTemplatesResponseBody,
  GetVisitNoteTemplatesResponseBody,
  UpdateNoteTemplateRequestVariables,
  UpdateNoteTemplateResponseBody,
} from 'QueryTypes';
import { EntitiesById } from 'StoreTypes';
import { getQueryUrl, jsonFetch } from 'utils/react-query';
import { makeUpdateFn } from 'utils/redux-query';

import { visitNoteTemplateSchema } from './visitNoteTemplatesSchemas';

type QueryConfigOptions = {
  ignoreWorkspace?: boolean;
};

const urls = {
  visitNoteTemplates(practiceId: number, options: QueryConfigOptions = {}): string {
    const { ignoreWorkspace } = options;
    const args = ignoreWorkspace ? `?ignore_workspace=${ignoreWorkspace}` : '';
    return `/practice/${practiceId}/visit-note-templates/${args}`;
  },
};

const queryKeys = {
  // Visit Note 1.0
  exportVisitNoteTemplate: (patientId: number, noteId: number, templateIds: number[]): QueryKey => [
    'patient',
    patientId,
    'visit-notes',
    noteId,
    'visit-note-templates',
    templateIds.join(';'),
  ],
  visitNoteTemplates: (practiceId: MaybeNullishId): QueryKey => [
    'practice',
    practiceId,
    'visit-note-templates',
  ],

  // Note 2.0
  noteTemplates: (practiceId: MaybeNullishId): QueryKey => [
    'gw',
    'visit-notes-api',
    'practices',
    practiceId,
    'note-templates',
  ],

  noteTemplateDetail: (practiceId: MaybeNullishId, noteTemplateId: MaybeNullishId): QueryKey => [
    ...queryKeys.noteTemplates(practiceId),
    noteTemplateId,
  ],
};

/**
 * Fetch visit note templates for the specified practice.
 *
 * FUTURE: deprecate in favor of `useGetVisitNoteTemplates`. Main blocker
 * right now is that this is being used by a few class components, which
 * can't use hooks.
 */
// eslint-disable-next-line import/prefer-default-export
export const visitNoteTemplatesQuery = (
  practiceId: number,
  options: QueryConfigOptions = {},
): AppQueryConfig => ({
  url: urls.visitNoteTemplates(practiceId, options),
  transform: (
    responseJson: VisitNoteTemplate[],
  ): { visitNoteTemplates: EntitiesById<VisitNoteTemplate> } =>
    normalize(responseJson, [visitNoteTemplateSchema]).entities,
  update: {
    visitNoteTemplates: makeUpdateFn<VisitNoteTemplate>({ merge: false }),
  },
});

/**
 * Fetches and returns the list of `VisitNoteTemplates` for the specified
 * practice. If the current user is in a workspace, this list will be
 * narrowed to just the templates in that workspace.
 */
export function useGetVisitNoteTemplates(
  practiceId: MaybeNullishId,
  options?: UseQueryOptions<GetVisitNoteTemplatesResponseBody>,
): UseQueryResult<GetVisitNoteTemplatesResponseBody> {
  return useQuery({
    queryKey: queryKeys.visitNoteTemplates(practiceId),
    ...options,
    enabled: Boolean(practiceId) && options?.enabled !== false,
  });
}

/**
 * Returns a mutation for exporting a list of visit note templates to a visit
 * note.
 */
export function useExportVisitNoteTemplate() {
  return useMutation({
    mutationFn(variables: { patientId: number; noteId: number; templateIds: number[] }) {
      const { noteId, patientId, templateIds } = variables;
      const url = getQueryUrl(queryKeys.exportVisitNoteTemplate(patientId, noteId, templateIds));
      return jsonFetch(url, { method: 'POST' });
    },
  });
}

/**
 * Fetches and returns the list of `NoteTemplate`s for the specified practice.
 */
export function useGetNoteTemplates(
  practiceId: MaybeNullishId,
): UseQueryResult<GetNoteTemplatesResponseBody> {
  return useQuery({
    queryKey: queryKeys.noteTemplates(practiceId),
    enabled: Boolean(practiceId),
    meta: { trailingSlashUrl: false },
  });
}

/**
 * Fetches and returns the specified `NoteTemplate`.
 */
export function useGetNoteTemplate(
  practiceId: MaybeNullishId,
  noteTemplateId: MaybeNullishId,
): UseQueryResult<GetNoteTemplateResponseBody> {
  return useQuery({
    queryKey: queryKeys.noteTemplateDetail(practiceId, noteTemplateId),
    enabled: Boolean(practiceId && noteTemplateId),
    meta: { trailingSlashUrl: false },
  });
}

/**
 * An imperative version of `useGetNoteTemplate`. Returns a function that can
 * be used to imperatively fetch the specified `NoteTemplate`.
 *
 * Only use this if you know you need imperative fetching and understand why
 * declarative fetching will NOT work for your use case; otherwise, use the
 * standard `useGetNoteTemplate` instead.
 */
export function useGetNoteTemplateImperative(): (
  practiceId: number,
  noteTemplateId: number,
) => Promise<NoteTemplate> {
  const queryClient = useQueryClient();

  const getNoteTemplateImperative = async (
    practiceId: number,
    noteTemplateId: number,
  ): Promise<NoteTemplate> => {
    const data = await queryClient.fetchQuery<GetNoteTemplateResponseBody>({
      queryKey: queryKeys.noteTemplateDetail(practiceId, noteTemplateId),
      meta: { trailingSlashUrl: false },
    });

    return data;
  };

  return getNoteTemplateImperative;
}

/**
 * Returns a mutation to create a new `NoteTemplate`.
 */
export function useCreateNoteTemplate() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn(variables: CreateNoteTemplateRequestVariables) {
      const { body, practiceId } = variables;
      const url = getQueryUrl(queryKeys.noteTemplates(practiceId), { trailingSlashUrl: false });
      return jsonFetch<CreateNoteTemplateResponseBody>(url, {
        body,
        method: 'POST',
      });
    },
    onSuccess(data, { practiceId }) {
      queryClient.invalidateQueries({ queryKey: queryKeys.noteTemplates(practiceId) });
      queryClient.setQueryData(queryKeys.noteTemplateDetail(practiceId, data.id), data);
    },
  });
}

/**
 * Returns a mutation to update the specified `NoteTemplate`.
 */
export function useUpdateNoteTemplate() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn(variables: UpdateNoteTemplateRequestVariables) {
      const { body, practiceId, templateId } = variables;
      const url = getQueryUrl(queryKeys.noteTemplateDetail(practiceId, templateId), {
        trailingSlashUrl: false,
      });
      return jsonFetch<UpdateNoteTemplateResponseBody>(url, {
        body,
        method: 'PATCH',
      });
    },
    onSuccess(data, { practiceId, templateId }) {
      queryClient.invalidateQueries({ queryKey: queryKeys.noteTemplates(practiceId) });
      queryClient.setQueryData(queryKeys.noteTemplateDetail(practiceId, templateId), data);
    },
  });
}

/**
 * Returns a mutation to delete the specified `NoteTemplate`.
 */
export function useDeleteNoteTemplate() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn(variables: DeleteNoteTemplateRequestVariables) {
      const { practiceId, templateId } = variables;
      const url = getQueryUrl(queryKeys.noteTemplateDetail(practiceId, templateId), {
        trailingSlashUrl: false,
      });
      return jsonFetch(url, { method: 'DELETE' });
    },
    onSuccess(_data, { practiceId, templateId }) {
      queryClient.invalidateQueries({ queryKey: queryKeys.noteTemplates(practiceId) });
      queryClient.removeQueries(queryKeys.noteTemplateDetail(practiceId, templateId));
    },
  });
}
