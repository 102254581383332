import {
  WorkspaceFeFilteredResourceTypenameArray,
  WorkspaceFrontendFiltersState,
} from './currentWorkspaceFiltersTypes';

export const buildWorkspaceFiltersInitialState: () => WorkspaceFrontendFiltersState = () => {
  const ret = {
    filterSets: {} as { [key: string]: Set<number> },
    filterToggles: {} as { [key: string]: boolean },
  };
  WorkspaceFeFilteredResourceTypenameArray.forEach((key) => {
    ret.filterSets[key] = new Set<number>();
    ret.filterToggles[key] = false;
  });
  return ret as WorkspaceFrontendFiltersState;
};
