import { VisitNoteType } from 'EntityTypes';
import { AppQueryConfig, GetVisitNoteTypesOptions } from 'QueryTypes';
import { EntitiesById } from 'StoreTypes';
import { makeUpdateFn } from 'utils/redux-query';
import { mapArrayToObject } from 'utils/arrays';
import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

type NullableNumber = number | null | undefined;

const urls = {
  visitNoteTypes(practiceId: number): string {
    return `/practice/${practiceId}/visit-note-types/`;
  },
};

const queryKeys = {
  visitNoteTypes: (practiceId: NullableNumber, options?: GetVisitNoteTypesOptions): QueryKey => {
    return ['practice', practiceId, 'visit-note-types', options];
  },
  visitNoteTypeDetail: (practiceId: NullableNumber, noteTypeId: NullableNumber): QueryKey => [
    ...queryKeys.visitNoteTypes(practiceId),
    noteTypeId,
  ],
};

/**
 * Fetch all visit note types for the specified practice.
 */
export const visitNoteTypesQuery = (practiceId: number): AppQueryConfig => ({
  url: urls.visitNoteTypes(practiceId),
  transform: (responseJson: VisitNoteType[]): { visitNoteTypes: EntitiesById<VisitNoteType> } => ({
    visitNoteTypes: mapArrayToObject(responseJson, 'id'),
  }),
  update: {
    visitNoteTypes: makeUpdateFn<VisitNoteType>({ merge: false }),
  },
});

/**
 * Fetches and returns the specified visit note type. This can be used to
 * fetch deleted visit note types.
 */
export const useGetVisitNoteType = (
  practiceId: NullableNumber,
  noteTypeId: NullableNumber,
  options?: UseQueryOptions<VisitNoteType>,
): UseQueryResult<VisitNoteType> => {
  return useQuery({
    queryKey: queryKeys.visitNoteTypeDetail(practiceId, noteTypeId),
    ...options,
    enabled: Boolean(practiceId && noteTypeId) && options?.enabled !== false,
  });
};

/**
 * Fetches and returns the list of visit note types for a given practice.
 * also optional filter by visit note types for patient
 */
export const useGetVisitNoteTypes = (
  practiceId: NullableNumber,
  options?: GetVisitNoteTypesOptions,
): UseQueryResult<VisitNoteType[]> => {
  return useQuery({
    queryKey: queryKeys.visitNoteTypes(practiceId, options),
    enabled: Boolean(practiceId),
  });
};
