import { QueryState } from 'redux-query';
/**
 * Returns true if any of the `QueryState`s passed in are in a pending state.
 */
export function isAnyQueryPending(...queryStates: QueryState[]): boolean {
  return queryStates.some((state) => state.isPending);
}

/**
 * Generates a random ID that should only be temporarily used
 * for optimistic creates. ID is negative to distinguish it from
 * the normal positive Elation Entity IDs. After the API response
 * comes back, care should be taken to remove this ID and update
 * it to the real one.
 */
export function createOptimisticId(): number {
  return Math.ceil(Math.random() * 100000000) * -1;
}

/**
 * Elation Entity IDs are always positive. Optimistically created
 * entities will have ids in the negatives to distinguish them from
 * true entities as they exist from the Server.
 * @param id An Elation Entity ID
 */
export function isOptimisticId(id: number): boolean {
  return id < 0;
}

/**
 * Creates a new QueryState based on a list of input QueryStates.
 * That is a query state that is:
 *  - Finished when ALL input QueryState's are finished
 *  - Pending when ANY input QueryState's are pending
 * @param qs List of QueryStates to aggregate
 */
export function aggregateQueryState(qs: QueryState[]): QueryState {
  const queryState: QueryState = {
    isFinished: qs.every((q) => q.isFinished),
    isPending: qs.some((q) => q.isPending),
  };
  return queryState;
}
