import { VisitNoteTemplate } from 'EntityTypes';
import { UseDataInfo } from 'QueryTypes';
import { useRequest } from 'redux-query-react';
import { factoryUseReferencesInWorkspace, ReferenceEdgeType } from 'modules/workspaces';
import { useCurrentPractice } from 'modules/practice';
import { useAppSelector } from 'utils/hooks';
import { getVisitNoteTemplates, visitNoteTemplatesQuery } from '.';

export const useVisitNoteTemplates = (): UseDataInfo<VisitNoteTemplate[]> => {
  const visitNoteTemplates = useAppSelector((state) => getVisitNoteTemplates(state));
  const practice = useCurrentPractice();
  const [queryState, refreshVisitNoteTemplatesSettings] = useRequest(
    visitNoteTemplatesQuery(practice.id, { ignoreWorkspace: true }),
  );
  return [visitNoteTemplates, queryState, refreshVisitNoteTemplatesSettings];
};

/**
 * Fetches and Selects the Visit Note Templates that have References to a specific
 * Workspace.
 *
 * Follow to factoryUseReferencesInWorkspace for comments on how it is done
 */
export const useVisitNoteTemplateReferencesInWorkspace =
  factoryUseReferencesInWorkspace<VisitNoteTemplate>(
    'VisitNoteTemplate',
    useVisitNoteTemplates,
    ReferenceEdgeType.PracticeWorkspaceFilter,
  );
