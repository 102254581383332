import { useMemo } from 'react';
import { useRequest } from 'redux-query-react';

import { UseDataInfo, AppQueryConfig } from 'QueryTypes';
import { RootState } from 'StoreTypes';

import useAppSelector from './useAppSelector';

// FUTURE: figure out how to properly test this... there are a lot of moving parts
/**
 * A general hook that standardizes the fetching and reading of data dependent on a single key
 * (usually, this key is an ID of something or someone).
 *
 * Sample usage (with not necessarily actual functions):
 * ```
 * useDataByKey(patientId, patientQuery, getPatient)
 * ```
 *
 * @param key - the key, e.g. a patient ID or user ID. Accepts `null` and `undefined` as
 * indicators that this hook should not attempt to fetch data or select data from the store,
 * since hooks cannot be conditionally called.
 * @param queryConfigFn - a query config function that accepts a single argument, `key`
 * @param selectorFn - a selector function that accepts two arguments, `state` and `key`,
 * in that order.
 */
export default function useDataByKey<TKey extends string | number, TSelectorValue>(
  key: TKey | null | undefined,
  queryConfigFn: (key: TKey) => AppQueryConfig,
  selectorFn: (state: RootState, key: TKey) => TSelectorValue,
): UseDataInfo<TSelectorValue | null> {
  const [queryState, refresh] = useRequest(key ? queryConfigFn(key) : null);

  let data = useAppSelector((state) => (key ? selectorFn(state, key) : null));
  if (data === undefined && queryState.isFinished) {
    data = null;
  }

  return [data, queryState, refresh];
}

/**
 * A general hook that standardizes the fetching and reading of data dependent on a single key
 * (usually, this key is an ID of something or someone). This is a variant of `useDataByKey`
 * that works with selector factories like "makeGetUser" as opposed to plain selectors like
 * "getUser".
 *
 * Sample usage (with not necessarily actual functions):
 * ```
 * useDataByKey(patientId, patientQuery, makeGetPatient)
 * ```
 *
 * @param key - the key, e.g. a patient ID or user ID. Accepts `null` and `undefined` as
 * indicators that this hook should not attempt to fetch data or select data from the store,
 * since hooks cannot be conditionally called.
 * @param queryConfigFn - a query config function that accepts a single argument, `key`
 * @param selectorFn - a selector factory function that returns a function that accepts two arguments,
 * `state` and `key`, in that order.
 */
export function useDataByKeyWithSelectorFactory<TKey extends string | number, TSelectorValue>(
  key: TKey | null | undefined,
  queryConfigFn: (key: TKey) => AppQueryConfig,
  makeSelectorFn: () => (state: RootState, key: TKey) => TSelectorValue,
): UseDataInfo<TSelectorValue | null> {
  const [queryState, refresh] = useRequest(key ? queryConfigFn(key) : null);

  const selectorFn = useMemo(() => makeSelectorFn(), [makeSelectorFn]);
  let data = useAppSelector((state) => (key ? selectorFn(state, key) : null));
  if (data === undefined && queryState.isFinished) {
    data = null;
  }

  return [data, queryState, refresh];
}
