/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Support Intake and Communication
 * This API defines the endpoints dealing with Customer feedback/support requests.

 * OpenAPI spec version: 0.1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type { Answer, InitQuestion, CaseCreation, Vendors, VendorForm } from './models';
import { customInstance } from '../core/customInstance';
import type { ErrorType } from '../core/customInstance';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const postSupportAskQuestion = (
  initQuestion: InitQuestion,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Answer>(
    {
      url: `/support/ask-question`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: initQuestion,
    },
    options,
  );
};

export const getPostSupportAskQuestionMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSupportAskQuestion>>,
    TError,
    { data: InitQuestion },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSupportAskQuestion>>,
  TError,
  { data: InitQuestion },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSupportAskQuestion>>,
    { data: InitQuestion }
  > = (props) => {
    const { data } = props ?? {};

    return postSupportAskQuestion(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSupportAskQuestionMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSupportAskQuestion>>
>;
export type PostSupportAskQuestionMutationBody = InitQuestion;
export type PostSupportAskQuestionMutationError = ErrorType<unknown>;

export const usePostSupportAskQuestion = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSupportAskQuestion>>,
    TError,
    { data: InitQuestion },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostSupportAskQuestionMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postSupportCreateCase = (
  caseCreation: CaseCreation,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/support/create-case`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: caseCreation,
    },
    options,
  );
};

export const getPostSupportCreateCaseMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSupportCreateCase>>,
    TError,
    { data: CaseCreation },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSupportCreateCase>>,
  TError,
  { data: CaseCreation },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSupportCreateCase>>,
    { data: CaseCreation }
  > = (props) => {
    const { data } = props ?? {};

    return postSupportCreateCase(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSupportCreateCaseMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSupportCreateCase>>
>;
export type PostSupportCreateCaseMutationBody = CaseCreation;
export type PostSupportCreateCaseMutationError = ErrorType<unknown>;

export const usePostSupportCreateCase = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSupportCreateCase>>,
    TError,
    { data: CaseCreation },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostSupportCreateCaseMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Returns a list of all integration vendors available for customers to request an integration with.
 */
export const getIntegrationVendors = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Vendors>(
    { url: `/support/integration-vendors`, method: 'get', signal },
    options,
  );
};

export const getGetIntegrationVendorsQueryKey = () => [`/support/integration-vendors`] as const;

export const getGetIntegrationVendorsQueryOptions = <
  TData = Awaited<ReturnType<typeof getIntegrationVendors>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getIntegrationVendors>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<Awaited<ReturnType<typeof getIntegrationVendors>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetIntegrationVendorsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getIntegrationVendors>>> = ({ signal }) =>
    getIntegrationVendors(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetIntegrationVendorsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIntegrationVendors>>
>;
export type GetIntegrationVendorsQueryError = ErrorType<unknown>;

export const useGetIntegrationVendors = <
  TData = Awaited<ReturnType<typeof getIntegrationVendors>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getIntegrationVendors>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetIntegrationVendorsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Returns the dynamic form for the specified vendor
 */
export const getIntegrationVendorForm = (
  vendor: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<VendorForm>(
    { url: `/support/integration-vendors/${vendor}`, method: 'get', signal },
    options,
  );
};

export const getGetIntegrationVendorFormQueryKey = (vendor: string) =>
  [`/support/integration-vendors/${vendor}`] as const;

export const getGetIntegrationVendorFormQueryOptions = <
  TData = Awaited<ReturnType<typeof getIntegrationVendorForm>>,
  TError = ErrorType<unknown>,
>(
  vendor: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getIntegrationVendorForm>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<Awaited<ReturnType<typeof getIntegrationVendorForm>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetIntegrationVendorFormQueryKey(vendor);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getIntegrationVendorForm>>> = ({
    signal,
  }) => getIntegrationVendorForm(vendor, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!vendor, ...queryOptions };
};

export type GetIntegrationVendorFormQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIntegrationVendorForm>>
>;
export type GetIntegrationVendorFormQueryError = ErrorType<unknown>;

export const useGetIntegrationVendorForm = <
  TData = Awaited<ReturnType<typeof getIntegrationVendorForm>>,
  TError = ErrorType<unknown>,
>(
  vendor: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getIntegrationVendorForm>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetIntegrationVendorFormQueryOptions(vendor, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
