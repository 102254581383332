import { useRequest } from 'redux-query-react';
import { getCurrentUser } from 'modules/practice-users';
import { getCurrentUserPractices, userPracticesQuery } from 'modules/user-practices';
import { Practice } from 'EntityTypes';
import { UseDataInfo } from 'QueryTypes';
import { useAppSelector } from 'utils/hooks';

export const useCurrentUserPractices = (options?: {
  limit?: number;
  offset?: number;
}): UseDataInfo<Practice[] | undefined> => {
  const userPractices = useAppSelector(getCurrentUserPractices);
  const currentUser = useAppSelector(getCurrentUser);
  const [queryState, refreshUserPractices] = useRequest(
    currentUser?.id ? userPracticesQuery(currentUser.id, options) : null,
  );
  return [userPractices, queryState, refreshUserPractices];
};
