import { useCallback } from 'react';
import { compose } from 'redux';
import { mutateAsync } from 'redux-query';
import { connectRequest } from 'redux-query-react';
import { connect } from 'react-redux';
import { getUserExperienceQuery } from 'modules/user-experiences';
import { isRequestPending } from 'utils/redux-query';
import { RootState } from 'StoreTypes';
import { AppQueryConfig, QueryResponse, ReduxQueryDispatch } from 'QueryTypes';
import UserExperience from 'constants/UserExperience';

export type WithDismissUserExperienceRenderProps = {
  isExperienceDismissed: boolean;
  isLoading: boolean;
  dismissExperience: () => void;
};

type OwnProps = {
  children?: (props: WithDismissUserExperienceRenderProps) => React.ReactNode;
  userExperience: UserExperience;
};

type StateProps = {
  isExperienceDismissed: boolean;
  isLoading: boolean;
  userId: number;
};

const mapStateToProps = (state: RootState, { userExperience }: OwnProps): StateProps => {
  const isExperienceDismissed = state.entities?.userExperiences?.byId?.[userExperience];
  const userId = state.user?.id;
  return {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
    isExperienceDismissed,
    isLoading: isRequestPending(state, getUserExperienceQuery(userId, userExperience)),
    userId,
  };
};

type DispatchProps = {
  setUserExperience: (userId: number, userExperience: string) => Promise<QueryResponse>;
};

const mapDispatchToProps = (dispatch: ReduxQueryDispatch): DispatchProps => ({
  // @ts-expect-error ts-migrate(2322) FIXME: Type '(userId: number, userExperience: UserExperie... Remove this comment to see the full error message
  setUserExperience: (userId: number, userExperience: UserExperience): Promise<QueryResponse> =>
    dispatch(mutateAsync(getUserExperienceQuery(userId, userExperience))),
});

const mapPropsToQueries = ({ userId, userExperience }: Props): AppQueryConfig =>
  getUserExperienceQuery(userId, userExperience);

type Props = OwnProps & StateProps & DispatchProps;

const WithDismissUserExperience = ({
  children,
  isExperienceDismissed,
  userExperience,
  isLoading,
  userId,
  setUserExperience,
}: Props): React.ReactNode => {
  const handleSetUserExperience = useCallback(
    (): Promise<QueryResponse> => setUserExperience(userId, userExperience),
    [setUserExperience, userExperience, userId],
  );
  return children
    ? children({
        isExperienceDismissed,
        isLoading,
        dismissExperience: handleSetUserExperience,
      })
    : null;
};

export default compose<React.FC<OwnProps>>(
  connect(mapStateToProps, mapDispatchToProps),
  connectRequest(mapPropsToQueries),
)(WithDismissUserExperience);
