import React, { useCallback, useState } from 'react';
import { IconSize, VdsIcon, Dropdown, DropdownItem } from '@el8/vital';
import cn from 'classnames';
import styles from './NavbarItemDropdown.less';
import { NavbarItemButton, NavbarItemButtonProps } from './NavbarItemButton';
import { NavbarItemDropdownItem, NavbarItemDropdownItemData } from './NavbarItemDropdownItem';

export interface NavbarItemDropdownProps extends Omit<NavbarItemButtonProps, 'type'> {
  type: 'dropdown';
  items: NavbarItemDropdownItemData[];
  onSelectItem?: (item: string | DropdownItem<NavbarItemDropdownItemData, {}>) => void;
}

export const NavbarItemDropdown = ({
  children,
  items,
  icon,
  tooltip,
  className,
  onSelectItem,
  ...restProps
}: React.PropsWithChildren<NavbarItemDropdownProps>): JSX.Element => {
  const [open, setOpen] = useState(false);

  const handleSelectItem = useCallback(
    (item: string | DropdownItem<NavbarItemDropdownItemData, {}>): void => {
      if (onSelectItem) onSelectItem(item);

      setOpen(false);
    },
    [onSelectItem],
  );

  return (
    <Dropdown<{}, NavbarItemDropdownItemData>
      className={styles.dropdown}
      onOpen={(): void => setOpen(true)}
      onClose={(): void => setOpen(false)}
      onSelect={handleSelectItem}
      isOpen={open}
      items={items.map((item) => {
        return {
          id: item['data-testid'],
          label: item.text,
          disabled: item.disabled,
          data: item,
        };
      })}
      renderMenuItem={(props): JSX.Element => {
        return <NavbarItemDropdownItem {...props} />;
      }}
      target={
        <NavbarItemButton
          {...restProps}
          className={cn(styles.dropdownButton, className)}
          rightIcon={<VdsIcon icon="menu-closed" size={IconSize.XSmall} className={styles.icon} />}
          icon={icon}
          tooltip={tooltip}
        >
          {children}
        </NavbarItemButton>
      }
    />
  );
};
