import React, { useEffect, useState } from 'react';

import { Dialog, HeaderText } from '@el8/vital';
import { RouteComponentProps } from '@reach/router';

import { Persona } from '@elation/api-client/src/support';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { RootState } from 'StoreTypes';
import { closeSupportDialog, FeedbackType, setSupportDialogUrgency } from 'ui-modules/support';
import { CaseIntakeStep } from 'modules/support/supportConstants';
import { logIntakeExit, logStartSupportIntake } from './amplitudeTaxonomy';
import SupportForm from './CaseIntakeForm';
import { StepConfig } from './CaseIntakeStepConfig';
import styles from './CaseIntakeModal.less';

const CaseIntakeModal = (_props: RouteComponentProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [steps, setSteps] = useState<CaseIntakeStep[]>([CaseIntakeStep.Intake]);
  const supportDialogState = useAppSelector((state: RootState) => state.support?.supportDialog);
  const isOpen =
    (supportDialogState?.isOpen &&
      supportDialogState.feedbackType === FeedbackType.NewFlowFeedbackType) ??
    false;

  useEffect(() => {
    // fire amplitude event when dialog is opened
    if (isOpen) {
      logStartSupportIntake(Persona.ehr_user);
    }
  }, [isOpen]);

  const handleExit = (): void => {
    logIntakeExit();
    // clear history for when we reopen
    setSteps([CaseIntakeStep.Intake]);
    dispatch(setSupportDialogUrgency(false));
    dispatch(closeSupportDialog());
  };

  const { title } = StepConfig[steps[steps.length - 1]];
  return (
    <Dialog
      className={supportDialogState?.isUrgent ? styles.critical : ''}
      isOpen={isOpen}
      onClose={handleExit}
      header={
        <HeaderText tag="h1" data-dd-privacy="allow">
          {title}
        </HeaderText>
      }
      bordered
      width={512}
      bodyClassName={styles.supportDialog}
    >
      <SupportForm steps={steps} setSteps={setSteps} isInModal />
    </Dialog>
  );
};

export default CaseIntakeModal;
