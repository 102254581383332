import get from 'lodash/get';
import omit from 'lodash/omit';
import { mapArrayToObject } from 'utils/arrays';
import { GenericEntity, GenericEntityState, EntitiesById } from 'StoreTypes';
import { OptimisticUpdateStrategy, RollbackStrategy, UpdateStrategy } from 'redux-query';
import { createOptimisticId } from './queryHelpers';

type EntityFindFn<T extends GenericEntity> = (value: T, index: number) => boolean;

/**
 * General updater function for a specific entity type, useful for simple updates,
 * especially with entities normalized by normalizr.
 *
 * Requirements to use this:
 * - The transform function for the query config using the returned updater returns
 *   an object shaped the same way as the output from a normalizr `normalize` call
 *   (i.e. an object where keys are entity names and values are objects mapping entity
 *   IDs to entities)
 * - The entity being updated follows the standard convention of storing entities
 *   under a `byId` key (rather than, say, directly at the top level of the particular
 *   entity type's state)
 */
export function makeUpdateFn<T extends GenericEntity>({ merge = true } = {}) {
  return function update(
    prevEntityState: GenericEntityState<T> = {},
    newEntitiesById: EntitiesById<T> = {},
  ): GenericEntityState<T> {
    const updatedEntitiesById = merge
      ? { ...prevEntityState.byId, ...newEntitiesById }
      : newEntitiesById;
    return {
      ...prevEntityState,
      byId: updatedEntitiesById,
    };
  };
}

/**
 * Creates update, optimistic update, and rollback functions for standard
 * delete queries. This can only be used for entity types that follow the standard
 * convention of storing their entities under a `byId` key.
 *
 * @deprecated - prefer `makeDeleteFnsNew`, which is more typesafe. Once we convert
 * all usages of this function to `makeDeleteFnsNew`, we'll remove this function
 * and rename `makeDeleteFnsNew` back to `makeDeleteFns`.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, require-jsdoc
export function makeDeleteFns<T extends GenericEntity>(entityId: number) {
  const update = (entityState: GenericEntityState<T>): GenericEntityState<T> => {
    if (!get(entityState, ['byId', entityId])) return entityState;

    return {
      ...entityState,
      byId: omit(entityState.byId, entityId),
    };
  };
  const optimisticUpdate = (entityState: GenericEntityState<T>): GenericEntityState<T> => {
    if (!get(entityState, ['byId', entityId])) return entityState;

    return {
      ...entityState,
      byId: omit(entityState.byId, entityId),
    };
  };
  const rollback = (
    initialEntityState: GenericEntityState<T>,
    currentEntityState: GenericEntityState<T>,
  ): GenericEntityState<T> => {
    if (!get(initialEntityState, ['byId', entityId]) || !get(currentEntityState, 'byId')) {
      return currentEntityState;
    }

    return {
      ...currentEntityState,
      byId: {
        ...currentEntityState.byId,
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        [entityId]: initialEntityState.byId[entityId],
      },
    };
  };

  return { update, optimisticUpdate, rollback };
}

interface QueryConfigUpdateMethods<TEntityState extends GenericEntityState<GenericEntity>> {
  update: UpdateStrategy<TEntityState | undefined>;
  optimisticUpdate: OptimisticUpdateStrategy<TEntityState | undefined>;
  rollback: RollbackStrategy<TEntityState | undefined>;
}

/**
 * Creates update, optimistic update, and rollback functions for standard
 * delete queries. This can only be used for entity types that follow the standard
 * convention of storing their entities under a `byId` key.
 */
export function makeDeleteFnsNew<TEntityState extends GenericEntityState<GenericEntity>>(
  entityId: number,
): QueryConfigUpdateMethods<TEntityState> {
  const update = (entityState: TEntityState | undefined): TEntityState | undefined => {
    if (!entityState?.byId?.[entityId]) return entityState;

    return {
      ...entityState,
      byId: omit(entityState.byId, entityId),
    };
  };
  const optimisticUpdate = (entityState: TEntityState | undefined): TEntityState | undefined => {
    if (!entityState?.byId?.[entityId]) return entityState;

    return {
      ...entityState,
      byId: omit(entityState.byId, entityId),
    };
  };
  const rollback = (
    initialEntityState: TEntityState | undefined,
    currentEntityState: TEntityState | undefined,
  ): TEntityState | undefined => {
    if (!initialEntityState?.byId?.[entityId] || !currentEntityState?.byId) {
      return currentEntityState;
    }

    return {
      ...currentEntityState,
      byId: {
        ...currentEntityState.byId,
        [entityId]: initialEntityState.byId[entityId],
      },
    };
  };

  return { update, optimisticUpdate, rollback };
}

/**
 * Creates update, optimistic update, and rollback functions for optimistic create queries
 * using a temporary entity with a client-side generated id, represented by a negative number,
 * which is then replaced by the new entity upon a successful response, or deleted upon a failed response.
 * Note:
 * - You probably do not need an optimistic create. This method should be used sparingly,
 * for situations that lend themselves well to, and benefit from, generating placeholder entities in redux.
 * - This can only be used for entity types that follow the standard
 * convention of storing their entities under a `byId` key.
 * Constraints:
 * - Because the ids will change, from a negative client id to a positive server generated real-id,
 * it is recommended that the entities you're creating have some other stable identifier to use as a react key,
 * otherwise, react will re-render your elements when the temp entity is swapped in and out.
 */
export function makeOptimisticCreateFns<
  TEntityState extends GenericEntityState<GenericEntity>,
  TEntity extends GenericEntity,
>(entity: Omit<TEntity, 'id'>): QueryConfigUpdateMethods<TEntityState> {
  const optimisticId = createOptimisticId();
  return {
    optimisticUpdate: (entityState: TEntityState | undefined): TEntityState | undefined => {
      if (!entityState?.byId) return entityState;
      const optimisticEntity = {
        ...entity,
        id: optimisticId,
      };

      return {
        ...entityState,
        byId: {
          ...entityState?.byId,
          [optimisticId]: optimisticEntity,
        },
      };
    },
    update: (
      prevEntityState: TEntityState | undefined,
      newEntityState: TEntityState | undefined,
    ): TEntityState | undefined => {
      if (!prevEntityState?.byId || !newEntityState?.byId) return newEntityState;

      return {
        ...prevEntityState,
        ...newEntityState,
        byId: {
          ...omit(prevEntityState.byId, optimisticId),
          ...newEntityState?.byId,
        },
      };
    },
    rollback: (
      initialEntityState: TEntityState | undefined,
      currentEntityState: TEntityState | undefined,
    ): TEntityState | undefined => {
      if (!initialEntityState?.byId || !currentEntityState?.byId) {
        return currentEntityState;
      }
      return {
        ...currentEntityState,
        byId: omit(currentEntityState.byId, optimisticId),
      };
    },
  };
}

/**
 * Creates an optimistic updater function for a specific entity type. Should be used
 * together with a rollback function created by `makeRollbackFn`.
 *
 * Requirements to use this:
 * - The entity being updated follows the standard convention of storing entities
 *   under a `byId` key (rather than, say, directly at the top level of the particular
 *   entity type's state)
 *
 * Example usage (in redux-query query config) -
 * optimisticUpdate: {
 *   prescriptions: makeOptimisticUpdateFn(prescriptionId, { description: 'updated description' }),
 *   users: makeOptimisticUpdateFn(user => user.name === 'Jimmy', { age: 5 }),
 * }
 */
export function makeOptimisticUpdateFn<T extends GenericEntity>(
  entityIdOrFindFn: number | EntityFindFn<T>,
  updates: Partial<T>,
) {
  return function updateOptimistically(entityState: GenericEntityState<T>): GenericEntityState<T> {
    let current: T;
    if (typeof entityIdOrFindFn === 'function') {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'T | undefined' is not assignable to type 'T'... Remove this comment to see the full error message
      current = Object.values(entityState.byId).find(entityIdOrFindFn);
    } else {
      // else entityIdOrFindFn is assumed to be an ID
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      current = entityState.byId[entityIdOrFindFn];
    }
    const updated: T = {
      ...current,
      ...updates,
    };
    return {
      ...entityState,
      byId: {
        ...entityState.byId,
        [current.id]: updated,
      },
    };
  };
}

/**
 * Creates a rollback function for a specific entity type. Should be used together
 * with an optimistic update function created by `makeOptimisticUpdateFn`.
 *
 * Requirements to use this:
 * - The entity being updated follows the standard convention of storing entities
 *   under a `byId` key (rather than, say, directly at the top level of the particular
 *   entity type's state)
 *
 * Example usage (in redux-query query config) -
 * rollback: {
 *   prescriptions: makeRollbackFn(prescriptionId, { description: 'updated description' })
 *   users: makeRollbackFn(user => user.name === 'Jimmy', { age: 5 }),
 * }
 */
export function makeRollbackFn<T extends GenericEntity>(
  entityIdOrFindFn: number | EntityFindFn<T>,
  updates: Partial<T>,
) {
  return function rollback(
    initialEntityState: GenericEntityState<T>,
    currentEntityState: GenericEntityState<T>,
  ): GenericEntityState<T> {
    let initial: T;
    let current: T;
    if (typeof entityIdOrFindFn === 'function') {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'T | undefined' is not assignable to type 'T'... Remove this comment to see the full error message
      initial = Object.values(initialEntityState.byId).find(entityIdOrFindFn);
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'T | undefined' is not assignable to type 'T'... Remove this comment to see the full error message
      current = Object.values(currentEntityState.byId).find(entityIdOrFindFn);
    } else {
      // else entityIdOrFindFn is assumed to be an ID
      initial = get(initialEntityState, ['byId', entityIdOrFindFn]);
      current = get(currentEntityState, ['byId', entityIdOrFindFn]);
    }
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'T'.
    const rolledBack: T = Object.keys(updates).reduce(
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      (accum, fieldName: Extract<keyof T, string>): T => {
        accum[fieldName] = initial[fieldName];
        return accum;
      },
      { ...current },
    );

    return {
      ...currentEntityState,
      byId: {
        ...currentEntityState.byId,
        [rolledBack.id]: rolledBack,
      },
    };
  };
}

/**
 * Creates paired optimistic updates and rollback functions. This should be preferred
 * over using `makeOptimisticUpdateFn` and `makeRollbackFn` separately, since optimistic
 * updates and rollbacks are typically symmetrical and will use the same parameters.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, require-jsdoc
export function makeOptimisticFns<T extends GenericEntity>(
  entityIdOrFindFn: number | EntityFindFn<T>,
  updates: Partial<T>,
) {
  return {
    optimisticUpdate: makeOptimisticUpdateFn(entityIdOrFindFn, updates),
    rollback: makeRollbackFn(entityIdOrFindFn, updates),
  };
}

/**
 * Make optimistic update and rollback functions for a sorting query.
 *
 * @param entityIds - the IDs of the entities to sort, in their new sorted
 * order
 * @param rankKey - the name of the key/field on the entity that indicates
 * the sort order of the entity, e.g. "rank" or "sequence"
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, require-jsdoc
export function makeSortingOptimisticFns<T extends GenericEntity>(
  entityIds: number[],
  rankKey: keyof T,
) {
  const optimisticUpdate = (entityState: GenericEntityState<T>): GenericEntityState<T> => {
    const updatedEntities = entityIds.map(
      (id, index): T => ({
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        ...entityState.byId[id],
        [rankKey]: index,
      }),
    );
    return {
      ...entityState,
      byId: {
        ...entityState.byId,
        ...mapArrayToObject(updatedEntities, 'id'),
      },
    };
  };

  const rollback = (
    initialEntityState: GenericEntityState<T>,
    currentEntityState: GenericEntityState<T>,
  ): GenericEntityState<T> => {
    const rolledBackEntities = entityIds
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      .filter((id: number): boolean => Boolean(currentEntityState.byId[id]))
      .map((id: number): T => {
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        const current = currentEntityState.byId[id];
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        const initial: T & { [rankKey: string]: number } = initialEntityState.byId[id];
        return {
          ...current,
          [rankKey]: initial[rankKey],
        };
      });
    return {
      ...currentEntityState,
      byId: {
        ...currentEntityState.byId,
        ...mapArrayToObject(rolledBackEntities, 'id'),
      },
    };
  };

  return {
    optimisticUpdate,
    rollback,
  };
}
