// for Typescript files, see AppointmentTimeSlotType.ts instead.
export default {
  FILLED_SLOT: 'FILLED_SLOT',
  OPEN_SLOT: 'OPEN_SLOT',
  APPOINTMENT: 'APPOINTMENT',
  EXTERNAL: 'EXTERNAL',
  REDACTED: 'REDACTED',
  OTHER_EVENT: 'OTHER_EVENT',
  UNKNOWN: 'UNKNOWN',
};
